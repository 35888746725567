import DateFnsUtils from "@date-io/date-fns";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";

type Props = {
  dateType: "start" | "end";
  startDateValue: Date | null;
  endDateValue: Date | null;
  handleChange: (value: Date | null) => void;
};

export default function BillingDateTimePicker({ dateType, handleChange, startDateValue, endDateValue }: Props) {
  const { t } = useTranslation();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DateTimePicker
        value={dateType === "start" ? startDateValue : endDateValue}
        onChange={(e) => handleChange(e)}
        label={dateType === "start" ? t("billing_page.start_date") : t("billing_page.end_date")}
        format="dd/MM/yy HH:mm:ss"
        minDate={dateType === "end" ? (startDateValue === null ? undefined : startDateValue) : undefined}
        variant="dialog"
        disableFuture={true}
        ampm={false}
        required
        data-testid={dateType === "start" ? "billing-start-date-picker" : "billing-end-date-picker"}
      />
    </MuiPickersUtilsProvider>
  );
}
