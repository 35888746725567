import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Switch,
  Select,
  FormControl,
  MenuItem,
} from "@material-ui/core";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import CustomSelect, { MenuItemsType } from "../../components/forms/formComponents/CustomSelect";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CustomDateTimePicker from "../../components/forms/formComponents/CustomDateTimePicker";
import { SubscriptionKind, SUBSCRIPTION_KINDS, SUBSCRIPTION_FEATURE_KINDS } from "@unissey/common";
import { useScopedTranslation } from "../../i18n";
import { UpdateSubscriptionForm } from "../../stores/forms";
import { observer } from "mobx-react-lite";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from "@material-ui/icons/Add";
import TitledSection from "../../components/TitledSection";

type Props = { form: UpdateSubscriptionForm };

const BUCKET_TYPES = {
  paid: 0,
  fallback: 1,
};

function UpdateSubscriptionFormView(props: Props) {
  const { form } = props;

  const { t, tCommon } = useScopedTranslation("subscription_page.form");

  const subscriptionMenuItems: MenuItemsType = SUBSCRIPTION_KINDS.map((kind) => [kind, tCommon(`sub_kind.${kind}`)]);

  const featureKindMenuItems: MenuItemsType = SUBSCRIPTION_FEATURE_KINDS.map((featureKind) => [
    featureKind,
    tCommon(`feature_kind.${featureKind}`),
  ]);

  return (
    <FormGroup>
      <Box py={2} mb={1}>
        <Grid container spacing={3}>
          <CustomTextField
            name="subscriptionName"
            width={12}
            onChange={(e) => form.setField("name", e.target.value)}
            value={form.data.name ?? ""}
            label={t("field_subscription_name")}
            autoFocus
            onBlur={(e) => form.setField("name", e.target.value.trim())}
            data-testid="subscription-name"
          />
          <CustomSelect
            width={6}
            labelId="subscription-form-select-kind"
            labelValue={t("field_subscription_type")}
            menuItems={subscriptionMenuItems}
            onChange={(e) => {
              const subKind = e.target.value as SubscriptionKind;
              form.setField("kind", subKind);
            }}
            value={form.data.kind}
            disabled={true}
          />

          {!form.isBucketSubscription ? (
            <CustomTextField
              name="subscriptionMaxSessionCount"
              type="number"
              required={form.isBucketSubscription}
              width={6}
              onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);
                form.setField("maxSessionCount", isNaN(parsed) ? undefined : parsed);
              }}
              value={form.data.maxSessionCount ?? ""}
              label={t("field_max_sessions")}
              data-testid="subscription-max-session"
            />
          ) : (
            <CustomTextField
              name="subscriptionFallBackBucketSize"
              type="number"
              required={form.isBucketSubscription}
              width={6}
              onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);
                form.setField("fallbackBucketSize", isNaN(parsed) ? undefined : parsed);
              }}
              value={form.data.fallbackBucketSize ?? ""}
              label={t("field_fallback_bucket_size")}
            />
          )}

          <CustomSelect
            width={3}
            labelId="subscription-form-select-feature-kind"
            labelValue={t("field_solution")}
            menuItems={featureKindMenuItems}
            disabled={true}
            value={form.data.featureKind}
            data-testid="subscription-kind"
          />

          <Box width="100%" m={2}>
            <TitledSection
              title={t("title_authorized_verifications")}
              child={
                <Grid item>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={form.data.canUseLiveness}
                        value={form.data.canUseLiveness}
                        onChange={(e) => {
                          const canUseLiveness = e.target.checked;
                          form.setField("canUseLiveness", canUseLiveness);
                        }}
                        data-testid="subscription-liveness"
                      />
                    }
                    label={t("field_can_use_liveness")}
                  />

                  {form.isOneToOneSubscription ? (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={form.data.canUseFaceComparison}
                            value={form.data.canUseFaceComparison}
                            onChange={(e) => {
                              const canUseFaceComparison = e.target.checked;
                              form.setField("canUseFaceComparison", canUseFaceComparison);
                            }}
                            data-testid="subscription-face-comparison"
                          />
                        }
                        label={t("field_can_use_face_comparison")}
                      />

                      <FormControlLabel
                        control={
                          <Switch
                            checked={form.data.canUseAge}
                            value={form.data.canUseAge}
                            onChange={(e) => {
                              const canUseAge = e.target.checked;
                              form.setField("canUseAge", canUseAge);
                            }}
                            data-testid="subscription-age"
                          />
                        }
                        label={t("field_can_use_age")}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <FormControlLabel
                    control={
                      <Switch
                        checked={form.data.canUseIad}
                        value={form.data.canUseIad}
                        onChange={(e) => {
                          const canUseIad = e.target.checked;
                          form.setField("canUseIad", canUseIad);
                        }}
                        data-testid="subscription-iad"
                      />
                    }
                    label={t("field_can_use_iad")}
                  />
                </Grid>
              }
            />
          </Box>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CustomDateTimePicker
              value={form.data.startDate ?? null}
              onChange={(date) => {
                if (date) {
                  form.setField("startDate", date);
                }
              }}
              label={t("field_start_date")}
              maxDate={form.data.endDate}
              width={6}
              fullWidth
            />

            <CustomDateTimePicker
              value={form.data.endDate ?? null}
              onChange={(date) => form.setField("endDate", date ?? undefined)}
              label={t("field_end_date")}
              minDate={form.data.startDate}
              width={6}
              fullWidth
            />
          </MuiPickersUtilsProvider>
        </Grid>

        {form.canActivateAutoRenewal && (
          <Box width="100%" display="flex" mx="12px" justifyContent="space-between" alignItems="center">
            <Box width="53%">
              <FormControlLabel
                name="hasAutorenewal"
                control={
                  <Switch
                    checked={form.data.hasAutorenewal ?? false}
                    value={form.data.hasAutorenewal ?? false}
                    onChange={(e) => {
                      form.setField("hasAutorenewal", e.target.checked);
                    }}
                  />
                }
                label={`${t("field_auto_renewal")}`}
              />
            </Box>

            <CustomSelect
              width={6}
              labelId="renewal-length"
              labelValue={t("field_renewal_length")}
              menuItems={[
                ["1_years", t("one_year")],
                ["6_months", t("six_months")],
              ]}
              onChange={(e) => props.form.setRenewalLength(e.target.value as "1_years" | "6_months")}
              value={`${form.data.autoRenewalLength}_${form.data.autoRenewalUnit}`}
              data-testid="subscription-renewal-length"
            />
          </Box>
        )}

        <Box display="flex" flexDirection="column" mt="10px">
          {form.isBucketSubscription && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={form.data.fallbackBucketAutoCreated ?? true}
                    onChange={(e) => form.setField("fallbackBucketAutoCreated", e.target.checked)}
                    name="fallbackBucketAutoCreated"
                    color="primary"
                  />
                }
                label={`${t("field_fallback_bucket_auto_created")}`}
              />

              <br />

              <Typography variant="subtitle1">{t("bucket_edit")}</Typography>
              <table>
                <tbody>
                  {form.data?.buckets.map((b, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Typography style={b.status === "consumed" ? { fontStyle: "italic", color: "#aaa" } : {}}>
                            {t("bucket")} {i + 1}
                          </Typography>
                        </td>
                        <td>
                          <Typography style={b.status === "consumed" ? { fontStyle: "italic", color: "#aaa" } : {}}>
                            {
                              {
                                in_progress: `${tCommon("subscription_page.bucket_status.in_progress")}`,
                                not_activated: `${tCommon("subscription_page.bucket_status.not_activated")}`,
                                consumed: `${tCommon("subscription_page.bucket_status.consumed")}`,
                              }[b.status]
                            }
                          </Typography>
                        </td>
                        <td>
                          <Typography style={b.status === "consumed" ? { fontStyle: "italic", color: "#aaa" } : {}}>
                            {b?.sessionCount || 0}
                          </Typography>

                          <Box mx="20px" />
                        </td>
                        <td>
                          {b.status !== "consumed" && (
                            <CustomTextField
                              name="size"
                              type="number"
                              required={true}
                              width={2}
                              size="small"
                              defaultValue={b.size}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value, 10);
                                form.updateBucketSize(i, newValue);
                              }}
                            />
                          )}
                        </td>
                        <td>
                          {b.status !== "consumed" && (
                            <FormControl variant="outlined">
                              <Select
                                labelId="select-role-label"
                                label=""
                                value={b.autoGenerated ? BUCKET_TYPES.fallback : BUCKET_TYPES.paid}
                                MenuProps={{
                                  variant: "menu",
                                  getContentAnchorEl: null,
                                }}
                                onChange={() => {
                                  form.updateBucketType(i);
                                }}
                                IconComponent={ArrowDropDownIcon}
                                data-testid="user-role"
                              >
                                <MenuItem dense value={BUCKET_TYPES.paid}>
                                  {tCommon("subscription_page.details_modal.bucket_paid")}
                                </MenuItem>
                                <MenuItem dense value={BUCKET_TYPES.fallback}>
                                  {tCommon("subscription_page.details_modal.bucket_fallback")}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          )}
                        </td>
                        <td>
                          {b.id && b.action === "delete" && (
                            <Button
                              variant="text"
                              size="small"
                              color="secondary"
                              onClick={() => form.revertBucketDeletion(i)}
                            >
                              <CancelIcon />
                              {t("cancel_bucket_deletion_button")}
                            </Button>
                          )}

                          {b.status === "not_activated" && b.action !== "delete" && form.hadManyValidBuckets && (
                            <Button variant="text" size="small" color="primary" onClick={() => form.deleteBucket(i)}>
                              <DeleteIcon />
                              {t("delete_bucket_button")}
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <Box mt="20px" />

              <Button
                variant="outlined"
                color="primary"
                size="small"
                fullWidth={false}
                onClick={form.addBucket}
                style={{ maxWidth: "200px" }}
                data-testid="subscription-add-bucket-btn"
              >
                <AddIcon /> {t("add_bucket_button")}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </FormGroup>
  );
}

export default observer(UpdateSubscriptionFormView);
