import { config } from "../constants/env";
import axios from "axios";
import { AxiosResponse } from "axios";
import { keycloak } from "../auth";
import { AnalyzeDemoRequest, AnalyzeParams, AnalyzeResponse, VqcHint } from "@unissey/common";

export async function analyze(params: AnalyzeParams, userId?: string): Promise<AnalyzeResponse> {
  const body: AnalyzeDemoRequest = {
    ...params,
    demoEnvironment: "web",
  };

  let res: AxiosResponse<AnalyzeResponse, any>;

  // If a user id is defined, i.e. if it's a temporary demo
  if (userId) {
    res = await axios.postForm<AnalyzeResponse>(
      `${config.apiAdminUrl}/analyze/temporary/demo`,
      { ...body, userId },
      {}
    );
  } else {
    res = await axios.postForm<AnalyzeResponse>(`${config.apiAdminUrl}/analyze/demo`, body, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
  }

  return res.data;
}

export async function prepareIad(userId?: string): Promise<string> {
  const body = {
    demoEnvironment: "web",
  };

  let res: AxiosResponse<string, any>;

  // If a user id is defined, i.e. if it's a temporary demo
  if (userId) {
    res = await axios.post<string>(`${config.apiAdminUrl}/analyze/temporary/demo/iad/prepare`, { ...body, userId }, {});
  } else {
    res = await axios.post<string>(`${config.apiAdminUrl}/analyze/demo/iad/prepare`, body, {
      headers: {
        Authorization: `Bearer ${keycloak.token}`,
      },
    });
  }

  return res.data;
}

type Hint = "generic" | "mask" | "brightness" | "mask-brightness";

export function getVqcHintsType(apiResponse: AnalyzeResponse): Hint {
  const selfieHints = apiResponse.data.details.hints_for_retry?.selfie ?? [];

  const hasMaskHint = selfieHints.includes(VqcHint.CovidMask);
  const hasLuminosityHint = selfieHints.includes(VqcHint.Brightness) || selfieHints.includes(VqcHint.Darkness);

  if (hasMaskHint && hasLuminosityHint) return "mask-brightness";

  if (hasLuminosityHint) return "brightness";

  if (hasMaskHint) return "mask";

  return "generic";
}

export function hasError(apiResponse: AnalyzeResponse) {
  return ![200, 206].includes(apiResponse.status);
}
