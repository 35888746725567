import React, { useMemo, useState } from "react";
import { Button, capitalize } from "@material-ui/core";
import { useAuth } from "../../auth";
import CreateSubscriptionModal from "./CreateSubscriptionModal";
import { useScopedTranslation } from "../../i18n";
import { useQuery } from "react-query";
import { Subscription, UpdateSubscription, isSubscriptionActive } from "@unissey/common";
import { getSubscriptions } from "../../services/api_services";
import { useHistory } from "react-router-dom";
import DataTable, { DataTableColumn } from "../../components/DataTable";
import OverflowTooltip from "../../components/OverflowTooltip";
import { Routes } from "../../constants/routes";
import TitledSection from "../../components/TitledSection";
import EditSubscriptionModal from "./EditSubscriptionModal";

type SubscriptionDisplay = {
  name: string;
  workspace: string;
  kind: string;
  featureKind: string;
  canUseLiveness: string;
  canUseFaceComparison: string;
  canUseAge: string;
  canUseIad: string;
  startDate: Date;
  endDate: Date | string;
  status: string;
  maxSessions: number | "N/A";
  sessionsCount: number;
  editSubscription?: React.ReactNode;
};

export default function SubscriptionsPage() {
  const auth = useAuth();
  const { t, tCommon } = useScopedTranslation("subscription_page");

  const { isLoading, data } = useQuery<Subscription[]>("subscriptions", () => getSubscriptions());

  const [subToEdit, setSubToEdit] = useState<UpdateSubscription>();
  const [creationModalIsOpen, setCreationModalIsOpen] = useState<boolean>(false);
  const [editionModalIsOpen, setEditionModalIsOpen] = useState<boolean>(false);

  const history = useHistory();

  const canEditSubscription = auth.hasRoles(["subscription.edit"]);

  const compareSubsFn = (a: Subscription, b: Subscription) => {
    let tmp = a.workspaceName.localeCompare(b.workspaceName);
    if (tmp !== 0) return tmp;
    tmp = a.kind.localeCompare(b.kind);
    if (tmp !== 0) return tmp;
    return a.name.localeCompare(b.name);
  };

  const subscriptions = data?.sort(compareSubsFn);

  const maxSessions = useMemo(() => {
    if (subscriptions) return Math.max(...subscriptions.map((sub) => sub.maxSessionCount ?? 0));
    return 0;
  }, [subscriptions]);

  const maxSessionsCount = useMemo(() => {
    if (subscriptions) return Math.max(...subscriptions.map((sub) => sub.sessionCount));
    return 0;
  }, [subscriptions]);

  const sessionCountMarks = (max: number) => {
    if (max === 0) return [0];
    return [0, 0.25 * max, 0.5 * max, 0.75 * max, max];
  };

  const columns: DataTableColumn<SubscriptionDisplay>[] = [
    {
      dataKey: "name",
      title: t(`table.header_name`),
      sorter: (a, b) => (a.name < b.name ? -1 : 1),
      filter: {
        label: t(`table.header_name`),
        widget: "select",
        lookup: "contains",
        values: subscriptions?.map((sub) => capitalize(sub.name)),
      },
    },
    {
      dataKey: "workspace",
      title: t(`table.header_workspace`),
      sorter: (a, b) => (a.workspace < b.workspace ? -1 : 1),
      filter: {
        label: t(`table.header_workspace`),
        widget: "select",
        lookup: "contains",
        values: Array.from(
          new Set(subscriptions?.map((sub) => sub.workspaceName)) // to remove duplicates
        ),
      },
    },
    {
      dataKey: "kind",
      title: t(`table.header_type`),
      sorter: (a, b) => (a.kind < b.kind ? -1 : 1),
      filter: {
        label: t(`table.header_type`),
        lookup: "contains",
        widget: "select",
        values: Array.from(new Set(subscriptions?.map((sub) => tCommon(`sub_kind.${sub.kind}`)))),
      },
    },
    {
      dataKey: "featureKind",
      title: t(`table.header_solution`),
      sorter: (a, b) => (a.featureKind < b.featureKind ? -1 : 1),
      filter: {
        label: t(`table.header_solution`),
        lookup: "contains",
        widget: "select",
        values: Array.from(new Set(subscriptions?.map((sub) => tCommon(`feature_kind.${sub.featureKind}`)))),
      },
    },
    {
      dataKey: "status",
      title: t(`table.header_status`),
      sorter: (a, b) => (a.status < b.status ? -1 : 1),
      filter: {
        label: t(`table.header_status`),
        widget: "select",
        lookup: "contains",
        values: ["Active", "Inactive"],
      },
    },
    {
      dataKey: "canUseLiveness",
      title: t(`table.header_liveness`),
      sorter: (a, b) => (b.canUseLiveness > a.canUseLiveness ? 1 : -1),
      filter: {
        label: t(`table.header_liveness`),
        widget: "select",
        lookup: "contains",
        values: Array.from(new Set(subscriptions?.map((sub) => tCommon(`can_use_feature.${sub.canUseLiveness}`)))),
      },
    },
    {
      dataKey: "canUseFaceComparison",
      title: t(`table.header_face_comparison`),
      sorter: (a, b) => (b.canUseFaceComparison > a.canUseFaceComparison ? 1 : -1),
      filter: {
        label: t(`table.header_face_comparison`),
        widget: "select",
        lookup: "contains",
        values: Array.from(
          new Set(subscriptions?.map((sub) => tCommon(`can_use_feature.${sub.canUseFaceComparison}`)))
        ),
      },
    },
    {
      dataKey: "canUseAge",
      title: t(`table.header_age`),
      sorter: (a, b) => (b.canUseAge > a.canUseAge ? 1 : -1),
      filter: {
        label: t(`table.header_age`),
        widget: "select",
        lookup: "contains",
        values: Array.from(new Set(subscriptions?.map((sub) => tCommon(`can_use_feature.${sub.canUseAge}`)))),
      },
    },
    {
      dataKey: "canUseIad",
      title: t(`table.header_iad`),
      sorter: (a, b) => (b.canUseIad > a.canUseIad ? 1 : -1),
      filter: {
        label: t(`table.header_iad`),
        widget: "select",
        lookup: "contains",
        values: Array.from(new Set(subscriptions?.map((sub) => tCommon(`can_use_feature.${sub.canUseIad}`)))),
      },
    },
    {
      dataKey: "startDate",
      title: t(`table.header_start_date`),
      sorter: (a, b) => (a.startDate < b.startDate ? -1 : 1),
      filter: {
        label: t(`table.header_start_date`),
        widget: "date",
        lookup: "interval",
      },
      render: (key: string, value: Date): JSX.Element => (
        <OverflowTooltip key={key} text={`${new Date(value).toLocaleDateString()}`} maxWidth={300} />
      ),
    },
    {
      dataKey: "endDate",
      title: t(`table.header_end_date`),
      sorter: (a, b) => {
        if (typeof a.endDate === "string") return 1;
        if (typeof b.endDate === "string") return -1;

        return a.endDate < b.endDate ? -1 : 1;
      },
      filter: {
        label: t(`table.header_end_date`),
        widget: "date",
        lookup: "interval",
      },
      render: (key: string, value: Date | string): JSX.Element => (
        <OverflowTooltip
          key={key}
          text={`${value === "N/A" ? value : new Date(value).toLocaleDateString()}`}
          maxWidth={300}
        />
      ),
    },
    {
      dataKey: "maxSessions",
      title: t(`table.header_max_sessions`),
      filter: {
        label: t(`table.header_max_sessions`),
        widget: "slider",
        lookup: "interval",
        values: [0, maxSessions],
        marks: sessionCountMarks(maxSessions),
      },
      sorter: (a, b) =>
        (a.maxSessions === "N/A" ? -1 : a.maxSessions) < (b.maxSessions === "N/A" ? -1 : b.maxSessions) ? -1 : 1,
    },
    {
      dataKey: "sessionsCount",
      title: t(`table.header_session_count`),
      filter: {
        label: t(`table.header_session_count`),
        widget: "slider",
        lookup: "interval",
        values: [0, maxSessionsCount],
        marks: maxSessionsCount > 0 ? sessionCountMarks(maxSessionsCount) : undefined,
      },
      sorter: (a, b) => (a.sessionsCount < b.sessionsCount ? -1 : 1),
    },
  ];

  if (canEditSubscription) columns.push({ dataKey: "editSubscription", title: "" });

  const displaySubscriptions = (): SubscriptionDisplay[] => {
    return (
      subscriptions?.map((sub) => {
        const startDate = new Date(sub.startDate);
        const endDate = sub.endDate ? new Date(sub.endDate) : undefined;
        const isActive = isSubscriptionActive({ ...sub, endDate, startDate });

        return {
          name: capitalize(sub.name),
          workspace: sub.workspaceName,
          kind: tCommon(`sub_kind.${sub.kind}`),
          featureKind: tCommon(`feature_kind.${sub.featureKind}`),
          status: isActive ? "Active" : "Inactive",
          canUseLiveness: tCommon(`can_use_feature.${sub.canUseLiveness}`),
          canUseFaceComparison: tCommon(`can_use_feature.${sub.canUseFaceComparison}`),
          canUseAge: tCommon(`can_use_feature.${sub.canUseAge}`),
          canUseIad: tCommon(`can_use_feature.${sub.canUseIad}`),
          startDate,
          endDate: endDate ?? "N/A",
          maxSessions: sub.maxSessionCount ?? "N/A",
          sessionsCount: sub.sessionCount,
          editSubscription: canEditSubscription ? (
            <Button
              data-testid={`edit-btn-${sub.name}`}
              onClick={(e) => {
                e.stopPropagation();
                setEditionModalIsOpen(true);
                setSubToEdit({
                  ...sub,
                  buckets: sub.buckets.map((bucket) => ({
                    id: bucket.id,
                    size: bucket.size,
                    sessionCount: bucket.sessionCount,
                    status: bucket.status,
                    action: "update",
                    autoGenerated: bucket.autoGenerated,
                  })),
                });
              }}
              variant="outlined"
            >
              {tCommon("button_edit")}
            </Button>
          ) : (
            <></>
          ),
        };
      }) || []
    );
  };

  function CreateSubscriptionButton() {
    if (!canEditSubscription) return null;
    return (
      <Button variant="outlined" onClick={() => setCreationModalIsOpen(true)} data-testid="create-subscription-btn">
        {t("button_create")}
      </Button>
    );
  }

  function onSubscriptionClick(index: number) {
    if (!subscriptions) return;
    // setSelectedSubscriptionId(subscriptions[index].id);
    history.push(`${Routes.SUBSCRIPTIONS}/${subscriptions[index].id}`);
  }

  return (
    <>
      <TitledSection
        child={
          <DataTable
            columns={columns}
            dataSource={displaySubscriptions()}
            onRowClick={onSubscriptionClick}
            lastColAlignRight={canEditSubscription}
            isLoading={isLoading}
          />
        }
        action={<CreateSubscriptionButton />}
      />

      {canEditSubscription ? (
        <CreateSubscriptionModal open={creationModalIsOpen} onClose={() => setCreationModalIsOpen(false)} />
      ) : null}

      {canEditSubscription && subToEdit ? (
        <EditSubscriptionModal
          subscription={subToEdit}
          open={editionModalIsOpen}
          onClose={() => {
            setSubToEdit(undefined);
            setEditionModalIsOpen(false);
          }}
        />
      ) : null}
    </>
  );
}
