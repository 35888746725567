import { action, computed, makeObservable, observable } from "mobx";

import { SubscriptionFields, SUBSCRIPTION_KINDS, UpdateSubscription } from "@unissey/common";

export class UpdateSubscriptionForm {
  constructor(public data: UpdateSubscription) {
    makeObservable(this, {
      data: observable,

      setField: action.bound,

      addBucket: action.bound,

      updateBucketSize: action.bound,

      deleteBucket: action.bound,

      revertBucketDeletion: action.bound,

      isValid: computed,

      isBucketSubscription: computed,

      isOneToOneSubscription: computed,
    });
  }

  setField<K extends keyof SubscriptionFields>(field: K, value: SubscriptionFields[K]) {
    (this.data as SubscriptionFields)[field] = value;
  }

  get canActivateAutoRenewal() {
    return this.data.kind === "per_packet" || this.data.kind === "pay_as_you_go";
  }

  addBucket() {
    this.data = {
      ...this.data,
      buckets: [...this.data.buckets, { size: 100000, status: "not_activated", action: "add" }],
    };
  }

  get hadManyValidBuckets() {
    let validBucketCount = 0;

    this.data.buckets.forEach((b) => {
      if (b.action !== "delete") validBucketCount += 1;
    });

    return validBucketCount > 1;
  }

  setRenewalLength(length: "1_years" | "6_months") {
    if (length === "1_years") {
      this.data.autoRenewalLength = 1;
      this.data.autoRenewalUnit = "years";
    } else {
      this.data.autoRenewalLength = 6;
      this.data.autoRenewalUnit = "months";
    }
  }

  updateBucketSize(index: number, newValue: number) {
    let buckets = [...this.data.buckets];
    const sessionCount = buckets[index].sessionCount ?? 0;

    if (newValue > sessionCount) {
      buckets[index].size = newValue;
    }
  }

  deleteBucket(index: number) {
    let buckets = [...this.data.buckets];

    // bucket had been fetched from api call
    if (buckets[index].id) {
      // flag existing bucket as bucket to delete
      buckets[index].action = "delete";
    } else {
      buckets.splice(index, 1);
    }

    this.data = {
      ...this.data,
      buckets,
    };
  }

  revertBucketDeletion(index: number) {
    let buckets = [...this.data.buckets];
    buckets[index].action = "update";

    this.data = {
      ...this.data,
      buckets,
    };
  }

  updateBucketType(index: number) {
    let buckets = [...this.data.buckets];
    buckets[index].autoGenerated = !buckets[index].autoGenerated;
  }

  get isBucketSubscription() {
    return this.data.kind === "per_packet";
  }

  get isOneToOneSubscription() {
    return this.data.featureKind === "one_one";
  }

  get isValid() {
    const nonEmptyName = this.data.name.length > 0;
    const validKind = SUBSCRIPTION_KINDS.includes(this.data.kind);
    const validDateRange =
      this.data.endDate === undefined ? true : new Date(this.data.startDate) < new Date(this.data.endDate);
    const validLimit = this.data.maxSessionCount === undefined ? true : this.data.maxSessionCount >= 0;

    return nonEmptyName && validKind && validDateRange && validLimit;
  }
}
