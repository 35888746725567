import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import DataTable from "../../components/DataTable";
import { useScopedTranslation } from "../../i18n";
import { SubscriptionByDateRange } from "@unissey/common";
import { BillingDisplay } from "./types/general";
import { getColumns } from "./utils/subscriptions";

export type Props = {
  subscriptions: SubscriptionByDateRange[] | undefined;
  isLoading: boolean;
};

export default function BillingDataTable({ subscriptions, isLoading }: Props) {
  const { t } = useTranslation();
  const { tCommon } = useScopedTranslation("subscription_page");

  const displayBilling = (subscriptions: SubscriptionByDateRange[] | undefined): BillingDisplay[] => {
    if (!subscriptions) return [];
    return subscriptions?.map((sub) => {
      return {
        workspace: sub.workspaceName,
        subscription: sub.subscriptionName,
        subscriptionType: tCommon(`sub_kind.${sub.subscriptionKind}`),
        livenessOnly: sub.livenessOnly,
        faceCompOnly: sub.faceCompOnly,
        livenessAndFaceComp: sub.livenessAndFaceComp,
        totalSessions: sub.totalSessions,
      };
    });
  };

  return (
    <Box>
      <DataTable
        columns={getColumns(subscriptions, t)}
        dataSource={displayBilling(subscriptions)}
        isLoading={isLoading}
        noDataMsg={t("no_session_data")}
      />
    </Box>
  );
}
