import { Box, Button, IconButton, Typography } from "@material-ui/core";
import DataTable, { DataTableColumn } from "../../components/DataTable";
import { getIdentities, getIdentity } from "../../services/api_services";
import { dateFormat } from "../../utils/parsing";
import OverflowTooltip from "../../components/OverflowTooltip";
import { useScopedTranslation } from "../../i18n";
import { Identity, IdentityListFilters } from "@unissey/common";
import OkIcon from "../../components/OkIcon";
import StopIcon from "../../components/StopIcon";
import { useEffect, useState } from "react";
import { IdentityFilters } from "./IdentitiesFilter";

import PhotoIcon from "@material-ui/icons/Photo";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { Modal } from "../../components/Modal";
import { useAuth } from "../../auth";
import { useQuery } from "react-query";

type IdentityToDisplay = {
  createdAt: Date;
  embeddingId?: number;
  workspaceName: string;
  registryName: string;
  lastEnrollmentDate: Date;
  gdprConsent: boolean;
};

export function IdentitiesPage() {
  const { t } = useScopedTranslation("identities_page");

  const auth = useAuth();

  const initialFilters: IdentityListFilters = {
    order: "desc",
    nbItems: 100,
  };

  const [idModalsOpened, setIdModalOpened] = useState(false);
  const [selectedIdentityId, setSelectedIdentityId] = useState("");

  const openIdModal = (id: string) => {
    setIdModalOpened(true);
    setSelectedIdentityId(id);
  };

  const closeIdModal = () => {
    setIdModalOpened(false);
    setSelectedIdentityId("");
  };

  const [mediaModalOpened, setMediaModalOpened] = useState(false);

  const openMediaModal = (id: string) => {
    setSelectedIdentityId(id);
    setMediaModalOpened(true);
  };

  const closeMediaModal = () => {
    setMediaModalOpened(false);
    setSelectedIdentityId("");
  };

  const [filters, setFilters] = useState(initialFilters);

  const [cursor, setCursor] = useState(new Date());

  const [identities, setIdentities] = useState<Identity[]>([]);

  const hasIdentities = () => identities.length > 0;

  const getIds = async () => {
    const initialCursor = filters.order === "desc" ? new Date() : new Date("1970-01-01");
    return getIdentities(initialCursor, filters).then((ids) => {
      setIdentities(ids);
      const nbIds = ids.length;
      if (nbIds > 0) {
        setCursor(new Date(ids[nbIds - 1].createdAt));
      }
    });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  // This effect is trigger only during the first rendering to fetch initial data
  // getIds changes at every render and should not be used on dependency array
  useEffect(() => {
    getIds();
  }, []);

  const handleLoadMoreData = async () => {
    const nextIdentities = await getIdentities(cursor, filters);
    if (nextIdentities.length > 0) {
      const newIdentityList = identities.concat(nextIdentities);
      const nbIdentities = newIdentityList.length;
      setIdentities(newIdentityList);
      setCursor(new Date(newIdentityList[nbIdentities - 1].createdAt));
    }
  };

  function ascSorter<T>(a: T, b: T) {
    if (a < b) return -1;
    return 1;
  }

  const columns: DataTableColumn<IdentityToDisplay>[] = [
    {
      dataKey: "createdAt",
      title: t("table.column_title.date"),
      sorter: (a, b) => ascSorter(a.createdAt, b.createdAt),
      render: (key: string, value: Date): JSX.Element => <OverflowTooltip key={key} text={dateFormat(value)} />,
    },
    {
      dataKey: "workspaceName",
      title: t("table.column_title.workspace"),
      sorter: (a, b) => ascSorter(a.workspaceName, b.workspaceName),
    },
    {
      dataKey: "registryName",
      title: t("table.column_title.registry"),
      sorter: (a, b) => ascSorter(a.registryName, b.registryName),
    },
    {
      dataKey: "lastEnrollmentDate",
      title: t("table.column_title.last_enrollment_date"),
      sorter: (a, b) => ascSorter(a.lastEnrollmentDate, b.lastEnrollmentDate),
      render: (key: string, value: Date): JSX.Element => <OverflowTooltip key={key} text={dateFormat(value)} />,
    },
    {
      dataKey: "gdprConsent",
      title: t("table.column_title.gdpr_consent"),
      sorter: (a, b) => ascSorter(a.gdprConsent, b.gdprConsent),
      render: (key: string, value: boolean): JSX.Element => (value ? <OkIcon key={key} /> : <StopIcon key={key} />),
    },
  ];

  if (auth.isInternalUser()) {
    columns.push({
      dataKey: "embeddingId",
      title: t("table.column_title.embedding_id"),
      sorter: (a, b) => ascSorter(a.embeddingId, b.embeddingId),
      render: (key: string, value: number): JSX.Element => <OverflowTooltip key={key} text={`${value}`} />,
    });
  }

  const dataSource: IdentityToDisplay[] =
    identities?.map((identity) => ({
      createdAt: new Date(identity.createdAt),
      workspaceName: identity.workspace.name,
      registryName: identity.registry.name,
      lastEnrollmentDate: identity.lastEnrollmentDate,
      gdprConsent: identity.gdprConsent,

      // Embedding Id is displayed only to internal users
      embeddingId: auth.isInternalUser() ? identity.embeddingId : undefined,
      actions: (
        <>
          <IconButton title={t("id_button_title")} onClick={() => openIdModal(identity.id)}>
            <PermIdentityIcon color="primary" />
          </IconButton>
          <IconButton title={t("media_button_title")} onClick={() => openMediaModal(identity.id)}>
            <PhotoIcon color="primary" />
          </IconButton>
        </>
      ),
    })) ?? [];

  const handleReset = () => {
    setFilters(initialFilters);
    getIds();
  };

  const handleFilter = () => {
    getIds();
  };

  return (
    <div>
      <Box m={3} />
      <IdentityFilters filters={filters} setFilters={setFilters} onFilter={handleFilter} onReset={handleReset} />
      <Box m={3} />
      <DataTable columns={columns} dataSource={dataSource} isLoading={false} />
      <Box m={3} />
      {hasIdentities() ? (
        <Button variant="contained" onClick={handleLoadMoreData} color="primary" disableElevation>
          {t("load_data_btn")}...
        </Button>
      ) : (
        <></>
      )}
      <Box m={5} />

      {/** Modal that displays embeddingId */}
      <Modal
        title={t("id_modal_title")}
        maxWidth="xs"
        open={idModalsOpened}
        body={<strong>{selectedIdentityId}</strong>}
        action={<></>}
        onCancel={closeIdModal}
        hideCancel={true}
      />

      {/** Modal that displays  */}
      <IdentityMediaModal open={mediaModalOpened} identityId={selectedIdentityId} onClose={closeMediaModal} />
    </div>
  );
}

type IdentityMediaModalProps = {
  onClose: () => void;
  open: boolean;
  identityId: string;
};

function IdentityMediaModal({ open, onClose, identityId }: IdentityMediaModalProps) {
  const { data: identity, isLoading } = useQuery([`identity-${identityId}`], () => getIdentity(identityId));

  const { t } = useScopedTranslation("identities_page");

  const mediaRender = (
    <Box display="flex" justifyContent="center" alignItems="center">
      {identity && identity.media?.blob && identity.media?.mimeType ? (
        <img src={`data:${identity.media?.mimeType};base64,${identity.media.blob}`} alt={`${identityId} media`}></img>
      ) : (
        <Typography>{t("no_media")}</Typography>
      )}
    </Box>
  );

  return (
    <Modal
      title={`${t("media_modal_title")}`}
      maxWidth="xs"
      open={open}
      body={isLoading ? <></> : mediaRender}
      action={<></>}
      hideCancel={true}
      onCancel={onClose}
    />
  );
}
