import { FR } from "@unissey/sdk-react";
import { ErrorCode, FieldCode, RoleKey } from "@unissey/common";
import { errorCodeKey, fieldCodeKey } from "..";
import { config } from "../../constants/env";

// prettier-ignore
export default {
  button_create: "Créer",
  button_edit: "Éditer",
  button_delete: "Supprimer",
  button_cancel: "Annuler",
  button_confirm: "Confirmer",
  button_back: "Retour",
  button_extract_table: "Extraire le tableau",

  loading: "Chargement…",

  no_data: "Aucune donnée disponible",

  no_session_data: "Aucune session réalisée sur cette période",

  clear_all: "Tout effacer",
  select_all: "Tout sélectionner",
  clear_all_filters: "Effacer tous les filtres",

  demo_reminder: "Votre souscription de démo est encore disponible pendant {{count}} jour et prendra fin le {{date}}",
  demo_reminder_plural: "Votre souscription de démo est encore disponible pendant {{count}} jours et prendra fin le {{date}}",

  settings: {
    injection_activation_title: "Activer les mesures d'anti-injection",
    injection_activation_help: "Activation en début de parcours uniquement",
    acquisition_preset_title: "Preset d'acquisition",
    iad_mode_title: "Mode d'anti-injection",
    retry_activation_title: "Activer les tentatives multiples",
    age_activation_title: "Activer l'estimation de l'âge",
    age_threshold_title: "Seuil d'âge"
  },

  roles: {
    "super_administrator": "Super administrateur",
    "internal_tech_user": "Tech interne",
    "business_administrator": "Administrateur Business",
    "sales_user": "Utilisateur commercial",
    "technical_administrator": "Administrateur technique",
    "administrator": "Administrateur",
    "user": "Utilisateur",
    "demo_access": "Accès démo",
  } as Record<RoleKey, string>,

  roles_descriptions: {
    "super_administrator": "Dispose de l'ensemble des autorisations",
    "internal_tech_user": "Visualise les espaces clients (incl. les sous-espaces clients) et accède aux détails de sessions (R&D)",
    "business_administrator": "Gère les espaces clients (incl. les sous-espaces clients) et les souscriptions",
    "sales_user": "Visualise les espaces clients (incl. sous-espaces clients) et gère les utilisateurs temporaires",
    "technical_administrator": "Gère les espaces clients (incl. les sous-espaces clients) et l'intégration via les clés API",
    "administrator": "Gère les espaces clients (incl. sous-espaces clients)",
    "user": "Visualise les espaces clients (incl. sous-espaces clients)",
    "demo_access": "Accède à la démo Unissey",
  } as Record<RoleKey, string>,

  sub_kind: {
    free_demo: "Démo",
    integrated_demo: config.useContinuousIntegrationSubscriptions ? "Intégration continue" : "Souscription d'essai",
    license: "Licence",
    pay_as_you_go: "Prix à la consommation",
    per_packet: "Par paquet",
    poc: "Poc",
  },

  identity_action_kind: {
    enroll: "Enrollement",
    delete: "Suppression",
    search: "Recherche",
    similar: "Similarité"
  },

  identity_result: {
    success: "Succès",
    fail: "Echec",
    conflict: "Conflit"
  },

  feature_kind: {
    one_one: "Analyze",
    one_to_many: "Identity"
  },

  can_use_feature: {
    true: "Autorisée",
    false: "Non autorisée"
  },

  page_headers: {
    sessions: "Sessions",
    identity_sessions: "Sessions d'identité",
    identities: "Identités",
    demo: "Démo",
    demo_capture: "Démo",
    profile: "Profil",
    workspaces: "Espace client",
    registries: "Registres",
    team: "Équipe",
    subscriptions: "Souscription & Consommation",
    applications: "Clés API",
    documentation: "Documentation",
    report: "Rapport",
    billing: "Facturation: Décompte de sessions"
  },
  nav_menu: {
    demo: "Démo",
    sessions: "Sessions",
    identity_sessions: "Sessions d'identité",
    identities: "Identités",
    workspaces: "Espace client",
    registries: "Registres",
    team: "Équipe",
    subscriptions: "Souscription",
    applications: "Clés API",
    documentation: "Documentation",
    report: "Rapport",
    billing: "Facturation"
  },
  nav_top_user_menu: {
    profile: "Profil",
    logout: "Déconnexion",
  },

  demo_page: {
    title: `Conditions d'utilisation`,
    terms_of_use: `Je certifie avoir lu et accepté les <0>conditions d'utilisation</0>`,
    // Note (Guillaume): text used for Edokial when we hardcoded a french version for them
    privacy_policy: `
        Je reconnais avoir lu la <0>politique de confidentialité</0>
        et compris toutes ses clauses concernant le traitement de mes données personnelles et de mon
        image. J'accepte expressément le traitement de mes données personnelles et l'enregistrement de mon
        image par UNISSEY dans le but d'utiliser l'application, d'améliorer les algorithmes qu'elle
        contient, ainsi qu'à des fins d'analyses statistiques
    `,

    network_err: "Erreur réseau",

    terms_of_use_url: "https://storage.googleapis.com/unissey-shared/unissey-fr-terms-of-service.pdf",
    privacy_policy_url: "https://storage.googleapis.com/unissey-shared/unissey-fr-privacy-policy.pdf",

    liveness: "Détection du vivant",
    liveness_plus_face_comparison: "Détection du vivant + Comparaison faciale",

    results_screen: {
      liveness: "Détection du vivant",
      face_comparison: "Comparaison faciale",
      age_verification: "Vérification d'âge",
      age_estimation: "Estimation d'âge",
      finish: "Terminer",
      results: "Résultats",
      max_retries_reached: "Vous avez atteint le nombre maximum de tentatives",
      retry: "Réessayer",
    },

    sdk_strings: {
      ...FR,
    },

    analysing: "En cours d'analyse",

    injection_instructions: {
      title_page1: "Activer l'anti-injection (1/2)",
      title_page2: "Activer l'anti-injection (2/2)",

      subtitle: "instructions à lire avant la première utilisation",

      content: {
        what_is_injection_header: "Qu'est-ce qu'une attaque par injection ?",
        what_is_injection_text: "À l'inverse d'une attaque par présentation (où le fraudeur présente physiquement son attaque à la caméra via un écran ou autre instrument), une attaque par injection cherche à modifier le flux vidéo envoyé à l'application à l'aide d'outils informatiques, comme une caméra virtuelle.",

        measures_header: "Les mesures d'anti-injection d'Unissey",
        measures_text_p1: "Nous avons développé des <0>contre-mesures qui cherchent à garantir la légitimité de la caméra utilisée et du flux vidéo enregistré</0>, afin de détecter les attaques par injection vidéo. Cette approche repose sur des mesures aléatoires, entièrement passives.",
        measures_text_p2: "Activer ces mesures permet de détecter ces tentatives de fraude. Cependant cela augmente aussi le risque de faux rejets (personne légitime rejetée par la solution). En effet certaines configurations ne permettent pas à nos algorithmes de garantir la fiabilité du navigateur et de la caméra, et donc de la sécurité des vérifications. En cas de doute, la solution Unissey rejettera la demande.",

        prevention_header: "Eviter un faux rejet",
        prevention_text_p1: "La version actuelle ne supporte qu’un nombre limité de navigateurs web. En particulier, il vous est demandé d'utiliser un des navigateurs suivants :",
        prevention_bullet1: "un navigateur basé sur Chromium (comme Google Chrome <0>{{iconChrome}}</0> ou Microsoft Edge) sur Windows et Linux;",
        prevention_bullet2: "Safari <0>{{iconSafari}}</0> sur MacOS",
        prevention_text_p2: "Une session réalisée sur d'autre navigateurs (comme Firefox) risque d’être automatiquement rejetée. ",
        prevention_text_p3: "De plus, pour réduire les risque de faux rejets nous recommandons :",
        prevention_bullet3: "d’utiliser la caméra intégrée de son ordinateur;",
        prevention_bullet4: "de s’asssurer que la caméra n’est pas ouverte dans un autre onglet. ",
        prevention_text_p4: "Ces contre-mesures constituent un premier niveau de protection, elles réduisent significativement la probabilité de réussir une attaque par injection.",
      },

      button_next: "Suivant",
      button_confirm: "J'ai compris",
      button_prev: "Précedent",
    },
  },
  profile_page: {
    information: {
      title: "Informations",
      first_name: "Prénom",
      last_name: "Nom",
      email: "Adresse email",
      assigned_roles: "Rôle assigné",
      workspace: "Espace client",
      password_update_email: "Envoyer un e-mail de mise à jour du mot de passe"
    },

    change_password: "Changer de mot de passe",

    button_saving: "Enregistrement…", //-> Est-ce le bouton "EDIT" ?
    button_delete_account: "Supprimer mon compte",

    change_password_dialog: {
      title: "Autoriser l'opération",
      content: `
        Pour autoriser l'opération, nous avons besoin de confirmer votre identité.
        Veuillez renseigner votre ancien mot de passe avant de confirmer.
      `,
      field_current_password: "Mot de passe actuel",
    },
  },
  documentation_page: {
    table: {
      header_type: "Type",
      header_link: "Lien",
    },
    previous_sdk_version_notice:
      "Si vous souhaitez consulter la documentation des anciens SDK, veuillez vous rapprocher de votre interlocuteur Unissey.",
  },
  restricted_access_page: {
    title: "Accès restreint",
    content: "Si vous pensez que c'est une erreur, veuillez contacter votre administrateur",
    user_authorizations: "Autorisations utilisateur",
    required_authorizations: "Autorisations requises",
  },
  workspace_page: {
    button_create: "Créer un espace client",
    button_create_temp_access: "Créer accès temporaire",

    link_temporary_extract: "Utilisation de la démo temporaire",

    button_invite: "Inviter un membre",
    own_workspace: "Votre espace client",
    table: {
      header_name: "Nom de l'espace client",
    },

    update_member: {
      title: "Modifier le membre",
      fields: {
        firstName: "Prénom",
        lastName: "Nom",
        email: "Adresse Email",
        role: "Selectionner le rôle",
        personalDataReader: "Donner à cet utilisateur l'accès à la lecture des données personnelles",
        personalDataReaderSubMsg:
          "Je certifie que cet utilisateur a lu et accepté le processus de sensibilisation à la RGPD",
      },
      save: "Enregistrer",
    },

    members_listing: {
      headers: {
        firstColumn: "Membre de l'équipe",
        secondColumn: "Rôle",
        thirdColumn: "Accès aux données personnelles",
      },
      personal_data: {
        yes: "Oui",
        no: "Non",
      },
    },

    edit_dialog: {
      title: "Modifier l'espace client",
      field_workspace_name: "Nom de l'espace client",
      button_delete: "Supprimer l'espace client",
    },

    create_dialog: {
      title: "Créer un espace client",

      subtitle_general_information: "Informations générales",
      subtitle_subscription: "Souscription",
      field_workspace_name: "Nom de l'espace client",

      team_members_section_title: "Membres de l'espace client",

      subtitle_administrator: "Administrateur",
      notice_send_invite_to_administrator:
        "En créant cet espace client, vous enverrez automatiquement une invitation à l'administrateur ci-dessus.",

      subscription_fields: {
        kind: "Type de souscription",
        name: "Nom",
        max_sessions: "Nombre max de Sessions",
        start_date: "Date de début",
        end_date: "Date de fin",
      },

      administrator_fields: {
        first_name: "Prénom",
        last_name: "Nom de famille",
        email: "Adresse email",
        role: "Rôle",
        personalDataReader: "Donner à cet utilisateur l'accès à la lecture des données personnelles",
        personalDataReaderSubMsg:
          "Je certifie que cet utilisateur a lu et accepté le processus de sensibilisation à la RGPD",
      },

      button_create_and_invite: "Créer",
    },

    delete_confirm_dialog: {
      title: "Supprimer {{firstName}} {{lastName}}",
      message: "Voulez vous retirer {{firstName}} {{lastName}} de l'espace {{workspaceName}} ?",
      cancelBtn: "Annuler",
      deleteBtn: "Supprimer",
    },

    create_temp_access_dialog: {
      title: "Créer un accès démo temporaire",

      field_first_name: "Prénom",
      field_last_name: "Nom",
      field_email: "Adresse email",

      button_create: "Créer",
      button_create_and_invite: "Créer et envoyer l'invitation",
      button_finish: "Terminer",
      button_send_email: "Envoyer mail",
      button_copy_link: "Copier URL",

      notice_created_and_email_sent: "Un accès temporaire a été créé pour <0>{{email}}</0>.",
      notice_interact_when_activated:
        "L'utilisateur peut interagir avec notre platforme pendant {{minutes}}min dès qu'il a activé son accès.",
      notice_send_procedure:
        "Ce lien URL peut lui être soit envoyé directement par l'administateur (en copiant le lien URL), soit reçu via un email automatique",
    },

    create_user_dialog: {
      title: "Inviter quelqu'un dans votre équipe",
      button_invite: "Inviter",
      
      field_first_name: "Prénom",
      field_last_name: "Nom",
      field_email: "Adresse email",
      field_select_workspace: "Sélectionner un espace client",
  
      field_role_team_invite: "Selectionner le rôle",
      field_role_workspace_create: "Rôle assigné",
  
      // Note (Guillaume): Roughly translated on my own…
      notice_gdpr_personal_data_reader_selected: `
        Le rôle "Personal Data Reader" donne accès à des données personnelles sensibles.
        Ce rôle doit uniquement être assigné aux personnes ayant été sensibilisées au RGPD.
      `,
    },

    temp_demo_usage_dialog: {
      title: "Utilisation de la démo temporaire",

      field_start: "Date de début",
      field_end: "Date de fin",

      column_email: "Email",
      column_created_at: "Date de création",
      column_activated_at: "Date d'activation",
      column_session_count: "Nombre de session",

      filter_domain: "domaine",

      never_activated: "Jamais",
    },
  },
  subscription_page: {
    button_create: "Créer une souscription",

    table: {
      header_name: "Nom",
      header_workspace: "Espace client",
      header_status: "Statut",
      header_liveness: "Détection du vivant",
      header_face_comparison: "Comparaison faciale",
      header_iad: "IAD",
      header_age: "Âge",
      header_type: "Type",
      header_solution: "Solution",
      header_start_date: "Date de début",
      header_end_date: "Date de fin",
      header_max_sessions: "Max de sessions",
      header_session_count: "Sessions effectuées",
    },

    details_modal: {
      title: "Souscription",

      title_details: "Détails",
      row_name: "Nom de la souscription",
      row_type: "Type de souscription",
      row_status: "Statut",
      row_workspace: "Nom de l'espace client",
      row_max_sessions: "Nombre maximum de sessions autorisées dans l'offre",
      row_start_date: "Date de début",
      row_end_date: "Date de fin",
      row_end_date_renewal: "Dernier renouvellement automatique",

      status_active: "Active",
      status_inactive: "Inactive",

      title_consumption: "Consommation",
      row_total_session_count: "Nombre total de sessions effectuées",
      row_remaining_session_count: "Nombre de sessions restantes dans l'offre",

      title_details_consumption: "Consommation détaillée par clé API",

      row_remaining_days: "Nombre de jours restants",
      title_overall_consumption: "Consommation générale",

      bucket_consumption: "Consommation des paquets",
      bucket_order: "Ordre",
      bucket_size: "Nombre de session",
      bucket_type: "Type",
      bucket_paid: "Payé",
      bucket_fallback: "Par défaut",
      bucket_creation_date: "Date de création",
      bucket_start_date: "Date de début",

      bucket: "Paquet",

      "N/A": "N/A",
    },

    create_dialog: {
      title: "Créer une souscription",
      button_create: "Créer la souscription",
    },

    edit_dialog: {
      title: "Modifier la souscription",
      button_delete: "Supprimer la souscription",
    },

    bucket_status: {
      in_progress: "En cours",
      not_activated: "Non activé",
      consumed: "Consommé",
    },

    // This is reused by the two dialogs above
    form: {
      field_subscription_name: "Nom de la souscription",
      field_workspace: "Espace client",
      field_solution: "Type de solution",
      field_subscription_type: "Type de souscription",
      field_can_use_liveness: "Détection du vivant",
      field_can_use_face_comparison: "Comparaison faciale",
      field_can_use_iad: "IAD",
      field_can_use_age: "Âge",
      field_max_sessions: "Nombre max de Sessions",
      field_start_date: "Date de début",
      field_end_date: "Date de fin",
      field_bucket_size: "Nombre de sessions du paquet",
      field_fallback_bucket_size: "Nombre de sessions par défaut",
      field_fallback_bucket_auto_created: "Activer le renouvellement automatique des paquets",
      field_auto_renewal: "Renouvellement automatique de la souscription",
      field_iad_policy: "IAD policy",
      field_renewal_length: "Durée du renouvellement",

      title_authorized_verifications: "Vérifications autorisées",
      bucket: "Paquet",
      bucket_edit: "Modifier les paquets",
      add_bucket_button: "Ajouter un paquet",
      delete_bucket_button: "Supprimer",
      cancel_bucket_deletion_button: "Annuler la suppression",

      one_year: "1 an",
      six_months: "6 mois"
    },
  },
  api_page: {
    button_create: "Créer une clé API",

    no_data: "Aucune clé API n'est créée, veuillez vous assurer que vous avez la possibilité en tant qu'administrateur technique de gérer cet onglet ou demandez de l'aide à un membre d'Unissey.",

    table: {
      header_name: "Nom",
      header_subscription_name: "Nom de la souscription",
      header_subscription_kind: "Type de la souscription",
      header_workspace: "Espace client",
      header_identity_registry: "Registre d'identités",
      header_key: "Clé",
      header_iad_policy: "Anti-injection",

      key_revoked: "Révoquée",
      key_passive: "Activée", // When the active challenge is made public, this value should be set to "Passif",
      key_active: "Actif",
      key_active_fallback: "Passif si possible ou actif",
      key_hash: "Vidéo hash uniquement",
      key_disabled: "Désactivée",
      key_optional: "Optionnelle",

      button_display: "Afficher",
      button_revoke: "Révoquer",
      // Attention, bien modifier le bouton "EDIT" par "MODIFIER" (et non "éditer")
    },

    create_dialog: {
      title: "Créer une clé API",

      field_application_name: "Nom de la clé API",
      field_workspace: "Choisir un espace client",
      field_identity_registry: "Choisir le régistre d'identités",
      field_subscription: "Choisir une souscription",
      field_iad_policy: "Anti-injection",
      field_liveness_threshold: "Niveau de sécurité: Détection du vivant",
      field_matching_threshold: "Niveau de sécurité: Comparaison faciale",

      key_enabled: "Activée",
      key_disabled: "Désactivée",
      key_optional: "Optionnelle",

      key_level_1: "Niveau 1",
      key_level_2: "Niveau 2",
      key_level_3: "Niveau 3",

      button_create: "Créer la clé API",
    },

    edit_dialog: {
      title: "Modifier le nom de la clé API",

      field_application_name: "Nom de la clé API",
      field_liveness_threshold: "Niveau de sécurité: Détection du vivant",
      field_matching_threshold: "Niveau de sécurité: Comparaison faciale",
    },

    display_dialog: {
      title: "Affichage de la clé API: {{applicationName}}",

      button_copy_to_clipboard: "Copier dans le presse papier",
      button_close: "Fermer",
    },
  },

  identity_sessions_page: {
    table: {
      column_title: {
        date: "Date",
        workspace: "Espace client",
        registry: "Régistre",
        application: "Application",
        subscription_type: "Souscription",
        identity_result: "Résultat",
        identity_action: "Action",
        liveness: "Détection du vivant",
        gdpr_consent: "Accord RGPD"
      }
    },
    filter_section: {
      title: "Filtrer les sessions",
      filters_title: {
        date: "Date",
        workspace: "Espace client",
        registry: "Registre",
        application: "Application",
        subscription_type: "Souscription",
        identity_result: "Résultat",
        identity_action: "Action",
        liveness: "Détection du vivant",
        gdpr_consent: "Accord RGPD",
        items_order: "Ordre par date",
        nb_items: "Nombre d'élements"
      },
      filters_label: {
        workspace_name: "Nom de l'espace client",
        registry_name: "Nom du régistre",
        application_name: "Nom de l'application",
      },
      order_asc: "Croissant",
      order_desc: "Décroissant",
      button_apply: "Filtrer",
      button_clear: "Effacer tous les filtres"
    },
    load_data_btn: "Charger plus de données"
  },

  registries_page: {
    button_create: "Créer un registre",
    name: "Nom",
    edit_button: "Modifier",
    create_modal: {
      title: "Créer un registre",
      button: "Créer",
      form: {
        name_label: "Nom du régistre",
        workspace_label: "Espace client"
      }
    },
    edit_modal: {
      title: "Modifier a registry",
      button: "Modifier",
      form: {
        name_label: "Nouveau nom du registre"
      }
    },
  },

  identities_page: {
    table: {
      column_title: {
        date: "Date",
        embedding_id: "Embedding Id",
        workspace: "Espace Client",
        registry: "Registre",
        last_enrollment_date: "Dernier enrollement",
        gdpr_consent: "Accord RGPD",
        reference_picture: "Photo de reférence"
      }
    },

    filter_section: {
      title: "Filtrer les identités",
      filters_title: {
        date: "Date",
        last_enrollment_date: "Dernier enrollement",
        workspace: "Espace client",
        registry: "Registre",
        gdpr_consent: "Accord RGPD",
        items_order: "Ordre par date",
        nb_items: "Nombre d'élements"
      },
      filters_label: {
        registry_name: "Registre",
        workspace_name: "Espace client"
      },
      order_asc: "Croissant",
      order_desc: "Décroissant",
      button_apply: "Filtrer",
      button_clear: "Effacer tous les filtres",
    },

    id_modal_title: "Id de l'identité",
    id_button_title: "Afficher l'id de l'identité",
    media_button_title: "Afficher le média de l'identity",

    media_modal_title: "Média de l'identité",
    no_media: "Aucun média sauvegardé pour cette identité",

    load_data_btn: "Charger plus de données"

  },

  sessions_page: {
    search_session_id: "Rechercher un ID de session spécifique…",
    invalid_id: "ID de la session erroné",
    status_selection: {
      label_all: "Toutes",
      label_synchronized: "Synchronisées",
      label_unsynchronized: "Non synchronisées",
    },

    filters: {
      label_search_by_id: "Rechercher l'identifiant d'une session",
      label_filter_workspace: "Rechercher un espace client",
      label_filter_applications: "Rechercher une clé API",
      label_filter_start_date: "Date de début",
      label_filter_end_date: "Date de fin",
      label_filter_presets: {
        one_day: "Dernier jour",
        two_days: "Derniers 2 jours",
        one_week: "Dernière semaine",
        one_month: "Dernier mois",
        custom: "Date personnalisée"
      },
      label_filter_confirm: "Confirmer",
      label_filter_cancel: "Annuler"
    },

    table: {
      header_date: "Date",
      header_workspace: "Espace client",
      header_application: "Clé API",
      header_subscription_type: "Type de souscription",
      header_liveness: "Détection du vivant",
      header_face_comparison: "Comparaison faciale",
      header_age: "Âge",
      header_retries: "Tentatives", // Note (Guillaume): Really not sure how to translate that one… "Tentatives" probably gives the wrong idea as we'd expect at least 1 "tentative" for 0 retries
      header_status: "Statut",
      header_gdpr: "Accord RGPD",

      chip_synchronized: "Synchronisée",
      chip_unsynchronized: "Non synchronisée",
    },

    details_modal: {
      breadcrumbs_sessions: "Session",
      button_previous: "Précédent",
      button_next: "Suivant",

      title_video: "Vidéo",
      title_video_plural: "Vidéo - Tentative {{attempt}}/{{count}}",
      title_reference_picture: "Image de référence",
      title_target_picture: "Image cible",

      notice_media_unavailable_gdpr: "Données personnelles non disponibles, absence de consentement RGPD",
      notice_missing_media: "Impossible de récupérer le média associé à cette session",
      notice_media_unavailable_missing_auth:
        "Vous ne disposez pas des droits de visualisation des données personnelles. Si nécessaire, veuillez contacter votre administrateur",

      title_results: "Résultats",
      row_results_liveness: "Détection du vivant",
      row_results_face_comparison: "Comparaison faciale",
      row_results_age_estimation: "Estimation d'âge",
      row_results_age_verification: "Vérification d'âge",
      label_date: "Date",
      label_application: "Clé API",
      label_processing_duration: "Durée du traitement (ms)",
      label_workspace: "Nom du workspace",
      label_subscription: "Nom de la souscription",

      title_details_liveness: "Détection du vivant",
      title_details_face_comparison: "Comparaison faciale",
      title_details_age_estimation: "Estimation d'âge",
      title_details_age_verification: "Vérification d'âge",
      title_details_face_quality: "Qualitée faciale",
      title_details_metadata: "Métadonnées",
      title_preprocessing_params: "Paramètres de prétraitement des médias",
      title_preprocessing_error: "Erreur de prétraitement des médias",
      title_frames_detection: "Détection de trames",

      title_api_version: "Version de l'API",
      workspace_name: "Nom du workspace",
      subscription_name: "Nom de la souscription",

      title_details_models: "Modèles",
      header_model_type: "Type",
      header_model_version: "Version",
      model_version_liveness: "Détection du vivant",
      model_version_face_comparison: "Comparaison faciale",
      model_version_age: "Âge",
      model_version_vqc: "VQC",

      title_quality_assessment: "Evaluation de la qualité",

      title_error_details: "Détails de l'erreur",

      title_lifecycle: "Cycle de vie",
      lifecycle_synchronized: "SYNCHRONISÉE",
      lifecycle_unsynchronized: "NON SYNCHRONISÉE",
      requested_deletion: "Suppression demandée",
      request_sync: "Demander une synchro",
      request_unsync: "Demander une désynchro",

      title_annotations: "Annotations",

      age_year: "ans",

      info_session_unavailable: "Session non disponible",
      verification: "Vérification",
      decision: "Décision",
      confidence: "Confiance",
      company: "Entreprise",
      confidenceLevelHelp:
        "Correspond au niveau de confiance dans le résultat de la session. Lorsque le degré de confiance est faible, la session doit être revue pour plus de sécurité.",
    },
  },

  strong_confirm_dialog: {
    title: "Autoriser l'opération",
    content: `
      Pour autoriser l'opération, veuillez saisir "<0>{{confirmText}}</0>"
      avant de confirmer.
    `,
    field_confirm_text: "Texte de confirmation",
  },

  transient_demo_page: {
    notice_error_missing_user_id: "Désolé, mais ce lien est invalide.",
    notice_error_user_expired: "Votre accès à la démo temporaire a expiré, mais l'aventure continue !",
    notice_error_help_text:
      "Si vous pensez qu'il s'agit d'une erreur, veuillez nous contacter à : <0>{{salesLeadEmail}}</0>",

    notice_welcome_p1: "Bienvenue sur la plateforme de démo Unissey !",
    notice_welcome_p2:
      "Vous disposez de 15 minutes pour utiliser notre solution de détection du vivant et de comparaison faciale",
    notice_welcome_p3: "N’hésitez pas à l’utiliser autant que vous le souhaitez durant cette durée !",
    notice_welcome_p4: "Votre période d’essai de 15 minutes a démarré",

    notice_welcome_p5: "Il vous reste",
    notice_welcome_p6: "min",

    start_button: "Commencer mes 15 min d'essai",
  },

  billing_page: {
    description: "Affichage de tous les espaces de travail et abonnements ayant fait l'objet d'au moins une session au cours de cette période:",
    session_count_button: "OBTENIR LE NOMBRE DE SESSIONS",
    clear_button: "EFFACER LA RECHERCHE ET RECOMMENCER",
    start_date: "Date de début",
    end_date: "Date de fin",
    select_month: "Choisir un mois",
    select_year: "Choisir une année",

    table: {
      header_workspace: "Espace client",
      header_subscription: "Souscription",
      header_subscriptionType: "Type de souscription",
      header_liveness: "Détection du vivant uniquement",
      header_faceComp: "Comparaison faciale uniquement",
      header_liveness_faceComp: "Détection du vivant + comparaison faciale",
      header_totalSessions: "Nombre total de sessions"
    },
    
    months: {
      "0": "Janvier",
      "1": "Février",
      "2": "Mars",
      "3": "Avril",
      "4": "Mai",
      "5": "Juin",
      "6": "Juillet",
      "7": "Août",
      "8": "Septembre",
      "9": "Octobre",
      "10": "Novembre",
      "11": "Décembre",
    }
  },

  chips: {
    "N/A": "N/A",
    undetermined: "Non déterminée",
    genuine: "Réussie",
    fail: "Echouée",
    match: "Identique",
    mismatch: "Différente",
    error: "Erreur",
    not_reached: "Pas exécuté",
    estimation: "Estimation",
    match_high: "Identique - élevé",
    match_low: "Identique - faible",
    mismatch_high: "Différente - élevé",
    mismatch_low: "Différente - faible low",
    genuine_high: "Réussie - élevé",
    genuine_low: "Réussie - faible",
    fail_high: "Echouée - élevé",
    fail_low: "Echoué - faible"
  },

  notif: {
    success: {
      api_key_revoked: "{{applicationName}} révoquée avec succès !",
      api_key_created: "Nouvelle application {{applicationName}} créée avec succès !",
      api_key_edited: "Application {{applicationName}} éditée avec succès !",

      account_deleted: "Votre compte a été supprimé",

      subscription_created: "Souscription créée avec succès !",
      subscription_edited: "Souscription éditée avec succès !",

      workspace_created: "Nouvel espace {{workspaceName}} créé avec succès !",
      workspace_edited: "Espace édité avec succès !",
      workspace_deleted: "Espace supprimé avec succès !",

      user_created: "Utilisateur créé avec succès !",
      user_edited: "Utilisateur édité avec succès !",
      user_deleted: "Utilisateur supprimé avec succès !",

      link_copied_to_clipboard: "Lien copié dans le presse papier",
      transient_demo_email_sent: "Un accès temporaire a été transmis à {{email}}",
      password_email_sent: "Un e-mail a été envoyé à {{email}}",

      registry_created: "Registre créé avec succès !",
      registry_edited: "Registre édité avec succès !"
    },

    warning: {
      "invalid-token": "L'authentification a échoué, veuillez actualiser votre navigateur",
      "missing-scheme": "Oups! une erreur est survenue lors de l'authentification",
      "no-token": "Oups! une erreur est survenue lors de l'authentification",
      "bad-payload": "Oops! une erreur est survenue lors de l'envoie des données",
      forbidden: "Vous n'êtes pas autorisé à effectuer cette action",
      unexpected: "Oups, quelque chose s'est mal passé! Aidez-nous à améliorer votre expérience en envoyant un rapport d'erreur",
      "not-found": "Une ressource requise est introuvable",
      user_action: "Aidez-nous à améliorer votre expérience en envoyant un rapport d'erreur",
      "invalid-api-key": "",
      "conflict": ""
    },

    error: {
      api_key_edit_missing_fields: "Tous les champs doivent être rensignés pour éditer l'application",
      user_edit_id_not_found: "Impossible d'éditer l'utilisateur: non trouvé",

      invalid_date_filter_input: "Les dates du filtre sont invalides",
      missing_api_key_fields: "Tous les champs requis doivent être rensignés pour créé l'application",

      connection: "Erreur réseau, Veuillez réessayer ultérieurement",

      [errorCodeKey(ErrorCode.notFound.APPLICATION_NOT_FOUND)]: "Application non trouvée",
      [errorCodeKey(ErrorCode.notFound.SESSION_NOT_FOUND)]: "Session non trouvée",
      [errorCodeKey(ErrorCode.notFound.SUBSCRIPTION_NOT_FOUND)]: "Souscription non trouvée",
      [errorCodeKey(ErrorCode.notFound.SUBSCRIPTIONS_NOT_FOUND)]: "Aucune souscription trouvée",
      [errorCodeKey(ErrorCode.notFound.USER_NOT_FOUND)]: "Identifiant utilisateur non trouvé",
      [errorCodeKey(ErrorCode.notFound.USERS_NOT_FOUND)]: "Aucun utilisateur trouvé",
      [errorCodeKey(ErrorCode.notFound.TOKEN_NOT_FOUND)]: "Erreur interne : Token souhaité non trouvé",
      [errorCodeKey(ErrorCode.notFound.WORKSPACE_NOT_FOUND)]: "Espace non trouvé",
      [errorCodeKey(ErrorCode.notFound.WORKSPACES_NOT_FOUND)]: "Aucun espace trouvé",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_CANNOT_FIND_USER)]: "Utilisateur non trouvé",
      [errorCodeKey(ErrorCode.notFound.SSO_AUTH_CANNOT_FIND_USER_ID)]: "Utilisateur non trouvé",
      [errorCodeKey(ErrorCode.notFound.SESSION_HAS_EXPIRED)]: "Session expirée",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_USER_NOT_FOUND)]: "Utilisateur inconnu",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_WORKSPACE_NOT_FOUND)]: "Espace non trouvé",
      [errorCodeKey(ErrorCode.notFound.INDEX_NOT_FOUND)]: "Index non trouvé",

      [errorCodeKey(ErrorCode.invArgs.ROLE_VERIFICATION_FAILED)]: "Echec d'affectation du rôle",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_REMOVE_LAST_WORKSPACE_ADMINISTRATOR)]:
        "Au moins un rôle Administrateur est nécessaire pour administrer votre espace. Celui-ci ne peut être supprimé",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_EDIT_LAST_WORKSPACE_ADMINISTRATOR_ROLE)]:
        "Au moins un rôle Administrateur est nécessaire pour administrer votre espace. Celui-ci ne peut être modifié",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_CREATE_FREE_DEMO_API_KEY)]:
        "Création d'une clé API en Free Demo impossible",
      [errorCodeKey(ErrorCode.invArgs.INVALID_SUBSCRIPTION_ID)]: "Identifiant de la souscription invalide",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_NAME_EXISTS)]: "Le nom de l'espace existe déjà",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_NAME_EXISTS_SCHEDULED_DELETION)]:
        "Le nom de l'espace est indisponible car déjà existant et en cours de suppression",
      [errorCodeKey(ErrorCode.invArgs.SSO_LOGIN_INVALID_EMAIL)]: "Email invalide",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_INVALID_ID)]: "Action impossible, veuillez réessayer ultérieurement",
      // STARTING BELOW ARE VALIDATORS (array of fields are integrated in the response)
      [errorCodeKey(ErrorCode.invArgs.CREATE_SESSION)]:
        "Erreur lors de la création de session, champ(s): {{fields}} invalide(s)",
      [errorCodeKey(ErrorCode.invArgs.GET_SESSION)]: "Champ(s) invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.GET_SESSIONS)]: "Champ(s) invalide(s) : {{fields}}",
      // [errorCodeKey(ErrorCode.invArgs.SYNC_SESSION)]:
      //   "Erreur lors de la synchronisation, champ(s) invalide(s) : {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.REVIEW_SESSION)]: "Champ(s) invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.EDIT_USER_ROLES)]: "Erreur lors de la modification du rôle utilisateur",
      [errorCodeKey(ErrorCode.invArgs.GET_USER_BY_AUTH_ID)]: "Action impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.invArgs.AUTHENTIFY_SSO)]: "Action impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.invArgs.RESET_PASSWORD)]:
        "Réinitialisation impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.invArgs.VALIDATE_PASSWORD_RESET_TOKEN)]:
        "Réinitialisation impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.invArgs.EDIT_PASSWORD)]: "Modification impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.invArgs.GET_USER)]: "Champ(s) invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.EDIT_USER)]: "Champ(s) invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.NEW_USER)]: "Création utilisateur impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_WORKSPACE)]: "Création impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_WORKSPACE)]: "Modification impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.DELETE_USER)]: "Suppression utilisateur impossible, {{fields}} invalide",
      [errorCodeKey(ErrorCode.invArgs.LIST_APPLICATIONS)]: "Action impossible, {{fields}} invalide(s)",
      [errorCodeKey(ErrorCode.invArgs.CREATE_APPLICATION)]: "Création impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_APPLICATION)]: "Mise à jour impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_SUBSCRIPTION)]:
        "Type de souscription invalide, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_SUBSCRIPTION)]:
        "Mise à jour impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.LOGIN)]: "Connexion impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.LOGIN_SSO)]: "Connexion impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.REFRESH_LOGIN_TOKEN)]: "Connexion impossible, champ(s)  invalide(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_SUBSCRIPTION_NAME_ALREADY_EXISTS)]:
        "Une souscription du même nom est déjà existante, veuillez renommer votre souscription",
      [errorCodeKey(ErrorCode.invArgs.CREATE_WORKSPACE_NAME_TOO_LONG)]:
        "Erreur lors de la création de l'espace client : nom limité à 30 caractères",

      [errorCodeKey(ErrorCode.permDenied.CANNOT_ACCESS_ROUTE_MISSING_AUTHORIZATION)]:
        "Accès non autorisé : habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_MAX_USES_EXCEEDED)]: "Session expirée, veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_WRONG_TYPE)]: "Session expirée, veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.permDenied.MISSING_API_KEY_AUTHORIZATION)]:
        "Accès non autorisé : habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.LIST_API_KEY_UNAUTHORIZED_WORKSPACE_IDS)]:
        "Accès non autorisé : habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SUB_WORKSPACE_AUTHORIZATION)]:
        "Accès à cet espace impossible : Habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SESSION_DATA_WRITE_AUTHORIZATION)]:
        "Annotation des sessions impossible : Habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SESSION_LIST_AUTHORIZATION)]:
        "Accès aux sessions impossible : Habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_LIST_AUTHORIZATION)]:
        "Accès aux rôles utilisateurs impossible : Habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_IAM_AUTHORIZATION)]:
        "Accès non autorisé : Habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_EDIT_AUTHORIZATION)]:
        "Modification utilisateur impossible : Habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.WORKSPACE_CANNOT_MODIFY_OWN)]: "Modification de votre espace non autorisée",
      [errorCodeKey(ErrorCode.permDenied.WORKSPACE_SPECIFIED_CANNOT_BE_MODIFIED)]:
        "Vous n'êtes pas autorisé à modifier cet espace",
      [errorCodeKey(ErrorCode.permDenied.MISSING_WORKSPACE_LIST_AUTHORIZATION)]:
        "Accès à cette page impossible : Habilitation insuffisante",
      [errorCodeKey(ErrorCode.permDenied.NO_TOKEN_PROVIDED)]: "Session expirée, veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_CANNOT_DECODE)]: "Session expirée, veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.permDenied.KEYCLOAK_BAD_PASSWORD)]:
        "Echec de connexion, identifiant ou mot de passe incorrect",
      [errorCodeKey(ErrorCode.permDenied.SQREEN_SUSPICIOUS_USER)]:
        "Connexion impossible, veuillez contacter votre administrateur",

      [errorCodeKey(ErrorCode.notAuthed.TOKEN_DATA_NOT_VALID)]: "Session expirée, veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.notAuthed.CANNOT_AUTHENTICATE_REQUEST)]: "Session expirée, veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_LOGIN_FAIL)]:
        "Echec de connexion : Nombre maximum de connexions atteint, veuillez réessayer dans 10min",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_RESET_PASSWORD_REQ_FAIL)]:
        "Echec de connexion : Nombre maximum de réinitialisations du mot de passe atteint, veuillez réessayer dans 10min",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_RESET_PASSW_REQ_FAIL)]:
        "Echec de connexion : Nombre maximum de réinitialisations du mot de passe atteint, veuillez réessayer dans 10min",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_USERNAME_PASSWORD)]:
        "Echec de connexion : Identifiant ou mot de passe invalide",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_EXPIRED_TOKEN)]: "Veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_TOKEN)]: "Veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_CODE)]: "Veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_CANNOT_REFRESH_EXPIRED_TOKEN)]: "Veuillez vous reconnecter",

      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_CONNECT)]:
        "Connexion au service d'authentification impossible, veuillez réessayer",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_EDIT_PASSWORD)]:
        "Modification impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_CREATE_USER)]:
        "Création de l'utilisateur impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.internal.UNKNOWN_INTERNAL_EXCEPTION)]: "Veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.internal.TOKEN_CANNOT_GENERATE)]: "Session expirée, veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.internal.TOKEN_CANNOT_DECODE)]: "Session expirée, veuillez vous reconnecter",
      [errorCodeKey(ErrorCode.internal.ROLES_NOT_FOUND)]: "Rôles non trouvés",
      [errorCodeKey(ErrorCode.internal.USER_DELETION_FAILED)]: "Suppression utilisateur impossible",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_INTERNAL_EXCEPTION)]: "Veuillez vous reconnecter ultérieurement",
      [errorCodeKey(ErrorCode.internal.CANNOT_GENERATE_EMAIL_TEMPLATE)]:
        "Action impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.internal.CANNOT_SEND_EMAIL)]: "Action impossible, veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.internal.ENUM_VALUE_NOT_FOUND)]: "Veuillez réessayer ultérieurement",
      [errorCodeKey(ErrorCode.internal.INVALID_REQUEST)]: "Veuillez réessayer ultérieurement",

      [fieldCodeKey(FieldCode.EMAIL)]: "mail",
      [fieldCodeKey(FieldCode.USERNAME)]: "nom utilisateur",
      [fieldCodeKey(FieldCode.PASSWORD)]: "mot de passe",
      [fieldCodeKey(FieldCode.FIRSTNAME)]: "prénom",
      [fieldCodeKey(FieldCode.LASTNAME)]: "nom de famille",
      [fieldCodeKey(FieldCode.POSITION)]: "poste",
      [fieldCodeKey(FieldCode.USER_ID)]: "identifiant",
      [fieldCodeKey(FieldCode.USER_AUTH_ID)]: "identifiant",
      [fieldCodeKey(FieldCode.ROLE_ID)]: "rôle",
      [fieldCodeKey(FieldCode.TOKEN)]: "token",
      [fieldCodeKey(FieldCode.TIME_RANGE)]: "time range",
      [fieldCodeKey(FieldCode.SESSION_ID)]: "identifiant de session",
      [fieldCodeKey(FieldCode.SESSION_ID_LIST)]: "liste des sessions",
      [fieldCodeKey(FieldCode.SESSION_SYNC)]: "?",
      [fieldCodeKey(FieldCode.WORKSPACE_ID)]: "identifiant de l'espace utilisateur",
      [fieldCodeKey(FieldCode.WORKSPACE_ID_LIST)]: "liste des espaces utilisateurs",
      [fieldCodeKey(FieldCode.WORKSPACE_NAME)]: "nom de l'espace utilisateur",
      [fieldCodeKey(FieldCode.WORKSPACE_DOMAIN)]: "domaine de l'espace utilisateur",
      [fieldCodeKey(FieldCode.APPLICATION_ID)]: "identifiant de l'application",
      [fieldCodeKey(FieldCode.APPLICATION_ID_LIST)]: "liste des applications",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_NAME)]: "nom de la souscription",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_KIND)]: "type de souscription",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_MAX_SESSION_COUNT)]: "compte maximum de sessions",
      [fieldCodeKey(FieldCode.LABEL_LIST)]: "labels",
      [fieldCodeKey(FieldCode.MEDIAS)]: "medias",
      [fieldCodeKey(FieldCode.PARENT_SESSION_ID)]: "identifiant de la session parent",
      [fieldCodeKey(FieldCode.RESULT_MISSING)]: "résultat",
      [fieldCodeKey(FieldCode.FACE_MATCHING_RESULT)]: "résultat de face comparison",
      [fieldCodeKey(FieldCode.LIVENESS_RESULT)]: "résultat de liveness",
      [fieldCodeKey(FieldCode.IS_REVIEWED)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_TYPE)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_IS_GENUINE)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_PROBABILITY)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_SCORE)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_TRIES)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_HEART_RATE)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_SIGNAL_LIST)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_SPECTRUM_LIST)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_REVIEW)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_MODEL_ID)]: "résultat",
      [fieldCodeKey(FieldCode.RESULT_THRESHOLD)]: "résultat du seuil",
      [fieldCodeKey(FieldCode.APPLICATION_NAME)]: "nom de l'application",
      [fieldCodeKey(FieldCode.REFERRER_LIST)]: "referrers",
    },
  },
} as const;
