import {
  Box,
  Button,
  Dialog,
  DialogProps,
  DialogTitle,
  FormGroup,
  Grid,
  Grow,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "./CloseIcon";

type Props = {
  body: ReactElement;
  title: string;
  titleClassName?: string;
  subtitle?: ReactElement;
  action: ReactElement;
  onCancel: CallableFunction;
  hideCancel?: boolean;
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  title: {
    fontSize: 24,
    fontWeight: 700,
  },
});

// Note (Guillaume): trying to introduce a new styling for modals…
export function Modal({
  body,
  title,
  titleClassName,
  subtitle,
  action,
  onCancel,
  hideCancel,
  ...rest
}: Props & DialogProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      classes={{ paperFullWidth: "calc(100% - 64px)" }}
      PaperProps={{ elevation: 0 }}
      TransitionComponent={Grow}
      maxWidth="md"
      {...rest}
    >
      <DialogTitle style={{ textAlign: "center" }}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>
            <Typography variant="h6" color="primary" className={titleClassName ?? classes.title}>
              {title}
            </Typography>
            {subtitle}
          </Box>
          <Box alignSelf={"flex-end"}>
            <IconButton onClick={() => onCancel()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      {/* <Box m={2} /> */}
      <Box padding={4} className={classes.container}>
        <FormGroup>
          {body}
          <Box m={2} />

          <Box py={2}>
            <Grid container spacing={2}>
              {hideCancel !== true && (
                <Grid item xs={6}>
                  <Button variant="outlined" disableElevation onClick={() => onCancel()} fullWidth>
                    {t("button_cancel")}
                  </Button>
                </Grid>
              )}
              {/* NOTE (Martin): We need all spaces when when we hide the default cancel button */}
              <Grid item xs={hideCancel ? 12 : 6}>
                {action}
              </Grid>
            </Grid>
          </Box>
        </FormGroup>
      </Box>
    </Dialog>
  );
}
