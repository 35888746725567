import {
  FormControl,
  Grid,
  GridSize,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import LockOutlined from "@material-ui/icons/LockOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HelpIcon from "@material-ui/icons/Help";
import { useState, useEffect, SetStateAction, Dispatch } from "react";

import CustomTextField from "./formComponents/CustomTextField";

import { EditUser, Role } from "@unissey/common";
import { getRoles } from "../../services/api_services";
import { useScopedTranslation } from "../../i18n";

type Props = {
  //TODO: improve typing of the any below
  setFormData: Dispatch<SetStateAction<any>>;
  userFormData: EditUser;
  targetWorkspaceId?: string;
  workspaceParentId?: string;
  isWorkspaceCreationForm?: boolean;
};

export default function UserForm({
  setFormData,
  userFormData,
  targetWorkspaceId,
  workspaceParentId,
  isWorkspaceCreationForm = false,
}: Props) {
  const { t, tCommon } = useScopedTranslation("workspace_page.create_user_dialog");

  const [roles, setRoles] = useState<Role[]>();

  useEffect(() => {
    (async () => {
      const r = await getRoles();
      setRoles(r?.sort((a, b) => a.order - b.order));
    })();
  }, [targetWorkspaceId]);

  function UserFormTextField(fieldName: keyof Omit<EditUser, "roleIds">, label: string, w: GridSize = 6) {
    return (
      <CustomTextField
        width={w}
        name={fieldName}
        onChange={(e) => setFormData(() => ({ ...userFormData, [fieldName]: e.target.value }))}
        value={userFormData[fieldName]}
        label={label}
        onBlur={(e) => setFormData({ ...userFormData, [fieldName]: e.target.value.trim() })}
        data-testid={`user-form-${fieldName}`}
      />
    );
  }

  const roleSelectorLabel = t(`field_role_${isWorkspaceCreationForm ? "workspace_create" : "team_invite"}`);

  function roleSelectorRenderValue(): string {
    return tCommon(`roles.${userFormData.roleKey}`);
  }

  return (
    <Grid container spacing={2}>
      {UserFormTextField("firstName", t("field_first_name"))}
      {UserFormTextField("lastName", t("field_last_name"))}
      {UserFormTextField("email", t("field_email"), 12)}
      <Grid item xs={12}>
        <FormControl required fullWidth variant="outlined">
          <InputLabel id="select-role-label">{roleSelectorLabel}</InputLabel>
          <Select
            labelId="select-role-label"
            label={roleSelectorLabel}
            // multiple
            value={userFormData.roleKey ?? ""}
            renderValue={roleSelectorRenderValue}
            MenuProps={{
              variant: "menu",
              getContentAnchorEl: null,
            }}
            onChange={(e) => {
              const roleKey = e.target.value as string;
              setFormData({ ...userFormData, roleKey });
            }}
            disabled={isWorkspaceCreationForm}
            IconComponent={isWorkspaceCreationForm ? LockOutlined : ArrowDropDownIcon}
            data-testid="user-form-role"
          >
            <MenuItem key="placeholder" style={{ display: "none" }} />
            {roles ? (
              roles
                .filter((role) => {
                  if (workspaceParentId) return role.category !== "internal";
                  return true;
                })
                .map((role, idx) => (
                  <MenuItem dense value={role.key} key={idx}>
                    <ListItemText primary={tCommon(`roles.${role.key}`)} />
                    <ListItemIcon>
                      <Tooltip title={<Typography>{tCommon(`roles_descriptions.${role.key}`)}</Typography>} interactive>
                        <HelpIcon />
                      </Tooltip>
                    </ListItemIcon>
                  </MenuItem>
                ))
            ) : (
              <Typography variant={"h6"} align={"center"}>
                {tCommon("loading")}
              </Typography>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
}
