import { ThemeProvider } from "@material-ui/core";
import React, { useCallback, useMemo, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// themes
import theme from "./config/theme";
// constants
import { Routes } from "./constants/routes";

// pages
import HomePage from "./pages/HomePage";

import { NotifProps } from "./types/notif";
import AlertNotif from "./components/AlertNotif";
import { TransientDemoPage } from "./pages/transient-demo/TransientDemoPage";

import axios from "axios";

import { QueryClient, QueryClientProvider } from "react-query";
import { HttpErrorsKind } from "@unissey/common";
import { NotifProvider } from "./contexts/notif_context";
import { AuthProvider, keycloak } from "./auth";
import { usePersistedState } from "./utils/persistence";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 15 * 60 * 1000, // 10mins
      staleTime: 5 * 60 * 1000, // 5 mins
    },
  },
});

export default function App() {
  const [notifIsOpen, setNotifIsOpen] = useState(false);
  const [notifProps, setNotifProps] = useState<NotifProps | undefined>(undefined);
  const [showDemoReminder, setShowDemoReminder] = usePersistedState<"display" | "hide" | "closed">(
    "demoReminder",
    "hide"
  );

  const handleDemoReminder = (value: "display" | "hide" | "closed") => {
    setShowDemoReminder(value);
  };

  const handleNotif = useCallback((props: NotifProps) => {
    setNotifProps(props);
    setNotifIsOpen(true);
  }, []);

  const handleOpen = useCallback((value: boolean) => {
    setNotifIsOpen(value);
  }, []);

  const notif = useMemo(() => {
    return { isOpen: notifIsOpen, props: notifProps, trigger: handleNotif, handleOpen };
  }, [notifIsOpen, notifProps, handleNotif, handleOpen]);

  // Display Notification oh http errors
  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // Display notification given the right error
      if (error.code === "ERR_NETWORK") {
        handleNotif({ textKey: "error.connection" });
        return Promise.reject(error);
      }

      // Do not display a notification when the IAD data preparation fails for something else than a network error
      if (error.request.responseURL.endsWith("/analyze/demo/iad/prepare")) {
        return Promise.reject(error);
      }

      let errorKind: HttpErrorsKind | undefined = error.response.data.kind;

      if (!errorKind) errorKind = "unexpected";

      const notification: NotifProps = {
        textKey: `warning.${errorKind}`,
      };

      handleNotif(notification);

      return Promise.reject(error);
    }
  );

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <NotifProvider value={notif}>
          <Router>
            <Switch>
              <Route exact path={Routes.TRANSIENT_DEMO} component={TransientDemoPage} />

              <Route path={Routes.HOME}>
                <AuthProvider keycloak={keycloak}>
                  <HomePage changeDemoReminder={handleDemoReminder} showDemoReminder={showDemoReminder} />
                </AuthProvider>
              </Route>
            </Switch>
            <AlertNotif />
          </Router>
        </NotifProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
}
