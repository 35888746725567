"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadConfig = void 0;
function loadConfig(spec) {
    const config = {};
    for (const [key, entry] of Object.entries(spec)) {
        Object.assign(config, { [key]: loadValue(entry) });
    }
    return config;
}
exports.loadConfig = loadConfig;
function loadValue(entry) {
    const envValue = process.env[entry.key];
    if (envValue) {
        if (entry.resolver !== undefined)
            return entry.resolver(envValue);
        else
            return envValue;
    }
    if (entry.default !== undefined) {
        console.warn("environment", `Missing '${entry.key}' variable => using default value '${entry.default}'`);
        return entry.default;
    }
    console.error("environment", `Missing required variable: '${entry.key}'`);
    process.exit(1);
}
