import { EN } from "@unissey/sdk-react";
import { ErrorCode, FieldCode, RoleKey } from "@unissey/common";
import { errorCodeKey, fieldCodeKey } from "..";
import { config } from "../../constants/env";

// prettier-ignore
export default {
  button_create: "Create",
  button_edit: "Edit",
  button_delete: "Delete",
  button_cancel: "Cancel",
  button_confirm: "Confirm",
  button_back: "Back",
  button_extract_table: "Extract table",

  loading: "Loading…",

  no_data: "No data available",

  no_session_data: "No session carried during this period",

  clear_all: "Clear all",
  select_all: "Select all",
  clear_all_filters: "Clear all filters",

  demo_reminder: "Your demo subscripiton is still available during {{count}} day, and will end on {{date}}",
  demo_reminder_plural: "Your demo subscription is still available during {{count}} days, and will end on {{date}}",

  settings: {
    injection_activation_title: "Activate anti-injection measures",
    injection_activation_help: "Activation at the beginning of the process only",
    acquisition_preset_title: "Acquisition preset",
    iad_mode_title: "Anti-injection mode",
    retry_activation_title: "Activate retries",
    age_activation_title: "Activate age",
    age_threshold_title: "Age threshold"
  },

  roles: {
    "super_administrator": "Super Administrator",
    "internal_tech_user": "Internal Tech User",
    "business_administrator": "Business administrator",
    "sales_user": "Sales user",
    "technical_administrator": "Technical Administrator",
    "administrator": "Administrator",
    "user": "User",
    "demo_access": "Demo access",
  } as Record<RoleKey, string>,

  roles_descriptions: {
    "super_administrator": "Full administrator - All authorizations",
    "internal_tech_user": "View all workspaces (incl. sub-workspaces) and access sessions details (R&D)",
    "business_administrator": "Manage all workspaces (incl. sub-workspaces) and subscriptions",
    "sales_user": "View all workspaces (incl. sub-workspaces) and manage temporary users",
    "technical_administrator":
      "Manage all workspaces (incl. sub-workspaces) and manage integration through API Keys",
    "administrator": "Manage all workspaces (incl. sub-workspaces)",
    "user": "View all workspaces (incl. sub-workspaces)",
    "demo_access": "Access Unissey demo (with no access to other tabs)",
  } as Record<RoleKey, string>,

  sub_kind: {
    free_demo: "Free Demo",
    integrated_demo: config.useContinuousIntegrationSubscriptions ? "Continuous integration" : "Trial subscription",
    license: "License",
    pay_as_you_go: "Pay-as-you-go",
    per_packet: "Bucket subscription",
    poc: "Poc",
  },

  identity_action_kind: {
    enroll: "Enroll",
    delete: "Delete",
    search: "Search",
    similar: "Similar"
  },

  identity_result: {
    success: "Success",
    fail: "Fail",
    conflict: "Conflict"
  },

  feature_kind: {
    one_one: "Analyze",
    one_to_many: "Identity"
  },

  can_use_feature: {
    true: "Allowed",
    false: "Not allowed"
  },

  page_headers: {
    sessions: "Sessions",
    identity_sessions: "Identity Sessions",
    identities: "Identities",
    demo: "Demo",
    demo_capture: "Demo Capture",
    profile: "Profile",
    workspaces: "Workspaces",
    registries: "Registries",
    team: "Team",
    subscriptions: "Subscriptions & Consumption tracker",
    applications: "API Keys",
    documentation: "Documentation",
    report: "Report",
    billing: "Billing: Session count"
  },
  nav_menu: {
    demo: "Demo",
    sessions: "Sessions",
    identity_sessions: "Identity Sessions",
    registries: "Registries",
    identities: "Identities",
    workspaces: "Workspace",
    team: "Team",
    subscriptions: "Subscriptions",
    applications: "API Keys",
    documentation: "Documentation",
    report: "Report",
    billing: "Billing",
  },
  nav_top_user_menu: {
    profile: "Profile",
    logout: "Log Out",
  },

  demo_page: {
    title: "Terms of Use",
    terms_of_use: `I certify that I have read and I accept the <0>Terms of Use</0>`,
    privacy_policy: `
        I acknowledge that I have read the <0>Privacy Policy</0>
        and have understood all of its terms relating to the processing of my personal data and my image.
        I expressly accept the processing of my personal data and the capture of my image by UNISSEY for the
        purposes of using the Application, to improve it and the algorithms it contains, as well as for
        statistical purposes. You may exercise your rights to your personal data at any time, following the
        procedure described in the Privacy Policy
    `,

    network_err: "Network Error",

    terms_of_use_url: "https://storage.googleapis.com/unissey-shared/unissey-en-terms-of-service.pdf",
    privacy_policy_url: "https://storage.googleapis.com/unissey-shared/unissey-en-privacy-policy.pdf",

    liveness: "Liveness",
    liveness_plus_face_comparison: "Liveness + Face Comparison",

    results_screen: {
      liveness: "Liveness",
      face_comparison: "Face comparison",
      age_verification: "Age verification",
      age_estimation: "Age estimation",
      finish: "Finish",
      results: "Results",
      max_retries_reached: "You have reached the maximum amount of retries",
      retry: "Try again",
    },

    sdk_strings: {
      ...EN,
    },

    analysing: "Analysing",

    injection_instructions: {
      title_page1: "Activating anti-injection measures (1/2)",
      title_page2: "Activating anti-injection measures (2/2)",

      subtitle: "instructions to read before first use",

      content: {
        what_is_injection_header: "What is an attack by injection ?",
        what_is_injection_text: "As opposed to presentation attacks (where the fraudster physically shows his attack instrument to the camera to be captured on the video recording), an injection attack tries to modify the data flow sent by the camera to the application using digital tools, such as a virtual camera.",

        measures_header: "Unissey's measures to detect injection",
        measures_text_p1: "We have developed <0>counter-measures to guarantee the genuineness and authenticity of the camera used and of the video registered</0>, to detect attacks by video injection. This approach relies on random and entirely passive measurements.",
        measures_text_p2: "Activating these measures allows the detection of such fraud attempts. However, it also increases the risk of a false rejection (i.e. a genuine person being wrongly rejected by the solution). Indeeed, certain device configurations do not allow our algorithms to guarantee the reliability of the browser and of the camera and hence to guarantee the security of the solution. When in doubt, the Unissey solution will reject the request.",

        prevention_header: "Preventing false rejection",
        prevention_text_p1: "The current version only supports a limited number of web browsers. In particular, please use one of the following web browsers:",
        prevention_bullet1: "a Chromium based browser (such as Google Chrome <0>{{iconChrome}}</0> or Microsoft Edge) on Windows and Linux;",
        prevention_bullet2: "Safari <0>{{iconSafari}}</0> on MacOS",
        prevention_text_p2: "A session carried out on another browser (such as Firefox) may be automatically rejected. ",
        prevention_text_p3: "Moreover, to reduce the risk of false rejections we recommend:",
        prevention_bullet3: "to use the integrated camera of your computer;",
        prevention_bullet4: "to ensure that the camera is not open in another tab.",
        prevention_text_p4: "These counter-measures are a first level of protection, they significantly reduce the probability of success of an injection attack.",
      },

      button_next: "Next",
      button_confirm: "I understand",
      button_prev: "Previous",
    },
  },

  profile_page: {
    information: {
      title: "Information",
      first_name: "First name",
      last_name: "Last name",
      email: "Email address",
      assigned_roles: "Assigned role",
      workspace: "Workspace",
      password_update_email: "Send Password Update Email"
    },

    change_password: "Update password",

    button_saving: "Saving…",
    button_delete_account: "Delete my account",

    change_password_dialog: {
      title: "Authorize operation",
      content: `
        In order to authorize this operation, we need to confirm your identity.
        Please enter your previous password before confirming.
      `,
      field_current_password: "Current password",
    },
  },
  documentation_page: {
    table: {
      header_type: "Type",
      header_link: "Link",
    },
    previous_sdk_version_notice:
      "If you need to read the documentation of the previous SDK, please ask your contact at Unissey.",
  },
  restricted_access_page: {
    title: "Restricted access.",
    content: "If you believe this is an error, please contact your system administrator.",
    user_authorizations: "User authorizations",
    required_authorizations: "Required authorizations",
  },
  workspace_page: {
    button_create: "Create workspace",
    button_create_temp_access: "Create temporary access",

    link_temporary_extract: "Temporary workspace extract",

    button_invite: "Invite a member",
    own_workspace: "Your workspace",
    table: {
      header_name: "Workspace name",
    },

    update_member: {
      title: "Edit team member",
      fields: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email Address",
        role: "Select role",
        personalDataReader: "Give personal data reader access to this user",
        personalDataReaderSubMsg: "I certify this user has read and accepted the GDPR awareness process",
      },
      save: "Save",
    },

    members_listing: {
      headers: {
        firstColumn: "Team member",
        secondColumn: "Role",
        thirdColumn: "Personal data",
      },
      personal_data: {
        yes: "Yes",
        no: "No",
      },
    },

    edit_dialog: {
      title: "Edit workspace",
      field_workspace_name: "Workspace name",
      button_delete: "Delete workspace",
    },

    create_dialog: {
      title: "Create a workspace",

      subtitle_general_information: "General information",
      subtitle_subscription: "Subscription",
      field_workspace_name: "Workspace name",

      team_members_section_title: "Team members",

      subtitle_administrator: "Administrator",
      notice_send_invite_to_administrator:
        "By creating this workspace, you will automatically send an invitation to the administrator above",

      subscription_fields: {
        kind: "Subscription kind",
        name: "Name",
        max_sessions: "Max session count",
        start_date: "Subscription start date",
        end_date: "Subscription end date",
      },

      administrator_fields: {
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email Address",
        role: "Role",
        personalDataReader: "Give personal data reader access to this user",
        personalDataReaderSubMsg: "I certify this user has read and accepted the GDPR awareness process",
      },

      button_create_and_invite: "Create",
    },

    delete_confirm_dialog: {
      title: "Deleting {{firstName}} {{lastName}}",
      message: "Do you want to remove {{firstName}} {{lastName}} from {{workspaceName}} workspace?",
      cancelBtn: "Cancel",
      deleteBtn: "Delete",
    },

    create_temp_access_dialog: {
      title: "Create a temporary access",

      field_first_name: "First name",
      field_last_name: "Last name",
      field_email: "Email address",

      button_create: "Create",
      button_create_and_invite: "Create and send invitation",
      button_finish: "Finish",
      button_send_email: "Send email",
      button_copy_link: "Copy link",

      notice_created_and_email_sent: "A temporary access has been created for <0>{{email}}</0>.",
      notice_interact_when_activated:
        "The user can interact with our plateforme during {{minutes}}min as soon as the access is activated.",
      notice_send_procedure:
        "The URL link below can be sent to them manually by the administrator (by copying/pasting it in an email) or by sending an automatic email.",
    },

    create_user_dialog: {
      title: "Invite someone to your team",
      button_invite: "Invite",
      
      field_first_name: "First name",
      field_last_name: "Last name",
      field_email: "Email address",
      field_select_workspace: "Select a workspace",
  
      field_role_team_invite: "Select role",
      field_role_workspace_create: "Assigned role",
  
      notice_gdpr_personal_data_reader_selected: `
        The Personal Data Reader role gives access to sensitive personal data.
        This role should only be assigned if the individual is aware of the GDPR.
      `,
    },

    temp_demo_usage_dialog: {
      title: "Temporary demo usage",

      field_start: "Start date",
      field_end: "End date",

      column_email: "Email",
      column_created_at: "Creation date",
      column_activated_at: "Activation date",
      column_session_count: "Session count",

      filter_domain: "domain",

      never_activated: "Never",
    },
  },

  subscription_page: {
    button_create: "Create subscription",

    table: {
      header_name: "Name",
      header_workspace: "Workspace",
      header_status: "Status",
      header_liveness: "Liveness",
      header_face_comparison: "Face comparison",
      header_iad: "IAD",
      header_age: "Age",
      header_type: "Type",
      header_solution: "Solution",
      header_start_date: "Start date",
      header_end_date: "End date",
      header_max_sessions: "Max sessions",
      header_session_count: "Session count",
    },

    details_modal: {
      title: "Subscription",

      title_details: "Details",
      row_name: "Subscription plan name",
      row_type: "Subscription plan type",
      row_status: "Status",
      row_workspace: "Workspace name",
      row_max_sessions: "Allowed number of sessions in plan",
      row_start_date: "Start date",
      row_end_date: "End date",
      row_end_date_renewal: "Last automatic renewal",

      status_active: "Active",
      status_inactive: "Inactive",

      title_consumption: "Consumption",
      row_total_session_count: "Total number of sessions conducted",
      row_remaining_session_count: "Total number of remaining sessions in plan",

      title_details_consumption: "Detailed consumption by API Key",

      row_remaining_days: "Remaining days",
      title_overall_consumption: "Overall consumption",

      bucket_consumption: "Bucket consumption",
      bucket_order: "Order",
      bucket_size: "Size",
      bucket_type: "Type",
      bucket_paid: "Paid",
      bucket_fallback: "Fallback",
      bucket_creation_date: "Created at",
      bucket_start_date: "Start date",

      bucket: "Bucket",

      "N/A": "N/A",
    },

    create_dialog: {
      title: "Create subscription",
      button_create: "Create subscription",
    },

    edit_dialog: {
      title: "Edit subscription",
      button_delete: "Delete subscription",
    },

    bucket_status: {
      in_progress: "In progress",
      not_activated: "Not Activated",
      consumed: "Consumed",
    },

    // This is reused by the two dialogs above
    form: {
      field_subscription_name: "Subscription name",
      field_workspace: "Workspace",
      field_solution: "Solution type",
      field_subscription_type: "Subscription type",
      field_can_use_liveness: "Liveness",
      field_can_use_face_comparison: "Face comparison",
      field_can_use_iad: "IAD",
      field_can_use_age: "Age",
      field_max_sessions: "Max session count",
      field_start_date: "Subscription start date",
      field_end_date: "Subscription end date",
      field_bucket_size: "Bucket size",
      field_fallback_bucket_size: "Fallback bucket size",
      field_fallback_bucket_auto_created: "Enable automatic bucket renewal",
      field_auto_renewal: "Automatic subscription renewal",
      field_iad_policy: "IAD policy",
      field_renewal_length: "Renewal length",

      title_authorized_verifications: "Authorized verifications",
      bucket: "Bucket",
      bucket_edit: "Edit buckets",
      add_bucket_button: "Add new bucket",
      delete_bucket_button: "Delete",
      cancel_bucket_deletion_button: "Cancel deletion",

      one_year: "1 year",
      six_months: "6 months"
    },
  },
  api_page: {
    button_create: "Create API Key",

    no_data: "No API key is created, please make sure you have the ability as a technical administrator to manage this tab or ask a Unissey member for any support.",

    table: {
      header_name: "Name",
      header_subscription_name: "Subscription name",
      header_subscription_kind: "Subscription kind",
      header_workspace: "Workspace",
      header_identity_registry: "Identity Registry",
      header_key: "Key",
      header_iad_policy: "IAD policy",

      key_revoked: "Revoked",
      key_passive: "Enabled", // When the active challenge is made public, this value should be set to "Passive",
      key_active: "Active",
      key_active_fallback: "Passive with active fallback",
      key_hash: "Video hash only",
      key_disabled: "Disabled",
      key_optional: "Optional",
      button_display: "Display",
      button_revoke: "Revoke",
    },

    create_dialog: {
      title: "Create an API Key",

      field_application_name: "Api Key name",
      field_workspace: "Select workspace",
      field_identity_registry: "Select Identity Registry",
      field_subscription: "Select subscription",
      field_iad_policy: "IAD policy",
      field_liveness_threshold: "Liveness security level",
      field_matching_threshold: "Face Comparison security level",

      key_enabled: "Enabled",
      key_disabled: "Disabled",
      key_optional: "Optional",

      key_level_1: "Level 1",
      key_level_2: "Level 2",
      key_level_3: "Level 3",

      button_create: "Create API Key",
    },

    edit_dialog: {
      title: "Edit API Key",

      field_application_name: "API key name",
      field_liveness_threshold: "Liveness security level",
      field_matching_threshold: "Face Comparison security level",
    },

    display_dialog: {
      title: "Display API key: {{applicationName}}",

      button_copy_to_clipboard: "Copy to clipboard",
      button_close: "Close",
    },
  },

  identity_sessions_page: {
    table: {
      column_title: {
        date: "Date",
        workspace: "Workspace",
        registry: "Registry",
        application: "Application",
        subscription_type: "Subscription Type",
        identity_result: "Result",
        identity_action: "Action",
        liveness: "Liveness",
        gdpr_consent: "GDPR Consent"
      }
    },
    filter_section: {
      title: "Filter sessions",
      filters_title: {
        date: "Date",
        workspace: "Workspace",
        registry: "Registry",
        application: "Application",
        subscription_type: "Subscription Type",
        identity_result: "Result",
        identity_action: "Action",
        liveness: "Liveness",
        gdpr_consent: "GDPR Consent",
        items_order: "Items date order",
        nb_items: "Number of items"
      },
      filters_label: {
        workspace_name: "Workspace name",
        registry_name: "Registry name",
        application_name: "Application name",
      },
      order_asc: "Ascendant",
      order_desc: "Descendant",
      button_apply: "Apply filters",
      button_clear: "Clear all filters"
    },
    load_data_btn: "Load more data"
  },

  registries_page: {
    button_create: "Create a registry",
    name: "Name",
    edit_button: "Edit",
    create_modal: {
      title: "Create a Registry",
      button: "Create",
      form: {
        name_label: "Registry Name",
        workspace_label: "Workspace"
      }
    },
    edit_modal: {
      title: "Edit a registry",
      button: "Edit",
      form: {
        name_label: "New Registry Name"
      }
    },
  },
  
  identities_page: {
    table: {
      column_title: {
        date: "Date",
        embedding_id: "Embedding Id",
        workspace: "Workspace",
        registry: "Registry",
        last_enrollment_date: "Last enrollment",
        gdpr_consent: "RGPD consent",
        reference_picture: "Reference picture"
      }
    },

    filter_section: {
      title: "Filter Identities",
      filters_title: {
        date: "Date",
        last_enrollment_date: "Last enrollement",
        workspace: "Workspace",
        registry: "Registry",
        gdpr_consent: "GDPR Consent",
        items_order: "Items date order",
        nb_items: "Number of items"
      },
      filters_label: {
        registry_name: "Registry",
        workspace_name: "Workspace"
      },
      order_asc: "Ascendant",
      order_desc: "Descendant",
      button_apply: "Apply filters",
      button_clear: "Clear all filters"
    },

    id_modal_title: "Identity Id",
    id_button_title: "Display Identity Id",
    media_button_title: "Display Identity Media",

    media_modal_title: "Identity Media",
    no_media: "There is no media stored for this identity.",

    load_data_btn: "Load more data"
  },

  sessions_page: {
    search_session_id: "Search a specific session ID…",
    invalid_id: "Unknown session ID",
    status_selection: {
      label_all: "All",
      label_synchronized: "Synchronized",
      label_unsynchronized: "Unsynchronized",
    },

    filters: {
      label_search_by_id: "Search a specific session id",
      label_filter_workspace: "Search a workspace",
      label_filter_applications: "Search an API key",
      label_filter_start_date: "Start date",
      label_filter_end_date: "End date",
      label_filter_presets: {
        one_day: "Past 1 day",
        two_days: "Past 2 days",
        one_week: "Past 1 week",
        one_month: "Past 1 month",
        custom: "Select custom date"
      },
      label_filter_confirm: "Confirm",
      label_filter_cancel: "Cancel"
    },

    table: {
      header_date: "Date",
      header_workspace: "Workspace",
      header_application: "Application",
      header_subscription_type: "Subscription type",
      header_liveness: "Liveness",
      header_face_comparison: "Face comparison",
      header_age: "Age",
      header_retries: "Attempts",
      header_status: "Status",
      header_gdpr: "GDPR consent",

      chip_synchronized: "Synchronized",
      chip_unsynchronized: "Unsynchronized",
    },

    details_modal: {
      breadcrumbs_sessions: "Sessions",
      button_previous: "Previous",
      button_next: "Next",

      title_video: "Video",
      title_video_plural: "Video - Attempt {{attempt}}/{{count}}",
      title_reference_picture: "Reference picture",
      title_target_picture: "Target picture",

      notice_media_unavailable_gdpr: "Personal data not available due to GDPR consent not given",
      notice_missing_media: "Failed to retrieve the media associated with this session",
      notice_media_unavailable_missing_auth:
        "You do not have the authorization to view personal data. For any purpose, please contact your administrator.",

      title_results: "Results",
      row_results_liveness: "Liveness",
      row_results_face_comparison: "Face comparison",
      row_results_age_estimation: "Age estimation",
      row_results_age_verification: "Age verification",
      label_date: "Date",
      label_application: "API key",
      label_processing_duration: "Processing duration (ms)",
      label_workspace: "Workspace Name",
      label_subscription: "Subscription Name",

      title_details_liveness: "Liveness",
      title_details_face_comparison: "Face comparison",
      title_details_age_estimation: "Age estimation",
      title_details_age_verification: "Age verification",
      title_details_face_quality: "Face quality",
      title_details_metadata: "Metadata",
      title_preprocessing_params: "Preprocessing Params",
      title_preprocessing_error: "Preprocessing Error",
      title_frames_detection: "Frames Detection",

      title_api_version: "API Version",
      workspace_name: "Workspace Name",
      subscription_name: "Subscription Name",

      title_details_models: "Models",
      header_model_type: "Type",
      header_model_version: "Version",
      model_version_liveness: "Liveness",
      model_version_face_comparison: "Face comparison",
      model_version_age: "Age",
      model_version_vqc: "VQC",
      
      title_quality_assessment: "Quality Assessment",

      title_error_details: "Error details",

      title_lifecycle: "Lifecycle",
      lifecycle_synchronized: "SYNCHRONIZED",
      lifecycle_unsynchronized: "UNSYNCHRONIZED",
      requested_deletion: "Requested deletion",
      request_sync: "Request sync",
      request_unsync: "Request unsync",

      title_annotations: "Annotations",

      age_year: "years old",

      info_session_unavailable: "Session not available",
      verification: "Verification",
      decision: "Decision",
      confidence: "Confidence",
      company: "Company",
      confidenceLevelHelp:
        "Represents the degree of confidence in the session's results. When the levels of confidence are low, the session needs to be reviewed to guarantee security.",
    },
  },

  strong_confirm_dialog: {
    title: "Authorize operation",
    content: `
      In order to authorize this operation, please enter "<0>{{confirmText}}</0>"
      before confirming.
    `,
    field_confirm_text: "Confirmation text",
  },

  transient_demo_page: {
    notice_error_missing_user_id: "Sorry, but that web page link is malformed",
    notice_error_user_expired: "Your temporary access has expired, but the adventure still goes on!",
    notice_error_help_text: "If you think this is an error, please contact us at: <0>{{salesLeadEmail}}</0>",

    notice_welcome_p1: "Welcome to the Unissey demo platform!",
    notice_welcome_p2: "Once started you will have 15 minutes to use our Liveness and Face Comparison solutions",
    notice_welcome_p3: "Feel free to test as much as you can!",
    notice_welcome_p4: "Your 15 minutes trial has started",

    notice_welcome_p5: "You have",
    notice_welcome_p6: "remaining",

    start_button: "Start my 15 minutes trial",
  },

  billing_page: {
    description: "Displaying all workspaces and subscriptions with at least one session during this period:",
    session_count_button: "GET SESSION COUNT",
    clear_button: "CLEAR SEARCH & START AGAIN",
    start_date: "Start date",
    end_date: "End date",
    select_month: "Select a month",
    select_year: "Select a year",
    
    table: {
      header_workspace: "Workspace",
      header_subscription: "Subscription",
      header_subscriptionType: "Subscription Type",
      header_liveness: "Liveness only",
      header_faceComp: "Face comparison only",
      header_liveness_faceComp: "Liveness + Face comparison",
      header_totalSessions: "Total sessions"
    },

    months: {
      "0": "January",
      "1": "February",
      "2": "March",
      "3": "April",
      "4": "May",
      "5": "June",
      "6": "July",
      "7": "August",
      "8": "September",
      "9": "October",
      "10": "November",
      "11": "December",
    }
  },

  chips: {
    "N/A": "N/A",
    undetermined: "Not determined",
    genuine: "Success",
    fail: "Fail",
    match: "Match",
    mismatch: "Mismatch",
    error: "Error",
    not_reached: "Not Reached",
    estimation: "Estimation",
    match_high: "Match high",
    match_low: "Match low",
    mismatch_high: "Mismatch high",
    mismatch_low: "Mismatch low",
    genuine_high: "Success high",
    genuine_low: "Success low",
    fail_high: "Fail high",
    fail_low: "Fail low"
  },

  notif: {
    success: {
      api_key_revoked: "{{applicationName}} was successfully revoked",
      api_key_created: "New API key {{applicationName}} was successfully created!",
      api_key_edited: "API key {{applicationName}} was successfully edited!",

      account_deleted: "Your account has been deleted",

      subscription_created: "Subscription successfully created",
      subscription_edited: "Subscription successfully edited",

      workspace_created: "New workspace {{workspaceName}} was successfully created!",
      workspace_edited: "Workspace successfully edited",
      workspace_deleted: "Workspace successfully deleted",

      user_created: "User created",
      user_edited: "User successfully edited",
      user_deleted: "User successfully deleted",

      link_copied_to_clipboard: "Link copied to clipboard",
      transient_demo_email_sent: "A temporary access has been sent to {{email}}",
      password_email_sent: "An email has been sent to {{email}}",

      registry_created: "Registry successfully created",
      registry_edited: "Registry successfully edited"
    },

    warning: {
      "invalid-token": "Authentication failed, please refresh your browser",
      "missing-scheme": "Oops! Something went wrong during authentication",
      "no-token": "Oops! Something went wrong during authentication",
      "bad-payload": "Oops! Something went wrong while sending datas",
      forbidden: "You don't have authorization to perform this action",
      unexpected: "Oops something went wrong! Help us improve your experience by sending an error report",
      "not-found": "A resource required not found",
      user_action: "Help us improve your experience by sending an error report",
      "invalid-api-key": "",
      "conflict": ""
    },

    error: {
      api_key_edit_missing_fields: "Every required field must be filled out to edit the API key",
      user_edit_id_not_found: "Cannot edit user: cannot find user identifier",

      invalid_date_filter_input: "Filter by date inputs are invalid",
      missing_api_key_fields: "Every required field must be filled out to create an API key",

      connection: "Network error. Please try again later",


      [errorCodeKey(ErrorCode.notFound.APPLICATION_NOT_FOUND)]: "Application not found",
      [errorCodeKey(ErrorCode.notFound.SESSION_NOT_FOUND)]: "Session not found",
      [errorCodeKey(ErrorCode.notFound.SUBSCRIPTION_NOT_FOUND)]: "Subscription not found",
      [errorCodeKey(ErrorCode.notFound.SUBSCRIPTIONS_NOT_FOUND)]: "No subscription found",
      [errorCodeKey(ErrorCode.notFound.USER_NOT_FOUND)]: "User not found",
      [errorCodeKey(ErrorCode.notFound.USERS_NOT_FOUND)]: "No users found",
      [errorCodeKey(ErrorCode.notFound.TOKEN_NOT_FOUND)]: "Token not found",
      [errorCodeKey(ErrorCode.notFound.WORKSPACE_NOT_FOUND)]: "Workspace not found",
      [errorCodeKey(ErrorCode.notFound.WORKSPACES_NOT_FOUND)]: "No workspace found",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_CANNOT_FIND_USER)]: "User not found",
      [errorCodeKey(ErrorCode.notFound.SSO_AUTH_CANNOT_FIND_USER_ID)]: "User not found",
      [errorCodeKey(ErrorCode.notFound.SESSION_HAS_EXPIRED)]: "Session has expired",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_USER_NOT_FOUND)]: "Unknown user",
      [errorCodeKey(ErrorCode.notFound.KEYCLOAK_WORKSPACE_NOT_FOUND)]: "Workspace not found",
      [errorCodeKey(ErrorCode.notFound.INDEX_NOT_FOUND)]: "Index not found",

      [errorCodeKey(ErrorCode.invArgs.ROLE_VERIFICATION_FAILED)]: "Failed to assign role",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_REMOVE_LAST_WORKSPACE_ADMINISTRATOR)]:
        "At least one administrator is needed to manage your workspace. They cannot be deleted",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_EDIT_LAST_WORKSPACE_ADMINISTRATOR_ROLE)]:
        "At least one administrator is needed to manage your workspace. The role cannot be changed",
      [errorCodeKey(ErrorCode.invArgs.CANNOT_CREATE_FREE_DEMO_API_KEY)]: "Failed to create a Free Demo application key",
      [errorCodeKey(ErrorCode.invArgs.INVALID_SUBSCRIPTION_ID)]: "Invalid subscription identifier",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_NAME_EXISTS)]: "Workspace name already exists",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_NAME_EXISTS_SCHEDULED_DELETION)]:
        "Workspace name is unavailable because it already exists and is being deleted",
      [errorCodeKey(ErrorCode.invArgs.SSO_LOGIN_INVALID_EMAIL)]: "Invalid email",
      [errorCodeKey(ErrorCode.invArgs.WORKSPACE_INVALID_ID)]: "Invalid workspace identifier",
      [errorCodeKey(ErrorCode.invArgs.CREATE_SESSION)]: "Failed to create session, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.GET_SESSION)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.GET_SESSIONS)]: "Invalid field(s): {{fields}}",
      // [errorCodeKey(ErrorCode.invArgs.SYNC_SESSION)]: "Failed to synchronize session, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.REVIEW_SESSION)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.EDIT_USER_ROLES)]: "Failed to edit user role",
      [errorCodeKey(ErrorCode.invArgs.GET_USER_BY_AUTH_ID)]: "Action failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.AUTHENTIFY_SSO)]: "Action failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.RESET_PASSWORD)]: "Reset failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.VALIDATE_PASSWORD_RESET_TOKEN)]: "Reset failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.EDIT_PASSWORD)]: "Edit failed. Please try again later",
      [errorCodeKey(ErrorCode.invArgs.GET_USER)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.EDIT_USER)]: "Invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.NEW_USER)]: "Failed to create user, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_WORKSPACE)]: "Failed to create workspace, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_WORKSPACE)]: "Failed to edit workspace, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.DELETE_USER)]: "Failed to delete user, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.LIST_APPLICATIONS)]: "Failed to list application, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_APPLICATION)]:
        "Failed to create application, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_APPLICATION)]:
        "Failed to update application, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_SUBSCRIPTION)]:
        "Failed to create subscription, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.UPDATE_SUBSCRIPTION)]:
        "Failed to update subscription, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.LOGIN)]: "Failed to connect, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.LOGIN_SSO)]: "Failed to connect, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.REFRESH_LOGIN_TOKEN)]: "Failed to connect, invalid field(s): {{fields}}",
      [errorCodeKey(ErrorCode.invArgs.CREATE_SUBSCRIPTION_NAME_ALREADY_EXISTS)]:
        "A subscription with that name already exists. Please rename your subscription",
      [errorCodeKey(ErrorCode.invArgs.CREATE_WORKSPACE_NAME_TOO_LONG)]:
        "Workspace creation failed: limited to 30 characters",

      [errorCodeKey(ErrorCode.permDenied.CANNOT_ACCESS_ROUTE_MISSING_AUTHORIZATION)]:
        "Unauthorized access: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_MAX_USES_EXCEEDED)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_WRONG_TYPE)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.permDenied.MISSING_API_KEY_AUTHORIZATION)]:
        "Unauthorized access: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.LIST_API_KEY_UNAUTHORIZED_WORKSPACE_IDS)]:
        "Unauthorized access: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SUB_WORKSPACE_AUTHORIZATION)]:
        "Cannot access this workspace: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SESSION_DATA_WRITE_AUTHORIZATION)]:
        "Cannot annotate session: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_SESSION_LIST_AUTHORIZATION)]:
        "Cannot access sessions: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_LIST_AUTHORIZATION)]:
        "Cannot access user roles: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_IAM_AUTHORIZATION)]:
        "Unauthorized access: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.MISSING_USER_EDIT_AUTHORIZATION)]:
        "Cannot edit users: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.WORKSPACE_CANNOT_MODIFY_OWN)]: "You cannot edit your own workspace",
      [errorCodeKey(ErrorCode.permDenied.WORKSPACE_SPECIFIED_CANNOT_BE_MODIFIED)]: "You cannot edit this workspace",
      [errorCodeKey(ErrorCode.permDenied.MISSING_WORKSPACE_LIST_AUTHORIZATION)]:
        "Cannot acces this page: insufficient permissions",
      [errorCodeKey(ErrorCode.permDenied.NO_TOKEN_PROVIDED)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.permDenied.TOKEN_CANNOT_DECODE)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.permDenied.KEYCLOAK_BAD_PASSWORD)]: "Failed to connect: incorrect login or password",
      [errorCodeKey(ErrorCode.permDenied.SQREEN_SUSPICIOUS_USER)]:
        "Failed to connect: please contact your administrator",

      [errorCodeKey(ErrorCode.notAuthed.TOKEN_DATA_NOT_VALID)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.CANNOT_AUTHENTICATE_REQUEST)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_LOGIN_FAIL)]:
        "Failed to connect: maximum connection attempts reached, please try again in 10 min",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_RESET_PASSWORD_REQ_FAIL)]:
        "Failed to connect: maximum passowrd reset attempts reached, please try again in 10 min",
      [errorCodeKey(ErrorCode.notAuthed.TOO_MUCH_RESET_PASSW_REQ_FAIL)]:
        "Failed to connect: maximum passowrd reset attempts reached, please try again in 10 min",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_USERNAME_PASSWORD)]:
        "Failed to connect: incorrect login or password",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_EXPIRED_TOKEN)]: "Please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_TOKEN)]: "Please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_INVALID_CODE)]: "Please reconnect",
      [errorCodeKey(ErrorCode.notAuthed.KEYCLOAK_CANNOT_REFRESH_EXPIRED_TOKEN)]: "Please reconnect",

      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_CONNECT)]:
        "Failed to reach authentication service: please try again",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_EDIT_PASSWORD)]:
        "Failed to edit password, please try again later",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_CANNOT_CREATE_USER)]: "Failed to create user, please try again later",
      [errorCodeKey(ErrorCode.internal.TOKEN_CANNOT_GENERATE)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.internal.TOKEN_CANNOT_DECODE)]: "Session expired, please reconnect",
      [errorCodeKey(ErrorCode.internal.ROLES_NOT_FOUND)]: "Roles not found",
      [errorCodeKey(ErrorCode.internal.USER_DELETION_FAILED)]: "Failed to delete user",
      [errorCodeKey(ErrorCode.internal.KEYCLOAK_INTERNAL_EXCEPTION)]: "Please reconnect later",
      [errorCodeKey(ErrorCode.internal.CANNOT_GENERATE_EMAIL_TEMPLATE)]:
        "Cannot perform action, please try again later",
      [errorCodeKey(ErrorCode.internal.CANNOT_SEND_EMAIL)]: "Cannot perform action, please try again later",
      [errorCodeKey(ErrorCode.internal.ENUM_VALUE_NOT_FOUND)]: "Please try again later",
      [errorCodeKey(ErrorCode.internal.INVALID_REQUEST)]: "Please try again later",
      [errorCodeKey(ErrorCode.internal.UNKNOWN_INTERNAL_EXCEPTION)]: "Unknown error. Please try again later",

      [fieldCodeKey(FieldCode.EMAIL)]: "email",
      [fieldCodeKey(FieldCode.USERNAME)]: "user name",
      [fieldCodeKey(FieldCode.PASSWORD)]: "password",
      [fieldCodeKey(FieldCode.FIRSTNAME)]: "first name",
      [fieldCodeKey(FieldCode.LASTNAME)]: "last name",
      [fieldCodeKey(FieldCode.POSITION)]: "position",
      [fieldCodeKey(FieldCode.USER_ID)]: "identifier",
      [fieldCodeKey(FieldCode.USER_AUTH_ID)]: "identifier",
      [fieldCodeKey(FieldCode.ROLE_ID)]: "role",
      [fieldCodeKey(FieldCode.TOKEN)]: "token",
      [fieldCodeKey(FieldCode.TIME_RANGE)]: "time range",
      [fieldCodeKey(FieldCode.SESSION_ID)]: "session identifier",
      [fieldCodeKey(FieldCode.SESSION_ID_LIST)]: "session list",
      [fieldCodeKey(FieldCode.SESSION_SYNC)]: "session sync status",
      [fieldCodeKey(FieldCode.WORKSPACE_ID)]: "workspace identifier",
      [fieldCodeKey(FieldCode.WORKSPACE_ID_LIST)]: "workspace list",
      [fieldCodeKey(FieldCode.WORKSPACE_NAME)]: "workspace name",
      [fieldCodeKey(FieldCode.WORKSPACE_DOMAIN)]: "workspace domain",
      [fieldCodeKey(FieldCode.APPLICATION_ID)]: "application identifier",
      [fieldCodeKey(FieldCode.APPLICATION_ID_LIST)]: "application list",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_NAME)]: "subscription name",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_KIND)]: "subscription type",
      [fieldCodeKey(FieldCode.SUBSCRIPTION_MAX_SESSION_COUNT)]: "maximum session count",
      [fieldCodeKey(FieldCode.LABEL_LIST)]: "labels",
      [fieldCodeKey(FieldCode.MEDIAS)]: "media",
      [fieldCodeKey(FieldCode.PARENT_SESSION_ID)]: "parent session identifier",
      [fieldCodeKey(FieldCode.RESULT_MISSING)]: "result",
      [fieldCodeKey(FieldCode.FACE_MATCHING_RESULT)]: "face comparison result",
      [fieldCodeKey(FieldCode.LIVENESS_RESULT)]: "liveness result",
      [fieldCodeKey(FieldCode.IS_REVIEWED)]: "result",
      [fieldCodeKey(FieldCode.RESULT_TYPE)]: "result",
      [fieldCodeKey(FieldCode.RESULT_IS_GENUINE)]: "result",
      [fieldCodeKey(FieldCode.RESULT_PROBABILITY)]: "result",
      [fieldCodeKey(FieldCode.RESULT_SCORE)]: "result",
      [fieldCodeKey(FieldCode.RESULT_TRIES)]: "result",
      [fieldCodeKey(FieldCode.RESULT_HEART_RATE)]: "result",
      [fieldCodeKey(FieldCode.RESULT_SIGNAL_LIST)]: "result",
      [fieldCodeKey(FieldCode.RESULT_SPECTRUM_LIST)]: "result",
      [fieldCodeKey(FieldCode.RESULT_REVIEW)]: "result",
      [fieldCodeKey(FieldCode.RESULT_MODEL_ID)]: "result",
      [fieldCodeKey(FieldCode.RESULT_THRESHOLD)]: "threshold result",
      [fieldCodeKey(FieldCode.APPLICATION_NAME)]: "application name",
      [fieldCodeKey(FieldCode.REFERRER_LIST)]: "referrers",
    },
  },
} as const;
