import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

// SDK
import {
  AcquisitionPreset,
  Button as UniButton,
  FullCapture,
  IadConfig,
  IadMode,
  RetryResult,
  SelfieCapture,
} from "@unissey/sdk-react";

// components
import DemoResults from "./DemoResult";

// constants
// import { config } from "../../constants/env";
import { Routes } from "../../constants/routes";

// types
import { isMobileDevice } from "../../utils/misc_util";
import { DemoMode } from "../../types/demo";
import { useScopedTranslation } from "../../i18n";
import { AgeSettings, SessionSettings } from "./SessionSettings";

import { useSessionConfig } from "../../hooks/use-session-config";
import { analyze, getVqcHintsType, prepareIad } from "../../services/analyze_service";
import InfoCard from "../../components/InfoCard";
import { ErrorOutlined, SignalWifiOff } from "@material-ui/icons";
import { usePersistedState } from "../../utils/persistence";
import { useAuth } from "../../auth";
import { AnalyzeProcessing, AnalyzeResponse } from "@unissey/common";
import { useQueryClient } from "react-query";
import axios from "axios";

export default function DemoPage() {
  const { t } = useScopedTranslation("demo_page.results_screen");

  const { preset: selectedPreset, iadEnabled, iadMode } = useSessionConfig();

  const auth = useAuth();

  const user = auth.user;

  const location = useLocation();
  const history = useHistory();

  const faceMatching = location.state ? (location?.state as any).faceMatching : undefined;

  useEffect(() => {
    if (location.hash !== "") history.push(Routes.DEMO_TOU + location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rawCgu = window.localStorage.getItem("cgu");
  if (!rawCgu) {
    return <Redirect to={Routes.DEMO_TOU + location.search} />;
  } else {
    const cgu = JSON.parse(rawCgu);
    if (!cgu.privacyPolicy || !cgu.termsOfUse) {
      return <Redirect to={Routes.DEMO_TOU + location.search} />;
    }
  }

  const handleBack = () => history.goBack();

  return (
    <>
      <Demo
        mode={faceMatching ? "liveness-and-face-comparison" : "liveness-only"}
        gdprConsent={user.privacyPolicy && user.termsOfUse}
        onExit={() => history.push(Routes.DEMO_TOU + location.search)}
        onFinish={() => history.push(Routes.DEMO_TOU)}
        finishText={t("finish")}
        preset={selectedPreset}
        iadEnabled={iadEnabled}
        iadMode={iadMode}
        onBack={handleBack}
      />
    </>
  );
}

type Props = {
  mode: DemoMode;
  gdprConsent: boolean;
  onExit?: () => void;
  onFinish: () => void;
  finishText: string;
  userId?: string;
  onBack: () => void;
  preset: AcquisitionPreset;
  iadEnabled: boolean;
  iadMode: IadMode;
};

type DemoStep =
  | "wait-for-iad-data"
  | "capture"
  | "analyzing"
  | "retry"
  | "result"
  | "liveness-retry"
  | "network-error"
  | "analysis-error";

export function Demo({ mode, onFinish, onBack, finishText, userId, preset, iadEnabled, iadMode }: Props) {
  const auth = useAuth();
  const { t, tCommon } = useScopedTranslation("demo_page");

  const [analyzeResponse, setAnalyzeResponse] = React.useState<AnalyzeResponse | undefined>(undefined);
  const [bundleId, setBundleId] = React.useState("");
  const [step, setStep] = React.useState<DemoStep>("capture");
  const [iadConfig, setIadConfig] = React.useState<IadConfig>({
    mode: IadMode.DISABLED,
    data: undefined,
  });

  const [analysisErrMsg, setAnalysisErrMsg] = React.useState("");
  const [analysisErrDetails, setAnalysisErrDetails] = React.useState("");

  const [allowRetry] = usePersistedState<boolean>("allowRetry", false);
  const [allowAge] = usePersistedState<AgeSettings>("allowAge", {
    userId: auth.user?.id,
    enabled: false,
    threshold: "18",
  });

  const [referenceMedia, setReferenceMedia] = React.useState<Blob | undefined>(undefined);

  const sdkStrings = t("sdk_strings", { returnObjects: true });

  const queryClient = useQueryClient();

  useEffect(() => {
    const iadDataSteps: DemoStep[] = ["capture", "liveness-retry"];

    if (iadDataSteps.includes(step) && iadEnabled && iadConfig?.mode !== iadMode) {
      let currentStep = step;
      setStep("wait-for-iad-data");
      prepareIad(userId)
        .then((data) => {
          setIadConfig({ mode: iadMode, data });
          setStep(currentStep);
        })
        .catch(() => {
          // If an error occurs, continue as normal, the user will then get an injection suspicion
          setIadConfig({ mode: iadMode });
          setStep(currentStep);
        });
    }
  }, [step, iadEnabled, iadConfig, userId, iadMode]);

  const handleRetry = () => {
    setIadConfig({ mode: IadMode.DISABLED, data: undefined });
    setStep("liveness-retry");
  };

  const handleFinish = () => setStep("result");

  const handleFullCaptureData = async (e: Event) => {
    const data = (e as CustomEvent<{ selfie: Blob; reference: Blob; metadata: unknown }>).detail;
    setReferenceMedia(data.reference);
    await performAnalyze(data);
  };

  const performAnalyze = async (data: { selfie: Blob; metadata: unknown; reference?: Blob }) => {
    setStep("analyzing");

    try {
      let processings: AnalyzeProcessing[] = ["liveness"];

      if (mode === "liveness-and-face-comparison") {
        processings.push("face-comparison");
      }

      if (auth.user?.canViewAgeFeature && allowAge?.enabled) {
        processings.push("age");
      }

      let analyzeResponse: AnalyzeResponse;

      try {
        analyzeResponse = await analyze(
          {
            selfie: data.selfie,
            gdprConsent: true,
            reference: data.reference,
            metadata: data.metadata as string,
            bundleId,
            processings,
            ageThreshold:
              auth.user?.canViewAgeFeature && allowAge?.enabled && allowAge?.threshold !== ""
                ? allowAge?.threshold
                : undefined,
          },
          userId
        );
      } catch (error) {
        console.log(error);

        if (axios.isAxiosError(error)) {
          setAnalysisErrMsg(error.response?.data.details.error.message);
          setAnalysisErrDetails(error.response?.data.details.error.details);
          setStep("analysis-error");
        }
        return;
      }

      setAnalyzeResponse(analyzeResponse);
      setBundleId(analyzeResponse.data.session_group_id);

      const shouldRetrySession =
        analyzeResponse && !analyzeResponse.data.is_genuine && analyzeResponse.data.retries_remaining > 0 && allowRetry;

      if (shouldRetrySession) setStep("retry");
      else setStep("result");

      await queryClient.invalidateQueries({ queryKey: ["subscriptionByDateRange"] });
    } catch (e) {
      if (e instanceof TypeError) {
        setStep("network-error");
      }
    }
  };

  const handleSelfie = async (e: Event) => {
    const data = (e as CustomEvent<{ media: Blob; metadata: unknown }>).detail;

    await performAnalyze({ selfie: data.media, reference: referenceMedia, metadata: data.metadata });
  };

  const isMobile = isMobileDevice();

  const backButton = (
    <UniButton variant="outlined" onClick={onBack}>
      <div slot="icon" style={{ fontSize: "12px" }}>
        <ArrowBackIosIcon fontSize="inherit" />
      </div>
      {tCommon("button_back")}
    </UniButton>
  );

  const stepToDisplay = {
    "wait-for-iad-data": (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="50vh">
        <CircularProgress />
      </Box>
    ),
    capture: (
      <>
        {mode === "liveness-only" && (
          <SelfieCapture
            strings={sdkStrings.selfieCapture}
            onSelfie={handleSelfie}
            recorderOptions={{ preset, config: { iadConfig } }}
          >
            <div slot="action-button">{backButton}</div>
          </SelfieCapture>
        )}

        {mode === "liveness-and-face-comparison" && (
          <FullCapture
            strings={{
              selfie: sdkStrings.selfieCapture,
              reference: sdkStrings.referenceCapture,
            }}
            onData={handleFullCaptureData}
            recorderOptions={{ preset, config: { iadConfig } }}
          >
            <div slot="action-button">{backButton}</div>
          </FullCapture>
        )}
      </>
    ),
    result: analyzeResponse && (
      <DemoResults
        finishText={finishText}
        response={analyzeResponse}
        onFinish={onFinish}
        retrySettingEnabled={allowRetry}
      />
    ),
    analyzing: (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="50vh">
        <CircularProgress /> <br /> <br /> <span style={{ fontSize: "16px" }}>{t("analysing")}...</span>
      </Box>
    ),
    retry: analyzeResponse && (
      <RetryResult
        hintKind={`${getVqcHintsType(analyzeResponse)}`}
        retriesLeft={analyzeResponse.data.retries_remaining}
        onFinish={handleFinish}
        onRetry={handleRetry}
        strings={sdkStrings.retryResult}
      />
    ),
    "liveness-retry": (
      <SelfieCapture
        strings={sdkStrings.selfieCapture}
        onSelfie={handleSelfie}
        recorderOptions={{ preset, config: { iadConfig } }}
      >
        <div slot="action-button">{backButton}</div>
      </SelfieCapture>
    ),
    "network-error": (
      <Box width="300px" height="300px" display="flex" flexDirection="column" alignItems="center">
        <InfoCard text={t("network_err")} icon={SignalWifiOff} /> <br /> {backButton}
      </Box>
    ),
    "analysis-error": (
      <Box width="300px" height="300px" display="flex" flexDirection="column" alignItems="center">
        <InfoCard text={`${analysisErrMsg} : ${analysisErrDetails}`} icon={ErrorOutlined} /> <br /> {backButton}
      </Box>
    ),
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box mr="1.5em" mt="2em" display="flex" justifyContent="flex-end">
        <SessionSettings />
      </Box>
      <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
        <Box
          display={isMobile ? "block" : "flex"}
          alignItems="center"
          justifyContent="center"
          m={isMobile ? "0" : "auto"}
          paddingTop={isMobile ? 0 : 2}
          maxWidth={isMobile ? "100%" : "85%"}
        >
          {stepToDisplay[step]}
        </Box>
        <Box mt={20} />
      </div>
    </Box>
  );
}
