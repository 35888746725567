import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useScopedTranslation } from "../../i18n";
import { ExpandMore } from "@material-ui/icons";
import { ListHeaderFilter } from "../../components/filters/ListFilter";
import CustomSelect from "../../components/forms/formComponents/CustomSelect";
import { useQuery } from "react-query";
import { getApplications, getIdentityRegistries, getWorkspaces } from "../../services/api_services";
import { DateHeaderFilter } from "../../components/filters/DateFilter";
import {
  SUBSCRIPTION_KINDS,
  IDENTITY_ACTIONS,
  IDENTITY_RESULTS,
  LivenessResultFilter,
  IdentitySessionsListFilters,
} from "@unissey/common";
import OkIcon from "../../components/OkIcon";
import StopIcon from "../../components/StopIcon";
import { LivenessChip } from "../../components/SessionResultChip";
import { Dispatch, SetStateAction } from "react";
import { IdentityResultChip } from "./IdentityResultChip";

type DateRange = {
  before?: Date;
  after?: Date;
};

type SortOrder = "asc" | "desc";
type GdprConsent = "granted" | "rejected";

type Props = {
  filters: IdentitySessionsListFilters;
  setFilters: Dispatch<SetStateAction<IdentitySessionsListFilters>>;
  onFilter: () => void;
  onReset: () => void;
};

export function IdentitySessionsFilters({ filters, setFilters, onFilter, onReset }: Props) {
  const { t, tCommon } = useScopedTranslation("identity_sessions_page.filter_section");

  const { data: workspaces } = useQuery(["workspaces-list"], () => getWorkspaces());
  const { data: applications } = useQuery(["applications-list"], () => getApplications());
  const { data: registries } = useQuery(["registries-list"], () => getIdentityRegistries());

  const workspacesItems = workspaces?.map((workspace) => ({ label: workspace.name })) ?? [];
  const subscriptionKindsItems = SUBSCRIPTION_KINDS.map((kind) => ({ label: tCommon(`sub_kind.${kind}`) }));
  const applicationsItems = applications?.map((application) => ({ label: application.name })) ?? [];
  const registriesItems = registries?.map((registry) => ({ label: registry.name })) ?? [];
  const actionKindItems = IDENTITY_ACTIONS.map((kind) => ({ label: tCommon(`identity_action_kind.${kind}`) }));

  const resultsItems = IDENTITY_RESULTS.map((result, i) => ({ label: <IdentityResultChip key={i} result={result} /> }));

  const LIVENESS_RESULTS: (LivenessResultFilter | undefined)[] = ["genuine", "fail", undefined];
  const GDPR_CONSENT_VALUES: GdprConsent[] = ["granted", "rejected"];

  const selectedWorkspaceIndices =
    workspaces && filters.workspacesIds
      ? filters.workspacesIds.map((id) => workspaces.findIndex((wk) => wk.id === id))
      : [];

  const selectedApplicationIndices =
    applications && filters.applicationIds
      ? filters.applicationIds.map((id) => applications.findIndex((app) => app.id === id))
      : [];

  const selectedRegistriesIndices =
    registries && filters.registryIds
      ? filters.registryIds.map((id) => registries.findIndex((app) => app.id === id))
      : [];

  const selectedSubKindIndices = filters.subscriptionKind
    ? filters.subscriptionKind.map((kind) => SUBSCRIPTION_KINDS.findIndex((k) => k === kind))
    : [];

  const selectedIdentityResultsIndices = filters.results
    ? filters.results.map((result) => IDENTITY_RESULTS.findIndex((r) => r === result))
    : [];

  const selectedActionKindIndices = filters.actionKind
    ? filters.actionKind.map((kind) => IDENTITY_ACTIONS.findIndex((k) => k === kind))
    : [];

  const selectedLivenessIndices = filters.liveness
    ? filters.liveness.map((result) => LIVENESS_RESULTS.findIndex((r) => r === result))
    : [];

  const selectedGdprConsentIndices = filters.gdprConsent
    ? filters.gdprConsent.map((value) => GDPR_CONSENT_VALUES.findIndex((v) => v === value))
    : [];

  const handleOrderSelection = (order: SortOrder) => setFilters((filter) => ({ ...filter, order }));
  const handleNbItemsSelection = (nbItems: number) => setFilters((filter) => ({ ...filter, nbItems }));

  const handleWorkspaceSelection = (selection: Set<number>) =>
    setFilters((filter) => ({
      ...filter,
      workspacesIds: workspaces ? Array.from(selection).map((index) => workspaces[index].id) : undefined,
    }));

  const handleApplicationSelection = (selection: Set<number>) =>
    setFilters((filter) => ({
      ...filter,
      applicationIds: applications ? Array.from(selection).map((index) => applications[index].id) : undefined,
    }));

  const handleRegistrySelection = (selection: Set<number>) =>
    setFilters((filter) => ({
      ...filter,
      registryIds: registries ? Array.from(selection).map((index) => registries[index].id) : undefined,
    }));

  const handleSubKindSelection = (selection: Set<number>) =>
    setFilters((filter) => ({
      ...filter,
      subscriptionKind: Array.from(selection).map((index) => SUBSCRIPTION_KINDS[index]),
    }));

  const handleResultSelection = (selection: Set<number>) =>
    setFilters((filter) => ({ ...filter, results: Array.from(selection).map((index) => IDENTITY_RESULTS[index]) }));

  const handleActionKindSelection = (selection: Set<number>) =>
    setFilters((filter) => ({ ...filter, actionKind: Array.from(selection).map((index) => IDENTITY_ACTIONS[index]) }));

  const handleLivenessSelection = (selection: Set<number>) =>
    setFilters((filter) => ({ ...filter, liveness: Array.from(selection).map((index) => LIVENESS_RESULTS[index]) }));

  const handleGdprConsentSelection = (selection: Set<number>) =>
    setFilters((filter) => ({
      ...filter,
      gdprConsent: Array.from(selection).map((index) => GDPR_CONSENT_VALUES[index]),
    }));

  const handleDateRangeChange = (range: DateRange) =>
    setFilters((filter) => ({ ...filter, beforeDate: range.before, afterDate: range.after }));

  const handleFilterValidation = () => {
    onFilter();
  };

  const handleFilterReset = () => {
    onReset();
  };

  return (
    <Box>
      <Accordion elevation={0} style={{ border: "solid 1px #ddd" }}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content">
          <Typography>{t("title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
              <Header
                text={t("filters_title.date")}
                filter={
                  <DateHeaderFilter
                    initialRange={{ before: filters.beforeDate, after: filters.afterDate }}
                    onRangeChanged={handleDateRangeChange}
                  />
                }
              />
              <Header
                text={t("filters_title.workspace")}
                filter={
                  <ListHeaderFilter
                    items={workspacesItems}
                    initialSelectedIndices={new Set(selectedWorkspaceIndices)}
                    onSelectionChanged={handleWorkspaceSelection}
                    textFilter={{ label: t("filters_label.workspace_name") }}
                  />
                }
              />
              <Header
                text={t("filters_title.registry")}
                filter={
                  <ListHeaderFilter
                    items={registriesItems}
                    initialSelectedIndices={new Set(selectedRegistriesIndices)}
                    onSelectionChanged={handleRegistrySelection}
                    textFilter={{ label: t("filters_label.registry_name") }}
                  />
                }
              />
              <Header
                text={t("filters_title.application")}
                filter={
                  <ListHeaderFilter
                    items={applicationsItems}
                    initialSelectedIndices={new Set(selectedApplicationIndices)}
                    onSelectionChanged={handleApplicationSelection}
                    textFilter={{ label: t("filters_label.application_name") }}
                  />
                }
              />
              <Header
                text={t("filters_title.subscription_type")}
                filter={
                  <ListHeaderFilter
                    items={subscriptionKindsItems}
                    initialSelectedIndices={new Set(selectedSubKindIndices)}
                    onSelectionChanged={handleSubKindSelection}
                  />
                }
              />
              <Header
                text={t("filters_title.identity_result")}
                filter={
                  <ListHeaderFilter
                    items={resultsItems}
                    initialSelectedIndices={new Set(selectedIdentityResultsIndices)}
                    onSelectionChanged={handleResultSelection}
                  />
                }
              />
              <Header
                text={t("filters_title.identity_action")}
                filter={
                  <ListHeaderFilter
                    items={actionKindItems}
                    initialSelectedIndices={new Set(selectedActionKindIndices)}
                    onSelectionChanged={handleActionKindSelection}
                  />
                }
              />
              <Header
                text={t("filters_title.liveness")}
                filter={
                  <ListHeaderFilter
                    items={LIVENESS_RESULTS.map((value) => ({ label: <LivenessChip value={value} /> }))}
                    initialSelectedIndices={new Set(selectedLivenessIndices)}
                    onSelectionChanged={handleLivenessSelection}
                  />
                }
              />
              <Header
                text={t("filters_title.gdpr_consent")}
                filter={
                  <ListHeaderFilter
                    items={[{ label: <OkIcon /> }, { label: <StopIcon /> }]}
                    initialSelectedIndices={new Set(selectedGdprConsentIndices)}
                    onSelectionChanged={handleGdprConsentSelection}
                  />
                }
              />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <CustomSelect
                style={{ height: "40px", marginRight: "20px" }}
                width={3}
                labelId="create-application-form-select-workspace"
                labelValue={t("filters_title.items_order")}
                menuItems={[
                  ["asc", t("order_asc")],
                  ["desc", t("order_desc")],
                ]}
                onChange={(e) => handleOrderSelection(e.target.value as SortOrder)}
                value={filters.order}
              />
              <CustomSelect
                style={{ height: "40px", marginRight: "20px" }}
                width={3}
                labelId="create-application-form-select-workspace"
                labelValue={t("filters_title.nb_items")}
                menuItems={[
                  [2, "2"],
                  [10, "10"],
                  [50, "50"],
                  [100, "100"],
                ]}
                onChange={(e) => handleNbItemsSelection(Number(e.target.value))}
                value={filters.nbItems}
              />
              <Button style={{ height: "30px" }} color="primary" variant="contained" onClick={handleFilterValidation}>
                {t("button_apply")}
              </Button>
              <Link href="#" style={{ cursor: "pointer", margin: 8 }} underline="always" onClick={handleFilterReset}>
                {t("button_clear")}
              </Link>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

type HeaderProps = {
  text: string;
  filter: React.ReactNode;
};

const useHeaderStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    margin: "4px",
    borderRight: "1px solid #eeeeee",
  },
  headerText: {
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

function Header({ text, filter }: HeaderProps) {
  const classes = useHeaderStyles();

  return (
    <Box className={classes.container}>
      <span className={classes.headerText}>{text}</span>
      <Box>{filter}</Box>
    </Box>
  );
}
