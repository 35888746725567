"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSubscriptionActive = void 0;
// An active subscription is a subscription that is usable
function isSubscriptionActive({ kind, startDate, endDate, fallbackBucketAutoCreated, buckets, maxSessionCount, sessionCount, }) {
    const currentDate = new Date();
    // We will store in the variable if the subscription is usable
    let isUsable = true;
    // We can't use a subscription that has not started
    if (startDate > currentDate)
        isUsable = false;
    // We can't use an expired subscription
    if (endDate && endDate < currentDate)
        isUsable = false;
    // Bucket subscription does not use the max session field, it is not relevant for this kind of subscriptions
    // If the max session is set and lower or equal to session count, the subscription is not usable
    if (kind !== "per_packet" && maxSessionCount && maxSessionCount <= sessionCount)
        isUsable = false;
    // Bucket subscription that does not has bucket autorenewal and has no bucket not consumed are not usable
    if (kind === "per_packet" &&
        !fallbackBucketAutoCreated &&
        buckets.filter((bucket) => bucket.status !== "consumed").length === 0)
        isUsable = false;
    // If the subscription does not match any of theses conditions it is usable and should be marked as active
    return isUsable;
}
exports.isSubscriptionActive = isSubscriptionActive;
