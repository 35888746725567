import {
  Box,
  Breadcrumbs,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { Routes } from "../../constants/routes";
import { useQuery } from "react-query";
import { GetSubscriptionResponse, UpdateSubscription, isSubscriptionActive } from "@unissey/common";
import { getSubscription } from "../../services/api_services";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { makeStyles } from "@material-ui/core/styles";
import { useScopedTranslation } from "../../i18n";
import dateFormat from "dateformat";
import EditSubscriptionModal from "./EditSubscriptionModal";
import { useState } from "react";
import { useAuth } from "../../auth";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  heading: {
    fontWeight: "bold",
  },
  cell: {
    paddingTop: "5px",
    paddingRight: "30px",
    paddingBottom: "5px",
  },
}));

export function SubscriptionDetails() {
  const auth = useAuth();
  const classes = useStyles();

  const canEditSubscription = auth.hasRoles(["subscription.edit"]);

  const { subscriptionId } = useParams<{ subscriptionId: string }>();

  const { t, tCommon } = useScopedTranslation("subscription_page");

  const [subToEdit, setSubToEdit] = useState<UpdateSubscription>();
  const [editionModalIsOpen, setEditionModalIsOpen] = useState<boolean>(false);

  const history = useHistory();

  const { data } = useQuery<GetSubscriptionResponse>(["subscriptionDetails", subscriptionId], () =>
    getSubscription(subscriptionId)
  );

  const isActive = data
    ? isSubscriptionActive({
        ...data.subscription,
        startDate: new Date(data.subscription.startDate),
        endDate: data.subscription.endDate ? new Date(data.subscription.endDate) : undefined,
      })
    : undefined;

  return (
    <>
      <Box display="flex" flexDirection="column" mt="25px">
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="primary" onClick={() => history.push(Routes.SUBSCRIPTIONS)} style={{ cursor: "pointer" }}>
              {t("details_modal.title")}
            </Link>
            <Typography>{data?.subscription?.name}</Typography>
          </Breadcrumbs>

          {canEditSubscription && (
            <Button
              data-testid={`edit-btn-${data?.subscription.name}`}
              onClick={(e) => {
                e.stopPropagation();
                setEditionModalIsOpen(true);
                if (data) {
                  setSubToEdit({
                    ...data?.subscription,
                    buckets: data?.subscription.buckets.map((bucket) => ({
                      id: bucket.id,
                      size: bucket.size,
                      sessionCount: bucket.sessionCount,
                      status: bucket.status,
                      action: "update",
                      autoGenerated: bucket.autoGenerated,
                    })),
                  });
                }
              }}
              variant="outlined"
            >
              {tCommon("button_edit")}
            </Button>
          )}
        </Box>

        <Box mt="45px" />

        <Typography variant="h6">{t("details_modal.title_details")}</Typography>

        <Box mt="20px" />

        <Box className={classes.root}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="details-pan" id="details-pan">
              <Typography className={classes.heading}>{t("details_modal.title_details")}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.cell}>
                      <strong>{t("details_modal.row_workspace")}</strong>
                    </td>
                    <td className={classes.cell}>{data?.subscription?.workspaceName}</td>
                  </tr>
                  <tr>
                    <td className={classes.cell}>
                      <strong>{t("details_modal.row_type")}</strong>
                    </td>
                    <td className={classes.cell}>
                      {data?.subscription !== undefined && tCommon(`sub_kind.${data?.subscription.kind}`)}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.cell}>
                      <strong>{t("details_modal.row_start_date")}</strong>
                    </td>
                    <td className={classes.cell}>{dateFormat(data?.subscription?.startDate, "dd/mm/yyyy HH:MM")}</td>
                  </tr>
                  <tr>
                    <td className={classes.cell}>
                      <strong>{t("details_modal.row_end_date")}</strong>
                    </td>
                    <td className={classes.cell}>
                      {data?.subscription?.endDate
                        ? dateFormat(data?.subscription?.endDate, "dd/mm/yyyy HH:MM")
                        : "N/A"}

                      {data && data?.subscription.autoRenewalDates?.length > 0 && (
                        <em style={{ color: "#aaa" }}>
                          &nbsp; ({t("details_modal.row_end_date_renewal")}:{" "}
                          {dateFormat(data?.subscription.autoRenewalDates.reverse()[0], "dd/mm/yyyy HH:MM")})
                        </em>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className={classes.cell}>
                      <strong>{t("details_modal.row_status")}</strong>
                    </td>
                    <td className={classes.cell}>
                      {isActive ? t("details_modal.status_active") : t("details_modal.status_inactive")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box mt="45px" />

        <Typography variant="h6">{t("details_modal.title_consumption")}</Typography>

        <Box mt="20px" />

        <Box className={classes.root}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="details-pan" id="details-pan">
              <Typography className={classes.heading}>{t("details_modal.title_overall_consumption")}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <table>
                <tbody>
                  <tr>
                    <td className={classes.cell}>
                      <strong>{t("details_modal.row_total_session_count")}</strong>
                    </td>
                    <td className={classes.cell}>{data?.subscription?.sessionCount}</td>
                  </tr>
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="details-pan" id="details-pan">
              <Typography className={classes.heading}>{t("details_modal.title_details_consumption")}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <table>
                <tbody>
                  {data?.applications?.map((app) => (
                    <tr key={app.id}>
                      <td className={classes.cell}>
                        <strong>{app.name}</strong>
                      </td>
                      <td className={classes.cell}>{app.sessionCount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AccordionDetails>
          </Accordion>

          {data?.subscription.kind === "per_packet" && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="details-pan" id="details-pan">
                <Typography className={classes.heading}>{t("details_modal.bucket_consumption")}</Typography>
              </AccordionSummary>

              <AccordionDetails>
                <table>
                  <thead>
                    <tr>
                      <th className={classes.cell}>{t("details_modal.bucket_order")}</th>
                      <th className={classes.cell}>{t("details_modal.title_consumption")}</th>
                      <th className={classes.cell}>{t("details_modal.bucket_size")}</th>
                      <th className={classes.cell}>{t("details_modal.row_status")}</th>
                      <th className={classes.cell}>{t("details_modal.bucket_type")}</th>
                      <th className={classes.cell}>{t("details_modal.bucket_creation_date")}</th>
                      <th className={classes.cell}>{t("details_modal.bucket_start_date")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.subscription.buckets?.map((bucket, i) => (
                      <tr key={i}>
                        <td className={classes.cell}>
                          {t("details_modal.bucket")} {i + 1}
                        </td>
                        <td className={classes.cell}>{bucket.sessionCount}</td>
                        <td className={classes.cell}>{bucket.size}</td>
                        <td className={classes.cell}>
                          {
                            {
                              in_progress: `${t("bucket_status.in_progress")}`,
                              not_activated: `${t("bucket_status.not_activated")}`,
                              consumed: `${t("bucket_status.consumed")}`,
                            }[bucket.status]
                          }
                        </td>
                        <td className={classes.cell}>
                          {bucket.autoGenerated ? t("details_modal.bucket_fallback") : t("details_modal.bucket_paid")}
                        </td>
                        <td className={classes.cell}>{dateFormat(bucket.createdAt, "dd/mm/yyyy HH:MM")}</td>
                        <td className={classes.cell}>
                          {bucket.startDate ? dateFormat(bucket.startDate, "dd/mm/yyyy HH:MM") : "N/A"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AccordionDetails>
            </Accordion>
          )}
        </Box>

        <Box mt="50px" />
      </Box>

      {canEditSubscription && subToEdit ? (
        <EditSubscriptionModal
          subscription={subToEdit}
          open={editionModalIsOpen}
          onClose={() => {
            setSubToEdit(undefined);
            setEditionModalIsOpen(false);
          }}
        />
      ) : null}
    </>
  );
}
