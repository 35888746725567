import React, { MouseEvent, useCallback, useState } from "react";
import { Box, IconButton, Menu, Link, Button, Chip, CircularProgress, Grid } from "@material-ui/core";
import { ExpandLess, ExpandMore, CheckCircleOutline } from "@material-ui/icons";
import theme from "../../config/theme";
import { useTranslation } from "react-i18next";

type Props = {
  onClearAll?: () => void;
  disableClear?: boolean;
  onSelectAll?: () => void;
  onCancel: () => void;
  onConfirm: () => void;
  activeIndicator?: string;
};

const DISPLAYING_TIME = 500;

export const HeaderFilterMenu: React.FC<Props> = ({
  onClearAll,
  disableClear,
  onSelectAll,
  onCancel,
  onConfirm,
  activeIndicator,
  children,
}) => {
  const { t } = useTranslation();
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null);
  const [iconState, setIconState] = useState<"loading" | "validate" | "">("");

  const handleTimeout = (handler: (() => void) | undefined) => {
    if (!handler) return;

    handler();
    setIconState("loading");
    setTimeout(() => {
      setIconState("validate");
    }, DISPLAYING_TIME / 2);
    setTimeout(() => {
      setIconState("");
    }, DISPLAYING_TIME * 2);
  };

  const callAndCloseMenu = (cb: () => void) => () => {
    setFilterAnchor(null);
    cb();
  };

  const handleCallAndClose = useCallback((cb: () => void) => {
    handleTimeout(callAndCloseMenu(cb));
  }, []);

  const handleClearAll = () => {
    handleTimeout(onClearAll);
  };

  const handleSelectAll = () => {
    handleTimeout(onSelectAll);
  };

  const menuContent = (
    <Box padding={1}>
      <Grid container direction="row" spacing={2}>
        {onClearAll && (
          <Grid item xs>
            <Link
              component="button"
              onClick={handleClearAll}
              disabled={disableClear}
              underline={disableClear ? "none" : "always"}
              style={{ cursor: disableClear ? "default" : "pointer" }}
            >
              {t("clear_all")}
            </Link>
          </Grid>
        )}
        {onSelectAll && (
          <Grid item xs>
            <Link component="button" onClick={handleSelectAll} underline="always" style={{ cursor: "pointer" }}>
              {t("select_all")}
            </Link>
          </Grid>
        )}
        <Grid item container direction="row-reverse" xs={4}>
          <Grid item style={{ color: theme.palette.success.main }}>
            {iconState === "loading" ? (
              <CircularProgress size={20} />
            ) : iconState === "validate" ? (
              <CheckCircleOutline />
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
      <Box m={1}>{children}</Box>
      <Box m={1} />
      <Box display="flex" justifyContent="center" m={1}>
        <Button
          onClick={callAndCloseMenu(onCancel)}
          variant="outlined"
          style={{
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
            borderWidth: "1px",
            borderStyle: "solid",
            padding: "unset",
            textTransform: "unset",
          }}
          disabled={iconState === "loading"}
        >
          {t("sessions_page.filters.label_filter_cancel")}
        </Button>
        <Box m={1} />
        <Button
          onClick={() => handleCallAndClose(onConfirm)}
          variant="contained"
          style={{
            backgroundColor: theme.palette.success.main,
            color: "white",
            padding: "unset",
            textTransform: "unset",
          }}
          disabled={iconState === "loading"}
        >
          {t("sessions_page.filters.label_filter_confirm")}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box display="flex">
      <IconButton
        aria-haspopup="true"
        aria-controls="customized-menu"
        onClick={(e: MouseEvent<HTMLElement>) => setFilterAnchor(e.currentTarget)}
        size="small"
      >
        {filterAnchor ? (
          <ExpandLess htmlColor={activeIndicator && theme.palette.success.main} />
        ) : (
          <ExpandMore htmlColor={activeIndicator && theme.palette.success.main} />
        )}
      </IconButton>
      {activeIndicator && (
        <Chip size="small" label={activeIndicator} style={{ backgroundColor: theme.palette.success.main }} />
      )}
      <Menu
        anchorEl={filterAnchor}
        getContentAnchorEl={null}
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        open={Boolean(filterAnchor)}
        variant="menu"
        onClose={() => {
          setFilterAnchor(null);
          onCancel();
        }}
      >
        {menuContent}
      </Menu>
    </Box>
  );
};
