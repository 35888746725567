import { SvgIconTypeMap, makeStyles, CircularProgress } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { useCallback, useEffect, useState } from "react";
import { isSafari } from "react-device-detect";

import FallbackSrc from "./FallbackSrc";
import RatioBox from "./RatioBox";
import PaginationButtons from "./PaginationButtons";
import TitledSection from "../../../components/TitledSection";

import { MjpgPlayer } from "../../../utils/mjpegPlayer";
import { useScopedTranslation } from "../../../i18n";

const useStyles = makeStyles({
  centeredContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

export interface VideoProp {
  src: string | undefined;
  className?: string;
  onError?: () => void;
}

export interface VideoFallbackProp {
  message: string;
  icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

export enum PlayerKind {
  Mjpg,
  Std,
}

interface VideoSectionProps {
  videoProps: VideoProp & VideoFallbackProp;
  playerKind: PlayerKind;
  videosCount: number;
  selectedIdx: number;
  onClick: (index: number) => void;
}

export default function VideoSection(props: VideoSectionProps) {
  const { t } = useScopedTranslation("sessions_page.details_modal");
  const classes = useStyles();

  return (
    <TitledSection
      title={t("title_video", { attempt: props.selectedIdx + 1, count: props.videosCount ?? 1 })}
      child={
        <div className={classes.centeredContent}>
          <RatioBox
            aspectRatio={[1, 1]}
            child={
              <FallbackSrc
                child={
                  props.playerKind === PlayerKind.Mjpg ? (
                    <MjpgVideoSection {...props.videoProps} />
                  ) : (
                    <StdVideoSection {...props.videoProps} />
                  )
                }
                {...props.videoProps}
              />
            }
          />
          {props.videosCount > 1 && (
            <PaginationButtons count={props.videosCount} selectedIdx={props.selectedIdx} onClick={props.onClick} />
          )}
        </div>
      }
    />
  );
}

function MjpgVideoSection(props: VideoProp) {
  const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
  const [player, setPlayer] = useState<MjpgPlayer>();
  const [reloading, setReloading] = useState(false);

  const { src, onError } = props;

  const [canvasRef, setCanvasRef] = useState<HTMLCanvasElement>();

  // Detect when / if the canvas ref changes to re-init the player
  const onRefChange = useCallback(
    (node) => {
      setCanvasRef(node);
      if (node) {
        player?.init(node);
      }
    },
    [player]
  );

  // Stop reloading when new src received
  useEffect(() => setReloading(false), [src]);

  // Fetch data whenever src changes
  useEffect(() => {
    const fetchVideo = async (videoUrl: string) => {
      await fetch(videoUrl)
        .then((r) => r.blob())
        .then((blob) => setVideoBlob(blob))
        .catch(() => {
          // props.onError should fetch new video
          // URL due to google api token expiration
          if (onError) {
            setReloading(true);
            onError();
          }
        });
    };
    if (src !== undefined) fetchVideo(src);
  }, [src, onError]);

  // Create or update the player whenever canvas or data changes
  useEffect(() => {
    const canvas = canvasRef;
    if (canvas && videoBlob) {
      let setupPlayer: () => Promise<void>;
      if (!player) {
        setupPlayer = async () => {
          const player = new MjpgPlayer(canvas);
          const openResult = await player.open(videoBlob);
          if (openResult !== null) {
            return onError?.();
          }
          player.start();
          setPlayer(player);
        };
      } else {
        setupPlayer = async () => {
          player.stop();
          await player.open(videoBlob);
          player.start();
        };
      }
      setupPlayer();
    }
  }, [videoBlob, canvasRef, onError, player]);

  if (reloading) return <CircularProgress />;

  return <canvas ref={onRefChange} className={props.className} />;
}

const StdVideoSection = (props: VideoProp) => {
  const [reloading, setReloading] = useState(false);

  useEffect(() => setReloading(false), [props]);
  if (reloading) return <CircularProgress />;

  return (
    <div style={{ height: "100%" }}>
      <video
        className={props.className}
        src={props.src}
        autoPlay
        loop
        controls
        onError={() => {
          // props.onError should fetch new video
          // URL due to google api token expiration
          if (props.onError && !isSafari) {
            setReloading(true);
            props.onError();
          }
        }}
      />
    </div>
  );
};
