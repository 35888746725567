import { action, autorun, makeObservable, observable, runInAction } from "mobx";
import { config } from "../../constants/env";
import { SubscriptionKind } from "@unissey/common";
import { WorkspaceCreateTeamMember } from "@unissey/common";

const DEFAULT_USER: WorkspaceCreateTeamMember = {
  email: "",
  firstName: "",
  hasGdpr: false,
  lastName: "",
  roleKey: "user",
};

const DEFAULT_ADMIN: WorkspaceCreateTeamMember = {
  email: "",
  firstName: "",
  hasGdpr: false,
  lastName: "",
  roleKey: "administrator",
};

const DEFAULT_SUBSCRIPTION: WorkspaceCreateSubscription = {
  name: "",
  kind: "integrated_demo",
  startDate: new Date(),
};

export const ALLOWED_SUBSCRIPTION_KIND: SubscriptionKind[] = ["free_demo", "integrated_demo"];

export type WorkspaceCreateSubscription = {
  name: string;
  kind: SubscriptionKind;
  maxSessionCount?: number;
  startDate: Date;
  endDate?: Date;
};

export type WorkspaceCreationState = {
  workspaceName: string;
};

export class WorkspaceCreation {
  administrator: WorkspaceCreateTeamMember;
  isLoading: boolean;
  users: Map<string, WorkspaceCreateTeamMember>;
  subscription?: WorkspaceCreateSubscription;
  wsState: WorkspaceCreationState;

  constructor({ withSubscription }: { withSubscription: boolean }) {
    makeObservable(
      this,
      {
        administrator: observable,
        isLoading: observable,
        users: observable,
        wsState: observable,
        subscription: observable,

        reset: action.bound,
        addTeamMember: action.bound,
        setIsLoading: action.bound,
        updateAdmin: action.bound,
        updateTeamMember: action.bound,
        updateWorkspace: action.bound,
        removeTeamMember: action.bound,
        updateSubscription: action.bound,
      },
      { autoBind: true }
    );
    this.administrator = DEFAULT_ADMIN;
    this.isLoading = false;
    this.users = new Map();
    this.wsState = { workspaceName: "" };

    if (withSubscription) {
      this.subscription = DEFAULT_SUBSCRIPTION;
    }

    // We automatically generate the subscription name based on some of the
    // other information
    autorun(() => {
      const sub = this.subscription;
      if (sub && this.wsState.workspaceName) {
        const subName = `${this.wsState.workspaceName} ${subscriptionKindName(sub.kind)}`;
        runInAction(() => (sub.name = subName));
      }
    });
  }

  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  public reset(): void {
    this.administrator = DEFAULT_ADMIN;
    this.users.clear();
    this.wsState = { workspaceName: "" };
    this.isLoading = false;

    if (this.subscription) {
      this.subscription = DEFAULT_SUBSCRIPTION;
    }
  }

  public updateWorkspace<K extends keyof WorkspaceCreationState>(field: K, value: WorkspaceCreationState[K]) {
    this.wsState[field] = value;
  }

  public updateAdmin<K extends keyof WorkspaceCreateTeamMember>(field: K, value: WorkspaceCreateTeamMember[K]) {
    this.administrator[field] = value;
  }

  public addTeamMember(id: string) {
    this.users.set(id, DEFAULT_USER);
  }

  public removeTeamMember(id: string) {
    this.users.delete(id);
  }

  public updateTeamMember<K extends keyof WorkspaceCreateTeamMember>(
    id: string,
    field: K,
    value: WorkspaceCreateTeamMember[K]
  ) {
    const tmp = this.users.get(id);
    if (tmp) {
      this.users.set(id, { ...tmp, [field]: value });
    }
  }

  public updateSubscription<K extends keyof WorkspaceCreateSubscription>(
    field: K,
    value: WorkspaceCreateSubscription[K]
  ) {
    if (this.subscription) {
      this.subscription[field] = value;
    }
  }
}

// This is used to generate subscription names. It should probably not be
// localized as its something we store in the database
// Right now we only allow creating subscription types from `ALLOWED_SUBSCRIPTION_KIND`
// but maybe porduct will want to loosen that restriction in the future, so we
// still allow handling all the cases for now
function subscriptionKindName(kind: SubscriptionKind): string {
  switch (kind) {
    case "free_demo":
      return "Free Demo";

    case "integrated_demo":
      return config.useContinuousIntegrationSubscriptions ? "Continuous Integration" : "Trial Subscription";

    default:
      return "not allowed";
  }
}
