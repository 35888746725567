import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { Trans } from "react-i18next";
// constants
import { Routes } from "../../constants/routes";

import FaceRounded from "@material-ui/icons/FaceRounded";
import WifiTetheringRounded from "@material-ui/icons/WifiTetheringRounded";
import theme from "../../config/theme";
import { useScopedTranslation } from "../../i18n";
import { DemoMode } from "../../types/demo";
import { SessionSettings } from "./SessionSettings";
import { usePersistedState } from "../../utils/persistence";

import { useAuth } from "../../auth";

const useStyles = makeStyles({
  formControlLabel: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[200]}`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: 0,
    fontSize: "12px",
  },

  button: {
    fontSize: "14px",
  },

  termsOfUseText: {
    fontSize: "16px",
  },

  [theme.breakpoints.down("xs")]: {
    button: {
      fontSize: "12px",
    },

    termsOfUseText: {
      fontSize: "14px",
    },
  },
});

export default function TermsOfUsePage() {
  const history = useHistory();
  const location = useLocation();

  return (
    <Box mt="2em">
      <TermsOfUse
        onDemoModeChosen={(mode) => {
          history.push({
            pathname: Routes.DEMO_CAPTURE,
            search: location.search,
            state: {
              faceMatching: mode === "liveness-and-face-comparison",
            },
          });
        }}
        availableModes={["liveness-and-face-comparison", "liveness-only"]}
      />
    </Box>
  );
}

type Props = {
  availableModes: DemoMode[];
  onDemoModeChosen: (mode: DemoMode) => void;
};

export function TermsOfUse({ availableModes, onDemoModeChosen }: Props) {
  const { t } = useScopedTranslation("demo_page");
  const classes = useStyles();
  const mobileBP = useMediaQuery("(min-width:600px)");
  const smallHeightBP = useMediaQuery("(min-height:800px)");

  const auth = useAuth();
  const user = auth.user!;

  const [cgu, setCgu] = usePersistedState("cgu", {
    userId: user?.id,
    termsOfUse: false,
    privacyPolicy: false,
  });

  const canContinue = cgu.termsOfUse && cgu.privacyPolicy;

  const onContinue = (mode: DemoMode) => () => {
    if (!canContinue) return;
    onDemoModeChosen(mode);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="flex-end" mr="1.5em">
        <SessionSettings enabled={true} />
      </Box>
      <div style={{ height: "100%", overflow: "auto", margin: "4px 4px" }}>
        <Box
          display="flex"
          flex="1"
          alignItems="center"
          justifyContent="center"
          paddingTop={mobileBP && smallHeightBP ? 8 : 2}
        >
          <Grid container alignItems="center" justifyContent="center">
            <Grid xs={12} md={8} lg={6} item>
              <Box display="flex" flexDirection="column" alignItems="stretch">
                <Typography align="center" variant="h6">
                  {t("title")}
                </Typography>

                <Box m={1} />

                <FormGroup row aria-required>
                  <Grid xs={12} item>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          checked={cgu.termsOfUse}
                          onChange={(event) => {
                            setCgu((prev) => ({ ...prev, termsOfUse: event.target.checked, userId: user?.id }));
                          }}
                          color="primary"
                          data-testid="cgu"
                        />
                      }
                      label={
                        <span className={classes.termsOfUseText}>
                          <Trans
                            i18nKey="demo_page.terms_of_use"
                            components={[
                              <a href={t("terms_of_use_url")} target="_blank" rel="noopener noreferrer">
                                policy
                              </a>,
                            ]}
                          />
                        </span>
                      }
                    />
                    <Box m={1} />
                  </Grid>

                  <Grid xs={12} item>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <Checkbox
                          checked={cgu.privacyPolicy}
                          onChange={(event) => {
                            setCgu((prev) => ({ ...prev, privacyPolicy: event.target.checked, userId: user?.id }));
                          }}
                          color="primary"
                          data-testid="gdpr"
                        />
                      }
                      label={
                        <span className={classes.termsOfUseText}>
                          <Trans
                            i18nKey="demo_page.privacy_policy"
                            components={[
                              <a href={t("privacy_policy_url")} target="_blank" rel="noopener noreferrer">
                                policy
                              </a>,
                            ]}
                          />
                        </span>
                      }
                    />
                    <Box m={1} />
                  </Grid>
                </FormGroup>

                <Box m={1} />

                <Box display="flex" justifyContent="center" flexDirection={!mobileBP ? "column" : "row"}>
                  {availableModes.includes("liveness-and-face-comparison") && (
                    <Button
                      startIcon={<FaceRounded />}
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      disableElevation
                      disabled={!canContinue}
                      onClick={onContinue("liveness-and-face-comparison")}
                      data-testid="liveness-and-face-comparison-btn"
                    >
                      {t("liveness_plus_face_comparison")}
                    </Button>
                  )}
                  <Box m={1} />
                  {availableModes.includes("liveness-only") && (
                    <Button
                      startIcon={<WifiTetheringRounded />}
                      color="primary"
                      variant="contained"
                      className={classes.button}
                      disableElevation
                      disabled={!canContinue}
                      onClick={onContinue("liveness-only")}
                      data-testid="liveness-only-btn"
                    >
                      {t("liveness")}
                    </Button>
                  )}
                </Box>
                <Box m={4} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Box>
  );
}
