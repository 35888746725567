import {
  AppBar,
  Box,
  Container,
  CssBaseline,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// Icons
import HowToRegIcon from "@material-ui/icons/HowToRegOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import SupervisedUserIcon from "@material-ui/icons/SupervisedUserCircleOutlined";
import VideocamIcon from "@material-ui/icons/VideocamOutlined";
import DnsIcon from "@material-ui/icons/Dns";
import LocalOffer from "@material-ui/icons/LocalOffer";
import MenuBook from "@material-ui/icons/MenuBookOutlined";
import TableChartIcon from "@material-ui/icons/TableChartOutlined";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";

import { RoleKey } from "@unissey/common";

import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
// components
import Copyright from "../components/Copyright";
import PrivateRoute from "../components/PrivateRoute";
// constants
import { pageHeader, Routes } from "../constants/routes";

// utils
import { isMobileDevice } from "../utils/misc_util";
// pages
import WorkspacePage from "./workspace/WorkspacePage";
import DemoPage from "./demo/DemoPage";
import NotFoundPage from "./error/NotFoundPage";
import ProfilePage from "./profile/ProfilePage";
import SessionsPage from "./session/SessionsPage";
import TermsOfUsePage from "./demo/TermsOfUsePage";
import ApiKeyPage from "./api/ApiKeyPage";
import SubscriptionsPage from "./subscription/SubscriptionsPage";
import DocumentationPage from "./documentation/DocumentationPage";
import BillingPage from "./billing/BillingPage";

import theme from "../config/theme";

import { useTranslation } from "react-i18next";
import { LanguageSwitchMenu } from "../components/LanguageSwitchMenu";
import { ProfileMenu } from "../components/ProfileMenu";
import { ArrowLeft, ArrowRight, FolderShared } from "@material-ui/icons";
import { UnisseySymbolIcon } from "../components/UnisseySymbolIcon";
import { SubscriptionDetails } from "./subscription/SubscriptionDetails";
import DemoReminder from "../components/DemoReminder";
import AppVersion from "../components/AppVersion";
import { useAuth } from "../auth";
import { IdentitySessionsPage } from "./identity-sessions/IdentitySessionsPage";
import { RegistriesPage } from "./registry/RegistriesPage";
import { IdentitiesPage } from "./identities/IdentitiesPage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
    height: "100vh",
  },
  rootReminder: {
    display: "flex",
    backgroundColor: theme.palette.common.white,
    height: "96vh",
  },
  toolbar: {
    paddingRight: isMobileDevice() ? theme.spacing(1) : 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    // padding: `0 ${theme.spacing(1.2)}px`,
    ...theme.mixins.toolbar,
  },
  logo: {
    marginRight: "auto",
    marginLeft: "auto",
    maxWidth: 120,
  },
  iconHitbox: {
    width: 32,
    height: 32,
    borderRadius: 15,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    border: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarReminder: {
    top: "4vh",
  },
  appBarShift: {
    marginLeft: isMobileDevice() ? 0 : drawerWidth,
    width: isMobileDevice() ? "100%" : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  userButton: {
    maxWidth: isMobileDevice() ? "50%" : "auto",
  },
  title: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    // paddingBottom: theme.spacing(2),
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: "#F3F5F8",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100%",
    width: `calc(100% - ${drawerWidth}px)`,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/wave-main.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
    backgroundSize: "auto 153px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    padding: `${isMobileDevice() ? theme.spacing(6) : 0}px ${isMobileDevice() ? theme.spacing(1) : theme.spacing(3)}px`,
    position: "relative",
    overflow: "auto",
    justifyContent: "flex-start",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: `${isMobileDevice() ? theme.spacing(2) : theme.spacing(2)}px`,
    paddingRight: `${isMobileDevice() ? theme.spacing(2) : theme.spacing(2)}px`,
    height: "100%",
  },
  navBottom: {
    marginTop: "auto",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    color: "white",

    height: "100%",

    backgroundImage: `url(${process.env.PUBLIC_URL}/images/wave-nav.svg)`,
    backgroundRepeat: "no-repeat",
    backgroundPositionY: "bottom",
  },
  navBackgroundOpen: {
    backgroundSize: "100% auto",
  },
  navBackgroundClosed: {
    backgroundSize: "auto 154px",
    backgroundPositionX: "right",
  },
  copyright: {
    marginTop: "auto",
  },
  iconClosedDrawer: {
    marginLeft: theme.spacing(1),
  },
  menuItemFocused: {
    backgroundColor: "lightgray",
  },
}));

export default function Dashboard({
  changeDemoReminder,
  showDemoReminder,
}: {
  changeDemoReminder: (value: "display" | "hide" | "closed") => void;
  showDemoReminder: "display" | "hide" | "closed";
}) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const [drawerOpen, setDrawerOpen] = useState(true);

  const auth = useAuth();
  const rawCgu = window.localStorage.getItem("cgu");
  const rawAllowAge = window.localStorage.getItem("allowAge");

  let refreshTimeout: NodeJS.Timeout;

  useEffect(() => {
    if (showDemoReminder === "hide") changeDemoReminder(auth.user.demoSubscription ? "display" : "closed");
  }, [auth.user.demoSubscription, changeDemoReminder, showDemoReminder]);

  useEffect(() => {
    if (rawCgu) {
      if (auth.user.id !== JSON.parse(rawCgu).userId) {
        window.localStorage.removeItem("cgu");
        history.go(0);
      }
    }
    if (rawAllowAge) {
      if (auth.user.id !== JSON.parse(rawAllowAge).userId) {
        window.localStorage.removeItem("allowAge");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mainMenuItems: {
    name: string;
    route: string;
    icon: any;
    roles: RoleKey[];
    roleCheck?: "oneOf" | "allOf";
    displayCondition?: boolean;
    testId: string;
  }[] = [
    {
      name: t("nav_menu.demo"),
      route: Routes.DEMO_TOU,
      icon: VideocamIcon,
      roles: ["demo.use"],
      testId: "menu-demo",
    },
    {
      name: t("nav_menu.sessions"),
      route: Routes.SESSIONS,
      icon: HowToRegIcon,
      roles: ["session.list"],
      testId: "menu-sessions",
    },
    {
      name: t("nav_menu.identity_sessions"),
      route: Routes.IDENTITY_SESSIONS,
      icon: HowToRegIcon,
      roles: ["super_administrator", "internal_tech_user"],
      roleCheck: "oneOf",
      testId: "menu-identity-sessions",
    },
    {
      name: t("nav_menu.identities"),
      route: Routes.IDENTITIES,
      icon: PermIdentityIcon,
      roles: ["super_administrator", "internal_tech_user"],
      roleCheck: "oneOf",
      testId: "menu-identities",
    },
    {
      name: t("nav_menu.workspaces"),
      route: Routes.WORKSPACE,
      icon: SupervisedUserIcon,
      roles: ["workspace.list"],
      testId: "menu-workspaces",
    },
    {
      name: t("nav_menu.registries"),
      route: Routes.REGISTRIES,
      icon: FolderShared,
      roles: ["super_administrator", "internal_tech_user"],
      roleCheck: "oneOf",
      testId: "menu-registries",
    },
    {
      name: t("nav_menu.subscriptions"),
      route: Routes.SUBSCRIPTIONS,
      icon: LocalOffer,
      roles: ["subscription.list"],
      displayCondition: auth.user?.workspace.isIntegrator,
      testId: "menu-subscriptions",
    },
    {
      name: t("nav_menu.applications"),
      route: Routes.APPLICATIONS,
      icon: DnsIcon,
      roles: ["apikey.list"],
      displayCondition: auth.user?.workspace.isIntegrator,
      testId: "menu-applications",
    },
    {
      name: t("nav_menu.documentation"),
      route: Routes.DOCUMENTATION,
      icon: MenuBook,
      roles: ["documentation.list"],
      testId: "menu-documentation",
    },
    {
      name: t("nav_menu.billing"),
      route: Routes.BILLING,
      icon: TableChartIcon,
      roles: ["billing.list"],
      testId: "menu-billing",
    },
  ];

  function handleLogout() {
    clearTimeout(refreshTimeout);
    auth.access.keycloak.logout();
  }

  const getDisplayName = () =>
    auth.user?.firstName && auth.user?.lastName && auth.user?.workspace.name
      ? `${auth.user?.firstName} ${auth.user?.lastName} (${auth.user?.workspace.name})`
      : auth.user?.authName;

  const availableMenuItems = mainMenuItems.filter((m) => {
    const roleCheck = m.roleCheck ?? "allOf";
    let isAuthorized = false;

    if (roleCheck === "allOf") isAuthorized = auth.hasRoles(m.roles);
    if (roleCheck === "oneOf") isAuthorized = auth.hasOneOfRoles(m.roles);

    return isAuthorized && m.displayCondition !== false;
  });

  const hasDemoAccess = auth.hasRoles(["demo.use"]);
  const defaultRoute = hasDemoAccess ? Routes.DEMO_TOU : mainMenuItems[0].route; // there should always at least be one item available

  return (
    <>
      {showDemoReminder === "display" && <DemoReminder handleReminder={changeDemoReminder} />}
      <div className={showDemoReminder === "display" ? classes.rootReminder : classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          elevation={0}
          className={clsx(
            classes.appBar,
            drawerOpen && classes.appBarShift,
            showDemoReminder === "display" && classes.appBarReminder
          )}
        >
          <Toolbar className={classes.toolbar}>
            {!drawerOpen && (
              <>
                {auth.user && auth.user?.workspace.logo?.length > 0 ? <MenuIcon /> : <UnisseySymbolIcon />}
                <IconButton
                  className={clsx(classes.iconHitbox, classes.iconClosedDrawer)}
                  onClick={() => setDrawerOpen(true)}
                  edge="end"
                >
                  <ArrowRight />
                </IconButton>
              </>
            )}
            <Box pt={8}></Box>

            <Typography color="textPrimary" variant="h6" noWrap className={classes.title}>
              {pageHeader(location.pathname, t)}
            </Typography>

            <ProfileMenu
              displayName={getDisplayName() ?? ""}
              onProfileClicked={() => history.replace(Routes.PROFILE + location.search)}
              onLogoutClicked={handleLogout}
            />

            <LanguageSwitchMenu />
          </Toolbar>
        </AppBar>

        {!isMobileDevice() && (
          <Drawer
            variant="permanent"
            classes={{ paper: clsx(classes.drawerPaper, !drawerOpen && classes.drawerPaperClose) }}
            open={drawerOpen}
          >
            <div className={classes.toolbarIcon}>
              {/* <IconButton onClick={() => setDrawerOpen(false)}>
              <MenuIcon />
            </IconButton> */}
              <img
                className={classes.logo}
                alt="logo"
                src={
                  auth.user && auth.user?.workspace.logo?.length > 0
                    ? `data:image;base64,${auth.user?.workspace.logo}`
                    : `${process.env.PUBLIC_URL}/images/unissey-logo.svg`
                }
              />
              <IconButton className={classes.iconHitbox} onClick={() => setDrawerOpen(false)}>
                <ArrowLeft />
              </IconButton>
              <div></div>
            </div>
            <Box p={1}>
              <List>
                {availableMenuItems.map((menuItem) => (
                  <React.Fragment key={menuItem.route}>
                    <ListItem
                      button
                      onClick={() => history.replace(menuItem.route + location.search)}
                      style={{
                        borderRadius: theme.shape.borderRadius,
                        marginBottom: theme.spacing(1),
                        backgroundColor:
                          menuItem.route === `/${location.pathname.split("/")[1]}`
                            ? theme.palette.action.selected
                            : undefined,
                      }}
                      data-testid={menuItem.testId}
                    >
                      <ListItemIcon style={{ color: theme.palette.common.black }}>
                        <menuItem.icon />
                      </ListItemIcon>
                      <ListItemText primary={menuItem.name} />
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Box>
            <Box
              className={clsx(classes.navBottom, drawerOpen ? classes.navBackgroundOpen : classes.navBackgroundClosed)}
              pt={4}
              display="flex"
              justifyContent="center"
            >
              {drawerOpen && (
                <Grid
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.copyright}
                >
                  <Grid item>
                    <AppVersion className={classes.copyright} color="inherit" />
                  </Grid>
                  <Grid item>
                    <Copyright className={classes.copyright} color="inherit" />
                  </Grid>
                </Grid>
              )}
            </Box>
          </Drawer>
        )}
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="xl" className={classes.container}>
            <Switch>
              <Route exact path={Routes.HOME}>
                <Redirect to={defaultRoute + location.search} />
              </Route>

              <PrivateRoute exact path={`${Routes.SESSIONS}`} roles={["session.list"]} redirectRoute={Routes.DEMO_TOU}>
                <SessionsPage />
              </PrivateRoute>

              <PrivateRoute exact path={`${Routes.SESSIONS}/:sessionId`} roles={["session.list"]}>
                <SessionsPage />
              </PrivateRoute>

              <PrivateRoute exact path={`${Routes.IDENTITY_SESSIONS}/`} roles={["session.list"]}>
                <IdentitySessionsPage />
              </PrivateRoute>

              <PrivateRoute exact path={`${Routes.IDENTITIES}/`} roles={["workspace.list"]}>
                <IdentitiesPage />
              </PrivateRoute>

              <PrivateRoute exact path={`${Routes.DEMO_TOU}`} roles={["demo.use"]} redirectRoute={Routes.PROFILE}>
                <TermsOfUsePage />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.PROFILE} roles={[]}>
                <ProfilePage />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.DEMO_CAPTURE} roles={["demo.use"]}>
                <DemoPage />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.WORKSPACE} roles={["workspace.list"]}>
                <WorkspacePage />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.REGISTRIES} roles={["workspace.list"]}>
                <RegistriesPage />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.APPLICATIONS} roles={["apikey.list"]}>
                <ApiKeyPage />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.SUBSCRIPTIONS} roles={["subscription.list"]}>
                <SubscriptionsPage />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.SUBSCRIPTION_DETAILS} roles={["subscription.list"]}>
                <SubscriptionDetails />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.DOCUMENTATION} roles={["documentation.list"]}>
                <DocumentationPage />
              </PrivateRoute>

              <PrivateRoute exact path={Routes.BILLING} roles={["billing.list"]}>
                <BillingPage />
              </PrivateRoute>

              <Route component={NotFoundPage} />
            </Switch>
          </Container>
        </main>
      </div>
    </>
  );
}
