import { AcquisitionPreset, IadMode } from "@unissey/sdk-react";
import { usePersistedState } from "../utils/persistence";

export function useSessionConfig() {
  const [instructionsSeen, setInstructionsSeen] = usePersistedState("injectionInstructionsSeen", false, "local");

  const [preset, setPreset] = usePersistedState<AcquisitionPreset>(
    "sessionPreset",
    AcquisitionPreset.SELFIE_FAST,
    "session"
  );

  const [iadEnabled, setIadEnabled] = usePersistedState<boolean>("iadEnabled", false, "local");

  const [iadMode, setIadMode] = usePersistedState<IadMode>("iadMode", IadMode.PASSIVE, "session");

  return {
    instructionsSeen,
    setInstructionsSeen,
    preset,
    setPreset,
    iadEnabled,
    setIadEnabled,
    iadMode,
    setIadMode,
  };
}
