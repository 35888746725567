import { Button, CircularProgress, DialogProps, FormGroup, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// services
import { updateWorkspace } from "../../services/api_services";
// types
import { ReadWorkspace, UpdateWorkspaceType } from "@unissey/common";
// components
import { Modal } from "../../components/Modal";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { useScopedTranslation } from "../../i18n";
import { useStores } from "../../hooks/use-stores";
import { useNotif } from "../../contexts/notif_context";
import { flatObjectHaveEmptyField, isFlatObjectEqual } from "../../utils/compare_utils";

type Props = DialogProps & {
  workspace: ReadWorkspace;
  onClose: () => void;
};

export default function UpdateWorkspace({ open, workspace, onClose }: Props) {
  const { t, tCommon } = useScopedTranslation("workspace_page.edit_dialog");

  const notif = useNotif();

  const { workspaceStore } = useStores();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editedWorkspace, setEditedWorkspace] = useState<UpdateWorkspaceType>({
    name: workspace.name,
    domain: workspace.domain,
    isIntegrator: workspace.isIntegrator,
  });

  useEffect(() => {
    setEditedWorkspace({ name: workspace.name, domain: workspace.domain, isIntegrator: workspace.isIntegrator });
  }, [workspace]);

  const handleWorkspaceUpdate = async () => {
    setIsLoading(true);
    if (await updateWorkspace(editedWorkspace, workspace.id))
      notif.trigger?.({
        textKey: "success.workspace_edited",
      });
    await workspaceStore.fetchWorkspaceById(workspace.id);
    onClose();
    setIsLoading(false);
  };

  const validateForm = () => {
    const compareWorkspaceKey = ["name"];
    const isValidName =
      isFlatObjectEqual(editedWorkspace, workspace, compareWorkspaceKey) ||
      !flatObjectHaveEmptyField(editedWorkspace, compareWorkspaceKey);

    return isValidName;
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      body={
        <FormGroup>
          <Grid container spacing={3}>
            <CustomTextField
              name={"name"}
              width={12}
              onChange={(e) => setEditedWorkspace({ ...editedWorkspace, name: e.target.value })}
              onBlur={(e) => setEditedWorkspace({ ...editedWorkspace, name: e.target.value.trim() })}
              value={editedWorkspace.name}
              label={t("field_workspace_name")}
              autoFocus
            />
          </Grid>
        </FormGroup>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleWorkspaceUpdate}
          startIcon={isLoading && <CircularProgress size={14} />}
          fullWidth
          disabled={isLoading || validateForm()}
        >
          {tCommon("button_confirm")}
        </Button>
      }
    />
  );
}
