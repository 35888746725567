import { Link } from "@material-ui/core";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { SubscriptionByDateRange } from "@unissey/common";

type Props = {
  subscriptions: SubscriptionByDateRange[] | undefined;
};

function BillingExtractButton({ subscriptions }: Props) {
  const { t } = useTranslation();

  const BILLING_HEADERS = [
    { label: t("billing_page.table.header_workspace"), key: "workspaceName" },
    { label: t("billing_page.table.header_subscription"), key: "subscriptionName" },
    { label: t("billing_page.table.header_subscriptionType"), key: "subscriptionKind" },
    { label: t("billing_page.table.header_liveness"), key: "livenessOnly" },
    { label: t("billing_page.table.header_faceComp"), key: "faceCompOnly" },
    { label: t("billing_page.table.header_liveness_faceComp"), key: "livenessAndFaceComp" },
    { label: t("billing_page.table.header_totalSessions"), key: "totalSessions" },
  ];

  return subscriptions === undefined || subscriptions.length === 0 ? (
    <Link style={{ textDecoration: "none" }}>{t("button_extract_table")}</Link>
  ) : (
    <CSVLink
      data={subscriptions || []}
      headers={BILLING_HEADERS}
      filename={"billing_extract.tsv"}
      separator={"\t"}
      target="_blank"
      style={{ cursor: "pointer", textDecoration: "underline" }}
      data-testid="billing-extract-button"
    >
      {t("button_extract_table")}
    </CSVLink>
  );
}

export default BillingExtractButton;
