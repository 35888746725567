import { Box, Button, Typography } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import dateFormat from "dateformat";
import { useTranslation } from "react-i18next";
import { isMobileDevice } from "../utils/misc_util";
import { useAuth } from "../auth";

type Props = {
  handleReminder: (value: "display" | "hide" | "closed") => void;
};

const MILLISECONDS_PER_SECONDS = 1000;
const SECONDS_PER_MINUTES = 60;
const MINUTES_PER_HOURS = 60;
const HOURS_PER_DAYS = 24;

function DemoReminder({ handleReminder }: Props) {
  const { t } = useTranslation();
  const auth = useAuth();
  const user = auth.user;

  const computeRemainingTime = (date: Date | undefined) => {
    if (!date) return;
    const currentDate = new Date();
    const endDate = new Date(date);

    const timeRemainingInMilliseconds = endDate.getTime() - currentDate.getTime();
    return Math.round(
      timeRemainingInMilliseconds /
        (MILLISECONDS_PER_SECONDS * SECONDS_PER_MINUTES * MINUTES_PER_HOURS * HOURS_PER_DAYS)
    );
  };

  return (
    <>
      <Box
        sx={{
          width: "100vw",
          height: "4vh",
          bgcolor: "#DCDCDC",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant={isMobileDevice() ? "caption" : "button"} align="center" style={{ fontStyle: "italic" }}>
          {t("demo_reminder", {
            count: computeRemainingTime(user.demoSubscription?.endDate),
            date: dateFormat(user.demoSubscription?.endDate, "dd/mm/yyyy"),
          })}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "5vw",
          height: "4vh",
          bgcolor: "#DCDCDC",
          position: "absolute",
          top: 0,
          right: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button onClick={() => handleReminder("closed")}>
          <CloseOutlined fontSize="large" />
        </Button>
      </Box>
    </>
  );
}

export default DemoReminder;
