import { Box, Button } from "@material-ui/core";
import DataTable, { DataTableColumn } from "../../components/DataTable";
import { getIdentitySessions } from "../../services/api_services";
import { dateFormat } from "../../utils/parsing";
import OverflowTooltip from "../../components/OverflowTooltip";
import { useScopedTranslation } from "../../i18n";
import {
  IdentityAction,
  IdentitySession,
  IdentityResult,
  IdentitySessionsListFilters,
  LivenessResult,
  SubscriptionKind,
} from "@unissey/common";
import OkIcon from "../../components/OkIcon";
import StopIcon from "../../components/StopIcon";
import { LivenessChip } from "../../components/SessionResultChip";
import { useEffect, useState } from "react";
import { IdentitySessionsFilters } from "./IdentitySessionsFilter";
import { IdentityResultChip } from "./IdentityResultChip";

type IdentitySessionsToDisplay = {
  createdAt: Date;
  workspaceName: string;
  registryName: string;
  applicationName: string;
  subscriptionType: string;
  identityResult?: string;
  identityAction: string;
  liveness: string;
  gdprConsent: boolean;
};

export function IdentitySessionsPage() {
  const { t, tCommon } = useScopedTranslation("identity_sessions_page");

  const [cursor, setCursor] = useState(new Date());

  const initialFilters: IdentitySessionsListFilters = {
    order: "desc",
    nbItems: 100,
  };

  const [filters, setFilters] = useState<IdentitySessionsListFilters>(initialFilters);

  const [sessions, setSessions] = useState<IdentitySession[]>([]);

  const hasSessions = () => sessions.length > 0;

  // Get sessions in situations sessions state array needs to be refresh
  // at first render, when filters are applied or reset
  const getSessions = async () => {
    const initialCursor = filters.order === "desc" ? new Date() : new Date("1970-01-01");
    return getIdentitySessions(initialCursor, filters).then((sessions) => {
      setSessions(sessions);

      const nbSessions = sessions.length;
      if (nbSessions > 0) {
        setCursor(new Date(sessions[nbSessions - 1].createdAt));
      }
    });
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  // This effect is trigger only during the first rendering to fetch initial data
  // getSessions changes at every render and should not be used on dependency array
  useEffect(() => {
    getSessions();
  }, []);

  const handleLoadMoreData = async () => {
    const nextSessions = await getIdentitySessions(cursor, filters);
    if (nextSessions.length > 0) {
      const newSessionsList = sessions.concat(nextSessions);
      const nbSessions = newSessionsList.length;
      setSessions(newSessionsList);
      setCursor(new Date(newSessionsList[nbSessions - 1].createdAt));
    }
  };

  function ascSorter<T>(a: T, b: T) {
    if (a < b) return -1;
    return 1;
  }

  const columns: DataTableColumn<IdentitySessionsToDisplay>[] = [
    {
      dataKey: "createdAt",
      title: t("table.column_title.date"),
      sorter: (a, b) => ascSorter(a.createdAt, b.createdAt),
      render: (key: string, value: Date): JSX.Element => <OverflowTooltip key={key} text={dateFormat(value)} />,
    },
    {
      dataKey: "workspaceName",
      title: t("table.column_title.workspace"),
      sorter: (a, b) => ascSorter(a.workspaceName, b.workspaceName),
    },
    {
      dataKey: "registryName",
      title: t("table.column_title.registry"),
      sorter: (a, b) => ascSorter(a.registryName, b.registryName),
    },
    {
      dataKey: "applicationName",
      title: t("table.column_title.application"),
      sorter: (a, b) => ascSorter(a.applicationName, b.applicationName),
    },
    {
      dataKey: "subscriptionType",
      title: t("table.column_title.subscription_type"),
      sorter: (a, b) => ascSorter(a.subscriptionType, b.subscriptionType),
      render: (key: string, value: SubscriptionKind): JSX.Element => (
        <OverflowTooltip key={key} text={tCommon(`sub_kind.${value}`)} />
      ),
    },
    {
      dataKey: "identityResult",
      title: t("table.column_title.identity_result"),
      sorter: (a, b) => ascSorter(a.identityResult, b.identityResult),
      render: (key: string, value: IdentityResult): JSX.Element => <IdentityResultChip key={key} result={value} />,
    },
    {
      dataKey: "identityAction",
      title: t("table.column_title.identity_action"),
      sorter: (a, b) => ascSorter(a.identityAction, b.identityAction),
      render: (key: string, value: IdentityAction): JSX.Element => {
        return <OverflowTooltip key={key} text={tCommon(`identity_action_kind.${value}`)} />;
      },
    },
    {
      dataKey: "liveness",
      title: t("table.column_title.liveness"),
      sorter: (a, b) => ascSorter(a.liveness, b.liveness),
      render: (key: string, value: LivenessResult): JSX.Element => <LivenessChip key={key} value={value} />,
    },
    {
      dataKey: "gdprConsent",
      title: t("table.column_title.gdpr_consent"),
      sorter: (a, b) => ascSorter(a.gdprConsent, b.gdprConsent),
      render: (key: string, value: boolean): JSX.Element => (value ? <OkIcon key={key} /> : <StopIcon key={key} />),
    },
  ];

  const dataSource: IdentitySessionsToDisplay[] =
    sessions?.map((session) => ({
      createdAt: new Date(session.createdAt),
      workspaceName: session.application.workspace.name,
      registryName: session.application.registry.name,
      applicationName: session.application.name,
      subscriptionType: session.application.subscription.kind,
      identityResult: session.result,
      identityAction: session.kind,
      liveness: session?.liveness ?? "N/A",
      gdprConsent: session.gdprConsent,
    })) ?? [];

  const handleFilters = () => {
    getSessions();
  };

  const handleReset = () => {
    setFilters(initialFilters);
    getSessions();
  };

  return (
    <div>
      <Box m={3} />
      <IdentitySessionsFilters
        filters={filters}
        setFilters={setFilters}
        onFilter={handleFilters}
        onReset={handleReset}
      />
      <Box m={3} />
      <DataTable columns={columns} dataSource={dataSource} isLoading={false} />
      <Box m={3} />
      {hasSessions() ? (
        <Button variant="contained" onClick={handleLoadMoreData} color="primary" disableElevation>
          {t("load_data_btn")}...
        </Button>
      ) : (
        <></>
      )}
      <Box m={5} />
    </div>
  );
}
