import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomTable from "./CustomTable";
import TitledSection from "./TitledSection";
import { getWorkspaces } from "../services/api_services";
import { ReadWorkspace } from "@unissey/common";
import ListWrapper from "./ListWrapper";
import { useAuth } from "../auth";

type Workspace = ReadWorkspace & { expand: boolean };

type Props = {
  getChildren: (workspace: Workspace, creationModalIsOpen: boolean) => JSX.Element;
  createButton: () => JSX.Element | null;
  creationModalIsOpen: boolean;
};

function WorkspaceList({ getChildren, createButton, creationModalIsOpen }: Props) {
  const auth = useAuth();
  const { t } = useTranslation();

  const [workspaces, setWorkspaces] = useState<Workspace[] | undefined>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleExpand = useCallback(
    (workspace: Workspace) => {
      if (!workspaces) return;

      const index = workspaces?.findIndex((ws) => ws.id === workspace.id);
      const updatedWorkspace = { ...workspaces[index], expand: !workspaces[index].expand };
      const newWorkspaces = [
        ...workspaces.slice(0, index),
        updatedWorkspace,
        ...workspaces.slice(index + 1, workspaces.length),
      ];
      setWorkspaces(newWorkspaces);
    },
    [workspaces]
  );

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      if (!workspaces || (workspaces && workspaces.length > 0)) {
        setIsLoading(false);
        return;
      }
      const works = (await getWorkspaces())?.sort((a, b) => {
        if (auth.user!.workspace.id === a.id) return -1;
        if (auth.user!.workspace.id === b.id) return 1;
        return a.name.localeCompare(b.name);
      });

      if (works?.length === 1) {
        const ws = works?.map((workspace) => {
          return {
            ...workspace,
            expand: true,
          };
        });
        setWorkspaces(ws);
      } else {
        const ws = works?.map((workspace) => {
          return {
            ...workspace,
            expand: false,
          };
        });
        setWorkspaces(ws);
      }

      setIsLoading(false);
    })();
  }, [workspaces, auth.user, toggleExpand]);

  const getWorkspaceRows = () => {
    const canListExternalUsers = auth.hasRoles(["external.user.list"]);

    return workspaces?.map((workspace) => {
      const isMyWorkspace = auth.user!.workspace.id === workspace.id;
      const isExpandable = isMyWorkspace || canListExternalUsers;
      return [
        <ListWrapper
          workspace={workspace}
          isExpandable={isExpandable}
          isMyWorkspace={isMyWorkspace}
          handleClick={toggleExpand}
          children={getChildren(workspace, creationModalIsOpen)}
        />,
      ];
    });
  };

  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <TitledSection
          child={
            <CustomTable
              heads={[t("workspace_page.table.header_name")]}
              isLoading={isLoading}
              rows={getWorkspaceRows()}
            />
          }
          action={createButton() || <></>}
        />
      </div>
    </>
  );
}

export default WorkspaceList;
