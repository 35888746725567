import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  GridSize,
  MenuItemProps,
  SelectProps,
} from "@material-ui/core";

export type MenuItemsType = [MenuItemProps["value"], string][];

type Props = Omit<SelectProps, "labelId"> & {
  width: GridSize;
  labelId: string;
  labelValue: string;
  menuItems?: MenuItemsType;
};

export default function CustomSelect({ width, labelId, labelValue, menuItems, ...attributes }: Props) {
  return (
    <Grid item xs={width}>
      <FormControl required fullWidth variant="outlined">
        <InputLabel id={labelId}>{labelValue}</InputLabel>
        <Select labelId={labelId} label={labelValue} {...attributes}>
          {/*
          The placeholder menu item avoids Material UI's default feature of shading the first menu item.
          For the multiple select functionality, this default styling causes confusion as first option always
          appears to be selected. More Info: https://github.com/mui-org/material-ui/issues/5186
           */}
          <MenuItem key="placeholder" style={{ display: "none" }} />
          {menuItems ? (
            menuItems.map(([value, text], idx) => (
              <MenuItem dense value={value} key={idx}>
                {text}
              </MenuItem>
            ))
          ) : (
            <Typography variant={"h6"} align={"center"}>
              {"Loading..."}
            </Typography>
          )}
        </Select>
      </FormControl>
    </Grid>
  );
}
