import { useTranslation } from "react-i18next";
import { useState } from "react";
import WorkspaceList from "../../components/WorkspaceList";
import { CreateRegistry, ReadWorkspace } from "@unissey/common";
import { Box, Button, FormGroup, Grid } from "@material-ui/core";
import ManageRegistry from "./ManageRegistry";
import { Modal } from "../../components/Modal";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import CustomSelect from "../../components/forms/formComponents/CustomSelect";
import { useMutation, useQuery } from "react-query";
import { createRegistry, getWorkspaces } from "../../services/api_services";
import { useNotif } from "../../contexts/notif_context";
import { useScopedTranslation } from "../../i18n";

export function RegistriesPage() {
  const { t } = useTranslation();

  const [creationModalIsOpen, setCreationModalIsOpen] = useState<boolean>(false);

  function CreateRegistryButton() {
    return (
      <Button variant="outlined" onClick={() => setCreationModalIsOpen(true)}>
        {t("registries_page.button_create")}
      </Button>
    );
  }

  const getRegistriesByWorkspace = (workspace: ReadWorkspace & { expand: boolean }, creationModalIsOpen: boolean) => {
    return <ManageRegistry workspaceId={workspace.id} creationModalIsOpen={creationModalIsOpen} />;
  };

  return (
    <>
      <WorkspaceList
        getChildren={getRegistriesByWorkspace}
        createButton={CreateRegistryButton}
        creationModalIsOpen={creationModalIsOpen}
      />

      <CreateRegistryModal isOpened={creationModalIsOpen} onClose={() => setCreationModalIsOpen(false)} />
    </>
  );
}

type CreateRegistryModalProps = {
  isOpened: boolean;
  onClose: () => void;
};

function CreateRegistryModal({ isOpened, onClose }: CreateRegistryModalProps) {
  const { data: workspaces } = useQuery(["workspaces-list"], () => getWorkspaces());
  const notif = useNotif();

  const { t } = useScopedTranslation("registries_page");

  const mutation = useMutation((newRegistry: CreateRegistry) => createRegistry(newRegistry), {
    onSuccess: (data, variables, context) => {
      setRegistryName("");
      setSelectedWorkspaceId("");
      onClose();
      notif.trigger?.({
        textKey: "success.registry_created",
      });
    },
  });

  const [registryName, setRegistryName] = useState("");
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState("");

  const isValid = registryName !== "" && selectedWorkspaceId !== "";

  const handeWorkspaceIdSelection = (id: string) => {
    setSelectedWorkspaceId(id);
  };

  const modalBody = (
    <Box>
      <FormGroup>
        <Box py={2} mb={1}>
          <Grid container spacing={2} data-testid="create-registry-form">
            <CustomTextField
              name="registryName"
              width={12}
              onChange={(e) => setRegistryName(e.target.value)}
              value={registryName}
              label={t("create_modal.form.name_label")}
              autoFocus
              data-testid="registry-name"
            />

            <CustomSelect
              width={12}
              labelId="create-registry-form-select-workspace"
              labelValue={t("create_modal.form.workspace_label")}
              menuItems={workspaces?.map((ws) => [ws.id, ws.name])}
              onChange={(e) => handeWorkspaceIdSelection(e.target.value as string)}
              value={selectedWorkspaceId}
              data-testid="registry-workspace"
            />
          </Grid>
        </Box>
      </FormGroup>
    </Box>
  );

  return (
    <Modal
      title={t("create_modal.title")}
      maxWidth="sm"
      action={
        <Button
          variant="contained"
          color="primary"
          disabled={!isValid || mutation.isLoading}
          style={{ width: "150px" }}
          onClick={() => mutation.mutate({ name: registryName, workspaceId: selectedWorkspaceId })}
        >
          {t("create_modal.button")}
        </Button>
      }
      body={modalBody}
      open={isOpened}
      onCancel={onClose}
      onClose={onClose}
    ></Modal>
  );
}
