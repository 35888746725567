import React, { useEffect, useRef, useState } from "react";

import { useScopedTranslation } from "../../i18n";

import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import { Modal } from "../../components/Modal";
import { Trans } from "react-i18next";
import { ChromeIcon } from "../../components/ChromeIcon";
import { SafariIcon } from "../../components/SafariIcon";

const isSmallViewPort = (window.visualViewport?.width ?? 800) < 720;

const useModalStyles = makeStyles({
  title: {
    fontSize: isSmallViewPort ? 18 : 28,
    fontWeight: 800,
  },
  subtitle: {
    fontStyle: "italic",
    fontSize: "14px",
  },
});

type Props = {
  open: boolean;

  onClose: () => void;
  onConfirm: () => void;
};

type Page = 1 | 2;

type PageProps = {
  onScrollMax: () => void;
};

export const InjectionInstructionsModal = ({ open, onClose, onConfirm }: Props) => {
  const { t } = useScopedTranslation("demo_page.injection_instructions");

  const classes = useModalStyles();

  const [nextBtnDisabled, setNextBtnDisabled] = useState(true && isSmallViewPort);
  const [confirmBtnDisabled, setConfirmBtnDisabled] = useState(true && isSmallViewPort);

  const handlePageOneScrollMax = () => setNextBtnDisabled(false);
  const handlePageTwoScrollMax = () => setConfirmBtnDisabled(false);

  const [page, setPage] = useState<Page>(1);

  useEffect(() => {
    setPage(1);
  }, [open]);

  const body =
    page === 1 ? (
      <FirstPage onScrollMax={handlePageOneScrollMax} />
    ) : (
      <SecondPage onScrollMax={handlePageTwoScrollMax} />
    );

  const action = {
    text: page === 1 ? t("button_next") : t("button_confirm"),
    onClick: page === 1 ? () => setPage(2) : onConfirm,
    previousText: t("button_prev"),
    onPrevious: () => setPage(1),
  };

  return (
    <Modal
      open={open}
      body={body}
      title={t(`title_page${page}`)}
      titleClassName={classes.title}
      subtitle={<Typography className={classes.subtitle}>{t("subtitle")}</Typography>}
      action={
        <Box display="flex">
          {page === 2 && (
            <>
              <Button variant="outlined" disableElevation fullWidth onClick={action.onPrevious}>
                {action.previousText}
              </Button>
              <Box m={1} />
            </>
          )}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={action.onClick}
            disabled={page === 1 ? nextBtnDisabled : confirmBtnDisabled}
          >
            {/* replacing spaces with their non breakable variant because it messes with computed height… */}
            {action.text.replace(" ", "\u00a0")}
          </Button>
        </Box>
      }
      onCancel={onClose}
      hideCancel={page === 2}
    />
  );
};

const usePageStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    height: isSmallViewPort ? "30vh" : "60vh",
    padding: "5px 5px",
    overflow: "auto",
  },

  header: {
    fontWeight: 700,
    fontSize: isSmallViewPort ? "16px" : "20px",
    marginTop: "1em",
  },

  paragraph: {
    fontSize: isSmallViewPort ? "13px" : "18px",
    marginTop: "1em",
  },

  list: {
    margin: 0,
  },

  icon: {
    position: "relative",
    top: "0.2em",
  },
});

type InstructionPageProps = {
  children: React.ReactNode;
  onScrollMax: () => void;
};

const InstructionPage = ({ children, onScrollMax }: InstructionPageProps) => {
  const classes = usePageStyles();

  const container = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (container.current && content.current) {
      const containerHeight = container.current?.clientHeight || 0;
      const contentHeight = content.current?.clientHeight || 0;

      container.current.addEventListener("scroll", () => {
        const y = container.current?.scrollTop || 0;
        const yMax = contentHeight - containerHeight;
        if (y >= yMax) onScrollMax();
      });
    }
  };

  useEffect(handleScroll, [onScrollMax]);

  return (
    <div className={classes.container} ref={container}>
      <div ref={content}>{children}</div>
    </div>
  );
};

const FirstPage = ({ onScrollMax }: PageProps) => {
  const { t } = useScopedTranslation("demo_page.injection_instructions.content");

  const classes = usePageStyles();

  return (
    <InstructionPage onScrollMax={onScrollMax}>
      <Typography color="primary" className={classes.header}>
        {t("what_is_injection_header")}
      </Typography>
      <Typography className={classes.paragraph}>{t("what_is_injection_text")}</Typography>

      <Typography color="primary" className={classes.header}>
        {t("measures_header")}
      </Typography>
      <Typography className={classes.paragraph}>
        <Trans i18nKey="demo_page.injection_instructions.content.measures_text_p1" components={[<strong></strong>]} />
      </Typography>
      <Typography className={classes.paragraph}>{t("measures_text_p2")}</Typography>
    </InstructionPage>
  );
};

const SecondPage = ({ onScrollMax }: PageProps) => {
  const { t } = useScopedTranslation("demo_page.injection_instructions.content");

  const classes = usePageStyles();

  return (
    <InstructionPage onScrollMax={onScrollMax}>
      <Typography color="primary" className={classes.header}>
        {t("prevention_header")}
      </Typography>
      <Typography component={"span"} className={classes.paragraph}>
        <Typography className={classes.paragraph}>
          <span>{t("prevention_text_p1")}</span>
          <ul className={classes.list}>
            <li>
              <Trans
                i18nKey="demo_page.injection_instructions.content.prevention_bullet1"
                components={[<ChromeIcon className={classes.icon} />]}
              />
            </li>
            <li>
              <Trans
                i18nKey="demo_page.injection_instructions.content.prevention_bullet2"
                components={[<SafariIcon className={classes.icon} />]}
              />
            </li>
          </ul>
        </Typography>
        <Typography className={classes.paragraph}>
          <span className={classes.paragraph}>{t("prevention_text_p2")}</span>
          <span className={classes.paragraph}>{t("prevention_text_p3")}</span>
          <ul className={classes.list}>
            <li>{t("prevention_bullet3")}</li>
            <li>{t("prevention_bullet4")}</li>
          </ul>
        </Typography>

        <Typography className={classes.paragraph}>{t("prevention_text_p4")}</Typography>
      </Typography>
    </InstructionPage>
  );
};
