import { Box, Grid, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import theme from "../../../config/theme";

type Props = {
  name?: string;
  weakResults: Array<{ name?: string; probability?: number; error?: unknown; age?: number; interval?: number[] }>;
  result?: { name: string; probability?: number };
  threshold?: number;
};

// styles
const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  table_cell: {
    color: theme.palette.grey[500],
    // padding: "6px 24px 6px 16px"
  },
});

export function ResultsTable(props: Props) {
  const classes = useStyles();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        {props.name && (
          <Typography variant="h6" component="h6">
            {props.name}
          </Typography>
        )}

        <Table className={classes.table} size="small" padding="none" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.table_cell}>Name</TableCell>
              {props.weakResults.find((wr) => wr.age !== undefined) && (
                <TableCell align="right" className={classes.table_cell}>
                  Age
                </TableCell>
              )}
              {props.weakResults.find((wr) => wr.interval !== undefined) && (
                <TableCell align="right" className={classes.table_cell}>
                  Interval
                </TableCell>
              )}
              {props.weakResults.find((wr) => wr.probability !== undefined) && (
                <TableCell align="right" className={classes.table_cell}>
                  Probability
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.weakResults
              //.sort((a, b) => a?.name?.localeCompare(b?.name))
              .map((result, idx) => (
                <TableRow key={idx}>
                  <TableCell component="th" scope="row">
                    {result.name ?? "N/A"}
                  </TableCell>
                  {result.age && <TableCell align="right">{result.age}</TableCell>}
                  {result.interval && (
                    <TableCell align="right">{`[${result.interval[0]} - ${result.interval[1]}]`}</TableCell>
                  )}
                  {result.probability && <TableCell align="right">{result.probability}</TableCell>}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
      {props.result && (
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Box
              padding={1}
              bgcolor="#DDF1F7"
              borderRadius={10}
              textAlign="center"
              fontWeight="fontWeightBold"
              color="#53BAD1"
            >
              <Box component="span" color={theme.palette.common.black} fontWeight="normal">
                {props.result.name + " "}
              </Box>
              {props.result.probability}
            </Box>

            {props.threshold !== undefined && (
              <Box
                padding={1}
                bgcolor="#ededed"
                borderRadius={10}
                textAlign="center"
                fontWeight="fontWeightBold"
                color="#202020"
              >
                <Box component="span" color={theme.palette.common.black} fontWeight="normal">
                  {props.weakResults.find((wr) => wr.age !== undefined) ? "Age reference " : "Threshold "}
                </Box>
                {props.threshold}
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
