import { DialogProps, FormGroup, Box, CircularProgress, Grid, Button } from "@material-ui/core";
import { useState } from "react";

import { TeamMemberForm } from "@unissey/common";

import { createUser } from "../../services/api_services";

import { useScopedTranslation } from "../../i18n";

import { Modal } from "../../components/Modal";
import UserForm from "../../components/forms/UserForm";
import CustomSelect from "../../components/forms/formComponents/CustomSelect";
import { reaction } from "mobx";
import { useStores } from "../../hooks/use-stores";
import { observer } from "mobx-react-lite";
import LockOutlined from "@material-ui/icons/LockOutlined";
import { useNotif } from "../../contexts/notif_context";
import { flatObjectHaveEmptyField } from "../../utils/compare_utils";
import { useAuth } from "../../auth";

type Props = {
  onClose: () => void;
};

export type SelectWorkspace = {
  name: string;
  id: string;
};

function CreateTeamMemberModal({ open, onClose }: Props & DialogProps) {
  const { workspaceStore } = useStores();
  const auth = useAuth();
  const notif = useNotif();

  const { t } = useScopedTranslation("workspace_page.create_user_dialog");

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<SelectWorkspace[]>([]);
  const [userToCreate, setUserToCreate] = useState<TeamMemberForm>({
    firstName: "",
    lastName: "",
    email: "",
    roleKey: "user",
    workspaceId: "",
    ownerId: "",
  });

  const validateForm = (): boolean => {
    const compareWorkspaceKeys = ["firstName", "lastName", "email", "roleKey", "workspaceId"];
    const isFormCompleted = flatObjectHaveEmptyField(userToCreate, compareWorkspaceKeys);

    return !isFormCompleted;
  };

  const dispose = reaction(
    () => workspaceStore.selectedWorkspace,
    (current) => {
      if (current !== undefined) {
        setUserToCreate({
          ...userToCreate,
          workspaceId: current.ws.workspace.id,
          ownerId: current.ws.workspace.id,
        });
        setWorkspaces([{ id: current.ws.workspace.id, name: current.ws.workspace.name }]);
      } else {
        setUserToCreate({
          ...userToCreate,
          workspaceId: "",
        });
        setWorkspaces([]);
      }
    }
  );

  const handleUserCreation = async () => {
    if (userToCreate && auth.user) {
      const user = {
        ...userToCreate,
        ownerId: userToCreate.ownerId,
        cguConsent: true,
      };
      setIsLoading(true);
      if (await createUser(user)) {
        notif.trigger?.({ textKey: "success.user_created" });
        if (workspaceStore.selectedWorkspace) {
          await workspaceStore.selectedWorkspace.fetchTeamMembers();
        }
      }
      dispose();
      onClose();
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={() => {
        dispose();
        onClose();
      }}
      title={t("title")}
      body={
        <FormGroup>
          <Grid container>
            <UserForm
              setFormData={setUserToCreate}
              userFormData={userToCreate}
              targetWorkspaceId={userToCreate.workspaceId}
              workspaceParentId={workspaceStore.selectedWorkspace?.ws.workspace.parent ?? undefined}
            />
            <Box py={2} width="100%">
              <CustomSelect
                width={12}
                labelId="create-team-member-select-workspace-label"
                labelValue={t("field_select_workspace")}
                value={userToCreate.workspaceId}
                menuItems={workspaces.map((workspace) => [workspace.id, workspace.name])}
                onChange={(e) => setUserToCreate({ ...userToCreate, ownerId: e.target.value as string })}
                IconComponent={LockOutlined}
                disabled
              />
            </Box>
          </Grid>
        </FormGroup>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleUserCreation}
          startIcon={isLoading && <CircularProgress size={14} />}
          disabled={isLoading || validateForm()}
          fullWidth
          data-testid="user-form-invite-btn"
        >
          {t("button_invite")}
        </Button>
      }
    />
  );
}

export default observer(CreateTeamMemberModal);
