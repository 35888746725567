"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Authorizations = void 0;
var Authorizations;
(function (Authorizations) {
    /**
     * Allows listing children workspaces
     */
    Authorizations[Authorizations["WORKSPACE_LIST"] = 100] = "WORKSPACE_LIST";
    /**
     * Allows adding and editing one's own or child workspace as well as deletion
     * of one's child workspace
     */
    Authorizations[Authorizations["WORKSPACE_EDIT"] = 101] = "WORKSPACE_EDIT";
    /**
     * Allows access to subscriptions' consumption data
     */
    Authorizations[Authorizations["SUBSCRIPTION_LIST"] = 200] = "SUBSCRIPTION_LIST";
    /**
     * Allows editing subscriptions' names, dates, types and limits
     */
    Authorizations[Authorizations["SUBSCRIPTION_EDIT"] = 201] = "SUBSCRIPTION_EDIT";
    /**
     * Allows retrieving information about users of one's own workspace
     */
    Authorizations[Authorizations["USER_LIST_OWN"] = 300] = "USER_LIST_OWN";
    /**
     * Allows retrieving information about users of one's child workspace
     */
    Authorizations[Authorizations["USER_LIST_EXTERNAL"] = 301] = "USER_LIST_EXTERNAL";
    /**
     * Allows adding, editing and deleting users of one's own or child workspace
     */
    Authorizations[Authorizations["USER_EDIT"] = 302] = "USER_EDIT";
    /**
     * Allows editing user roles of one's own or child workspace
     */
    Authorizations[Authorizations["USER_IAM_EDIT"] = 303] = "USER_IAM_EDIT";
    /**
     * Allows creating links to invite "short lived" users to try out the demo
     * for a limited amount of time
     */
    Authorizations[Authorizations["USER_TEMPORARY"] = 304] = "USER_TEMPORARY";
    /**
     * Allows giving the "Super Administrator" role to a Unissey user
     */
    Authorizations[Authorizations["USER_IAM_SUPERADMIN_EDIT"] = 305] = "USER_IAM_SUPERADMIN_EDIT";
    /**
     * Allows querying "customer-facing" data from sessions
     */
    Authorizations[Authorizations["SESSION_LIST"] = 400] = "SESSION_LIST";
    /**
     * Allows querying media (personal data) associated to sessions of one's own
     * workspace if they are attached to a "Demo" subscription
     */
    Authorizations[Authorizations["SESSION_MEDIA_DEMO_OWN"] = 402] = "SESSION_MEDIA_DEMO_OWN";
    /**
     * Allows querying media (personal data) associated to sessions of one's child
     * workspace if they are attached to a "Demo" subscription
     */
    Authorizations[Authorizations["SESSION_MEDIA_DEMO_EXTERNAL"] = 403] = "SESSION_MEDIA_DEMO_EXTERNAL";
    /**
     * Allows querying media (personal data) associated to sessions of one's own
     * or child workspace if they are attached to a non "Demo" subscription
     */
    Authorizations[Authorizations["SESSION_MEDIA"] = 404] = "SESSION_MEDIA";
    /**
     * Allows querying "internal" data (model results and metadata) from sessions
     */
    Authorizations[Authorizations["SESSION_DATA_DETAILED"] = 405] = "SESSION_DATA_DETAILED";
    /**
     * Allows annotating and synchronizing sessions
     */
    Authorizations[Authorizations["SESSION_DATA_WRITE"] = 406] = "SESSION_DATA_WRITE";
    /**
     * Allows access to reporting data related to sessions
     */
    Authorizations[Authorizations["SESSION_REPORT"] = 407] = "SESSION_REPORT";
    /**
     * Allows querying the domain name of a temporary user's email that made a
     * session
     */
    Authorizations[Authorizations["SESSION_TEMPORARY_USER_DOMAIN"] = 408] = "SESSION_TEMPORARY_USER_DOMAIN";
    /**
     * Allows querying applications
     */
    Authorizations[Authorizations["APIKEY_LIST"] = 500] = "APIKEY_LIST";
    /**
     * Allows adding, editing and deleting applications
     */
    Authorizations[Authorizations["APIKEY_EDIT"] = 501] = "APIKEY_EDIT";
    /**
     * Allows querying an application's secret (API key)
     */
    Authorizations[Authorizations["APIKEY_SECRET"] = 502] = "APIKEY_SECRET";
    /**
     * Allows editing "sensitive" or "experimental" attributes of an application
     */
    Authorizations[Authorizations["APIKEY_EDIT_INTERNAL"] = 503] = "APIKEY_EDIT_INTERNAL";
    /**
     * Allows access to resources nessary to use the admin platform's demo
     */
    Authorizations[Authorizations["DEMO_USE"] = 600] = "DEMO_USE";
    /**
     * Allows customizing the SDK's presets on the admin platform's demo
     */
    Authorizations[Authorizations["DEMO_PRESETS"] = 602] = "DEMO_PRESETS";
    /**
     * Allows access to API and SDK documentation links
     */
    Authorizations[Authorizations["DOCUMENTATION_LIST"] = 700] = "DOCUMENTATION_LIST";
    /**
     * Allows access to the Billing page
     */
    Authorizations[Authorizations["BILLING_LIST"] = 800] = "BILLING_LIST";
})(Authorizations = exports.Authorizations || (exports.Authorizations = {}));
