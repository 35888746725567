import { Button, Grid, MenuItem, TextField, useMediaQuery } from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DateRange } from "./types/general";
import { getEndDate, getMonthsBySelectedYear, getNLastYears, LIMIT_DAY } from "./utils/date";

function mod(n: number, m: number) {
  return ((n % m) + m) % m;
}

const MONTH_TRANSLATION_KEYS = [
  "billing_page.months.0",
  "billing_page.months.1",
  "billing_page.months.2",
  "billing_page.months.3",
  "billing_page.months.4",
  "billing_page.months.5",
  "billing_page.months.6",
  "billing_page.months.7",
  "billing_page.months.8",
  "billing_page.months.9",
  "billing_page.months.10",
  "billing_page.months.11",
] as const;

export type TRANSLATION_KEYS = (typeof MONTH_TRANSLATION_KEYS)[number];

type ArrowButtonProps = {
  onClick: () => void;
  back?: boolean;
  disabled?: boolean;
};

const ArrowButton = ({ onClick, back, disabled }: ArrowButtonProps) => {
  return (
    <Grid item>
      <Button color="primary" size="small" style={{ borderRadius: "100%" }} onClick={onClick} disabled={disabled}>
        {back ? <ArrowBack /> : <ArrowForward />}
      </Button>
    </Grid>
  );
};

type Props = {
  dateRange: DateRange;
  handleDateRange: (newDates: { start?: Date; end?: Date }) => void;
  manualMode: boolean;
  handleManualChange: (mode: boolean) => void;
  nbOfYearsToDisplay: number;
  isLoading: boolean;
};

const initialMonth = () => {
  const currentDate = new Date();
  if (currentDate.getDate() >= LIMIT_DAY) return currentDate.getMonth() + 1;
  return currentDate.getMonth();
};

function BillingMonthSelector({
  dateRange,
  handleDateRange,
  manualMode,
  handleManualChange,
  nbOfYearsToDisplay,
  isLoading,
}: Props) {
  const { t } = useTranslation();
  const onMobile = useMediaQuery("(min-width:600px)");

  const [month, setMonth] = useState<number>(initialMonth());
  const [year, setYear] = useState(new Date().getFullYear());

  const displayYears = useMemo(() => getNLastYears(nbOfYearsToDisplay), [nbOfYearsToDisplay]);
  const displayMonth = useMemo(() => getMonthsBySelectedYear(year, MONTH_TRANSLATION_KEYS), [year]);

  const onPreviousClick = () => {
    if (mod(month - 1, 12) === 0) updateDateRange({ newMonth: -1 });
    else if (month === 0) {
      updateDateRange({ newYear: year - 1, newMonth: mod(month - 2, 12) });
      setYear((prev) => prev - 1);
    } else updateDateRange({ newMonth: mod(month - 2, 12) });
    setMonth((prev) => mod(prev - 1, 12));
  };

  const onNextClick = () => {
    if (mod(month, 12) === 11) {
      updateDateRange({ newMonth: 11 });
      setYear((prev) => prev + 1);
    } else updateDateRange({ newMonth: month });
    setMonth((prev) => mod(prev + 1, 12));
  };

  const updateDateRange = ({ newYear, newMonth }: { newYear?: number; newMonth?: number }) => {
    const startDate = new Date(newYear ?? year, newMonth ?? month - 1, LIMIT_DAY);
    const endDate = getEndDate(startDate);

    handleDateRange({ start: startDate, end: endDate });
    handleManualChange(false);
  };

  const handleMonthChange = (value: number) => {
    setMonth(value);
    updateDateRange({ newMonth: value - 1 });
  };

  const handleYearChange = (value: number) => {
    setYear(value);
    updateDateRange({ newYear: value });
  };

  return (
    <Grid container justifyContent="space-evenly" alignItems="center">
      <ArrowButton onClick={onPreviousClick} back disabled={manualMode || isLoading} />
      <Grid item container xs>
        <Grid item>
          <TextField
            select
            value={mod(month, 12)}
            onChange={(e) => handleMonthChange(Number(e.target.value))}
            label={t("billing_page.select_month")}
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              paddingRight: "10px",
              width: `${!onMobile ? "150px" : "100%"}`,
            }}
          >
            {displayMonth.map((value, index) => (
              <MenuItem key={index} value={index}>
                {t(value)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <TextField
            select
            value={year}
            onChange={(e) => handleYearChange(Number(e.target.value))}
            label={t("billing_page.select_year")}
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              paddingRight: "10px",
              width: `${!onMobile ? "150px" : "100%"}`,
            }}
          >
            {displayYears.map((value, index) => (
              <MenuItem key={index} value={value} disabled={value === 2023 && new Date().getMonth() < month}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <ArrowButton
        onClick={onNextClick}
        disabled={manualMode || dateRange.end.getTime() !== getEndDate(dateRange.start).getTime() || isLoading}
      />
    </Grid>
  );
}

export default BillingMonthSelector;
