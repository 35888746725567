"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROLES = exports.INTERNAL_ROLES = exports.COMPOSITE_ROLES_LIST = exports.ROLES_LIST = void 0;
// for creating a strong type on roles keys
exports.ROLES_LIST = [
    "workspace.list",
    "workspace.edit",
    "subscription.list",
    "subscription.edit",
    "user.list",
    "external.user.list",
    "user.edit",
    "user.iam.edit",
    "user.temporary",
    "user.iam.superadmin.edit",
    "session.list",
    "session.media.demo",
    "session.media",
    "session.data.detailed",
    "session.data.write",
    "session.report",
    "session.temporary_user_domain",
    "apikey.list",
    "apikey.edit",
    "apikey.secret",
    "internal.apikey.edit",
    "demo.use",
    "demo.presets",
    "documentation.list",
    "billing.list",
    "super_administrator",
    "rnd_user",
    "internal_tech_user",
    "business_administrator",
    "sales_user",
    "technical_administrator",
    "administrator",
    "user",
    "demo_access",
];
exports.COMPOSITE_ROLES_LIST = [
    { key: "super_administrator", order: 0, category: "internal" },
    { key: "technical_administrator", order: 1, category: "integrator" },
    { key: "business_administrator", order: 2, category: "internal" },
    { key: "administrator", order: 3, category: "generic" },
    { key: "internal_tech_user", order: 4, category: "internal" },
    { key: "rnd_user", order: 4, category: "internal" },
    { key: "sales_user", order: 5, category: "internal" },
    { key: "user", order: 6, category: "generic" },
    { key: "demo_access", order: 7, category: "generic" },
];
// NOTE: rnd_user is deprecated
exports.INTERNAL_ROLES = [
    "super_administrator",
    "rnd_user",
    "internal_tech_user",
    "business_administrator",
    "sales_user",
];
exports.ROLES = {
    SUPER_ADMINISTRATOR: "super_administrator",
    INTERNAL_TECH_USER: "internal_tech_user",
    BUSINESS_USER: "business_administrator",
    SALES_USER: "sales_user",
    TECHNICAL_ADMINISTRATOR: "technical_administrator",
    ADMINISTRATOR: "administrator",
    USER: "user",
    DEMO_ACCESS: "demo_access",
};
