import { SnackbarOrigin, SnackbarProps } from "@material-ui/core";
import { StringMap, TOptions } from "i18next";
import { ReactNode } from "react";
import { I18nCommonKeys } from "../i18n";
import { FIELD_CODES } from "@unissey/common";

type NotifType = "success" | "warning" | "error";

type TextKeyForType<T extends NotifType> = `${T}.${I18nCommonKeys<`notif.${T}`> extends string | number
  ? I18nCommonKeys<`notif.${T}`>
  : never}`;

type TextKey = TextKeyForType<"success"> | TextKeyForType<"warning"> | TextKeyForType<"error">;

export function extractNotifType<K extends TextKey>(k: K): NotifType | undefined {
  if (k.startsWith("warning")) return "warning";
  if (k.startsWith("success")) return "success";
  if (k.startsWith("error")) return "error";
}

export type NotifProps = {
  textKey: TextKey;
  textParams?: TOptions<StringMap>;
  errorFields?: typeof FIELD_CODES;
  icon?: ReactNode | undefined;
  anchorOrigin?: SnackbarOrigin | undefined;
};

export type AlertNotifProps = {
  snackbarProps?: SnackbarProps;
};

export type Notif = (notif: NotifProps) => void;

export type NotifContextType = {
  isOpen: boolean;
  props: NotifProps | undefined;
  trigger: ((props: NotifProps) => void) | undefined;
  handleOpen: (value: boolean) => void;
};
