import { config } from "../constants/env";

// types
import {
  CreateUser,
  ReadUser,
  Role,
  UpdateUser,
  User,
  CreateWorkspace,
  CreateWorkspaceExtended,
  ReadWorkspace,
  UpdateWorkspaceType,
  CreateSubscription,
  GetSubscriptionResponse,
  Subscription,
  SubscriptionByDateRange,
  SubscriptionFields,
  UpdateSubscription,
  CreateTemporaryUserResponse,
  GetTemporaryUserResponse,
  TemporaryDemoUsageResponse,
  TemporaryUserFormData,
  ReadApplication,
  CreateApplication,
  EditApplication,
  Session,
  SessionAnnotation,
  SimplifiedSession,
  AgeEstimationResult,
  IdentityRegistry,
  IdentitySession,
  IdentitySessionsListFilters,
  CreateRegistry,
  Identity,
  IdentityListFilters,
  IdentityWithMedia,
} from "@unissey/common";

// utils
import { FaceComparisonResultFilter, LivenessResultFilter } from "../types/results";

import axios from "axios";
import { keycloak } from "../auth";

// NOTE:
// The token is use in case the storageServices misses token, but it is available in the context
// It's a temporary fix for the issue with the signInHandler
export const getParams = (token?: string) => ({
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${keycloak.token ?? token}`,
  },
});

export type SessionSortableField =
  | "date"
  | "workspace"
  | "application"
  | "subscription"
  | "liveness"
  | "face_comparison"
  | "age"
  | "attempts"
  | "status";
export type SessionFieldOrder = {
  field: SessionSortableField;
  order: "asc" | "desc";
};

export async function getSessions(
  cursor: Date,
  [afterDate, beforeDate]: [Date | null, Date | null] = [null, null],
  workspaceIds: string[] = [],
  applicationIds: string[] = [],
  subscriptionTypes: string[] = [],
  livenessResults: (LivenessResultFilter | undefined)[] = [],
  faceComparisonResults: (FaceComparisonResultFilter | undefined)[] = [],
  ageEstimationResults: (AgeEstimationResult | undefined)[] = [],
  attempts: ("1" | "2" | "3+")[] = [],
  syncStatus: "synchronized" | "unsynchronized" | "any" = "any",
  orderBy: SessionFieldOrder[] = [],
  gdprConsentStatus: boolean[] = []
): Promise<SimplifiedSession[] | undefined> {
  const queryParams = new URLSearchParams({
    cursor: cursor.toISOString(),
  });

  const addArrayParam = (name: string, array: string[]) => {
    if (array.length > 0) queryParams.append(name, array.join(","));
  };

  if (afterDate) queryParams.append("after_date", afterDate.toISOString());
  if (beforeDate) queryParams.append("before_date", beforeDate.toISOString());
  addArrayParam("workspace_ids", workspaceIds);
  addArrayParam("application_ids", applicationIds);
  addArrayParam("subscription_types", subscriptionTypes);
  addArrayParam(
    "liveness_results",
    livenessResults.map((r) => r ?? "n/a")
  );
  addArrayParam(
    "face_comparison_results",
    faceComparisonResults.map((r) => r ?? "n/a")
  );
  addArrayParam(
    "age_estimation_results",
    ageEstimationResults.map((r) => r ?? "n/a")
  );
  addArrayParam("attempts", attempts);
  queryParams.append("sync_status", syncStatus);

  addArrayParam(
    "order_by",
    orderBy.map(({ field, order }) => `${field}:${order}`)
  );

  addArrayParam(
    "gdpr_consent",
    gdprConsentStatus.map((s) => (s ? "true" : "false"))
  );

  const res = await axios.get<SimplifiedSession[]>(`${config.apiAdminUrl}/sessions?${queryParams}`, getParams());

  return res.data;
}

export async function getSession(sessionId: string): Promise<Session | undefined> {
  const session = await axios.get<Session>(`${config.apiAdminUrl}/sessions/${sessionId}`, getParams());

  return session.data;
}

export async function syncSession(sessionID: string, sync: number) {
  await axios.patch(`${config.apiAdminUrl}/sessions/${sessionID}/sync`, {}, getParams());
}

export async function desyncSession(sessionId: string) {
  await axios.delete(`${config.apiAdminUrl}/sessions/${sessionId}/sync`, getParams());
}

export async function annotateSession(annot: SessionAnnotation, sessionID: string) {
  await axios.put(`${config.apiAdminUrl}/sessions/${sessionID}/annotate`, annot, getParams());
}

export async function getApplications(): Promise<ReadApplication[] | undefined> {
  const res = await axios.get<ReadApplication[]>(`${config.apiAdminUrl}/applications?`, getParams());

  return res.data;
}

export async function getApiKeySecret(applicationId: string): Promise<string | undefined> {
  const res = await axios.get<{ id: string; key: string }>(
    `${config.apiAdminUrl}/applications/${applicationId}/secret`,
    getParams()
  );

  return res.data.key;
}

export async function createApplication(fields: CreateApplication): Promise<boolean | undefined> {
  await axios.post(`${config.apiAdminUrl}/applications`, fields, getParams());

  return true;
}

export async function editApplication(id: string, fields: Required<EditApplication>): Promise<boolean | undefined> {
  await axios.put(`${config.apiAdminUrl}/applications/${id}`, fields, getParams());
  return true;
}

export async function revokeApplication(id: string): Promise<boolean | undefined> {
  await axios.delete(`${config.apiAdminUrl}/applications/${id}`, getParams());
  return true;
}

export async function getWorkspaces(directChildren?: boolean): Promise<ReadWorkspace[] | undefined> {
  const queryParams = new URLSearchParams();
  //if (directChildren) queryParams.append("directChildren", directChildren.toString());

  const workspaces = await axios.get<ReadWorkspace[]>(`${config.apiAdminUrl}/workspaces?${queryParams}`, getParams());
  return workspaces.data;
}

export async function getWorkspaceById(workspaceId: string): Promise<ReadWorkspace> {
  const workspace = await axios.get<ReadWorkspace>(`${config.apiAdminUrl}/workspaces/${workspaceId}`, getParams());
  return workspace.data;
}

export async function updateWorkspace(ws: UpdateWorkspaceType, wsId: string): Promise<boolean | undefined> {
  await axios.put(`${config.apiAdminUrl}/workspaces/${wsId}`, ws, getParams());
  return true;
}

export async function createWorkspace(ws: CreateWorkspace): Promise<string> {
  const res = await axios.post<{ id: string }>(`${config.apiAdminUrl}/workspaces`, ws, getParams());
  return res.data.id;
}

export async function createWorkspaceExtended(ws: CreateWorkspaceExtended): Promise<string> {
  const res = await axios.post<{ id: string }>(`${config.apiAdminUrl}/workspaces/c/extended/`, ws, getParams());
  return res.data.id;
}

export async function deleteWorkspace(wsId: string): Promise<boolean | undefined> {
  await axios.delete(`${config.apiAdminUrl}/workspaces/${wsId}`, getParams());
  return true;
}

export async function createUser(user: CreateUser): Promise<boolean | undefined> {
  await axios.post(`${config.apiAdminUrl}/users`, user, getParams());
  return true;
}

export async function updateProfile(user: UpdateUser): Promise<User | undefined> {
  const updatedUser = await axios.patch<User>(`${config.apiAdminUrl}/users/profile/me`, { ...user }, getParams());

  return updatedUser.data;
}

export async function updateUser(user: UpdateUser, userId: string): Promise<User | undefined> {
  const updatedUser = await axios.patch<User>(`${config.apiAdminUrl}/users/${userId}`, user, getParams());

  return updatedUser.data;
}

export async function triggerPasswordUpdate(userId: string) {
  await axios.put(`${config.apiAdminUrl}/users/${userId}/password`, {}, getParams());
}

export async function deleteUser(userId: string): Promise<boolean | undefined> {
  await axios.delete(`${config.apiAdminUrl}/users/${userId}`, getParams());
  return true;
}

export async function getRoles(): Promise<Role[]> {
  const res = await axios.get<Role[]>(`${config.apiAdminUrl}/roles`, getParams());

  return res.data;
}

export async function getTeamMembers(workspaceId: string): Promise<ReadUser[]> {
  const res = await axios.get<ReadUser[]>(`${config.apiAdminUrl}/workspaces/${workspaceId}/users`, getParams());

  return res.data;
}

export async function getSubscriptions(excludeKind?: string, isActive?: boolean): Promise<Subscription[]> {
  const queryParams = new URLSearchParams();
  if (isActive) queryParams.append("isActive", isActive.toString());
  if (excludeKind) queryParams.append("excludeKind", excludeKind);

  let res = await axios.get<Subscription[]>(
    `${config.apiAdminUrl}/subscriptions?${queryParams.toString()}`,
    getParams()
  );

  return res.data;
}

export async function getSubscriptionsByDateRange(
  startDate: Date | null,
  endDate: Date | null
): Promise<SubscriptionByDateRange[]> {
  if (!startDate || !endDate) return [];
  const queryParams = new URLSearchParams();

  queryParams.append("startDate", startDate.toISOString());
  queryParams.append("endDate", endDate.toISOString());

  return (
    await axios.get<SubscriptionByDateRange[]>(
      `${config.apiAdminUrl}/subscriptions/stats/billing?${queryParams.toString()}`,
      getParams()
    )
  ).data;
}

export async function getSubscription(id: string): Promise<GetSubscriptionResponse> {
  let res = await axios.get<GetSubscriptionResponse>(`${config.apiAdminUrl}/subscriptions/${id}`, getParams());

  return res.data;
}

export async function getSubscriptionsByWorkspaceId(
  workspaceId: string | undefined,
  excludeKind?: string,
  isActive?: boolean
): Promise<Subscription[]> {
  const queryParams = new URLSearchParams();

  if (isActive) queryParams.append("isActive", isActive.toString());
  if (excludeKind) queryParams.append("excludeKind", excludeKind);

  if (!workspaceId) return [];
  let res = await axios.get<Subscription[]>(
    `${config.apiAdminUrl}/subscriptions/workspaces/${workspaceId}?${queryParams.toString()}`,
    getParams()
  );

  return res.data;
}

function parseDates(reqBody: SubscriptionFields): {
  startDate: string;
  endDate: string | undefined;
} {
  const startDate = reqBody.startDate.toISOString();
  const endDate = reqBody.endDate ? reqBody.endDate.toISOString() : undefined;
  return { startDate, endDate };
}

export async function createSubscription(reqBody: CreateSubscription): Promise<boolean | undefined> {
  const { startDate, endDate } = parseDates(reqBody);
  await axios.post(`${config.apiAdminUrl}/subscriptions`, { ...reqBody, startDate, endDate }, getParams());

  return true;
}

export async function updateSubscription(reqBody: UpdateSubscription): Promise<boolean | undefined> {
  const body = { ...reqBody };
  await axios.put(`${config.apiAdminUrl}/subscriptions/${reqBody.id}`, body, getParams());
  return true;
}

export async function createTemporaryUser(reqBody: TemporaryUserFormData): Promise<CreateTemporaryUserResponse> {
  const res = await axios.post<CreateTemporaryUserResponse>(
    `${config.apiAdminUrl}/temporary_users`,
    reqBody,
    getParams()
  );

  return res.data;
}

export async function getTemporaryUser(userId: string): Promise<GetTemporaryUserResponse> {
  const res = await axios.get<GetTemporaryUserResponse>(`${config.apiAdminUrl}/temporary_users/${userId}`, getParams());

  return res.data;
}

export async function activateTemporaryUser(userId: string): Promise<GetTemporaryUserResponse> {
  const res = await axios.get<GetTemporaryUserResponse>(
    `${config.apiAdminUrl}/temporary_users/${userId}/activate`,
    getParams()
  );

  return res.data;
}

export async function sendTemporaryUserInvitation(userId: string, firstName: string, lastName: string) {
  await axios.post(
    `${config.apiAdminUrl}/temporary_users/${userId}/send_invite`,
    { userId, firstName, lastName },
    getParams()
  );
}

export async function addPersonalDataAuthException(userId: string): Promise<void> {
  await axios.put(`${config.apiAdminUrl}/users/${userId}/personal-data`, undefined, getParams());
}

export async function removePersonalDataAuthException(userId: string): Promise<void> {
  await axios.delete(`${config.apiAdminUrl}/users/${userId}/personal-data`, getParams());
}

export async function getTemporaryDemoUsage(start: Date, end?: Date): Promise<TemporaryDemoUsageResponse> {
  const searchParams = new URLSearchParams({
    start: start.toISOString(),
  });
  if (end) searchParams.append("end", end.toISOString());

  const res = await axios.get<TemporaryDemoUsageResponse>(
    `${config.apiAdminUrl}/temporary_users/sessions/usage?${searchParams.toString()}`,
    getParams()
  );

  return res.data;
}

export async function getIdentityRegistries(workspaceIds?: string[]): Promise<IdentityRegistry[]> {
  const queryParams = new URLSearchParams();

  if (workspaceIds && workspaceIds.length > 0) {
    queryParams.append("workspaceIds", workspaceIds.join(","));
  }

  const res = await axios.get<IdentityRegistry[]>(
    `${config.apiAdminUrl}/identify/registries?${queryParams.toString()}`,
    getParams()
  );
  return res.data;
}

export async function getIdentitySessions(cursor?: Date, filters?: IdentitySessionsListFilters) {
  const queryParams = new URLSearchParams();

  if (cursor) queryParams.append("cursor", cursor.toISOString());
  if (filters && filters.actionKind && filters.actionKind.length > 0)
    queryParams.append("action_kind", filters.actionKind.join(","));

  if (filters && filters.results && filters.results.length > 0)
    queryParams.append("identity_result", filters.results.join(","));

  if (filters && filters.applicationIds && filters.applicationIds.length > 0)
    queryParams.append("application_ids", filters.applicationIds.join(","));

  if (filters && filters.workspacesIds && filters.workspacesIds.length > 0)
    queryParams.append("workspace_ids", filters.workspacesIds.join(","));

  if (filters && filters.registryIds && filters.registryIds.length > 0)
    queryParams.append("registry_ids", filters.registryIds.join(","));

  if (filters && filters.subscriptionKind && filters.subscriptionKind.length > 0)
    queryParams.append("subscription_kind", filters.subscriptionKind.join(","));

  if (filters && filters.gdprConsent && filters.gdprConsent.length > 0)
    queryParams.append("gdpr_consent", filters.gdprConsent.join(","));

  if (filters && filters.liveness && filters.liveness.length > 0)
    queryParams.append("liveness_result", filters.liveness.join(","));

  if (filters && filters.beforeDate) queryParams.append("before_date", filters.beforeDate.toISOString());
  if (filters && filters.afterDate) queryParams.append("after_date", filters.afterDate.toISOString());
  if (filters) {
    queryParams.append("order", filters.order);
    queryParams.append("nb_items", `${filters.nbItems}`);
  }

  const res = await axios.get<IdentitySession[]>(
    `${config.apiAdminUrl}/identify/identity-sessions?${queryParams.toString()}`,
    getParams()
  );

  return res.data;
}

export async function createRegistry(registry: CreateRegistry) {
  await axios.post(`${config.apiAdminUrl}/identify/registries`, registry, getParams());
}

export async function editRegistry(id: string, name: string) {
  await axios.patch(`${config.apiAdminUrl}/identify/registries/${id}`, { name }, getParams());
}

export async function getIdentities(cursor?: Date, filters?: IdentityListFilters) {
  const queryParams = new URLSearchParams();

  if (cursor) queryParams.append("cursor", cursor.toISOString());

  if (filters && filters.workspacesIds && filters.workspacesIds.length > 0)
    queryParams.append("workspace_ids", filters.workspacesIds.join(","));

  if (filters && filters.registryIds && filters.registryIds.length > 0)
    queryParams.append("registry_ids", filters.registryIds.join(","));

  if (filters && filters.beforeDate) queryParams.append("before_date", filters.beforeDate.toISOString());
  if (filters && filters.afterDate) queryParams.append("after_date", filters.afterDate.toISOString());

  if (filters && filters.lastEnrollmentBeforeDate)
    queryParams.append("last_enrollment_before_date", filters.lastEnrollmentBeforeDate.toISOString());
  if (filters && filters.lastEnrollmentAfterDate)
    queryParams.append("last_enrollment_after_date", filters.lastEnrollmentAfterDate.toISOString());

  if (filters && filters.gdprConsent && filters.gdprConsent.length > 0)
    queryParams.append("gdpr_consent", filters.gdprConsent.join(","));

  if (filters) {
    queryParams.append("order", filters.order);
    queryParams.append("nb_items", `${filters.nbItems}`);
  }

  const res = await axios.get<Identity[]>(
    `${config.apiAdminUrl}/identify/identities?${queryParams.toString()}`,
    getParams()
  );
  return res.data;
}

export async function getIdentity(id: string) {
  const res = await axios.get<IdentityWithMedia>(`${config.apiAdminUrl}/identify/identities/${id}`, getParams());

  return res.data;
}
