import { TFunction } from "i18next";
import { DataTableColumn } from "../../../components/DataTable";
import { SubscriptionByDateRange } from "@unissey/common";
import { BillingDisplay } from "../types/general";

export const getColumns = (
  subscriptions: SubscriptionByDateRange[] | undefined,
  t: TFunction
): DataTableColumn<BillingDisplay>[] => {
  return [
    {
      dataKey: "workspace",
      title: t("billing_page.table.header_workspace"),
      filter: {
        label: t("billing_page.table.header_workspace"),
        widget: "select",
        lookup: "contains",
        values: Array.from(new Set(subscriptions?.map((sub) => sub.workspaceName))),
      },
    },
    {
      dataKey: "subscription",
      title: t("billing_page.table.header_subscription"),
      filter: {
        label: t("billing_page.table.header_subscription"),
        widget: "select",
        lookup: "contains",
        values: Array.from(new Set(subscriptions?.map((sub) => sub.subscriptionName))),
      },
    },
    {
      dataKey: "subscriptionType",
      title: t("billing_page.table.header_subscriptionType"),
      filter: {
        label: t("billing_page.table.header_subscriptionType"),
        widget: "select",
        lookup: "contains",
        values: Array.from(new Set(subscriptions?.map((sub) => t(`sub_kind.${sub.subscriptionKind}`) as string))),
      },
    },
    {
      dataKey: "livenessOnly",
      title: t("billing_page.table.header_liveness"),
      sorter: (a, b) => (a.livenessOnly < b.livenessOnly ? -1 : 1),
    },
    {
      dataKey: "faceCompOnly",
      title: t("billing_page.table.header_faceComp"),
      sorter: (a, b) => (a.faceCompOnly < b.faceCompOnly ? -1 : 1),
    },
    {
      dataKey: "livenessFaceComp",
      title: t("billing_page.table.header_liveness_faceComp"),
      sorter: (a, b) => (a.livenessAndFaceComp < b.livenessAndFaceComp ? -1 : 1),
    },
    {
      dataKey: "totalSessions",
      title: t("billing_page.table.header_totalSessions"),
      sorter: (a, b) => (a.totalSessions < b.totalSessions ? -1 : 1),
    },
  ];
};
