// TODO: use model of CreateTeamMember.tsx
import { Button, CircularProgress, DialogProps } from "@material-ui/core";
import React from "react";

import { Modal } from "../../components/Modal";

import { useScopedTranslation } from "../../i18n";
import { useStores } from "../../hooks/use-stores";
import CreateWorkspaceForm from "./CreateWorkspaceForm";
import { observer, useLocalObservable } from "mobx-react-lite";
import { toJS } from "mobx";
import { WorkspaceCreation, WorkspaceCreationState } from "../../stores/forms";
import { createWorkspaceExtended } from "../../services/api_services";
import { COMPOSITE_ROLES_LIST, CreateWorkspaceExtended, WorkspaceCreateTeamMember } from "@unissey/common";
import { useNotif } from "../../contexts/notif_context";
import { useAuth } from "../../auth";

type Props = DialogProps & {
  onClose: () => void;
  handleClose: () => void;
};

function validateTeamMemberForm(form: WorkspaceCreateTeamMember): boolean {
  const nonEmptyFirstName = form.firstName.length > 0;
  const nonEmptyLastName = form.lastName.length > 0;
  const validRoleKey = COMPOSITE_ROLES_LIST.map((r) => r.key).includes(form.roleKey);
  const validEmail = /\S+@\S+\.\S+/.test(form.email);
  return nonEmptyFirstName && nonEmptyLastName && validRoleKey && validEmail;
}

function validateWsStateForm(form: WorkspaceCreationState): boolean {
  return form.workspaceName.length > 0;
}

function validateWorkspaceForm(form: WorkspaceCreation): boolean {
  const f = toJS(form);

  const usersValidation = Array.from(f.users).every(([_, v]) => validateTeamMemberForm(v));
  const adminValidation = validateTeamMemberForm(f.administrator);
  const wsValidation = validateWsStateForm(f.wsState);
  return usersValidation && adminValidation && wsValidation;
}

function CreateWorkspaceModal({ open, onClose, handleClose }: Props) {
  const { workspaceStore } = useStores();
  const { t } = useScopedTranslation("workspace_page.create_dialog");

  const notif = useNotif();
  const auth = useAuth();
  const canCreateSubscription = auth.hasRoles(["subscription.edit"]);
  const form = useLocalObservable(() => new WorkspaceCreation({ withSubscription: canCreateSubscription }));

  const handleCreateWS = async () => {
    form.setIsLoading(true);
    if (form && form.wsState && auth.user) {
      const { wsState, administrator, users, subscription } = toJS(form);
      const createWSReq: CreateWorkspaceExtended = {
        name: wsState.workspaceName,
        parent: auth.user.workspace.id,
        domain: `http://${administrator.email.split("@", 2)[1]}`,
        adminFirstName: administrator.firstName,
        adminLastName: administrator.lastName,
        adminEmail: administrator.email,
        adminRoleKey: administrator.roleKey,
        adminHasGdpr: administrator.hasGdpr,
        members: [],
        subscription,
      };

      const usersToAdd = Array.from(users.values());
      for (const user of usersToAdd) {
        createWSReq.members.push({
          email: user.email,
          roleKey: user.roleKey,
          firstName: user.firstName,
          lastName: user.lastName,
          hasGdpr: user.hasGdpr,
        });
      }

      try {
        const id = await createWorkspaceExtended(createWSReq);
        notif.trigger?.({
          textKey: "success.workspace_created",
          textParams: { workspaceName: form.wsState.workspaceName },
        });
        form.reset();
        await workspaceStore.fetchWorkspaceById(id);
      } catch (err) {}
    }
    form.setIsLoading(false);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      maxWidth="md"
      body={<CreateWorkspaceForm form={form} />}
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={handleCreateWS}
          fullWidth
          startIcon={form.isLoading && <CircularProgress size={14} />}
          disabled={form.isLoading || !validateWorkspaceForm(form)}
          data-testid="create-workspace-form-btn"
        >
          {t("button_create_and_invite")}
        </Button>
      }
    />
  );
}

export default observer(CreateWorkspaceModal);
