import { Box, Button, Typography } from "@material-ui/core";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";

// components
import CustomTable from "../../components/CustomTable";
import InfoCard from "../../components/InfoCard";
import {
  LivenessChip,
  FaceComparisonChip,
  AgeVerificationChip,
  AgeEstimationResultChip,
} from "../../components/SessionResultChip";
import { useScopedTranslation } from "../../i18n";
import { Cancel, CheckCircle } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";

import { AgeResult, FaceComparisonResult, LivenessResult } from "../../types/results";
import { hasError } from "../../services/analyze_service";
import { AnalyzeResponse } from "@unissey/common";
import { useAuth } from "../../auth";
import { AgeSettings } from "./SessionSettings";
import { usePersistedState } from "../../utils/persistence";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    margin: "auto auto",
    width: "100%",

    alignItems: "center",
  },
  "@media (min-width: 600px)": {
    container: {
      width: "75%",
    },
  },
});

type Props = {
  response: AnalyzeResponse;
  onFinish: () => void;
  finishText: string; // Note (Guillaume): We were asked to tweak the text in case of a temporary demo
  retrySettingEnabled: boolean;
};

export default function DemoResults({ response, onFinish, finishText, retrySettingEnabled }: Props) {
  const auth = useAuth();
  const { t, tCommon } = useScopedTranslation("demo_page.results_screen");

  const classes = useStyles();

  const [allowAge] = usePersistedState<AgeSettings>("allowAge", {
    userId: auth.user?.id,
    enabled: false,
    threshold: "18",
  });

  let responseDetails: JSX.Element;

  if (hasError(response)) {
    responseDetails = <InfoCard text={response.message ?? "Analysis error"} icon={ErrorOutlineOutlinedIcon} />;
  } else {
    const { liveness, faceComparison, age } = extractResults(response);
    const overallOk = liveness === "genuine" && faceComparison !== "mismatch";
    const rows = [
      [<Typography>{t("liveness")}</Typography>, <LivenessChip value={liveness} />],
      [<Typography>{t("face_comparison")}</Typography>, <FaceComparisonChip value={faceComparison} />],
    ];

    if (auth.user?.canViewAgeFeature && allowAge?.enabled && allowAge?.threshold) {
      rows.push(
        [
          <Typography>{t("age_estimation")}</Typography>,
          <AgeEstimationResultChip ageResult={age} value={response.data.details.age?.age_estimation} />,
        ],
        [
          <Typography>{t("age_verification")}</Typography>,
          <AgeVerificationChip
            value={`${response.data.details?.age?.age_threshold} ${tCommon("sessions_page.details_modal.age_year")}`}
            ageResult={age}
          />,
        ]
      );
    } else if (auth.user?.canViewAgeFeature && allowAge?.enabled) {
      rows.push([
        <Typography>{t("age_estimation")}</Typography>,
        <AgeEstimationResultChip ageResult={age} value={response.data.details.age?.age_estimation} />,
      ]);
    }

    responseDetails = (
      <>
        {liveness === "fail" && response.data.retries_remaining === 0 && retrySettingEnabled && (
          <>
            <InfoCard text={t("max_retries_reached")} />
            <Box m={6} />
          </>
        )}
        {
          <>
            <Typography align="center" variant="h6" style={{ fontWeight: 700 }}>
              {t("results")}
            </Typography>
            {overallOk ? (
              <CheckCircle htmlColor="#0fdb91" style={{ fontSize: "7em", margin: "64px 0" }} />
            ) : (
              <Cancel htmlColor="#3d59e8" style={{ fontSize: "7em", margin: "64px 0" }} />
            )}

            {<CustomTable noEvenOddColoring lastColAlignRight rows={rows} />}
          </>
        }
      </>
    );
  }
  return (
    <Box className={classes.container}>
      {responseDetails}
      <Box m={6} />
      <Button
        color="primary"
        variant="contained"
        disableElevation
        fullWidth
        onClick={onFinish}
        data-testid="demo-finish-button"
      >
        {finishText}
      </Button>
    </Box>
  );
}

function extractResults(response: AnalyzeResponse): {
  liveness: LivenessResult;
  faceComparison?: FaceComparisonResult;
  age?: AgeResult;
} {
  const liveness = response.data.is_genuine ? "genuine" : "fail";

  let faceComparison: FaceComparisonResult | undefined = undefined;
  if (response.data.is_match !== undefined) {
    faceComparison = response.data.is_match ? "match" : "mismatch";
  }

  let age: AgeResult | undefined = response.data.details.age?.result;

  return { liveness, faceComparison, age };
}
