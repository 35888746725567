"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FIELD_CODES = exports.FieldCode = exports.ERROR_CODES = exports.ErrorCode = void 0;
exports.ErrorCode = {
    notFound: {
        APPLICATION_NOT_FOUND: 100,
        SESSION_NOT_FOUND: 101,
        SUBSCRIPTION_NOT_FOUND: 102,
        SUBSCRIPTIONS_NOT_FOUND: 103,
        USER_NOT_FOUND: 104,
        USERS_NOT_FOUND: 105,
        TOKEN_NOT_FOUND: 106,
        WORKSPACE_NOT_FOUND: 108,
        WORKSPACES_NOT_FOUND: 109,
        KEYCLOAK_CANNOT_FIND_USER: 110,
        SSO_AUTH_CANNOT_FIND_USER_ID: 111,
        SESSION_HAS_EXPIRED: 112,
        KEYCLOAK_USER_NOT_FOUND: 113,
        KEYCLOAK_WORKSPACE_NOT_FOUND: 114,
        INDEX_NOT_FOUND: 115, // "Identity index not found"
    },
    invArgs: {
        ROLE_VERIFICATION_FAILED: 201,
        CANNOT_REMOVE_LAST_WORKSPACE_ADMINISTRATOR: 202,
        CANNOT_EDIT_LAST_WORKSPACE_ADMINISTRATOR_ROLE: 203,
        CANNOT_CREATE_FREE_DEMO_API_KEY: 205,
        INVALID_SUBSCRIPTION_ID: 210,
        WORKSPACE_NAME_EXISTS: 212,
        WORKSPACE_NAME_EXISTS_SCHEDULED_DELETION: 213,
        SSO_LOGIN_INVALID_EMAIL: 215,
        WORKSPACE_INVALID_ID: 221,
        // STARTING BELOW ARE VALIDATORS (array of fields are integrated in the response)
        CREATE_SESSION: 222,
        GET_SESSION: 223,
        GET_SESSIONS: 224,
        SYNC_SESSION: 224,
        REVIEW_SESSION: 225,
        EDIT_USER_ROLES: 236,
        GET_USER_BY_AUTH_ID: 237,
        AUTHENTIFY_SSO: 238,
        RESET_PASSWORD: 239,
        VALIDATE_PASSWORD_RESET_TOKEN: 240,
        EDIT_PASSWORD: 241,
        GET_USER: 242,
        EDIT_USER: 243,
        NEW_USER: 244,
        CREATE_WORKSPACE: 245,
        UPDATE_WORKSPACE: 246,
        DELETE_USER: 247,
        LIST_APPLICATIONS: 248,
        CREATE_APPLICATION: 249,
        UPDATE_APPLICATION: 250,
        CREATE_SUBSCRIPTION: 251,
        UPDATE_SUBSCRIPTION: 252,
        LOGIN: 253,
        LOGIN_SSO: 254,
        REFRESH_LOGIN_TOKEN: 255,
        CREATE_SUBSCRIPTION_NAME_ALREADY_EXISTS: 256,
        CREATE_WORKSPACE_NAME_TOO_LONG: 257,
    },
    permDenied: {
        CANNOT_ACCESS_ROUTE_MISSING_AUTHORIZATION: 300,
        TOKEN_MAX_USES_EXCEEDED: 301,
        TOKEN_WRONG_TYPE: 302,
        MISSING_API_KEY_AUTHORIZATION: 303,
        LIST_API_KEY_UNAUTHORIZED_WORKSPACE_IDS: 304,
        MISSING_SUB_WORKSPACE_AUTHORIZATION: 305,
        MISSING_SESSION_DATA_WRITE_AUTHORIZATION: 306,
        MISSING_SESSION_LIST_AUTHORIZATION: 307,
        MISSING_USER_LIST_AUTHORIZATION: 308,
        MISSING_USER_IAM_AUTHORIZATION: 309,
        MISSING_USER_EDIT_AUTHORIZATION: 310,
        WORKSPACE_CANNOT_MODIFY_OWN: 311,
        WORKSPACE_SPECIFIED_CANNOT_BE_MODIFIED: 312,
        MISSING_WORKSPACE_LIST_AUTHORIZATION: 313,
        NO_TOKEN_PROVIDED: 314,
        TOKEN_CANNOT_DECODE: 315,
        KEYCLOAK_BAD_PASSWORD: 316,
        SQREEN_SUSPICIOUS_USER: 317,
    },
    notAuthed: {
        TOKEN_DATA_NOT_VALID: 400,
        CANNOT_AUTHENTICATE_REQUEST: 401,
        TOO_MUCH_LOGIN_FAIL: 402,
        TOO_MUCH_RESET_PASSW_REQ_FAIL: 403,
        KEYCLOAK_INVALID_USERNAME_PASSWORD: 404,
        KEYCLOAK_EXPIRED_TOKEN: 405,
        KEYCLOAK_INVALID_TOKEN: 406,
        KEYCLOAK_INVALID_CODE: 407,
        TOO_MUCH_RESET_PASSWORD_REQ_FAIL: 408,
        KEYCLOAK_CANNOT_REFRESH_EXPIRED_TOKEN: 409, // "Cannot refresh an expired token"
    },
    internal: {
        KEYCLOAK_CANNOT_CONNECT: 500,
        KEYCLOAK_CANNOT_EDIT_PASSWORD: 501,
        KEYCLOAK_CANNOT_CREATE_USER: 502,
        UNKNOWN_INTERNAL_EXCEPTION: 503,
        TOKEN_CANNOT_GENERATE: 504,
        TOKEN_CANNOT_DECODE: 505,
        ROLES_NOT_FOUND: 506,
        USER_DELETION_FAILED: 507,
        KEYCLOAK_INTERNAL_EXCEPTION: 508,
        CANNOT_GENERATE_EMAIL_TEMPLATE: 509,
        CANNOT_SEND_EMAIL: 510,
        ENUM_VALUE_NOT_FOUND: 511,
        INVALID_REQUEST: 512,
    },
};
exports.ERROR_CODES = Object.values(exports.ErrorCode).flatMap((category) => Object.values(category));
exports.FieldCode = {
    EMAIL: 0,
    USERNAME: 1,
    PASSWORD: 2,
    FIRSTNAME: 3,
    LASTNAME: 4,
    POSITION: 5,
    USER_ID: 6,
    USER_AUTH_ID: 7,
    ROLE_ID: 8,
    TOKEN: 9,
    TIME_RANGE: 10,
    SESSION_ID: 11,
    SESSION_ID_LIST: 12,
    SESSION_SYNC: 13,
    WORKSPACE_ID: 14,
    WORKSPACE_ID_LIST: 15,
    WORKSPACE_NAME: 16,
    WORKSPACE_DOMAIN: 17,
    APPLICATION_ID: 18,
    APPLICATION_ID_LIST: 19,
    SUBSCRIPTION_NAME: 20,
    SUBSCRIPTION_KIND: 21,
    SUBSCRIPTION_MAX_SESSION_COUNT: 22,
    LABEL_LIST: 23,
    MEDIAS: 24,
    PARENT_SESSION_ID: 25,
    RESULT_MISSING: 26,
    FACE_MATCHING_RESULT: 27,
    LIVENESS_RESULT: 28,
    IS_REVIEWED: 29,
    RESULT_TYPE: 30,
    RESULT_IS_GENUINE: 31,
    RESULT_PROBABILITY: 32,
    RESULT_SCORE: 33,
    RESULT_TRIES: 34,
    RESULT_HEART_RATE: 35,
    RESULT_SIGNAL_LIST: 36,
    RESULT_SPECTRUM_LIST: 37,
    RESULT_REVIEW: 38,
    RESULT_MODEL_ID: 39,
    RESULT_THRESHOLD: 40,
    APPLICATION_NAME: 41,
    REFERRER_LIST: 42,
};
exports.FIELD_CODES = Object.values(exports.FieldCode);
