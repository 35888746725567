import type { TFunction } from "react-i18next";

export enum Routes {
  HOME = "/",
  SIGNIN = "/signin",
  SIGNUP = "/signup",
  SESSION = "/session",
  IDENTITY_SESSIONS = "/identity-sesssions",
  REGISTRIES = "/registries",
  IDENTITIES = "/identities",
  SETTINGS = "/settings",
  WORKSPACE = "/workspace",
  TEAM = "/team",
  APPLICATIONS = "/applications",
  SUBSCRIPTIONS = "/subscriptions",
  SUBSCRIPTION_DETAILS = "/subscriptions/:subscriptionId",
  DEMO_TOU = "/demo",
  DEMO_CAPTURE = "/demo/capture",
  REPORT = "/report",
  FINALIZE_SIGNUP = "/signup/finalize",
  RESET_PASSWORD = "/password-reset",
  PROFILE = "/profile",
  SESSIONS = "/sessions",
  DOCUMENTATION = "/docs",
  TRANSIENT_DEMO = "/transient-demo",
  BILLING = "/billing",
}

export function pageHeader(route: string, t: TFunction) {
  const routeBases = <const>[
    [Routes.SESSIONS, t("page_headers.sessions")],
    [Routes.IDENTITY_SESSIONS, t("page_headers.identity_sessions")],
    [Routes.REGISTRIES, t("page_headers.registries")],
    [Routes.IDENTITIES, t("page_headers.identities")],
    [Routes.DEMO_TOU, t("page_headers.demo")],
    [Routes.DEMO_CAPTURE, t("page_headers.demo_capture")],
    [Routes.PROFILE, t("page_headers.profile")],
    [Routes.WORKSPACE, t("page_headers.workspaces")],
    [Routes.TEAM, t("page_headers.team")],
    [Routes.SUBSCRIPTIONS, t("page_headers.subscriptions")],
    [Routes.APPLICATIONS, t("page_headers.applications")],
    [Routes.DOCUMENTATION, t("page_headers.documentation")],
    [Routes.REPORT, t("page_headers.report")],
    [Routes.BILLING, t("page_headers.billing")],
  ];

  return routeBases.find(([base, _]) => route.startsWith(base))?.[1];
}
