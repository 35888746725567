import { Box, Button, CircularProgress, Grid, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BillingDateTimePicker from "./BillingDateTimePicker";
import { DateRange } from "./types/general";

const useStyles = makeStyles(() => ({
  date_form: {
    width: "100%",
  },
}));

export type Props = {
  dateRange: DateRange;
  handleDateRange: (newDates: { start?: Date; end?: Date }) => void;
  loading: boolean;
};

export default function BillingDateForm({ dateRange, handleDateRange, loading }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [temporaryDates, setTemporaryDates] = useState<DateRange>(dateRange);

  useEffect(() => {
    setTemporaryDates(dateRange);
  }, [dateRange]);

  const isClickable = () => {
    const compareStartDate = dateRange.start.getTime() === temporaryDates.start.getTime();
    const compareEndDate = dateRange.end.getTime() === temporaryDates.end.getTime();
    if (compareStartDate && compareEndDate) return true;
    return false;
  };

  const handleTempDates = (newDates: { start?: Date; end?: Date }) => {
    setTemporaryDates((prev) => ({
      ...prev,
      ...newDates,
    }));
  };

  const handleStartChange = (value: Date | null) => {
    if (!value) handleTempDates({ start: undefined });
    else handleTempDates({ start: value });
  };

  const handleEndChange = (value: Date | null) => {
    if (!value) handleTempDates({ end: undefined });
    else handleTempDates({ end: value });
  };

  const handleSubmit = () => {
    handleDateRange({ start: temporaryDates.start, end: temporaryDates.end });
  };

  return (
    <Box className={classes.date_form}>
      <Grid item container direction="row" justifyContent="space-evenly" alignItems="center">
        <Grid item>
          <BillingDateTimePicker
            dateType="start"
            startDateValue={temporaryDates.start}
            endDateValue={temporaryDates.end}
            handleChange={handleStartChange}
          />
        </Grid>
        <Grid item>
          <BillingDateTimePicker
            dateType="end"
            startDateValue={temporaryDates.start}
            endDateValue={temporaryDates.end}
            handleChange={handleEndChange}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={loading || isClickable()}
            data-testid="billing-get-session-button"
          >
            {loading ? (
              <>
                {t("loading")} <CircularProgress size={20} />
              </>
            ) : (
              t("billing_page.session_count_button")
            )}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
