"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROLE_IDS = exports.Roles = void 0;
exports.Roles = {
    SUPER_ADMINISTRATOR: 1,
    RD_USER: 2,
    BUSINESS_USER: 3,
    SALES_USER: 8,
    TECHNICAL_ADMINISTRATOR: 4,
    ADMINISTRATOR: 5,
    USER: 6,
    DEMO_ACCESS: 7,
};
exports.ROLE_IDS = Object.values(exports.Roles);
