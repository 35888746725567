import { Box, CircularProgress } from "@material-ui/core";
import axios from "axios";
import Keycloak from "keycloak-js";
import { createContext, useContext, useEffect, useState } from "react";

import { INTERNAL_ROLES, RoleKey, UserAuth } from "@unissey/common";

import { config } from "./constants/env";

export const keycloak = new Keycloak({
  url: config.kcAuthUrl,
  realm: config.kcRealm,
  clientId: config.kcClientId,
});

const TOKEN_VALIDITY = 60 * 10; // 60s * 10mn;

keycloak.onTokenExpired = () => keycloak.updateToken(TOKEN_VALIDITY);

// We check the issued at value (iat) and the auth_time value on the token.
// If iat ~= auth_time (with a margin of error of one second), the user has just logged in, so we clear the cache.
// Otherwise, the user simply refreshed the tab and we do nothing.
keycloak.onAuthSuccess = () => {
  if (keycloak.tokenParsed?.iat && keycloak.tokenParsed?.auth_time) {
    const difference = Math.abs(keycloak.tokenParsed?.iat - keycloak.tokenParsed?.auth_time);
    if (difference <= 1) {
      window.localStorage.removeItem("iadEnabled");
      window.localStorage.removeItem("allowRetry");
      window.localStorage.removeItem("demoReminder");
      window.sessionStorage.clear();
    }
  }
};

type AuthContextType = {
  roles: RoleKey[];
  keycloak: Keycloak;
  user?: UserAuth;
};

const AuthContext = createContext<AuthContextType>({
  roles: [],
  keycloak,
});

type AuthProviderProps = {
  children: JSX.Element;
  keycloak: Keycloak;
};

async function getUserAuth(accessToken: string) {
  const url = `${config.apiAdminUrl}/users/profile/me`;

  const res = await axios.get<UserAuth>(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return res.data;
}

export const AuthProvider = ({ children, keycloak }: AuthProviderProps) => {
  const [roles, setRoles] = useState([] as RoleKey[]);
  const [accessToken, setAccessToken] = useState(undefined as string | undefined);

  const [user, setUser] = useState(undefined as UserAuth | undefined);

  // Init keycloak for getting tokens and users roles
  useEffect(() => {
    keycloak
      .init({
        onLoad: "login-required",
        pkceMethod: "S256",
        checkLoginIframe: false,
      })
      .then(() => {
        setRoles((keycloak.tokenParsed?.realm_access?.roles as RoleKey[]) ?? []);
        setAccessToken(keycloak?.token);
      })
      .catch((err) => {
        alert("failed to initialize");
      });
  }, [keycloak]);

  // fetch user details
  useEffect(() => {
    if (accessToken) getUserAuth(accessToken).then((user) => setUser((u) => user));
  }, [accessToken]);

  return (
    <AuthContext.Provider value={{ roles, keycloak, user }}>
      {user ? (
        children
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100vh">
          <CircularProgress />
        </Box>
      )}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const authContext = useContext(AuthContext);

  const hasRoles = (roles: RoleKey[]) => roles.every((role) => authContext.roles.includes(role));

  const hasOneOfRoles = (roles: RoleKey[]) => roles.some((role) => authContext.roles.includes(role));

  const isInternalUser = () => hasOneOfRoles(INTERNAL_ROLES);

  return {
    access: {
      roles: authContext.roles,
      keycloak,
    },
    user: authContext.user!, // Given the provider is rendered only if the user datas has been fetch we can force it to be defined
    hasRoles,
    hasOneOfRoles,
    isInternalUser,
  };
}

type CanProps = {
  roles: RoleKey[];
  children: JSX.Element;
};

export function Can({ roles, children }: CanProps) {
  const auth = useContext(AuthContext);

  const shouldRenderChildren = roles.every((role) => auth.roles.includes(role));

  if (shouldRenderChildren) return <>{children}</>;

  return null;
}
