import {
  Box,
  createStyles,
  FormControl,
  Input,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  Switch,
  Theme,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { AcquisitionPreset, IadMode } from "@unissey/sdk-react";
import React, { useState } from "react";
import { SettingsDisabledIcon } from "../../components/SettingsDisabledIcon";
import { SettingsIcon } from "../../components/SettingsIcon";

import { useSessionConfig } from "../../hooks/use-session-config";
import { useScopedTranslation } from "../../i18n";
import { usePersistedState } from "../../utils/persistence";

import { InjectionInstructionsModal } from "./InjectionInstructionsModal";
import { InjectionInstructionsPopover } from "./InjectionInstructionsPopover";
import { useAuth } from "../../auth";

// TODO: Create a custom theme and use variables instead of hard coded colors
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      backgroundColor: "#f5f5f5",
      padding: "10px",
      maxWidth: "300px",
    },
    notActive: {
      color: "#979797",
    },
    title: {
      fontSize: "14px",
      lineHeight: "16px",
    },
    subheading: {
      fontSize: "12px",
      lineHeight: "18px",
    },
    formControl: {
      margin: theme.spacing(1),
      width: 200,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const GreenSwitch = withStyles({
  switchBase: {
    color: "#5e6c75",
    "&$checked": {
      color: "#0cdb91",
    },
    "&$checked + $track": {
      backgroundColor: "#fff",
      border: "solid 1px #0cdb91",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#fff",
    border: "solid 1px #5e6c75",
  },
})(Switch);

const LighterGreenSwitch = withStyles({
  switchBase: {
    color: "#9aebcf",
    "&$checked": {
      color: "#9aebcf",
    },
    "&$checked + $track": {
      backgroundColor: "#fff",
      border: "solid 4px #9aebcf",
    },
  },
  checked: {},
  track: {
    backgroundColor: "#fff",
    border: "solid 1px #9aebcf",
  },
})(Switch);

type SessionSettingsProps = {
  enabled?: boolean;
};

export type AgeSettings = {
  userId: string;
  enabled: boolean;
  threshold: string;
};

export const SessionSettings = ({ enabled }: SessionSettingsProps) => {
  const { preset, setPreset, iadEnabled, setIadEnabled, instructionsSeen, setInstructionsSeen, iadMode, setIadMode } =
    useSessionConfig();

  const classes = useStyles();

  const auth = useAuth();

  const { tCommon } = useScopedTranslation("demo_page");

  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [selectedPreset, setSelectedPreset] = useState(preset ?? AcquisitionPreset.SELFIE_FAST);
  const [selectedIadMode, setSelectedIadMode] = useState(iadMode ?? IadMode.PASSIVE);
  const [instructionsOpen, setInstructionsOpen] = useState(false);
  const canEditPreset = auth.hasRoles(["demo.presets"]);

  const [allowRetry, setAllowRetry] = usePersistedState<boolean>("allowRetry", false);
  const [allowAge, setAllowAge] = usePersistedState<AgeSettings>("allowAge", {
    userId: auth.user?.id,
    enabled: false,
    threshold: "18",
  });

  const handlePresetChange = (value: AcquisitionPreset) => {
    setPreset(value);
    setSelectedPreset(value);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActivationChange = (_: React.ChangeEvent, checked: boolean) => {
    if (checked && !instructionsSeen) {
      setInstructionsOpen(true);
    } else {
      setIadEnabled(checked);
    }
  };

  const handleIadModeChange = (value: IadMode) => {
    setIadMode(value);
    setSelectedIadMode(value);
  };

  const handleRetryChange = (_: React.ChangeEvent, checked: boolean) => {
    setAllowRetry(checked);
  };

  const handleAgeActivationChange = (_: React.ChangeEvent, checked: boolean) => {
    setAllowAge((prev) => ({ ...prev, enabled: checked }));
  };

  const handleInjectionInstructionConfirm = () => {
    setInstructionsOpen(false);
    setInstructionsSeen(true);
    setIadEnabled(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "injection-settings-popover" : undefined;

  const displayedIcon = (() => {
    if (enabled) {
      return <SettingsIcon />;
    } else {
      return <SettingsDisabledIcon />;
    }
  })();

  return (
    <Box>
      <InjectionInstructionsModal
        open={instructionsOpen}
        onClose={() => setInstructionsOpen(false)}
        onConfirm={handleInjectionInstructionConfirm}
      />

      <Box style={{ cursor: "pointer" }} onClick={handleClick}>
        {displayedIcon}
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock={true}
      >
        <Box className={`${classes.box} ${enabled ? "" : classes.notActive}`}>
          {canEditPreset && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box my="1em">
                <FormControl className={classes.formControl}>
                  <Typography component={"div"} className={classes.title}>
                    {tCommon("settings.acquisition_preset_title")}
                  </Typography>
                  <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    value={selectedPreset}
                    onChange={(e) => handlePresetChange(e.target.value as AcquisitionPreset)}
                    displayEmpty
                  >
                    <MenuItem value={AcquisitionPreset.SELFIE_FAST}>FAST</MenuItem>
                    <MenuItem value={AcquisitionPreset.SELFIE_SUBSTANTIAL}>SUBSTANTIAL</MenuItem>
                    <MenuItem value={AcquisitionPreset.SELFIE_OPTIMIZED}>OPTIMIZED</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box my="1em" mx="8px">
              <Typography component={"div"} className={`${classes.title}`}>
                {tCommon("settings.retry_activation_title")}
              </Typography>
            </Box>
            <Box>
              <GreenSwitch
                checked={allowRetry}
                onChange={handleRetryChange}
                style={{ display: "inline-block" }}
                disabled={!enabled}
              />
            </Box>
          </Box>

          {auth.user?.canViewAgeFeature && (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box my="1em" mx="8px">
                  <Typography component={"div"} className={`${classes.title}`}>
                    {tCommon("settings.age_activation_title")}
                  </Typography>
                </Box>
                <Box>
                  <GreenSwitch
                    checked={allowAge.enabled}
                    onChange={handleAgeActivationChange}
                    style={{ display: "inline-block" }}
                    disabled={!enabled}
                  />
                </Box>
              </Box>

              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box my="1em" mx="8px">
                  <Typography
                    component={"div"}
                    className={`${classes.title} ${!allowAge.enabled && classes.notActive}`}
                  >
                    {tCommon("settings.age_threshold_title")}
                  </Typography>
                </Box>
                <Box mr="4px" width={"50px"}>
                  <Input
                    disabled={!allowAge.enabled || !enabled}
                    style={{ display: "inline-block" }}
                    name="Age"
                    value={allowAge.threshold}
                    onChange={(e) => {
                      let age: number = Number(e.target.value);

                      if (age < 1) setAllowAge((prev) => ({ ...prev, threshold: "" }));
                      else if (age > 99) setAllowAge((prev) => ({ ...prev, threshold: "99" }));
                      else setAllowAge((prev) => ({ ...prev, threshold: `${Math.round(age)}` }));
                    }}
                    type="number"
                  />
                </Box>
              </Box>
            </>
          )}

          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box my="1em" mx="8px">
              <Typography component={"div"} className={`${classes.title}`}>
                {tCommon("settings.injection_activation_title")}
                <InjectionInstructionsPopover />
              </Typography>
            </Box>
            <Box>
              {enabled ? (
                <GreenSwitch
                  checked={iadEnabled}
                  onChange={handleActivationChange}
                  style={{ display: "inline-block" }}
                />
              ) : (
                <LighterGreenSwitch checked={iadEnabled} disabled />
              )}
            </Box>
          </Box>

          {canEditPreset && iadEnabled && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box my="1em">
                <FormControl className={classes.formControl}>
                  <Typography component={"div"} className={classes.title}>
                    {tCommon("settings.iad_mode_title")}
                  </Typography>
                  <Select
                    labelId="demo-simple-select-placeholder-label-label"
                    id="demo-simple-select-placeholder-label"
                    value={selectedIadMode}
                    onChange={(e) => handleIadModeChange(e.target.value as IadMode)}
                    displayEmpty
                  >
                    <MenuItem value={IadMode.PASSIVE}>PASSIVE</MenuItem>
                    <MenuItem value={IadMode.ACTIVE_FALLBACK}>ACTIVE FALLBACK</MenuItem>
                    <MenuItem value={IadMode.ACTIVE}>ACTIVE</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}

          <Typography component="em" className={`${classes.subheading}`}>
            {tCommon("settings.injection_activation_help")}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
};
