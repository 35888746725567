import { Button, CircularProgress, DialogProps, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";

import { Modal } from "../../components/Modal";
import CreateSubscriptionFormView from "./CreateSubscriptionForm";

import { createSubscription, getWorkspaces } from "../../services/api_services";
import { useScopedTranslation } from "../../i18n";
import { observer, useLocalObservable } from "mobx-react-lite";
import { CreateSubscriptionForm } from "../../stores/forms";
import { toJS } from "mobx";
import { ReadWorkspace } from "@unissey/common";

import { useQueryClient } from "react-query";
import { useNotif } from "../../contexts/notif_context";

type Props = DialogProps & {
  onClose: () => void;
};

export function CreateSubscriptionModal({ open, onClose }: Props) {
  const { t } = useScopedTranslation("subscription_page");

  const notif = useNotif();

  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<ReadWorkspace[]>([]);
  const form = useLocalObservable(() => new CreateSubscriptionForm());

  async function handleCreate() {
    if (!form.validate()) return;

    setIsLoading(true);
    if (await createSubscription(toJS(form.data))) {
      onClose();
      notif.trigger?.({ textKey: "success.subscription_created" });
      queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getWorkspaces(true).then((workspaces) => {
      const ws = workspaces?.sort((a, b) => (a.name.toLocaleUpperCase() > b.name.toLocaleUpperCase() ? 1 : -1));
      setWorkspaces(ws ?? []);
    });
    form.setField("canUseLiveness", true);
    form.setField("canUseFaceComparison", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        onCancel={onClose}
        maxWidth="md"
        title={t("create_dialog.title")}
        body={<CreateSubscriptionFormView form={form} workspaces={workspaces} />}
        action={
          <div>
            <Grid container>
              <Button
                variant="contained"
                color="primary"
                disableElevation
                onClick={handleCreate}
                startIcon={isLoading && <CircularProgress size={14} />}
                disabled={isLoading || !form.isValid}
                fullWidth
                data-testid="create-subscription-form-btn"
              >
                {t("create_dialog.button_create")}
              </Button>
            </Grid>
          </div>
        }
      />
    </>
  );
}

export default observer(CreateSubscriptionModal);
