import React from "react";

import { Box } from "@material-ui/core";
import { ConfidenceLevel } from "@unissey/common";
import ConfidenceHighIcon from "./ConfidenceHighIcon";
import ConfidenceLowIcon from "./ConfidenceLowIcon";

type ConfidencePictosProps = {
  confidence: ConfidenceLevel;
};

const ConfidencePictos = ({ confidence }: ConfidencePictosProps) => {
  return (
    <Box>
      {confidence === ConfidenceLevel.CONFIDENCE_HIGH_0 ? (
        <Box>
          <ConfidenceHighIcon />
        </Box>
      ) : (
        <Box>
          <ConfidenceLowIcon />
        </Box>
      )}
    </Box>
  );
};

export default ConfidencePictos;
