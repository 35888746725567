import { Box, FormControlLabel, FormGroup, Grid, Checkbox, Switch } from "@material-ui/core";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import CustomSelect, { MenuItemsType } from "../../components/forms/formComponents/CustomSelect";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CustomDateTimePicker from "../../components/forms/formComponents/CustomDateTimePicker";
import {
  SubscriptionKind,
  SUBSCRIPTION_KINDS,
  SubscriptionFeatureKind,
  SUBSCRIPTION_FEATURE_KINDS,
} from "@unissey/common";
import { useScopedTranslation } from "../../i18n";
import { ReadWorkspace } from "@unissey/common";
import { CreateSubscriptionForm } from "../../stores/forms";
import { observer } from "mobx-react-lite";
import TitledSection from "../../components/TitledSection";

type Props = {
  form: CreateSubscriptionForm;
  workspaces: ReadWorkspace[];
};

function CreateSubscriptionFormView(props: Props) {
  const { form } = props;

  const { t, tCommon } = useScopedTranslation("subscription_page.form");

  const subscriptionMenuItems: MenuItemsType = SUBSCRIPTION_KINDS.map((kind) => [kind, tCommon(`sub_kind.${kind}`)]);

  const featureKindMenuItems: MenuItemsType = SUBSCRIPTION_FEATURE_KINDS.map((featureKind) => [
    featureKind,
    tCommon(`feature_kind.${featureKind}`),
  ]);

  return (
    <FormGroup>
      <Box py={2} mb={1}>
        <Grid container spacing={3} data-testid="subscription-form">
          <CustomTextField
            name="subscriptionName"
            width={6}
            onChange={(e) => form.setField("name", e.target.value)}
            value={form.data.name ?? ""}
            label={t("field_subscription_name")}
            autoFocus
            onBlur={(e) => form.setField("name", e.target.value.trim())}
            data-testid="subscription-name"
          />

          <CustomSelect
            width={6}
            labelId="create-application-form-select-workspace"
            labelValue={t("field_workspace")}
            menuItems={props.workspaces.map((ws) => [ws.id, ws.name])}
            onChange={(e) => props.form.setWorkspaceId(e.target.value as string)}
            value={props.form.data.workspaceId}
            data-testid="subscription-workspace"
          />

          <CustomSelect
            width={6}
            labelId="subscription-form-select-kind"
            labelValue={t("field_subscription_type")}
            menuItems={subscriptionMenuItems}
            onChange={(e) => {
              const subKind = e.target.value as SubscriptionKind;
              form.setField("kind", subKind);
            }}
            value={form.data.kind}
            data-testid="subscription-kind"
          />

          {!form.isBucketSubscription ? (
            <CustomTextField
              name="subscriptionMaxSessionCount"
              type="number"
              required={form.isBucketSubscription}
              width={6}
              onChange={(e) => {
                const parsed = parseInt(e.target.value, 10);
                form.setField("maxSessionCount", isNaN(parsed) ? undefined : parsed);
              }}
              value={form.data.maxSessionCount ?? ""}
              label={t("field_max_sessions")}
            />
          ) : (
            <Box width="20px" height="20px" />
          )}

          <CustomSelect
            width={3}
            labelId="subscription-form-select-feature-kind"
            labelValue={t("field_solution")}
            menuItems={featureKindMenuItems}
            onChange={(e) => {
              const featureKind = e.target.value as SubscriptionFeatureKind;
              form.setField("featureKind", featureKind);
            }}
            value={form.data.featureKind}
            data-testid="subscription-kind"
          />

          <Box width="100%" m={2}>
            <TitledSection
              title={t("title_authorized_verifications")}
              child={
                <Grid item spacing={3}>
                  <FormControlLabel
                    control={
                      <Switch
                        value={form.data.canUseLiveness}
                        defaultChecked
                        onChange={(e) => {
                          const canUseLiveness = e.target.checked;
                          form.setField("canUseLiveness", canUseLiveness);
                        }}
                      />
                    }
                    label={t("field_can_use_liveness")}
                  />

                  {/* Only one to one subscription can use age and face comparison  */}
                  {form.isOneToOneSubscription ? (
                    <>
                      <FormControlLabel
                        control={
                          <Switch
                            value={form.data.canUseFaceComparison}
                            defaultChecked
                            onChange={(e) => {
                              const canUseFaceComparison = e.target.checked;
                              form.setField("canUseFaceComparison", canUseFaceComparison);
                            }}
                          />
                        }
                        label={t("field_can_use_face_comparison")}
                      />

                      <FormControlLabel
                        control={
                          <Switch
                            value={form.data.canUseAge}
                            onChange={(e) => {
                              const canUseAge = e.target.checked;
                              form.setField("canUseAge", canUseAge);
                            }}
                          />
                        }
                        label={t("field_can_use_age")}
                      />
                    </>
                  ) : (
                    <></>
                  )}

                  <FormControlLabel
                    control={
                      <Switch
                        value={form.data.canUseIad}
                        onChange={(e) => {
                          const canUseIad = e.target.checked;
                          form.setField("canUseIad", canUseIad);
                        }}
                      />
                    }
                    label={t("field_can_use_iad")}
                  />
                </Grid>
              }
            />
          </Box>

          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <CustomDateTimePicker
              value={form.data.startDate ?? null}
              onChange={(date) => {
                if (date) {
                  form.setField("startDate", date);
                }
              }}
              label={t("field_start_date")}
              maxDate={form.data.endDate}
              width={6}
              fullWidth
              data-testid="subscription-start-date"
            />

            <CustomDateTimePicker
              value={form.data.endDate ?? null}
              onChange={(date) => form.setField("endDate", date ?? undefined)}
              label={t("field_end_date")}
              minDate={form.data.startDate}
              width={6}
              fullWidth
            />
          </MuiPickersUtilsProvider>

          {form.canActivateAutoRenewal && (
            <Box width="100%" display="flex" mx="12px" justifyContent="space-between" alignItems="center">
              <Box width="53%">
                <FormControlLabel
                  name="hasAutorenewal"
                  control={
                    <Switch
                      checked={form.data.hasAutorenewal ?? false}
                      value={form.data.hasAutorenewal ?? false}
                      onChange={(e) => {
                        form.setField("hasAutorenewal", e.target.checked);
                      }}
                    />
                  }
                  label={`${t("field_auto_renewal")}`}
                />
              </Box>

              <CustomSelect
                width={6}
                labelId="renewal-length"
                labelValue={t("field_renewal_length")}
                menuItems={[
                  ["1_years", t("one_year")],
                  ["6_months", t("six_months")],
                ]}
                onChange={(e) => props.form.setRenewalLength(e.target.value as "1_years" | "6_months")}
                value={`${form.data.autoRenewalLength}_${form.data.autoRenewalUnit}`}
                data-testid="subscription-renewal-length"
              />
            </Box>
          )}

          {form.isBucketSubscription && (
            <>
              <CustomTextField
                name="subscriptionMaxSessionCount"
                type="number"
                required={form.isBucketSubscription}
                width={6}
                onChange={(e) => {
                  const parsed = parseInt(e.target.value, 10);
                  form.setField("maxSessionCount", isNaN(parsed) ? undefined : parsed);
                }}
                value={form.data.maxSessionCount ?? ""}
                label={`${t("field_bucket_size")}`}
                data-testid="subscription-bucket-size"
              />

              <CustomTextField
                name="subscriptionFallbackBucketSize"
                type="number"
                required={true}
                width={6}
                onChange={(e) => {
                  const parsed = parseInt(e.target.value, 10);
                  form.setField("fallbackBucketSize", isNaN(parsed) ? undefined : parsed);
                }}
                value={form.data.fallbackBucketSize ?? ""}
                label={`${t("field_fallback_bucket_size")}`}
                data-testid="subscription-fallback-bucket-size"
              />

              <FormControlLabel
                style={{ paddingLeft: "12px" }}
                control={
                  <Checkbox
                    checked={form.data.fallbackBucketAutoCreated ?? true}
                    onChange={(e) => form.setField("fallbackBucketAutoCreated", e.target.checked)}
                    name="fallbackBucketAutoCreated"
                    color="primary"
                  />
                }
                label={`${t("field_fallback_bucket_auto_created")}`}
              />
            </>
          )}
        </Grid>
      </Box>
    </FormGroup>
  );
}

export default observer(CreateSubscriptionFormView);
