import { Box, Button, CircularProgress, Grid, InputAdornment, Typography } from "@material-ui/core";
import LockOutlined from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import { deleteUser, triggerPasswordUpdate, updateProfile } from "../../services/api_services";
import { COMPOSITE_ROLES_LIST, UpdateUser } from "@unissey/common";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import theme from "../../config/theme";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import { useScopedTranslation } from "../../i18n";
import { useNotif } from "../../contexts/notif_context";
import { useAuth } from "../../auth";

export default function ProfilePage() {
  const auth = useAuth();
  const notif = useNotif();

  const { t, tCommon } = useScopedTranslation("profile_page");
  const user = auth.user!; // auth.user must be defined here

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [isUserDataFormLoading, setIsUserDataFormLoading] = useState(false);
  const [accountDeletionLoading, setAccountDeletionLoading] = useState<boolean>(false);
  const [accDelModalIsOpen, setAccDelModalIsOpen] = useState<boolean>(false);

  const handlePasswordUpdate = async () => {
    await triggerPasswordUpdate(user.id);
    notif.trigger?.({ textKey: "success.password_email_sent", textParams: { email: user.email } });
  };

  const isUserDataFormValid = () => {
    return !!firstName && !!lastName && (firstName !== user.firstName || lastName !== user.lastName);
  };

  const onUserDataFormValiatedClick = async () => {
    setIsUserDataFormLoading(true);
    const editInfos: UpdateUser = {
      id: user.id,
      firstName,
      lastName,
    };

    if (!auth.user?.id) {
      // TODO: place this in Error File
      notif.trigger?.({ textKey: "error.user_edit_id_not_found" });
      return;
    }

    await editUser(editInfos);

    setIsUserDataFormLoading(false);
  };

  const editUser = async (infos: UpdateUser) => {
    await updateProfile(infos);
  };

  const handleAccountDeletion = async () => {
    setAccountDeletionLoading(true);
    if (await deleteUser(user.id)) {
      auth.access.keycloak.logout();
      notif.trigger?.({ textKey: "success.account_deleted" });
    }
    setAccountDeletionLoading(false);
  };

  const lockedInputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <LockOutlined />
      </InputAdornment>
    ),
  };

  return (
    <Box mt={5}>
      <ConfirmDialog
        open={accDelModalIsOpen}
        confirmText={user.email}
        onClose={() => setAccDelModalIsOpen(false)}
        onConfirm={handleAccountDeletion}
      />

      <Grid container spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography style={{ fontWeight: 700, fontSize: 16, marginBottom: 10 }}>{t("information.title")}</Typography>
          <CustomTextField
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            onBlur={(e) => setFirstName(e.target.value.trim())}
            label={t("information.first_name")}
            autoComplete="given-name" // This allows browser autofill functionality
            required={false}
          />
          <CustomTextField
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            onBlur={(e) => setLastName(e.target.value.trim())}
            label={t("information.last_name")}
            autoComplete="family-name" // This allows browser autofill functionality
            required={false}
          />
          <Box m={2} />
          <CustomTextField
            value={user.email}
            label={t("information.email")}
            disabled
            InputProps={lockedInputProps}
            required={false}
          />
          <CustomTextField
            disabled
            label={t("information.assigned_roles")}
            value={tCommon(
              `roles.${
                auth.access.roles.filter((role) =>
                  COMPOSITE_ROLES_LIST.map((compositeRole) => compositeRole.key as string).includes(role)
                )[0]
              }`
            )}
            InputProps={lockedInputProps}
            required={false}
          />
          <CustomTextField
            value={user.workspace.name}
            label={t("information.workspace")}
            disabled
            InputProps={lockedInputProps}
            required={false}
          />
          <Box m={3} />
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={!isUserDataFormValid() || isUserDataFormLoading}
            onClick={onUserDataFormValiatedClick}
            fullWidth
          >
            {isUserDataFormLoading ? t("button_saving") : tCommon("button_edit")}
          </Button>
          <Box m={3} />
          <Button
            size="large"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => setAccDelModalIsOpen(true)}
            style={{ backgroundColor: theme.palette.error.light }}
            startIcon={accountDeletionLoading && <CircularProgress size={14} />}
          >
            {t("button_delete_account")}
          </Button>
        </Grid>

        <Grid item sm={12} md={6}>
          <Typography style={{ fontWeight: 700, fontSize: 16, marginBottom: 10 }}>{t("change_password")}</Typography>
          <Box mt={2} />
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ width: "100%" }}
            onClick={handlePasswordUpdate}
          >
            {t("information.password_update_email")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}
