import { useMutation, useQuery, useQueryClient } from "react-query";
import { editRegistry, getIdentityRegistries } from "../../services/api_services";
import { Box, Button, FormGroup, Grid } from "@material-ui/core";
import DataTable, { DataTableColumn } from "../../components/DataTable";
import { useScopedTranslation } from "../../i18n";
import OverflowTooltip from "../../components/OverflowTooltip";
import { EditIcon } from "../../components/EditIcon";
import { Modal } from "../../components/Modal";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { useState } from "react";
import { useNotif } from "../../contexts/notif_context";

type RegistriesDisplay = {
  name: string;
  actions: JSX.Element;
};

export default function ManageRegistry({
  workspaceId,
  creationModalIsOpen,
}: {
  workspaceId: string;
  creationModalIsOpen: boolean;
}) {
  const { t } = useScopedTranslation("registries_page");

  const { data: registries, isLoading } = useQuery(`registries-${workspaceId}`, () =>
    getIdentityRegistries([workspaceId])
  );

  const [selectedRegistryId, setSelectedRegistryId] = useState("");
  const [selectedRegistryName, setSelectedRegistryName] = useState("");
  const [editModalOpened, setEditModalOpened] = useState(false);

  const handleEditButton = (registryId: string, registryName: string) => {
    setSelectedRegistryId(registryId);
    setSelectedRegistryName(registryName);
    if (!creationModalIsOpen) {
      setEditModalOpened(true);
    }
  };

  const handleEditModalClose = () => {
    setEditModalOpened(false);
    setSelectedRegistryId("");
    setSelectedRegistryName("");
  };

  const columns: DataTableColumn<RegistriesDisplay>[] = [
    {
      dataKey: "name",
      title: t(`name`),
      render: (key: string, value: string): JSX.Element => <OverflowTooltip key={key} text={value} />,
    },
  ];

  const dataSource =
    registries?.map((registry) => ({
      name: registry.name,
      actions: (
        <Box display="flex" justifyContent="end">
          <Button
            startIcon={<EditIcon />}
            style={{ textTransform: "none" }}
            onClick={() => handleEditButton(registry.id, registry.name)}
          >
            {t("edit_button")}
          </Button>
        </Box>
      ),
    })) ?? [];

  return (
    <Box>
      <DataTable columns={columns} dataSource={dataSource} isLoading={isLoading} />
      <EditRegistryModal
        isOpened={editModalOpened}
        registryId={selectedRegistryId}
        currentRegistryName={selectedRegistryName}
        workspaceId={workspaceId}
        onClose={handleEditModalClose}
      />
    </Box>
  );
}

type EditRegistryModalProps = {
  isOpened: boolean;
  onClose: () => void;
  registryId: string;
  currentRegistryName: string;
  workspaceId: string;
};

function EditRegistryModal({
  isOpened,
  onClose,
  registryId,
  workspaceId,
  currentRegistryName,
}: EditRegistryModalProps) {
  const notif = useNotif();

  const queryClient = useQueryClient();

  const { t } = useScopedTranslation("registries_page");

  const mutation = useMutation((name: string) => editRegistry(registryId, name), {
    onSuccess: (data, variables, context) => {
      setRegistryName("");
      onClose();
      notif.trigger?.({
        textKey: "success.registry_edited",
      });
      queryClient.invalidateQueries([`registries-${workspaceId}`]);
    },
  });

  const [registryName, setRegistryName] = useState("");

  const isValid = registryName !== "" && registryName !== currentRegistryName;

  const fieldValue = registryName === "" ? currentRegistryName : registryName;

  const modalBody = (
    <Box>
      <FormGroup>
        <Box py={2} mb={1}>
          <Grid container spacing={2} data-testid="create-registry-form">
            <CustomTextField
              name="registryName"
              width={12}
              onChange={(e) => setRegistryName(e.target.value)}
              value={fieldValue}
              label={t("edit_modal.form.name_label")}
              autoFocus
              data-testid="registry-name"
            />
          </Grid>
        </Box>
      </FormGroup>
    </Box>
  );

  return (
    <Modal
      title={t("edit_modal.title")}
      maxWidth="sm"
      action={
        <Button
          variant="contained"
          color="primary"
          disabled={!isValid || mutation.isLoading}
          style={{ width: "150px" }}
          onClick={() => mutation.mutate(registryName)}
        >
          {t("edit_modal.button")}
        </Button>
      }
      body={modalBody}
      open={isOpened}
      onCancel={onClose}
      onClose={onClose}
    ></Modal>
  );
}
