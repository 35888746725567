import {
  Box,
  CircularProgress,
  Grid,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
// icons
import LockOutlined from "@material-ui/icons/LockOutlined";
import BrokenImageOutlined from "@material-ui/icons/BrokenImageOutlined";
import { useCallback, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ReactJson from "react-json-view";
// components
import CustomTable from "../../components/CustomTable";
import FallbackSrc from "./components/FallbackSrc";
import InfoCard from "../../components/InfoCard";
import LifecycleViewer from "./components/LifecycleViewer";
import { ResultsTable } from "./components/ResultsTable";
import SessionAnnotationControls from "./components/SessionAnnotationControls";
import TitledSection from "../../components/TitledSection";
import theme from "../../config/theme";
import VideoSection, { PlayerKind, VideoFallbackProp, VideoProp } from "./components/VideoSection";
// enums
import { annotateSession, desyncSession, getSession, syncSession } from "../../services/api_services";
// types
import { ConfidenceLevel, ReadMedia, Session, SessionAnnotation, ProcessType } from "@unissey/common";

// utils
import {
  LivenessChip,
  FaceComparisonChip,
  AgeEstimationResultChip,
  AgeVerificationChip,
} from "../../components/SessionResultChip";
import { dateFormat } from "../../utils/parsing";
import { useScopedTranslation } from "../../i18n";
import { TFunction, useTranslation } from "react-i18next";
import ConfidencePictos from "./components/ConfidencePictos";
import HelpIcon from "@material-ui/icons/Help";

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { useAuth } from "../../auth";
import { copyToClipboard } from "../../utils/misc_util";

const AGE_ESTIMATION_ERROR_VALUE = -1;
const AGE_VERIFICATION_ERROR_VALUE = -2;

interface MediaProps {
  src: string | undefined;
  gdprConsent?: string;
  onError?: () => void;
  t: TFunction;
}

function getMediaProp({ src, gdprConsent, onError, t }: MediaProps) {
  if (!src && gdprConsent === "false") {
    return {
      src: undefined,
      message: t("sessions_page.details_modal.notice_media_unavailable_gdpr"),
      icon: LockOutlined,
      onError,
    };
  } else if (!src) {
    // ImageNotSupportedOutlined icon could be used once we upgrade to the latest MUI version
    return {
      src: undefined,
      message: t("sessions_page.details_modal.notice_missing_media"),
      icon: BrokenImageOutlined,
      onError,
    };
  } else {
    return {
      src,
      message: t(`sessions_page.details_modal.notice_media_unavailable_missing_auth`),
      icon: LockOutlined,
      onError,
    };
  }
}

interface MediaSectionProps {
  medias: Array<ReadMedia>;
  vidCount: number;
  selSessionIdx: number;
  hasFaceMatch: boolean;
  onClick: (index: number) => void;
  onErr?: () => void;
}

const b64toBlob = (b64Data: string, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

const srcFromMedia = (media: ReadMedia): string => {
  if (media.blob === undefined) {
    return media.link;
  } else {
    const blob = b64toBlob(media.blob, media.mimeType ?? "");
    return URL.createObjectURL(blob);
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
      backgroundColor: "grey",
      maxWidth: "400px",
    },
  })
);

function MediaSection({ medias, vidCount, selSessionIdx, hasFaceMatch, onClick, onErr }: MediaSectionProps) {
  const { t } = useTranslation();
  const [initialFailure, setInitialFailure] = useState(false); // Used to fallback to the standard video player if the mjpeg one were to fail
  const [selfie, setSelfie] = useState<ReadMedia>();
  const [reference, setReference] = useState<ReadMedia>();
  const [videoProps, setVideoProps] = useState<VideoProp & VideoFallbackProp>();

  const onError = useCallback(() => {
    if (initialFailure) {
      onErr?.();
    } else {
      setInitialFailure(true);
    }
  }, [initialFailure, onErr]);

  useEffect(() => {
    const selfie = medias.find((media) => media.type === "selfie");
    const reference = medias.find((media) => media.type === "reference");

    setSelfie(selfie);
    setReference(reference);

    setVideoProps(
      getMediaProp({
        src: selfie && srcFromMedia(selfie),
        gdprConsent: selfie?.gdprConsent,
        onError,
        t,
      })
    );
  }, [medias, onError, t]);

  let [preferredPlayer, fallbackPlayer] = selfie?.link.endsWith(".avi")
    ? [PlayerKind.Mjpg, PlayerKind.Std]
    : [PlayerKind.Std, PlayerKind.Mjpg];

  return (
    <Grid container spacing={3}>
      <Grid item xs={7}>
        {videoProps &&
          (selfie?.mimeType?.startsWith("image") ? (
            <PictureSection
              kind="target"
              media={{ src: selfie && srcFromMedia(selfie), gdprConsent: selfie.gdprConsent, t }}
              videosCount={vidCount}
            />
          ) : (
            <VideoSection
              videoProps={videoProps}
              playerKind={initialFailure ? fallbackPlayer : preferredPlayer}
              videosCount={vidCount}
              selectedIdx={selSessionIdx}
              onClick={onClick}
            />
          ))}
      </Grid>
      {hasFaceMatch && (
        <Grid item xs={5}>
          <PictureSection
            kind="reference"
            media={{ src: reference && srcFromMedia(reference), gdprConsent: reference?.gdprConsent, t }}
            videosCount={vidCount}
          />
        </Grid>
      )}
    </Grid>
  );
}

function PictureSection({
  kind,
  media,
  videosCount,
}: {
  kind: "target" | "reference";
  media: MediaProps;
  videosCount: number;
}) {
  const { t } = useScopedTranslation("sessions_page.details_modal");

  const divCSS: { [key: string]: string | number } = {
    // backgroundColor: theme.palette.grey[100],
    overflow: "hidden",
    display: "flex",
    alignSelf: "baseline",
    width: "100%",
  };
  if (videosCount > 1) divCSS["marginBottom"] = 60;
  return (
    <TitledSection
      title={t(`title_${kind}_picture`)}
      child={FallbackSrc({
        child: (
          <div style={divCSS}>
            <img alt="Reference" src={media.src} style={{ width: "100%", borderRadius: theme.shape.borderRadius }} />
          </div>
        ),
        ...getMediaProp(media),
      })}
    />
  );
}

export default function SessionPage() {
  const { t } = useScopedTranslation("sessions_page.details_modal");
  const sessionId = useParams<{ sessionId?: string }>().sessionId;
  const auth = useAuth();

  const [session, setSession] = useState<Session>();
  const [lifecycleLoading, setLifecycleLoading] = useState<boolean>(false);
  const [parentSession, setParentSession] = useState<Session>();
  const [selectedSessionIdx, setSelectedSessionIdx] = useState(0);
  const [progress, setProgress] = useState<boolean>(true);

  const classes = useStyles();

  const [confidenceHelpAnchorEl, setConfidenceHelpAnchorEl] = useState<HTMLElement | null>(null);
  const handleConfidenceHelpPopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setConfidenceHelpAnchorEl(event.currentTarget);
  };
  const handleConfidenceHelpPopoverClose = () => {
    setConfidenceHelpAnchorEl(null);
  };
  const openConfidenceHelp = Boolean(confidenceHelpAnchorEl);

  const reloadTimer = useRef<number | undefined>(undefined);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(reloadTimer?.current);
    };
  }, []);

  const getSessions = useCallback(async () => {
    if (!sessionId) return;
    const session = await getSession(sessionId);

    setSession(session);
    if (session) {
      setSelectedSessionIdx(session.children.length);
    }
    setParentSession(session);
    setProgress(false);
  }, [sessionId]);

  const onMediaErr = useCallback(() => {
    console.log("Failed to play media");
    clearTimeout(reloadTimer?.current);
    reloadTimer.current = window.setTimeout(getSessions, 5000);
  }, [reloadTimer, getSessions]);

  useEffect(() => {
    setSelectedSessionIdx(0);
    if (!sessionId) return;
    setProgress(true);
    getSessions(); // NOTE: is async
  }, [sessionId, getSessions]);

  useEffect(() => {
    if (selectedSessionIdx === 0) {
      setSession(parentSession);
    } else if (parentSession) {
      setSession(parentSession.children[selectedSessionIdx - 1]);
    }
  }, [parentSession, selectedSessionIdx]);

  if (progress) {
    return (
      <Box height="90vh" display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={70} />
      </Box>
    );
  }

  if (!session) {
    return (
      <Box height="90vh" display="flex" alignItems="center" justifyContent="center">
        <InfoCard text={t("info_session_unavailable")} fullHeight />
      </Box>
    );
  }

  const requestSync = async () => {
    setLifecycleLoading(true);
    const sync = !session.lifecycle.requested_sync;

    if (sync) {
      await syncSession(session.id, sync ? 1 : 0);
    } else {
      await desyncSession(session.id);
    }

    const newSession = { ...session };
    newSession.lifecycle.requested_sync = sync;
    setSession(newSession);
    setLifecycleLoading(false);
  };

  const canAccessErrorDetails =
    (session.liveness && session.liveness !== "genuine") ||
    (session.faceComparison && session.faceComparison !== "match");
  const canAccessAnnotations = auth.hasRoles(["session.data.write"]);
  const canAccessDetails = auth.hasRoles(["session.data.detailed"]);

  const canViewTempUserCompany = auth.hasRoles(["session.temporary_user_domain"]);

  const livenessResult = session.results.find((r) => r.type === "conductor");
  const faceComparisonResult = session.results.find((r) => r.type === "face_matching");
  const ageEstimationResult = session.results.find((r) => r.type === "age_estimation");
  const ageVerificationResult = session.results.find((r) => r.type === "age_verification");
  const livenessWeakResults = session.results.filter((r) =>
    (["conductor", "pad", "cardiac"] as ProcessType[]).includes(r.type)
  );
  const faceComparisonWeakResults = session.results.filter((r) =>
    (["weak_face_matching", "face_matching"] as ProcessType[]).includes(r.type)
  );
  const ageEstimationWeakResult = session.results.filter((r) =>
    (["weak_age_estimation", "age_estimation"] as ProcessType[]).includes(r.type)
  );
  const ageVerificationWeakResult = session.results.filter((r) =>
    (["weak_age_verification", "age_verification"] as ProcessType[]).includes(r.type)
  );
  const faceQualityResult = session.results.find((r) => r.type === "face_quality");
  const faceQualityWeakResults = session.results.filter((r) => r.type === "face_quality");

  const livenessWeakResultHasErrors = livenessWeakResults.some((result) => (result.error ? true : false));
  const faceComparisonWeakResultHasErrors = faceComparisonWeakResults.some((result) => (result.error ? true : false));
  const hasAgeError = session.ageEstimation && session.ageEstimation < 0;

  const isInternalUser =
    auth.hasRoles(["super_administrator"]) ||
    auth.hasRoles(["sales_user"]) ||
    auth.hasRoles(["business_administrator"]) ||
    auth.hasRoles(["internal_tech_user"]);

  let metadata: unknown = {};

  try {
    metadata = JSON.parse(session?.videoMetadata || "{}");
  } catch (error) {
    console.warn(`malformed metadata for session ${sessionId}`);
  }

  const targetMedia = session.medias.find((m) => m.type === "selfie");
  const referenceMedia = session.medias.find((m) => m.type === "reference");

  const processHadMediaErrors =
    targetMedia?.faceDetectionError?.errorOneof?.$case !== undefined ||
    referenceMedia?.faceDetectionError?.errorOneof?.$case !== undefined;

  //
  const displayAgeEstimationResult =
    session.ageEstimation === AGE_ESTIMATION_ERROR_VALUE ||
    session.ageEstimation === AGE_VERIFICATION_ERROR_VALUE ||
    ageEstimationResult ||
    ageVerificationResult;
  const displayAgeVerificationResult = session.ageEstimation === AGE_VERIFICATION_ERROR_VALUE || ageVerificationResult;

  const framesDetection: Record<string, unknown> = {};
  if (targetMedia) framesDetection.target = targetMedia.framesDetection ?? "unknown";
  if (referenceMedia) framesDetection.reference = referenceMedia.framesDetection ?? "unknown";

  const preprocessingParams: Record<string, unknown> = {};
  if (targetMedia) preprocessingParams.target = targetMedia.preprocessingParams ?? "unknown";
  if (referenceMedia) preprocessingParams.reference = referenceMedia.preprocessingParams ?? "unknown";

  // Discussed in https://thedeepsense.atlassian.net/browse/ITSM-15
  // Synchronizing sessions without stored/accessible media could lead to issues
  // with regards to the synchronization script
  const isSyncAllowed = (session?.medias ?? []).length > 0 && session?.medias.every((m) => m.gdprConsent !== "false");

  const syncAnnotations = async (annotation: SessionAnnotation) => {
    console.debug(`syncing annotations: ${JSON.stringify(annotation)}`);
    await annotateSession(annotation, session.id);
    session.annotation = annotation;
    if (parentSession) {
      let child = parentSession.children[selectedSessionIdx - 1];
      if (child) {
        child.annotation = annotation;
      }
    }

    // We automatically perform a sync request when annotating
    if (isSyncAllowed && session.lifecycle?.requested_sync === false) {
      await requestSync();
    }
  };

  return (
    <Box height="90vh">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <MediaSection
            medias={session.medias}
            vidCount={parentSession ? parentSession.childrenIDs.length + 1 : 1}
            selSessionIdx={selectedSessionIdx}
            hasFaceMatch={session.faceComparison !== undefined}
            onClick={(idx) => setSelectedSessionIdx(idx)}
            onErr={onMediaErr}
          />
        </Grid>
        <Grid item xs={6}>
          <Box flex={"3 1"}>
            <TitledSection
              title={t("title_results")}
              child={
                <>
                  <CustomTable
                    noEvenOddColoring
                    lastColAlignRight
                    rows={[
                      [
                        <></>,
                        <Box maxWidth="100%" width="150px">
                          <Typography align="center" style={{ color: "#000" }}>
                            {t("decision")}
                          </Typography>
                        </Box>,
                        <Box display="flex" justifyContent="center">
                          <Typography style={{ color: "#000" }}>{t("confidence")}</Typography>
                          <Box ml="0.3em" />
                          <Box
                            aria-owns={openConfidenceHelp ? "mouse-over-popover" : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handleConfidenceHelpPopoverOpen}
                            onMouseLeave={handleConfidenceHelpPopoverClose}
                            style={{ cursor: "pointer" }}
                          >
                            <HelpIcon />
                          </Box>

                          <Popover
                            id="mouse-over-popover"
                            className={classes.popover}
                            classes={{
                              paper: classes.paper,
                            }}
                            open={openConfidenceHelp}
                            anchorEl={confidenceHelpAnchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            onClose={handleConfidenceHelpPopoverClose}
                            disableRestoreFocus
                          >
                            <Box color="#fff">{t("confidenceLevelHelp")}</Box>
                          </Popover>
                        </Box>,
                      ],
                      [
                        <Typography>{t("row_results_liveness")}</Typography>,
                        <LivenessChip value={session.liveness} />,
                        <Box display="flex" justifyContent="center" alignItems="center">
                          {session.livenessConfidence !== undefined &&
                            session.livenessConfidence !== ConfidenceLevel.UNKNOWN && (
                              <ConfidencePictos confidence={session.livenessConfidence} />
                            )}

                          {livenessWeakResultHasErrors && (
                            <ConfidencePictos confidence={session?.livenessConfidence || ConfidenceLevel.UNKNOWN} />
                          )}
                        </Box>,
                      ],
                      [
                        <Typography>{t("row_results_face_comparison")}</Typography>,
                        <FaceComparisonChip value={session.faceComparison} />,
                        <Box display="flex" justifyContent="center" alignItems="center">
                          {session.faceComparisonConfidence !== undefined &&
                            session.faceComparisonConfidence !== ConfidenceLevel.UNKNOWN && (
                              <ConfidencePictos confidence={session.faceComparisonConfidence} />
                            )}

                          {faceComparisonWeakResultHasErrors && (
                            <ConfidencePictos
                              confidence={session?.faceComparisonConfidence || ConfidenceLevel.UNKNOWN}
                            />
                          )}
                        </Box>,
                      ],
                      displayAgeEstimationResult
                        ? [
                            <Typography>{t("row_results_age_estimation")}</Typography>,
                            <AgeEstimationResultChip
                              ageResult={
                                session.ageEstimation !== AGE_ESTIMATION_ERROR_VALUE &&
                                session.ageEstimation !== AGE_VERIFICATION_ERROR_VALUE
                                  ? "estimation_only"
                                  : "not_reached"
                              }
                              value={
                                !hasAgeError
                                  ? {
                                      estimated_age:
                                        ageEstimationResult?.ageResult?.estimation ??
                                        ageVerificationResult?.ageResult?.estimation ??
                                        0,
                                      age_range: [
                                        ageEstimationResult?.ageResult?.estimationLow ??
                                          ageVerificationResult?.ageResult?.estimationLow ??
                                          0,
                                        ageEstimationResult?.ageResult?.estimationHigh ??
                                          ageVerificationResult?.ageResult?.estimationHigh ??
                                          0,
                                      ],
                                    }
                                  : undefined
                              }
                            />,
                            <></>,
                          ]
                        : [],
                      displayAgeVerificationResult
                        ? [
                            <Typography>{t("row_results_age_verification")}</Typography>,
                            <AgeVerificationChip
                              value={`${ageVerificationResult?.ageResult?.threshold?.toString() ?? "0"} ${t(
                                "age_year"
                              )}`}
                              ageResult={
                                ageVerificationResult?.ageResult?.older === true
                                  ? "over_age"
                                  : ageVerificationResult?.ageResult?.older === false
                                  ? "under_age"
                                  : "not_reached"
                              }
                            />,
                            <Typography align="center">
                              {ageVerificationResult?.ageResult?.confidenceLevel !== undefined &&
                              ageVerificationResult?.ageResult?.confidenceLevel !== ConfidenceLevel.UNKNOWN ? (
                                <ConfidencePictos confidence={ageVerificationResult?.ageResult?.confidenceLevel} />
                              ) : (
                                <></>
                              )}
                            </Typography>,
                          ]
                        : [],
                    ]}
                  />
                  <Box my={2} />
                  <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <Box m={2}>
                      <Typography component="span" style={{ color: theme.palette.grey[600] }}>
                        {t("label_date")}:
                      </Typography>
                      &nbsp;
                      <Typography component="span">{dateFormat(session.createdAt)}</Typography>
                    </Box>
                    <Box ml={2}>
                      <Typography component="span" style={{ color: theme.palette.grey[600] }}>
                        {t("label_workspace")}:
                      </Typography>
                      &nbsp;
                      <Typography component="span">{session.application.workspaceName}</Typography>
                    </Box>
                    <Box ml={2}>
                      <Typography component="span" style={{ color: theme.palette.grey[600] }}>
                        {t("label_subscription")}:
                      </Typography>
                      &nbsp;
                      <Typography component="span">{session.application.subscriptionName}</Typography>
                    </Box>
                    <Box ml={2}>
                      <Typography component="span" style={{ color: theme.palette.grey[600] }}>
                        {t("label_application")}:
                      </Typography>
                      &nbsp;
                      <Typography component="span">{session.application.name}</Typography>
                    </Box>
                    {isInternalUser && session.processingDuration && (
                      <Box ml={2}>
                        <Typography component="span" style={{ color: theme.palette.grey[600] }}>
                          {t("label_processing_duration")}:
                        </Typography>
                        &nbsp;
                        <Typography component="span">{session.processingDuration}</Typography>
                      </Box>
                    )}
                  </Grid>
                </>
              }
            />
            {session.lifecycle && canAccessAnnotations && (
              <>
                <Box mt={2} />
                <TitledSection
                  title={t("title_lifecycle")}
                  child={
                    <LifecycleViewer
                      lifecycle={session.lifecycle}
                      onSyncRequested={requestSync}
                      loading={lifecycleLoading}
                      disabled={!isSyncAllowed}
                    />
                  }
                />
              </>
            )}
          </Box>
        </Grid>
        {canViewTempUserCompany && session.tempUserDomain && (
          <Box>
            <Box display="flex" alignItems="center">
              <Typography variant="h6">{t("company")} :</Typography>
              <Typography style={{ fontSize: "1rem" }}>&nbsp; {session.tempUserDomain} </Typography>
            </Box>
            <Box my={2} />
          </Box>
        )}
        <Box display="flex" width="100%" flexDirection="row">
          <Grid item xs={6}>
            {canAccessDetails && (
              <>
                {ageEstimationResult && (
                  <ResultsTable
                    name={t("title_details_age_estimation")}
                    weakResults={ageEstimationWeakResult.map((r) => ({
                      name: r.modelId,
                      age: r.ageResult?.estimation,
                      interval: [r.ageResult?.estimationLow ?? 0, r.ageResult?.estimationHigh ?? 0],
                    }))}
                    result={{ name: "Global Age estimation", probability: ageEstimationResult.ageResult?.estimation }}
                  />
                )}
                {ageVerificationResult && (
                  <ResultsTable
                    name={t("title_details_age_verification")}
                    weakResults={ageVerificationWeakResult.map((r) => ({
                      name: r.modelId,
                      age: r.ageResult?.estimation,
                      interval: [r.ageResult?.estimationLow ?? 0, r.ageResult?.estimationHigh ?? 0],
                      probability: r.ageResult?.score,
                    }))}
                    result={{ name: "Global Age estimation", probability: ageVerificationResult.ageResult?.estimation }}
                    threshold={ageVerificationResult.ageResult?.threshold}
                  />
                )}
                {livenessResult && !livenessResult?.error && (
                  <ResultsTable
                    name={t("title_details_liveness")}
                    weakResults={livenessWeakResults.map((r) => ({
                      name: r.modelId,
                      probability: r.probability,
                    }))}
                    result={{ name: "Global Liveness", probability: livenessResult.probability }}
                    threshold={livenessResult.threshold}
                  />
                )}
                {(livenessResult?.error || livenessWeakResultHasErrors) && (
                  <Box pt={2}>
                    <Typography variant="h6" component="h6">
                      {t("title_details_liveness")}
                    </Typography>
                    <ReactJson
                      src={{ conductor: livenessResult, weakResults: livenessWeakResults }}
                      name={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      enableClipboard={copyToClipboard}
                    />
                  </Box>
                )}
                {faceComparisonResult && !faceComparisonResult?.error && (
                  <>
                    <Box pt={2} />
                    <ResultsTable
                      name={t("title_details_face_comparison")}
                      weakResults={faceComparisonWeakResults.map((r) => ({
                        name: r.modelId,
                        probability: r.probability,
                      }))}
                      // TODO: We only hand out reference context for now, we might want to send the target as well
                      result={{
                        name: "Global Face Comparison",
                        probability: faceComparisonResult.probability,
                      }}
                      threshold={faceComparisonResult.threshold}
                    />
                  </>
                )}
                {(faceComparisonResult?.error || faceComparisonWeakResultHasErrors) && (
                  <Box pt={2}>
                    <Typography variant="h6" component="h6">
                      {t("title_details_face_comparison")}
                    </Typography>
                    <ReactJson
                      src={{ face_matching: faceComparisonResult?.error, weakResults: faceComparisonWeakResults }}
                      enableClipboard={copyToClipboard}
                    />
                  </Box>
                )}
                {faceQualityResult && !faceQualityResult?.error && (
                  <>
                    <Box pt={2} />
                    <ResultsTable
                      name={t("title_details_face_quality")}
                      weakResults={faceQualityWeakResults.map((r) => ({
                        name: r.modelId,
                        probability: r.probability,
                        error: r.error,
                      }))}
                      result={{
                        name: "Global Face Quality",
                        probability: faceQualityResult.probability,
                      }}
                      threshold={faceQualityResult.threshold}
                    />
                  </>
                )}

                {faceQualityResult?.error && (
                  <Box pt={2}>
                    <Typography variant="h6" component="h6">
                      {t("title_error_details")}
                    </Typography>
                    <ReactJson src={{ errors: faceQualityResult.error }} enableClipboard={copyToClipboard} />
                  </Box>
                )}

                {session.apiVersion && (
                  <>
                    <Box pt={2}>
                      <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                        <Grid item>
                          <Typography variant="h6">{t("title_api_version")} :</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="body1"> API {session.apiVersion} </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}

                {session.modelVersions && (
                  <>
                    <Box pt={2} />
                    <Typography variant="h6" component="h6">
                      {t("title_details_models")}
                    </Typography>
                    <Table size="small" padding="none" aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ color: theme.palette.grey[500] }}>{t("header_model_type")}</TableCell>
                          <TableCell style={{ color: theme.palette.grey[500] }} align="right">
                            {t("header_model_version")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t("model_version_liveness")}
                          </TableCell>
                          <TableCell align="right">{session.modelVersions.liveness || "unknown"}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t("model_version_face_comparison")}
                          </TableCell>
                          <TableCell align="right">
                            {session.faceComparison === undefined
                              ? "N/A"
                              : session.modelVersions.faceComparison || "unknown"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t("model_version_age")}
                          </TableCell>
                          <TableCell align="right">
                            {session.ageEstimation === undefined ? "N/A" : session.modelVersions.age || "unknown"}
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row">
                            {t("model_version_vqc")}
                          </TableCell>
                          <TableCell align="right">{session.modelVersions.vqc || "unknown"}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </>
                )}

                {session.qualityAssessment && (
                  <>
                    <Box pt={2}>
                      <Typography variant="h6" component="h6">
                        {t("title_quality_assessment")}
                      </Typography>
                      <ReactJson
                        src={{ result: session.qualityAssessment }}
                        name={false}
                        displayDataTypes={false}
                        displayObjectSize={false}
                        enableClipboard={copyToClipboard}
                      />
                    </Box>
                  </>
                )}

                {canAccessErrorDetails && session.apiVersion === "v3" && (
                  <>
                    <Box pt={2} />
                    <Typography variant="h6" component="h6">
                      {t("title_error_details")}
                    </Typography>
                    <ReactJson
                      src={{
                        livenessResult: session.liveness,
                        faceComparisonResult: session.faceComparison,
                        ageResult: session.ageEstimation && session.ageEstimation < 0 ? "not_reached" : undefined,
                        ...((session.liveness === "not_reached" || session.faceComparison === "not_reached") && {
                          details: {
                            ...(session.liveness === "not_reached" && {
                              target: {
                                mediaValidation: targetMedia?.mediaValidation,
                                faceDetectionResult: targetMedia?.faceDetectionError,
                              },
                            }),
                            ...(session.faceComparison === "not_reached" && {
                              reference: {
                                mediaValidation: referenceMedia?.mediaValidation,
                                faceDetectionResult: referenceMedia?.faceDetectionError,
                              },
                            }),
                          },
                        }),
                      }}
                      name={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      enableClipboard={copyToClipboard}
                      sortKeys={false}
                    />
                  </>
                )}

                {processHadMediaErrors && session.apiVersion === "v2" && (
                  <>
                    <Box pt={2} />
                    <Typography variant="h6" component="h6">
                      {t("title_preprocessing_error")}
                    </Typography>
                    <ReactJson
                      src={{
                        selfie: targetMedia?.faceDetectionError?.errorOneof?.$case,
                        reference: referenceMedia?.faceDetectionError?.errorOneof?.$case,
                      }}
                      name={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      enableClipboard={copyToClipboard}
                      sortKeys={true}
                    />
                  </>
                )}

                {metadata && typeof metadata === "object" && JSON.stringify(metadata) !== "{}" && (
                  <>
                    <Box pt={2} />
                    <Typography variant="h6" component="h6">
                      {t("title_details_metadata")}
                    </Typography>
                    <ReactJson
                      src={{ ...metadata }}
                      name={false}
                      collapsed={1}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      enableClipboard={copyToClipboard}
                      sortKeys={true}
                    />
                  </>
                )}

                {
                  <>
                    <Box pt={2} />
                    <Typography variant="h6" component="h6">
                      {t("title_preprocessing_params")}
                    </Typography>
                    <ReactJson
                      src={{ ...preprocessingParams }}
                      name={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      enableClipboard={copyToClipboard}
                      sortKeys={true}
                    />
                  </>
                }

                {
                  <>
                    <Box pt={2} />
                    <Typography variant="h6" component="h6">
                      {t("title_frames_detection")}
                    </Typography>
                    <ReactJson
                      src={{ ...framesDetection }}
                      name={false}
                      displayDataTypes={false}
                      displayObjectSize={false}
                      enableClipboard={copyToClipboard}
                      sortKeys={true}
                    />
                  </>
                }
              </>
            )}
          </Grid>
          <Grid item xs={6}>
            {canAccessAnnotations && (
              <Box display="flex" marginLeft="2%">
                <TitledSection
                  title={t("title_annotations")}
                  child={<SessionAnnotationControls session={session} onChange={syncAnnotations} />}
                />
              </Box>
            )}
          </Grid>
        </Box>
      </Grid>
    </Box>
  );
}
