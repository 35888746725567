"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HTTP_ERRORS_MESSAGES = void 0;
exports.HTTP_ERRORS_MESSAGES = {
    "invalid-token": "Invalid credentials provided",
    "missing-scheme": "Missing or bad authentication scheme on authaurization header",
    "no-token": "No credentials provided",
    "bad-payload": "Bad body payload",
    "invalid-api-key": "Api is invalid",
    forbidden: "Forbidden access",
    conflict: "Resource conflict with another one",
    unexpected: "An unexpected error occured",
    "not-found": "Resource not found",
};
