import { TRANSLATION_KEYS } from "../BillingMonthSelector";

export const LIMIT_DAY = 26;

export const getMonthsBySelectedYear = (year: number, keys: readonly TRANSLATION_KEYS[]) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getDate() >= LIMIT_DAY ? currentDate.getMonth() + 1 : currentDate.getMonth();

  let months: TRANSLATION_KEYS[] = [];

  if (currentYear === year) {
    let i = 0;

    while (i <= currentMonth) {
      months.push(keys[i]);
      i++;
    }
    return months;
  }
  return keys;
};

export const getNLastYears = (numberOfYear: number) => {
  let year = new Date().getFullYear();
  let i = 0;
  let years: number[] = [];

  while (i < numberOfYear) {
    years.push(year);
    year--;
    i++;
  }
  return years;
};

export const getEndDate = (startDate: Date) => {
  const currentDate = new Date();
  if (startDate.getFullYear() === currentDate.getFullYear() && startDate.getMonth() === currentDate.getMonth())
    return currentDate;
  if (
    startDate.getFullYear() === currentDate.getFullYear() &&
    startDate.getMonth() + 1 === currentDate.getMonth() &&
    currentDate.getDate() < LIMIT_DAY
  )
    return currentDate;
  if (
    startDate.getFullYear() + 1 === currentDate.getFullYear() &&
    startDate.getMonth() === 11 &&
    currentDate.getMonth() === 0 &&
    currentDate.getDate() < LIMIT_DAY
  )
    return currentDate;
  if (startDate.getMonth() === 11) return new Date(startDate.getFullYear() + 1, 0, LIMIT_DAY);
  return new Date(startDate.getFullYear(), startDate.getMonth() + 1, LIMIT_DAY);
};
