import { useState } from "react";
import { Button, capitalize, Typography } from "@material-ui/core";
import { ReadApplication, SecurityLevel, SubscriptionFeatureKind } from "@unissey/common";
import { getApplications, revokeApplication } from "../../services/api_services";

import EditApiKeyModal from "./EditApiKey";
import theme from "../../config/theme";
import { ConfirmDialog } from "../../components/ConfirmDialog";
import ShowApiKeyModal from "./ShowApiKey";
import { useScopedTranslation } from "../../i18n";
import DataTable, { DataTableColumn } from "../../components/DataTable";
import { useNotif } from "../../contexts/notif_context";
import { useAuth } from "../../auth";
import { useQuery } from "react-query";

type ApiKeysDisplay = {
  name: string;
  subscription: string;
  subKind: string;
  workspace: string;
  display: JSX.Element;
  editApiKeys: JSX.Element;
  revokeApiKeys: JSX.Element;
};

export default function ManageApiKey({
  workspaceId,
  creationModalIsOpen,
}: {
  workspaceId: string;
  creationModalIsOpen: boolean;
}) {
  const auth = useAuth();
  const { t, tCommon } = useScopedTranslation("api_page");

  const notif = useNotif();

  const [editionModalIsOpen, setEditionModalIsOpen] = useState<boolean>(false);
  const [apiKeyToEdit, setApiKeyToEdit] = useState<{
    id: string;
    name: string;
    livenessThreshold: SecurityLevel;
    matchingThreshold: SecurityLevel;
    subscriptionFeatureKind: SubscriptionFeatureKind;
  }>();
  const [revokeModalIsOpen, setRevokeModalIsOpen] = useState<boolean>(false);
  const [apiKeyToRevoke, setApiKeyToRevoke] = useState<ReadApplication>();
  const [apiKeyModalIsOpen, setApiKeyModalIsOpen] = useState<boolean>(false);
  const [apiKeyToShow, setApiKeyToShow] = useState<ReadApplication>();
  const [isLoading, setIsLoading] = useState(false);

  const canEditApiKeys = auth.hasRoles(["apikey.edit"]);
  const canViewApiKeySecrets = auth.hasRoles(["apikey.secret"]);

  const compareApplicationFn = (a: ReadApplication, b: ReadApplication) => {
    //  Sort by Workspace, then Subscription, then Environment, then Name
    let tmp = a.workspaceName.localeCompare(b.workspaceName);
    if (tmp !== 0) return tmp;
    tmp = a.subscriptionName.localeCompare(b.subscriptionName);
    if (tmp !== 0) return tmp;
    return a.name.localeCompare(b.name);
  };

  const { data: applications } = useQuery(`applications-${workspaceId}`, async () => {
    setIsLoading(true);
    const apps = await getApplications();
    setIsLoading(false);
    return apps?.sort(compareApplicationFn).filter((app) => app.workspaceId === workspaceId);
  });

  async function revokeApiKey() {
    if (!apiKeyToRevoke) return;
    if (await revokeApplication(apiKeyToRevoke.id))
      notif.trigger?.({
        textKey: "success.api_key_revoked",
        textParams: { applicationName: apiKeyToRevoke.name },
      });
    setApiKeyToRevoke(undefined);
    setRevokeModalIsOpen(false);
  }

  const columns: DataTableColumn<ApiKeysDisplay>[] = [
    {
      dataKey: "name",
      title: t(`table.header_name`),
    },
    {
      dataKey: "subscription",
      title: t(`table.header_subscription_name`),
    },
    {
      dataKey: "subKind",
      title: t(`table.header_subscription_kind`),
    },
    {
      dataKey: "workspace",
      title: t(`table.header_workspace`),
    },
    {
      dataKey: "registryName",
      title: t(`table.header_identity_registry`),
    },
    {
      dataKey: "iadPolicy",
      title: t(`table.header_iad_policy`),
    },
  ];

  if (canEditApiKeys) columns.push({ dataKey: "editApiKeys", title: "" }, { dataKey: "revokeApiKeys", title: "" });

  const displayApiKeys = (): ApiKeysDisplay[] => {
    return (
      applications?.map((app) => ({
        name: capitalize(app.name),
        subscription: capitalize(app.subscriptionName),
        subKind: tCommon(`sub_kind.${app.subscriptionKind}`),
        workspace: capitalize(app.workspaceName),
        reigstryName: app.registryName ? capitalize(app.registryName) : "N/A",
        iadPolicy: t(`table.key_${app.iadPolicy}`),
        display: app.isRevoked ? (
          <Typography style={{ color: theme.palette.grey["600"], paddingLeft: "14px" }}>
            {t("table.key_revoked")}
          </Typography>
        ) : (
          <Button
            onClick={() => {
              setApiKeyModalIsOpen(true);
              setApiKeyToShow(app);
            }}
            variant="outlined"
            disabled={!canViewApiKeySecrets}
          >
            {t("table.button_display")}
          </Button>
        ),
        editApiKeys: canEditApiKeys ? (
          <Button
            onClick={() => {
              setEditionModalIsOpen(true);
              setApiKeyToEdit({
                id: app.id,
                name: app.name,
                livenessThreshold: app.livenessThreshold,
                matchingThreshold: app.matchingThreshold,
                subscriptionFeatureKind: app.subscriptionFeatureKind,
              });
            }}
            variant="outlined"
          >
            {tCommon("button_edit")}
          </Button>
        ) : (
          <> </>
        ),
        revokeApiKeys: canEditApiKeys ? (
          <Button
            onClick={() => {
              setApiKeyToRevoke(app);
              setRevokeModalIsOpen(true);
            }}
            style={{ color: app.isRevoked ? undefined : theme.palette.error.main }}
            variant="outlined"
            disabled={app.isRevoked}
          >
            {t("table.button_revoke")}
          </Button>
        ) : (
          <> </>
        ),
      })) || []
    );
  };

  return (
    <>
      {!applications || applications.length === 0 ? (
        !isLoading && (
          <Typography variant="body2" style={{ textAlign: "center" }}>
            {t("no_data")}
          </Typography>
        )
      ) : (
        <DataTable columns={columns} dataSource={displayApiKeys()} isLoading={isLoading} noDataMsg={t("no_data")} />
      )}

      {canEditApiKeys && apiKeyToRevoke ? (
        <ConfirmDialog
          open={revokeModalIsOpen}
          confirmText={apiKeyToRevoke.name}
          onClose={() => {
            setApiKeyToRevoke(undefined);
            setRevokeModalIsOpen(false);
          }}
          onConfirm={revokeApiKey}
        />
      ) : null}

      {canEditApiKeys && apiKeyToEdit ? (
        <EditApiKeyModal
          open={editionModalIsOpen}
          onClose={() => {
            setApiKeyToEdit(undefined);
            setEditionModalIsOpen(false);
          }}
          apiKeyFields={apiKeyToEdit}
          workspaceId={workspaceId}
        />
      ) : null}

      {canViewApiKeySecrets && apiKeyToShow ? (
        <ShowApiKeyModal
          open={apiKeyModalIsOpen}
          application={apiKeyToShow}
          onClose={() => {
            setApiKeyToShow(undefined);
            setApiKeyModalIsOpen(false);
          }}
        />
      ) : null}
    </>
  );
}
