import { Box, Grid, IconButton, InputAdornment, Link, TextField } from "@material-ui/core";
import React, { useEffect, useState, createRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import SessionsTable, { AttemptFilter, SessionsSorting, SyncStatusFilter } from "./components/SessionsTable";
import SessionModal from "./components/SessionModal";
import { Routes } from "../../constants/routes";
// services
import { getSession, getSessions, SessionFieldOrder } from "../../services/api_services";
import {
  ReadApplication,
  SimplifiedSession,
  SubscriptionKind,
  ReadWorkspace,
  AgeEstimationResult,
} from "@unissey/common";

// types
import { usePersistedState } from "../../utils/persistence";
import { FaceComparisonResultFilter, LivenessResultFilter } from "../../types/results";
import { useTranslation } from "react-i18next";
import { Search } from "@material-ui/icons";
import { useAuth } from "../../auth";

const tableContainer = createRef<HTMLDivElement>();

const DEFAULT_SORTING: SessionsSorting = { date: "desc" };

// A hack to make cursor work
// We store the state in a global variable
// It is a temporary workaround before sessions page refactor
let sessionsInitialCursor = undefined as Date | undefined;

export default function SessionsPage() {
  const { t } = useTranslation();

  const sessionId = useParams<{ sessionId?: string }>().sessionId;
  const defaultTableHeight = 200;

  const initialAscDate = new Date("1970-01-01");
  const initialDescDate = new Date();

  // This cursor is used after the initial request, on inifinite scroll
  const [sessionsCursor, setSessionsCursor] = useState(initialDescDate);

  const [selectedSessionId, setSelectedSessionId] = useState<string | null>(sessionId ?? null);
  const [sessions, setSessions] = useState<SimplifiedSession[]>([]);
  const [limitExceeded, setLimitExceeded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [tableHeight, setTableHeight] = useState<number>(defaultTableHeight);

  const [searchSessionId, setSearchSessionId] = useState<string>();
  const [searchSessionError, setSearchSessionError] = useState(false);

  const [selSyncStatus, setSelSyncStatus] = usePersistedState<SyncStatusFilter[]>("syncStatusFilter", [], "session");
  const [selWorkspaces, setSelWorkspaces] = usePersistedState<ReadWorkspace[]>("workspaceFilter", [], "session");
  const [selApplications, setSelApplications] = usePersistedState<ReadApplication[]>(
    "applicationFilter",
    [],
    "session"
  );
  const [selSubscriptionTypes, setSelSubscriptionTypes] = usePersistedState<SubscriptionKind[]>(
    "subscriptionFilter",
    []
  );
  const [selLivenessResults, setSelLivenessResults] = usePersistedState<(LivenessResultFilter | undefined)[]>(
    "livenessFilter",
    [],
    "session"
  );
  const [selFaceComparisonResults, setSelFaceComparisonResults] = usePersistedState<
    (FaceComparisonResultFilter | undefined)[]
  >("faceComparisonFilter", [], "session");
  const [selAgeEstimationResults, setSelAgeEstimationResults] = usePersistedState<(AgeEstimationResult | undefined)[]>(
    "ageEstimationFilter",
    [],
    "session"
  );
  const [selAttempts, setSelAttempts] = usePersistedState<AttemptFilter[]>("attemptsFilter", [], "session");

  const [selGdprConsentStatus, setSelGdprConsentStatus] = usePersistedState<boolean[]>(
    "gdprConsentStatus",
    [],
    "session"
  );

  type dateRangeType = [Date | null, Date | null];

  const [selDateRange, setSelDateRange] = usePersistedState<dateRangeType>("dateFilter", [null, null], "session");
  const [orderBy, setOrderBy] = useState<SessionsSorting>(DEFAULT_SORTING);
  // The following is a workaround the fact that JSON.stringify and JSON.parse do not seem to parse dates the same way
  // DOC: https://weblog.west-wind.com/posts/2014/jan/06/javascript-json-date-parsing-and-real-dates#how-date-parsing-works-with-json-serializers
  if (typeof selDateRange[0] === "string" || typeof selDateRange[1] === "string")
    setSelDateRange([
      selDateRange[0] ? new Date(selDateRange[0]) : null,
      selDateRange[1] ? new Date(selDateRange[1]) : null,
    ]);
  const auth = useAuth();
  const history = useHistory();

  function getTableHeight(): number {
    const clientRect: DOMRect | undefined = tableContainer.current?.getBoundingClientRect();
    if (clientRect) return window.innerHeight - clientRect.top;
    return defaultTableHeight;
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>): void => {
    const scrollLocationHeight = (event.target as HTMLTextAreaElement).scrollTop + (tableHeight ? tableHeight : 50);

    const loadPageTriggerHeight = (event.target as HTMLTextAreaElement).scrollHeight * 0.8; // between 1 and 0;

    if (scrollLocationHeight >= loadPageTriggerHeight && !isLoading && !limitExceeded) {
      // Setting the loading state early prevents the scroll handler to trigger a page update multiple times
      // in a short time-span when the user "scrolls fast".
      // Multiple calls to `setPage` could otherwise be done before the `useEffect` sets the loading status,
      // causing multiple api calls to be performed concurrently, leading to sessions being skipped based on a
      // race condition
      setIsLoading(true);

      setPage(page + 1);
    }
  };

  function reverseInitialCursor() {
    sessionsInitialCursor = orderBy.date === "asc" ? initialDescDate : initialAscDate;
  }

  function setInitialCursor() {
    sessionsInitialCursor = orderBy.date === "asc" ? initialAscDate : initialDescDate;
  }

  function resetInitialCursor() {
    sessionsInitialCursor = undefined;
  }

  function emptySessions(): void {
    setSessions([]);
    setPage(0);
  }

  const onClearAll = () => {
    emptySessions();
    setInitialCursor();

    // Reset filters
    setSelDateRange([null, null]);
    setSelWorkspaces([]);
    setSelApplications([]);
    setSelSubscriptionTypes([]);
    setSelLivenessResults([]);
    setSelFaceComparisonResults([]);
    setSelAgeEstimationResults([]);
    setSelAttempts([]);
    setSelSyncStatus([]);
    setSelGdprConsentStatus([]);

    // Reset sorting
    setOrderBy(DEFAULT_SORTING);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setLimitExceeded(false);
      const userWorkspace = auth.user?.subWorkspacesIds.concat([auth.user.workspace.id]) ?? [];
      const response = await getSessions(
        sessionsInitialCursor ?? sessionsCursor,
        selDateRange,
        selWorkspaces.length > 0 ? selWorkspaces.map((w) => w.id) : userWorkspace,
        selApplications.map((a) => a.id),
        selSubscriptionTypes,
        selLivenessResults,
        selFaceComparisonResults,
        selAgeEstimationResults,
        selAttempts,
        selSyncStatus.length !== 1 ? "any" : selSyncStatus[0],
        Object.entries(orderBy)
          .map(([field, order]) => ({ field: field as SessionFieldOrder["field"], order }))
          .filter((fieldOrder): fieldOrder is SessionFieldOrder => fieldOrder.order !== undefined),
        selGdprConsentStatus
      );

      if (response && response.length > 0) {
        const nbOfFetchedSessions = response.length;
        const lastSession = response[nbOfFetchedSessions - 1];
        setSessionsCursor(new Date(lastSession.createdAt));
        resetInitialCursor();
        setSessions(sessions.concat(response));
      }
      setTableHeight(getTableHeight());
      if (!response || response.length === 0) setLimitExceeded(true);
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selSyncStatus,
    selWorkspaces,
    selApplications,
    selDateRange,
    selSubscriptionTypes,
    selLivenessResults,
    selFaceComparisonResults,
    selAgeEstimationResults,
    selAttempts,
    selGdprConsentStatus,
    orderBy,
    page,
  ]);

  const _onSessionSelected = (sessionId: string | null) => {
    setSelectedSessionId(sessionId);
    history.push(`${Routes.SESSIONS}/${sessionId?.trim() ?? ""}`);
  };

  const searchSession = (sessionId: string) => {
    getSession(sessionId)
      .then((session) => {
        session === undefined ? setSearchSessionError(true) : _onSessionSelected(session.id);
      })
      .catch(() => setSearchSessionError(true));
  };

  useEffect(() => {
    const adjustTableHeight = () => setTableHeight(getTableHeight);

    window.addEventListener("window", adjustTableHeight);
    window.addEventListener("resize", adjustTableHeight);

    return () => {
      window.removeEventListener("window", adjustTableHeight);
      window.removeEventListener("resize", adjustTableHeight);
    };
  }, []);

  return (
    <div style={{ overflow: "none" }}>
      <SessionModal
        selectedSessionId={selectedSessionId}
        sessions={sessions}
        page={page}
        setPage={setPage}
        _onSessionSelected={_onSessionSelected}
      />
      <Grid container>
        <Grid item container>
          <Box display="flex" width="100%" alignItems="center">
            <Box width="100%" maxWidth="375px">
              <SearchSessionId
                id={searchSessionId ?? ""}
                onIdChanged={(id) => {
                  setSearchSessionError(false);
                  setSearchSessionId(id);
                }}
                onSearch={searchSession}
                error={searchSessionError ? t("sessions_page.invalid_id") : undefined}
              />
            </Box>
            <Link onClick={onClearAll} underline="always" style={{ cursor: "pointer", margin: 8 }}>
              {t("clear_all_filters")}
            </Link>
          </Box>
          <div style={{ width: "100%", paddingRight: "8px", paddingLeft: "5px" }} ref={tableContainer}>
            <SessionsTable
              sessions={sessions}
              isLoading={isLoading}
              height={tableHeight}
              onScroll={handleScroll}
              onSessionIdSelected={_onSessionSelected}
              selectedSessionId={selectedSessionId}
              sorting={orderBy}
              onSorting={(sorting) => {
                emptySessions();
                reverseInitialCursor();
                setOrderBy(sorting);
              }}
              selWorkspaces={selWorkspaces}
              setSelWorkspaces={setSelWorkspaces}
              selApplications={selApplications}
              setSelApplications={setSelApplications}
              selDateRange={selDateRange}
              setSelDateRange={setSelDateRange}
              selSyncStatus={selSyncStatus}
              setSelSyncStatus={setSelSyncStatus}
              selSubscriptionTypes={selSubscriptionTypes}
              setSelSubscriptionTypes={setSelSubscriptionTypes}
              selLivenessResults={selLivenessResults}
              setSelLivenessResults={setSelLivenessResults}
              selFaceComparisonResults={selFaceComparisonResults}
              setSelFaceComparisonResults={setSelFaceComparisonResults}
              selAgeEstimationResults={selAgeEstimationResults}
              setSelAgeEstimationResults={setSelAgeEstimationResults}
              selAttempts={selAttempts}
              setSelAttempts={setSelAttempts}
              selGdprConsentStatus={selGdprConsentStatus}
              setSelGdprConsentStatus={setSelGdprConsentStatus}
              emptySessions={emptySessions}
              setInitialCursor={setInitialCursor}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

type SearchSessionIdProps = {
  id: string;
  onIdChanged: (newId: string) => void;
  onSearch: (id: string) => void;
  error?: string;
};

function SearchSessionId(props: SearchSessionIdProps) {
  const { t } = useTranslation();

  return (
    <TextField
      value={props.id}
      onChange={(e) => props.onIdChanged(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter" && props.id) {
          props.onSearch(props.id);
          e.preventDefault();
        }
      }}
      label={t("sessions_page.search_session_id")}
      margin="dense"
      variant="outlined"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => props.onSearch(props.id)} disabled={!props.id}>
              <Search />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={!!props.error}
      helperText={props.error}
    />
  );
}
