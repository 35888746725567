import { Typography, TypographyTypeMap } from "@material-ui/core";
import pkg from "../../package.json";

function AppVersion({ className, color }: { color?: TypographyTypeMap["props"]["color"]; className?: string }) {
  return (
    <Typography variant="caption" color={color ?? "textSecondary"} align="center" className={className}>
      Version {pkg.version}
    </Typography>
  );
}

export default AppVersion;
