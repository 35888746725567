import { Grid, makeStyles, Typography } from "@material-ui/core";
import dateFormat from "dateformat";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { getSubscriptionsByDateRange } from "../../services/api_services";
import BillingDataTable from "./BillingDataTable";
import BillingDateForm from "./BillingDateForm";
import BillingExtractButton from "./BillingExtractButton";
import BillingMonthSelector from "./BillingMonthSelector";
import { DateRange } from "./types/general";
import { LIMIT_DAY } from "./utils/date";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "60px 20px",
    flex: 1,
    gap: "20px",
  },
  description: {
    textAlign: "center",
  },
}));

const lastMonthStartingDate = () => {
  const currentDate = new Date();
  if (currentDate.getDate() >= LIMIT_DAY) return new Date(currentDate.getFullYear(), currentDate.getMonth(), 26);
  if (currentDate.getMonth() === 0) return new Date(currentDate.getFullYear() - 1, 11, 26);
  return new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 26);
};

export default function BillingPage() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState<DateRange>({ start: lastMonthStartingDate(), end: new Date() });
  const [manualMode, setManualMode] = useState<boolean>(false);

  const { data: subscriptions, isLoading } = useQuery({
    queryKey: ["subscriptionByDateRange", dateRange],
    queryFn: async () => await getSubscriptionsByDateRange(dateRange.start, dateRange.end),
  });

  const handleDateRange = (newDates: { start?: Date; end?: Date }) => {
    setDateRange((prev) => ({
      ...prev,
      ...newDates,
    }));
  };

  const handleManualChange = (mode: boolean) => {
    setManualMode(mode);
  };

  const handleManualDateRange = (newDates: { start?: Date; end?: Date }) => {
    handleDateRange(newDates);
    handleManualChange(true);
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={5}
      className={classes.root}
    >
      <Grid item>
        <Typography variant="h6" className={classes.description}>
          {t("billing_page.description")}
        </Typography>
      </Grid>
      <BillingDateForm dateRange={dateRange} handleDateRange={handleManualDateRange} loading={isLoading} />
      <Grid item style={{ width: "100%" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <BillingMonthSelector
              dateRange={dateRange}
              handleDateRange={handleDateRange}
              manualMode={manualMode}
              handleManualChange={handleManualChange}
              nbOfYearsToDisplay={5}
              isLoading={isLoading}
            />
          </Grid>
          <Grid item>
            <BillingExtractButton subscriptions={subscriptions} />
          </Grid>
        </Grid>
        <Typography style={{ textAlign: "right", padding: "5px", fontStyle: "italic" }}>{`${dateFormat(
          dateRange.start,
          "dd/mm/yyyy HH:MM"
        )} - ${dateFormat(dateRange.end, "dd/mm/yyyy HH:MM")}`}</Typography>
        <BillingDataTable subscriptions={subscriptions} isLoading={isLoading} />
      </Grid>
    </Grid>
  );
}
