import { action, computed, makeObservable, observable } from "mobx";
import { CreateSubscription, SubscriptionFields, SUBSCRIPTION_KINDS } from "@unissey/common";

export class CreateSubscriptionForm {
  data: Partial<CreateSubscription> = {
    canUseAge: false, // default value
    canUseIad: false, // default value
    fallbackBucketAutoCreated: true, // default value
    hasAutorenewal: false,
    autoRenewalLength: 1,
    autoRenewalUnit: "years",
  };

  constructor() {
    makeObservable(this, {
      data: observable,

      setField: action.bound,
      setWorkspaceId: action.bound,

      isValid: computed,

      isBucketSubscription: computed,

      isOneToOneSubscription: computed,
    });
  }

  setField<K extends keyof SubscriptionFields>(field: K, value: SubscriptionFields[K]) {
    this.data[field] = value;

    this.data = { ...this.data }; // to trigger render
  }

  setData(data: CreateSubscription) {
    this.data = { ...data };
  }

  setRenewalLength(length: "1_years" | "6_months") {
    if (length === "1_years") {
      this.data.autoRenewalLength = 1;
      this.data.autoRenewalUnit = "years";
    } else {
      this.data.autoRenewalLength = 6;
      this.data.autoRenewalUnit = "months";
    }
  }

  setWorkspaceId(wsId: string) {
    this.data.workspaceId = wsId;
  }

  get canActivateAutoRenewal() {
    return this.data.kind === "per_packet" || this.data.kind === "pay_as_you_go";
  }

  get isBucketSubscription() {
    return this.data.kind === "per_packet";
  }

  get isOneToOneSubscription() {
    return this.data.featureKind === "one_one";
  }

  get isEdit() {
    return false;
  }

  get isValid() {
    if (!this.data.workspaceId) return false;
    if (!this.data.name) return false;
    if (!this.data.kind) return false;
    if (!this.data.startDate) return false;

    const nonEmptyName = this.data.name.length > 0;
    const validKind = SUBSCRIPTION_KINDS.includes(this.data.kind);
    const validDateRange = this.data.endDate === undefined ? true : this.data.startDate < this.data.endDate;
    const validLimit = this.data.maxSessionCount === undefined ? true : this.data.maxSessionCount >= 0;

    return nonEmptyName && validKind && validDateRange && validLimit;
  }

  validate(): this is Omit<this, "data"> & { data: CreateSubscription } {
    return this.isValid;
  }
}
