import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Link,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { useScopedTranslation } from "../../i18n";
import { ExpandMore } from "@material-ui/icons";
import { ListHeaderFilter } from "../../components/filters/ListFilter";
import CustomSelect from "../../components/forms/formComponents/CustomSelect";
import { useQuery } from "react-query";
import { getIdentityRegistries, getWorkspaces } from "../../services/api_services";
import { DateHeaderFilter } from "../../components/filters/DateFilter";
import { IdentityListFilters } from "@unissey/common";
import OkIcon from "../../components/OkIcon";
import StopIcon from "../../components/StopIcon";
import { Dispatch, SetStateAction } from "react";

type DateRange = {
  before?: Date;
  after?: Date;
};

type SortOrder = "asc" | "desc";
type GdprConsent = "granted" | "rejected";

type Props = {
  filters: IdentityListFilters;
  setFilters: Dispatch<SetStateAction<IdentityListFilters>>;
  onFilter: () => void;
  onReset: () => void;
};

export function IdentityFilters({ filters, setFilters, onFilter, onReset }: Props) {
  const { t } = useScopedTranslation("identities_page.filter_section");

  const { data: workspaces } = useQuery(["workspaces-list"], () => getWorkspaces());
  const { data: registries } = useQuery(["registries-list"], () => getIdentityRegistries());

  const workspacesItems = workspaces?.map((workspace) => ({ label: workspace.name })) ?? [];
  const registriesItems = registries?.map((registry) => ({ label: registry.name })) ?? [];

  const GDPR_CONSENT_VALUES: GdprConsent[] = ["granted", "rejected"];

  const selectedWorkspaceIndices =
    workspaces && filters.workspacesIds
      ? filters.workspacesIds.map((id) => workspaces.findIndex((wk) => wk.id === id))
      : [];

  const selectedRegistriesIndices =
    registries && filters.registryIds
      ? filters.registryIds.map((id) => registries.findIndex((app) => app.id === id))
      : [];

  const selectedGdprConsentIndices = filters.gdprConsent
    ? filters.gdprConsent.map((value) => GDPR_CONSENT_VALUES.findIndex((v) => v === value))
    : [];

  const handleOrderSelection = (order: SortOrder) => setFilters((filter) => ({ ...filter, order }));
  const handleNbItemsSelection = (nbItems: number) => setFilters((filter) => ({ ...filter, nbItems }));

  const handleWorkspaceSelection = (selection: Set<number>) =>
    setFilters((filter) => ({
      ...filter,
      workspacesIds: workspaces ? Array.from(selection).map((index) => workspaces[index].id) : undefined,
    }));

  const handleRegistrySelection = (selection: Set<number>) =>
    setFilters((filter) => ({
      ...filter,
      registryIds: registries ? Array.from(selection).map((index) => registries[index].id) : undefined,
    }));

  const handleGdprConsentSelection = (selection: Set<number>) =>
    setFilters((filter) => ({
      ...filter,
      gdprConsent: Array.from(selection).map((index) => GDPR_CONSENT_VALUES[index]),
    }));

  const handleDateRangeChange = (range: DateRange) =>
    setFilters((filter) => ({ ...filter, beforeDate: range.before, afterDate: range.after }));

  const handleLastEnrollmentDateRangeChange = (range: DateRange) =>
    setFilters((filter) => ({
      ...filter,
      lastEnrollmentBeforeDate: range.before,
      lastEnrollmentAfterDate: range.after,
    }));

  const handleFilterValidation = () => {
    onFilter();
  };

  const handleFilterReset = () => {
    onReset();
  };

  return (
    <Box>
      <Accordion elevation={0} style={{ border: "solid 1px #ddd" }}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content">
          <Typography>{t("title")}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row">
              <Header
                text={t("filters_title.date")}
                filter={
                  <DateHeaderFilter
                    initialRange={{ before: filters.beforeDate, after: filters.afterDate }}
                    onRangeChanged={handleDateRangeChange}
                  />
                }
              />
              <Header
                text={t("filters_title.workspace")}
                filter={
                  <ListHeaderFilter
                    items={workspacesItems}
                    initialSelectedIndices={new Set(selectedWorkspaceIndices)}
                    onSelectionChanged={handleWorkspaceSelection}
                    textFilter={{ label: t("filters_label.workspace_name") }}
                  />
                }
              />
              <Header
                text={t("filters_title.registry")}
                filter={
                  <ListHeaderFilter
                    items={registriesItems}
                    initialSelectedIndices={new Set(selectedRegistriesIndices)}
                    onSelectionChanged={handleRegistrySelection}
                    textFilter={{ label: t("filters_label.registry_name") }}
                  />
                }
              />
              <Header
                text={t("filters_title.last_enrollment_date")}
                filter={
                  <DateHeaderFilter
                    initialRange={{ before: filters.lastEnrollmentBeforeDate, after: filters.lastEnrollmentAfterDate }}
                    onRangeChanged={handleLastEnrollmentDateRangeChange}
                  />
                }
              />
              <Header
                text={t("filters_title.gdpr_consent")}
                filter={
                  <ListHeaderFilter
                    items={[{ label: <OkIcon /> }, { label: <StopIcon /> }]}
                    initialSelectedIndices={new Set(selectedGdprConsentIndices)}
                    onSelectionChanged={handleGdprConsentSelection}
                  />
                }
              />
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <CustomSelect
                style={{ height: "40px", marginRight: "20px" }}
                width={3}
                labelId=""
                labelValue={t("filters_title.items_order")}
                menuItems={[
                  ["asc", t("order_asc")],
                  ["desc", t("order_desc")],
                ]}
                onChange={(e) => handleOrderSelection(e.target.value as SortOrder)}
                value={filters.order}
              />
              <CustomSelect
                style={{ height: "40px", marginRight: "20px" }}
                width={3}
                labelId="create-application-form-select-workspace"
                labelValue={t("filters_title.nb_items")}
                menuItems={[
                  [2, "2"],
                  [10, "10"],
                  [50, "50"],
                  [100, "100"],
                ]}
                onChange={(e) => handleNbItemsSelection(Number(e.target.value))}
                value={filters.nbItems}
              />
              <Button style={{ height: "30px" }} color="primary" variant="contained" onClick={handleFilterValidation}>
                {t("button_apply")}
              </Button>
              <Link href="#" style={{ cursor: "pointer", margin: 8 }} underline="always" onClick={handleFilterReset}>
                {t("button_clear")}
              </Link>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

type HeaderProps = {
  text: string;
  filter: React.ReactNode;
};

const useHeaderStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    margin: "4px",
    borderRight: "1px solid #eeeeee",
  },
  headerText: {
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
});

function Header({ text, filter }: HeaderProps) {
  const classes = useHeaderStyles();

  return (
    <Box className={classes.container}>
      <span className={classes.headerText}>{text}</span>
      <Box>{filter}</Box>
    </Box>
  );
}
