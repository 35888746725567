import { Box, Button, CircularProgress, DialogProps, FormGroup, Grid } from "@material-ui/core";
import { useState } from "react";
import { Modal } from "../../components/Modal";
import { EditApplication, SecurityLevel, SubscriptionFeatureKind } from "@unissey/common";
import { editApplication } from "../../services/api_services";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { has } from "../../types/utils";
import { useScopedTranslation } from "../../i18n";
import { isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/core/styles";
import { useNotif } from "../../contexts/notif_context";
import { flatObjectHaveEmptyField, isFlatObjectEqual } from "../../utils/compare_utils";
import { useQueryClient } from "react-query";
import { useAuth } from "../../auth";
import CustomSelect, { MenuItemsType } from "../../components/forms/formComponents/CustomSelect";

const useStyles = makeStyles({
  editTextField: {
    width: isMobile ? "95%" : "500px",
  },
});

type Props = DialogProps & {
  onClose: () => void;
  apiKeyFields: {
    id: string;
    name: string;
    livenessThreshold: SecurityLevel;
    matchingThreshold: SecurityLevel;
    subscriptionFeatureKind: SubscriptionFeatureKind;
  };
  workspaceId: string;
};

export default function EditApiKeyModal({ open, onClose, apiKeyFields, workspaceId }: Props) {
  const { t, tCommon } = useScopedTranslation("api_page.edit_dialog");

  const auth = useAuth();
  const notif = useNotif();

  const queryClient = useQueryClient();

  const canEditSecurityLevels = auth.hasOneOfRoles(["super_administrator", "internal_tech_user"]);

  const securityLevels: MenuItemsType = [
    ["LEVEL_1", tCommon(`api_page.create_dialog.key_level_1`)],
    ["LEVEL_2", tCommon(`api_page.create_dialog.key_level_2`)],
    ["LEVEL_3", tCommon(`api_page.create_dialog.key_level_3`)],
  ];

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [apiKeyForm, setApiKeyForm] = useState<EditApplication>({
    name: apiKeyFields.name,
    referers: [],
    livenessThreshold: apiKeyFields.livenessThreshold,
    matchingThreshold: apiKeyFields.matchingThreshold,
  });

  const classes = useStyles();

  const validateForm = () => {
    const compareApiKeys = ["name", "livenessThreshold", "matchingThreshold"];
    const isValidName =
      isFlatObjectEqual(apiKeyForm, apiKeyFields, compareApiKeys) ||
      !flatObjectHaveEmptyField(apiKeyForm, compareApiKeys);

    return isValidName;
  };

  async function editApiKey() {
    setIsLoading(true);
    if (!has(apiKeyForm, "name")) {
      notif.trigger?.({
        textKey: "error.api_key_edit_missing_fields",
      });
      setIsLoading(false);
      return;
    }
    if (await editApplication(apiKeyFields.id, apiKeyForm)) {
      notif.trigger?.({
        textKey: "success.api_key_edited",
        textParams: { applicationName: apiKeyForm.name },
      });
      queryClient.invalidateQueries(`applications-${workspaceId}`);
    }
    onClose();
    setIsLoading(false);
  }

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      body={
        <FormGroup>
          <Box py={2}>
            <Grid container spacing={2}>
              <CustomTextField
                name="applicationName"
                label={t("field_application_name")}
                width={12}
                className={classes.editTextField}
                value={apiKeyForm.name}
                autoFocus
                onChange={(e) => setApiKeyForm({ ...apiKeyForm, name: e.target.value })}
                onBlur={(e) => setApiKeyForm({ ...apiKeyForm, name: e.target.value.trim() })}
              />

              {canEditSecurityLevels && (
                <CustomSelect
                  width={6}
                  labelId="create-application-form-select-liveness-threshold"
                  labelValue={t("field_liveness_threshold")}
                  data-testid="apikey-liveness-threshold"
                  menuItems={securityLevels}
                  onChange={(e) => {
                    let livenessThreshold = e.target.value as SecurityLevel;
                    setApiKeyForm((prev) => ({ ...prev, livenessThreshold }));
                  }}
                  value={apiKeyForm.livenessThreshold}
                />
              )}
              {canEditSecurityLevels && apiKeyFields.subscriptionFeatureKind === "one_one" && (
                <CustomSelect
                  width={6}
                  labelId="create-application-form-select-matching-threshold"
                  labelValue={t("field_matching_threshold")}
                  data-testid="apikey-matching-threshold"
                  menuItems={securityLevels}
                  onChange={(e) => {
                    let matchingThreshold = e.target.value as SecurityLevel;
                    setApiKeyForm((prev) => ({ ...prev, matchingThreshold }));
                  }}
                  value={apiKeyForm.matchingThreshold}
                />
              )}
            </Grid>
          </Box>
        </FormGroup>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={editApiKey}
          startIcon={isLoading && <CircularProgress size={14} />}
          disabled={isLoading || validateForm()}
          fullWidth
        >
          {tCommon("button_edit")}
        </Button>
      }
    />
  );
}
