"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUBSCRIPTION_FEATURE_KINDS = exports.SUBSCRIPTION_KINDS = void 0;
exports.SUBSCRIPTION_KINDS = [
    "free_demo",
    "integrated_demo",
    "license",
    "pay_as_you_go",
    "per_packet",
    "poc",
];
exports.SUBSCRIPTION_FEATURE_KINDS = ["one_one", "one_to_many"];
