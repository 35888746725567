import { CSSProperties } from "react";
import { Chip, ChipTypeMap } from "@material-ui/core";

import theme from "../config/theme";
import { useScopedTranslation } from "../i18n";
import {
  AgeResult,
  FaceComparisonResult,
  FaceComparisonResultFilter,
  LivenessResult,
  LivenessResultFilter,
} from "../types/results";
import { AnalyzeAgeEstimationResult } from "@unissey/common";

type Props = {
  value:
    | LivenessResult
    | FaceComparisonResult
    | LivenessResultFilter
    | FaceComparisonResultFilter
    | "undetermined"
    | "estimation"
    | "N/A";
};

function SessionResultChip({ value }: Props) {
  const { t } = useScopedTranslation("chips");

  const label = t(value);
  const { disabled, color, borderColor } = chipProps(value);

  return (
    <Chip
      label={label}
      variant="outlined"
      disabled={disabled}
      color={color}
      style={{
        borderColor,
        fontSize: "0.9rem",
        width: "150px",
        maxWidth: "100%",
        cursor: "inherit",
      }}
    />
  );
}

function chipProps(
  value:
    | LivenessResult
    | FaceComparisonResult
    | AgeResult
    | LivenessResultFilter
    | FaceComparisonResultFilter
    | "undetermined"
    | "estimation"
    | "N/A"
): {
  color: ChipTypeMap["props"]["color"];
  borderColor: CSSProperties["borderColor"];
  disabled: boolean;
} {
  switch (value) {
    case "N/A":
    case "estimation_only":
    case "undetermined":
      return {
        color: "default",
        borderColor: undefined,
        disabled: true,
      };

    case "genuine":
    case "match":
    case "over_age":
    case "match_high":
    case "genuine_high":
      return {
        color: "primary",
        borderColor: theme.palette.success.main,
        disabled: false,
      };

    case "fail":
    case "mismatch":
    case "under_age":
    case "fail_high":
    case "mismatch_high":
      return {
        color: "primary",
        borderColor: "#3d59e8", // Unissey's "fail blue"
        disabled: false,
      };

    case "estimation":
      return {
        color: "primary",
        borderColor: theme.palette.success.main,
        disabled: false,
      };

    case "not_reached":
      return {
        color: "primary",
        borderColor: "#3d59e8",
        disabled: false,
      };

    case "genuine_low":
    case "match_low":
      return {
        color: "primary",
        borderColor: "#87FCD4",
        disabled: false,
      };

    case "fail_low":
    case "mismatch_low":
      return {
        color: "primary",
        borderColor: "#4759fc",
        disabled: false,
      };

    case "error":
      return {
        color: "primary",
        borderColor: "red",
        disabled: false,
      };
  }
}

// We provide wrappers to "hide" errors from users and fallback to fail/mismatch

export function LivenessChip({ value }: { value?: LivenessResult | LivenessResultFilter }) {
  return <SessionResultChip value={value ?? "N/A"} />;
}

export function FaceComparisonChip({ value }: { value?: FaceComparisonResult | FaceComparisonResultFilter }) {
  const failed = value === "error" || value === "not_reached";
  const overridenValue = failed ? "mismatch" : value;
  return <SessionResultChip value={overridenValue ?? "N/A"} />;
}

export function AgeVerificationChip({ ageResult, value }: { ageResult?: AgeResult; value?: string }) {
  if (ageResult === "not_reached") {
    return <SessionResultChip value="undetermined" />;
  } else if (ageResult) {
    const { disabled, borderColor } = chipProps(ageResult);

    return (
      <Chip
        label={ageResult === "over_age" ? `> ${value}` : `< ${value}`}
        disabled={disabled}
        variant="outlined"
        color="primary"
        style={{
          borderColor,
          fontSize: "0.9rem",
          width: "150px",
          maxWidth: "100%",
          cursor: "inherit",
        }}
      />
    );
  } else {
    return <></>;
  }
}

export function AgeEstimationChip({ value }: { value: "estimation" | "undetermined" | "N/A" }) {
  return <SessionResultChip value={value} />;
}

export function AgeEstimationResultChip({
  ageResult,
  value,
}: {
  ageResult?: AgeResult;
  value?: AnalyzeAgeEstimationResult;
}) {
  if (!ageResult || ageResult === "not_reached") return <SessionResultChip value="undetermined" />;
  else {
    return (
      <Chip
        label={
          <span>
            {value?.estimated_age}
            {value?.age_range && (
              <span style={{ fontSize: "0.7rem", width: "125px", color: "#979797" }}>
                {" "}
                ({value.age_range[0]} - {value.age_range[1]})
              </span>
            )}
          </span>
        }
        variant="outlined"
        color="primary"
        style={{
          borderColor: "#979797",
          fontSize: "0.9rem",
          width: "150px",
          maxWidth: "100%",
          cursor: "inherit",
        }}
      />
    );
  }
}
