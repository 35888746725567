import { createContext, FC, ReactNode, useContext } from "react";
import { NotifContextType } from "../types/notif";

export const NotifContext = createContext<NotifContextType>({
  isOpen: false,
  props: undefined,
  trigger: undefined,
  handleOpen: (value: boolean) => {},
});

export const useNotif = () => {
  return useContext(NotifContext);
};

export const NotifProvider: FC<{ children: ReactNode; value: NotifContextType }> = ({ children, value }) => {
  return <NotifContext.Provider value={value}>{children}</NotifContext.Provider>;
};
