import { Slide, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import theme from "../config/theme";
import { useNotif } from "../contexts/notif_context";
import { useScopedTranslation } from "../i18n";
import { AlertNotifProps, extractNotifType } from "../types/notif";

export default function AlertNotif({
  snackbarProps = {
    anchorOrigin: { vertical: "top", horizontal: "center" },
    style: { position: "absolute", top: theme.spacing(1) },
  },
}: AlertNotifProps) {
  const { t } = useScopedTranslation("notif");

  const notif = useNotif();

  let notifText = undefined;
  if (notif.props) {
    let params = notif.props.textParams ?? {};
    if (notif.props.errorFields) {
      params = { ...params, fields: notif.props.errorFields.map((f) => t(`error.field_${f}`)).join(", ") };
    }
    notifText = t(notif.props.textKey, params);
  }

  return (
    <Snackbar
      {...snackbarProps}
      TransitionComponent={Slide}
      open={notif.isOpen}
      autoHideDuration={6000}
      onClose={() => notif.handleOpen(false)}
    >
      <Alert
        onClose={() => notif.handleOpen(false)}
        severity={notif.props && extractNotifType(notif.props.textKey)}
        icon={notif.props?.icon}
        variant="filled"
        elevation={0}
      >
        {notifText}
      </Alert>
    </Snackbar>
  );
}
