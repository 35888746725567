import { action, makeObservable, observable } from "mobx";
import { SessionAnnotation } from "@unissey/common";

export type SingleValueAnnotation = Omit<SessionAnnotation, keyof MultiValueAnnotation>;
export type MultiValueAnnotation = Pick<
  SessionAnnotation,
  "reference_face_quality" | "target_face_quality" | "rejected"
>;

export class Annotations {
  constructor(public sessionAnnotation: SessionAnnotation) {
    makeObservable(this, {
      sessionAnnotation: observable,

      toggleSingle: action.bound,
      toggleMulti: action.bound,
    });
  }

  toggleSingle(key: keyof SingleValueAnnotation, value: number) {
    if (this.sessionAnnotation[key] === value) {
      this.sessionAnnotation[key] = undefined;
    } else {
      this.sessionAnnotation[key] = value;
    }
  }

  toggleMulti(key: keyof MultiValueAnnotation, value: number) {
    const annotation = this.sessionAnnotation[key];

    if (annotation === undefined) {
      // No annotation for a multi key means that we need to create a new array
      // with the new value
      this.sessionAnnotation[key] = [value];
    } else {
      // Having an existing annotation for the multi key means that we need to
      // either add a new value to the array or remove an existing one
      const idx = annotation.indexOf(value);

      if (idx === -1) {
        annotation.push(value);
      } else {
        annotation.splice(idx, 1);

        // To save some space and avoid having 2 different values for the same
        // meaning (undefined and []), we normalize empty arrays back to
        // an undefined value
        if (annotation.length === 0) {
          this.sessionAnnotation[key] = undefined;
        }
      }
    }
  }
}
