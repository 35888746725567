import {
  Box,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { CheckBoxOutlineBlank } from "@material-ui/icons";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HelpIcon from "@material-ui/icons/Help";
import { useEffect, useState } from "react";

import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import { useScopedTranslation } from "../../i18n";
import { getRoles } from "../../services/api_services";
import { CompositeRoleKey, Role, UpdateUserExtended } from "@unissey/common";

export type ExceptionEditStatus = "none" | "add" | "delete";

type Props = {
  userToEdit: UpdateUserExtended;
  targetWorkspaceId?: string;
  parentWorkspaceId?: string;
  onFieldChanged: <K extends keyof UpdateUserExtended>(field: K, value: UpdateUserExtended[K]) => void;
  onPersonalDataChange: (status: ExceptionEditStatus) => void;
};

export default function UpdateTeamMemberForm({
  userToEdit,
  onFieldChanged,
  onPersonalDataChange,
  targetWorkspaceId,
  parentWorkspaceId,
}: Props) {
  const hasGdpr = userToEdit.mediaAccess;
  const { t, tCommon } = useScopedTranslation("workspace_page.update_member");

  const [roles, setRoles] = useState<Role[]>();
  const [gdpr, setGdpr] = useState<boolean>(hasGdpr);
  const [initialHasGdprAuthException] = useState<boolean>(hasGdpr);

  function roleSelectorRenderValue(): string {
    const role = roles?.find((r) => userToEdit.roleKey === r.key);
    if (role !== undefined) return tCommon(`roles.${role.key}`);

    return "";
  }

  useEffect(() => {
    (async () => {
      const r = await getRoles();
      setRoles(() => r?.sort((a, b) => a.order - b.order));
    })();
  }, []);

  return (
    <FormGroup>
      <Box py={2}>
        <Grid container spacing={2}>
          <CustomTextField
            width={6}
            name={"firstName"}
            label={t("fields.firstName")}
            value={userToEdit.firstName}
            onChange={(e) => onFieldChanged("firstName", e.target.value)}
            onBlur={(e) => onFieldChanged("firstName", e.target.value.trim())}
            data-testid="edit-firstname"
          />
          <CustomTextField
            width={6}
            name={"lastName"}
            label={t("fields.lastName")}
            value={userToEdit.lastName}
            onChange={(e) => onFieldChanged("lastName", e.target.value)}
            onBlur={(e) => onFieldChanged("lastName", e.target.value.trim())}
            data-testid="edit-lastname"
          />
        </Grid>
      </Box>
      <Box py={2}>
        <Grid container spacing={2}>
          <CustomTextField
            width={6}
            name={"email"}
            label={t("fields.email")}
            value={userToEdit.email}
            onChange={(e) => onFieldChanged("email", e.target.value)}
            onBlur={(e) => onFieldChanged("email", e.target.value.trim())}
          />
          <Grid item xs={6}>
            <FormControl required fullWidth variant="outlined">
              <InputLabel id="select-role-label">{t("fields.role")}</InputLabel>
              <Select
                labelId="select-role-label"
                label={t("fields.role")}
                value={userToEdit.roleKey ?? ""}
                renderValue={roleSelectorRenderValue}
                MenuProps={{
                  variant: "menu",
                  getContentAnchorEl: null,
                }}
                onChange={(e) => onFieldChanged("roleKey", e.target.value as CompositeRoleKey)}
                IconComponent={ArrowDropDownIcon}
              >
                <MenuItem key="placeholder" style={{ display: "none" }} />
                {roles ? (
                  roles
                    .filter((role) => {
                      if (parentWorkspaceId) return role.category !== "internal";
                      return true;
                    })
                    .map((role, idx) => (
                      <MenuItem dense value={role.key} key={idx}>
                        <ListItemText primary={tCommon(`roles.${role.key}`)} />
                        <ListItemIcon>
                          <Tooltip
                            title={<Typography>{tCommon(`roles_descriptions.${role.key}`)}</Typography>}
                            interactive
                          >
                            <HelpIcon />
                          </Tooltip>
                        </ListItemIcon>
                      </MenuItem>
                    ))
                ) : (
                  <Typography variant={"h6"} align={"center"}>
                    {tCommon("loading")}
                  </Typography>
                )}
              </Select>
            </FormControl>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setGdpr(e.target.checked);
                  if (e.target.checked) {
                    onPersonalDataChange(initialHasGdprAuthException ? "none" : "add");
                  } else {
                    onPersonalDataChange(!initialHasGdprAuthException ? "none" : "delete");
                  }
                }}
                color="primary"
                checked={gdpr}
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                name="personal-data-reader"
              />
            }
            label={
              <Typography>
                <b>{t("fields.personalDataReader")}</b>
                <br /> {t("fields.personalDataReaderSubMsg")}
              </Typography>
            }
            labelPlacement="start"
          />
        </Grid>
      </Box>
    </FormGroup>
  );
}
