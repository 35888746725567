import DateFnsUtils from "@date-io/date-fns";
import { Grid, List, ListItem, ListItemIcon, ListItemText, Radio } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HeaderFilterMenu } from ".";
import CustomDateTimePicker from "../forms/formComponents/CustomDateTimePicker";

type Preset = "1 day" | "2 days" | "1 week" | "1 month" | "Custom";

type DateRange = {
  after?: Date;
  before?: Date;
};

type Props = {
  initialRange: DateRange;
  onRangeChanged: (range: DateRange) => void;
};

const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
const PRESETS: Preset[] = ["1 day", "2 days", "1 week", "1 month", "Custom"];

export function DateHeaderFilter({ initialRange, onRangeChanged }: Props) {
  const { t } = useTranslation();
  const [range, setRange] = useState(initialRange);
  const [checkedPreset, setCheckedPreset] = useState<[string, number] | null>(null);

  const clearDate = () => {
    setCheckedPreset(null);
    setRange({
      before: undefined,
      after: undefined,
    });
  };

  const isDateCleared = (): boolean => {
    return !range.before && !range.after && checkedPreset === null;
  };

  const selectDatePreset = (preset: Preset) => {
    if (preset === "Custom") {
      setRange({ before: undefined, after: undefined });
      return;
    }
    const currentDate = new Date();
    setRange((prev) => ({ ...prev, before: currentDate }));

    switch (preset) {
      case "1 day":
        setRange((prev) => ({ ...prev, after: new Date(currentDate.getTime() - ONE_DAY_IN_MILLISECONDS) }));
        break;
      case "2 days":
        setRange((prev) => ({ ...prev, after: new Date(currentDate.getTime() - 2 * ONE_DAY_IN_MILLISECONDS) }));
        break;
      case "1 week":
        setRange((prev) => ({ ...prev, after: new Date(currentDate.getTime() - 7 * ONE_DAY_IN_MILLISECONDS) }));
        break;
      case "1 month":
        setRange((prev) => ({
          ...prev,
          after: new Date(currentDate.getTime() - 30 * ONE_DAY_IN_MILLISECONDS),
        }));
        break;
      default:
        break;
    }
    return;
  };

  const handleClick = (value: Preset, index: number) => {
    if (checkedPreset && checkedPreset[1] === index) {
      setCheckedPreset(null);
      setRange({ before: undefined, after: undefined });
      return;
    }
    selectDatePreset(value);
    setCheckedPreset([value, index]);
  };

  const selectTranslation = (preset: Preset) => {
    switch (preset) {
      case "1 day":
        return t("sessions_page.filters.label_filter_presets.one_day");
      case "2 days":
        return t("sessions_page.filters.label_filter_presets.two_days");
      case "1 week":
        return t("sessions_page.filters.label_filter_presets.one_week");
      case "1 month":
        return t("sessions_page.filters.label_filter_presets.one_month");
      case "Custom":
        return t("sessions_page.filters.label_filter_presets.custom");
      default:
        break;
    }
  };

  return (
    <HeaderFilterMenu
      onConfirm={() => onRangeChanged(range)}
      onCancel={() => setRange(initialRange)}
      onClearAll={clearDate}
      disableClear={isDateCleared()}
      activeIndicator={(initialRange.after || initialRange.before) && "*"}
    >
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
        <Grid item>
          <List style={{ maxHeight: "250px", maxWidth: "300px", overflowY: "auto" }}>
            {PRESETS.map((value, index) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => handleClick(value, index)}
                  dense
                  disableGutters
                  style={{ paddingLeft: "8px" }}
                >
                  <ListItemIcon style={{ minWidth: "unset" }}>
                    <Radio
                      edge="start"
                      checked={checkedPreset ? checkedPreset[1] === index : false}
                      style={{ height: "20px", boxSizing: "border-box" }}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={selectTranslation(value)} style={{ fontSize: "small", cursor: "pointer" }} />
                </ListItem>
              );
            })}
          </List>
        </Grid>
        <Grid item>
          {checkedPreset && checkedPreset[0] === "Custom" && (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <CustomDateTimePicker
                value={range.after ?? null}
                onChange={(date) => setRange({ ...range, after: date ?? undefined })}
                label={t("sessions_page.filters.label_filter_start_date")}
                maxDate={range.before}
                width={"auto"}
              />
              <CustomDateTimePicker
                value={range.before ?? null}
                onChange={(date) => setRange({ ...range, before: date ?? undefined })}
                label={t("sessions_page.filters.label_filter_end_date")}
                minDate={range.after}
                width={"auto"}
              />
            </MuiPickersUtilsProvider>
          )}
        </Grid>
      </Grid>
    </HeaderFilterMenu>
  );
}
