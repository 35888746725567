import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import { Link, IconButton, Box, makeStyles } from "@material-ui/core";
import React, { useState, useMemo } from "react";
import CustomTable from "./CustomTable";
import OverflowTooltip from "./OverflowTooltip";
import theme from "../config/theme";
import { ListHeaderFilter } from "./filters/ListFilter";
import { DateHeaderFilter } from "./filters/DateFilter";
import { SliderFilter } from "./filters/SliderFilter";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  headCellChild: {
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    padding: `0 ${theme.spacing(1)}px`,
  },
  sortIcon: {
    width: "13px",
    height: "13px",
  },
  successColor: {
    color: "#05DB91",
  },
});

type FilterWidgetKind = "select" | "date" | "slider";

type DataFilterType = "exact" | "iexact" | "contains" | "gte" | "lte" | "interval" | "substring";

type TableRowData = number | string | Date;

type FilterData<T> = {
  dataKey: string;
  value: T | T[];
  lookup: DataFilterType;
};

interface InitFilter<T> {
  label: string;
  widget: FilterWidgetKind;
  values?: T[];
  marks?: number[];
  lookup: DataFilterType;
}

interface FilterWidgetData extends InitFilter<any> {
  dataKey: string;
}

export type DataTableColumn<T> = {
  dataKey: string;
  title: string;
  render?: (key: string, value: any) => JSX.Element;
  sorter?: (a: T, b: T) => number;
  filter?: InitFilter<TableRowData>;
};

type DataTableProps<T> = {
  columns: DataTableColumn<T>[];
  dataSource: T[];
  onRowClick?: (index: number) => void;
  lastColAlignRight?: boolean;
  defaultSort?: {
    key: string;
    order: "asc" | "desc" | "";
  };
  isLoading: boolean;
  noDataMsg?: string;
};

const DataTable = <T,>({
  columns,
  dataSource,
  onRowClick,
  lastColAlignRight,
  defaultSort,
  isLoading,
  noDataMsg,
}: DataTableProps<T>) => {
  const initialFilters: FilterData<TableRowData>[] = [];
  const [filters, setFilters] = useState(initialFilters);
  const [sortKey, setSortKey] = useState(defaultSort?.key ?? "");
  const [sortOrder, setSortOrder] = useState<string>(defaultSort?.order ?? "asc");

  const { t } = useTranslation();
  const classes = useStyles();
  const resetFilters = () => setFilters([]);

  /**
   * Callback used to set wich column of the datatable will be used for sorting, and set the order
   * @param newSortKey the new data key to use
   * @param order the sort order
   * @returns
   */
  const handleSortChange = (newSortKey: string, order: string) => {
    return () => {
      if (newSortKey === sortKey && sortOrder === order) {
        setSortKey("");
        setSortOrder("asc");
      } else {
        setSortKey(newSortKey);
        setSortOrder(order);
      }
    };
  };

  /**
   * Replace an existing filter or add a new filter to the list of filters
   * @param newFilter
   */
  const handleFilterchange = (newFilter: FilterData<any>) => {
    let filtersCopy = [...filters];

    const existingFilterIndex = filtersCopy.findIndex((filter) => filter.dataKey === newFilter.dataKey);
    if (existingFilterIndex === -1) filtersCopy.push(newFilter);
    else filtersCopy.splice(existingFilterIndex, 1, newFilter);

    setFilters(filtersCopy);
  };

  // Apply the appropriate filter method given the lookup type
  const filtersLookups = (lookup: DataFilterType) => {
    return (a: TableRowData | TableRowData[], b: TableRowData) => {
      switch (lookup) {
        case "exact":
          return a === b;
        case "gte":
          return a >= b;
        case "lte":
          return a <= b;
        case "iexact":
          return `${a}`.toLowerCase() === `${b}`.toLowerCase(); // only for strings
        case "contains":
          if (Array.isArray(a) && a.length === 0) return true; // we do not filter if the filter value is empty;
          return Array.isArray(a) && a.includes(b);
        case "interval":
          if (Array.isArray(a) && a.length === 0) return true; // we do not filter if the interval is not provided
          // In case one of the extremum is not provided
          if (Array.isArray(a) && typeof a[0] !== typeof a[1]) return b >= a[0] || b <= a[1];
          // If the two extremum are provided
          else if (Array.isArray(a) && a.length === 2) return b >= a[0] && b <= a[1];
          else return false;

        case "substring":
          if (Array.isArray(a)) {
            if (a.length === 0) return true;
            return a.some((substr) => b.toString().includes(substr.toString()));
          } else {
            return b.toString().includes(a.toString());
          }
      }
    };
  };

  const dataToDisplay = useMemo(() => {
    const applySort = (data: T[]): T[] => {
      const sortColumn = columns.find((column) => column.dataKey === sortKey);
      if (sortColumn) {
        if (sortOrder === "desc") return data.sort(sortColumn.sorter).reverse();
        else return data.sort(sortColumn.sorter);
      }

      return data;
    };

    const applyFilters = (data: T[]): T[] => {
      let filteredData: T[] = [...data];

      filteredData = filteredData.filter((value: any) => {
        let check = true;
        filters.forEach((filter) => {
          if (filter.dataKey in value) {
            check = check && filtersLookups(filter.lookup)(filter.value, value[filter.dataKey]);
          }
        });
        return check;
      });

      return filteredData;
    };

    return applySort(applyFilters(dataSource));
  }, [dataSource, columns, sortKey, sortOrder, filters]);

  const handleRowClick = (index: number) => {
    const selectedItem = dataToDisplay[index];
    const selectedItemIndexOnDataSource = dataSource.findIndex((entry) => selectedItem === entry); // we compare references
    onRowClick?.(selectedItemIndexOnDataSource);
  };

  const headers = columns.map((column) => (
    <div key={column.dataKey}>
      <Box display="flex" alignItems="flex-start" justifyContent="space-between" className={classes.headCellChild}>
        {column.title}

        <Box display="flex" flexDirection="row" justifyContent="end">
          {column.filter && (
            <DisplayFilterWidget
              activeFilters={filters}
              filter={{ ...column?.filter, dataKey: column.dataKey }}
              onFilterChange={handleFilterchange}
            />
          )}

          <Box display="flex" flexDirection="column" justifyContent="end" alignItems="end">
            {column.sorter && (
              <>
                <IconButton onClick={handleSortChange(column.dataKey, "asc")} size="small" className={classes.sortIcon}>
                  <ArrowDropUp
                    className={sortKey === column.dataKey && sortOrder === "asc" ? classes.successColor : ""}
                  />
                </IconButton>
                <IconButton
                  onClick={handleSortChange(column.dataKey, "desc")}
                  size="small"
                  className={classes.sortIcon}
                >
                  <ArrowDropDown
                    className={sortKey === column.dataKey && sortOrder === "desc" ? classes.successColor : ""}
                  />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  ));

  const rows = dataToDisplay.map((row: any, i) => {
    return Object.keys(row).map((dataKey, j) => {
      const value = row[dataKey];

      const renderFunction = columns[j]?.render;

      //priority:
      //1. render with render function if it exists
      //2. Display the element if it is a valid react element
      //3. display as text with OverflowTooltip

      const key = `${i}-${j}`;

      if (renderFunction) {
        return renderFunction(key, value);
      }
      if (React.isValidElement(value))
        return (
          <Box key={key} data-testid={key}>
            {value}
          </Box>
        );

      return <OverflowTooltip key={key} text={`${value}`} maxWidth={300} />;
    });
  });

  return (
    <div>
      {filters.length > 0 && (
        <Link href="#" onClick={resetFilters} underline="always">
          {t("clear_all_filters")}
        </Link>
      )}
      <CustomTable
        heads={headers}
        rows={rows}
        rowOnClick={onRowClick ? handleRowClick : undefined} // to make row clickable only if the callback is passed
        setMinHeight
        lastColAlignRight={lastColAlignRight}
        isLoading={isLoading}
        noDataMsg={noDataMsg}
      />
    </div>
  );
};

export default DataTable;

interface FilterWidgetProps {
  filter: FilterWidgetData;
  activeFilters: FilterData<any>[];
  onFilterChange: (value: FilterData<any>) => void;
}

const DisplayFilterWidget = ({ activeFilters, filter, onFilterChange }: FilterWidgetProps) => {
  switch (filter.widget) {
    case "select":
      return <SelectWidget activeFilters={activeFilters} filter={filter} onFilterChange={onFilterChange} />;
    case "date":
      return <DateWidget activeFilters={activeFilters} filter={filter} onFilterChange={onFilterChange} />;
    case "slider":
      return <SliderWidget activeFilters={activeFilters} filter={filter} onFilterChange={onFilterChange} />;
  }
};

const SliderWidget = ({ activeFilters, filter, onFilterChange }: FilterWidgetProps) => {
  const handleChange = (range: { after?: number; before?: number }) => {
    onFilterChange({
      dataKey: filter.dataKey,
      lookup: filter.lookup,
      value: [range.after, range.before],
    });
  };

  const currentFilter = activeFilters.find((af) => af.dataKey === filter.dataKey);
  const initialRange = currentFilter ? { after: currentFilter.value[0], before: currentFilter.value[1] } : {};

  const markLabel = (mark: number): string => {
    if (mark >= 1000 && mark < 1000000) return `${Math.ceil(mark / 1000)}K`;
    else if (mark >= 1000000) return `${Math.ceil(mark / 1000000)}M`;
    return `${Math.ceil(mark)}`;
  };

  const marks = filter.marks?.map((mark) => ({ value: mark, label: markLabel(mark) }));

  return (
    <SliderFilter
      initialRange={initialRange}
      min={filter.values?.[0]}
      max={filter.values?.[1]}
      marks={marks}
      onRangeChanged={handleChange}
    />
  );
};

const DateWidget = ({ activeFilters, filter, onFilterChange }: FilterWidgetProps) => {
  const currentFilter = activeFilters.find((af) => af.dataKey === filter.dataKey);
  const initialRange = currentFilter ? { after: currentFilter.value[0], before: currentFilter.value[1] } : {};

  const handleChange = (range: { after?: Date; before?: Date }) => {
    onFilterChange({
      dataKey: filter.dataKey,
      lookup: filter.lookup,
      value: range.after || range.before ? [range.after, range.before] : [],
    });
  };
  return <DateHeaderFilter initialRange={initialRange} onRangeChanged={handleChange} />;
};

const SelectWidget = ({ activeFilters, filter, onFilterChange }: FilterWidgetProps) => {
  const items = filter.values?.map((choice) => ({ label: choice }));

  let initialIndices = new Set<number>();

  filter.values?.forEach((filterValue, index) => {
    activeFilters
      .filter((af) => af.dataKey === filter.dataKey)
      .forEach((af) => {
        if (af.value.includes(filterValue)) initialIndices.add(index);
      });
  });

  const handleChange = (indexes: Set<number>) => {
    let values: string[] = [];

    indexes.forEach((index) => (filter.values ? values.push(filter.values[index]) : ""));

    onFilterChange({
      dataKey: filter.dataKey,
      lookup: filter.lookup,
      value: values,
    });
  };

  return (
    <ListHeaderFilter
      items={items || []}
      initialSelectedIndices={initialIndices}
      onSelectionChanged={handleChange}
      textFilter={{ label: filter.label }}
    />
  );
};
