import { Button } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import WorkspaceList from "../../components/WorkspaceList";
import { ReadWorkspace } from "@unissey/common";

import CreateApiKeyModal from "./CreateApiKey";
import ManageApiKey from "./ManageApiKey";
import { useAuth } from "../../auth";

function ApiKeyPage() {
  const auth = useAuth();
  const { t } = useTranslation();

  const [creationModalIsOpen, setCreationModalIsOpen] = useState<boolean>(false);

  const canEditApiKeys = auth.hasRoles(["apikey.edit"]);

  function CreateApiKeyButton() {
    if (!canEditApiKeys) return null;
    return (
      <Button variant="outlined" onClick={() => setCreationModalIsOpen(true)}>
        {t("api_page.button_create")}
      </Button>
    );
  }

  const getApiKeysByWorkspace = (workspace: ReadWorkspace & { expand: boolean }, creationModalIsOpen: boolean) => {
    return <ManageApiKey workspaceId={workspace.id} creationModalIsOpen={creationModalIsOpen} />;
  };

  return (
    <>
      <WorkspaceList
        getChildren={getApiKeysByWorkspace}
        createButton={CreateApiKeyButton}
        creationModalIsOpen={creationModalIsOpen}
      />

      {canEditApiKeys ? (
        <CreateApiKeyModal open={creationModalIsOpen} onClose={() => setCreationModalIsOpen(false)} />
      ) : null}
    </>
  );
}

export default ApiKeyPage;
