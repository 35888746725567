export const isFlatObjectEqual = (objA: any, objB: any, keys: string[] = Object.keys(objA)): boolean => {
  if (typeof objA !== "object" || typeof objB !== "object") return false;

  const checks = keys.map((key) => {
    return objA[key] === objB[key];
  });

  return checks.every((current) => current === true);
};

export const flatObjectHaveEmptyField = (objA: any, keys: string[] = Object.keys(objA)): boolean => {
  if (typeof objA !== "object") return false;

  const checks = keys.map((key) => {
    let check: boolean;
    typeof objA[key] === "string"
      ? (check = objA[key] !== "" && objA[key] !== undefined)
      : (check = objA[key] !== undefined);
    return check;
  });

  return checks.every((current) => current === true);
};

export const areObjectsDeepEqual = (objA: any, objB: any, keys: string[] = Object.keys(objA)): boolean => {
  if (typeof objA !== "object" || typeof objB !== "object") return false;

  const checks = keys.map((key) => {
    if (typeof objA[key] === "object" && typeof objB[key] === "object")
      return areObjectsDeepEqual(objA[key], objB[key]);
    return objA[key] === objB[key];
  });

  return checks.every((current) => current === true);
};
