import React from "react";
import { Redirect, Route, RouteProps, useLocation } from "react-router-dom";

import { RoleKey } from "@unissey/common";

// constants
import { Routes } from "../constants/routes";
// contexts

import RestrictedPage from "../pages/error/RestrictedPage";
import { useAuth } from "../auth";

type Props = {
  roles: RoleKey[];
  redirectRoute?: string;
};

const PrivateRoute = ({ roles, redirectRoute, ...rest }: Props & RouteProps) => {
  const location = useLocation();
  const auth = useAuth();

  if (!auth.user) {
    return (
      <Route {...rest}>
        <Redirect
          to={{
            pathname: Routes.SIGNIN,
            state: { from: location },
            search: location.search,
          }}
        />
      </Route>
    );
  }

  if (auth.access.roles.length === 0 && roles.length > 0) {
    return (
      <Route {...rest}>
        <RestrictedPage roles={roles} />
      </Route>
    );
  }

  return (
    <Route {...rest}>
      {auth.user && auth.hasRoles(roles) ? (
        rest.children
      ) : redirectRoute ? (
        <Redirect
          to={{
            pathname: redirectRoute,
            state: { from: location },
            search: location.search,
          }}
        />
      ) : (
        <RestrictedPage roles={roles} />
      )}
    </Route>
  );
};

export default PrivateRoute;
