"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DSUnauthenticatedException = exports.DSPermissionDeniedException = exports.DSNotFoundException = exports.DSInvalidArgumentException = exports.DSInternalException = exports.DSBaseError = exports.getDSError = exports.HttpStatusCode = exports.GrpcStatus = void 0;
const error_codes_1 = require("./error-codes");
var GrpcStatus;
(function (GrpcStatus) {
    GrpcStatus[GrpcStatus["OK"] = 0] = "OK";
    GrpcStatus[GrpcStatus["CANCELLED"] = 1] = "CANCELLED";
    GrpcStatus[GrpcStatus["UNKNOWN"] = 2] = "UNKNOWN";
    GrpcStatus[GrpcStatus["INVALID_ARGUMENT"] = 3] = "INVALID_ARGUMENT";
    GrpcStatus[GrpcStatus["DEADLINE_EXCEEDED"] = 4] = "DEADLINE_EXCEEDED";
    GrpcStatus[GrpcStatus["NOT_FOUND"] = 5] = "NOT_FOUND";
    GrpcStatus[GrpcStatus["ALREADY_EXISTS"] = 6] = "ALREADY_EXISTS";
    GrpcStatus[GrpcStatus["PERMISSION_DENIED"] = 7] = "PERMISSION_DENIED";
    GrpcStatus[GrpcStatus["OUT_OF_RANGE"] = 11] = "OUT_OF_RANGE";
    GrpcStatus[GrpcStatus["UNIMPLEMENTED"] = 12] = "UNIMPLEMENTED";
    GrpcStatus[GrpcStatus["INTERNAL"] = 13] = "INTERNAL";
    GrpcStatus[GrpcStatus["UNAVAILABLE"] = 14] = "UNAVAILABLE";
    GrpcStatus[GrpcStatus["DATA_LOSS"] = 15] = "DATA_LOSS";
    GrpcStatus[GrpcStatus["UNAUTHENTICATED"] = 16] = "UNAUTHENTICATED";
})(GrpcStatus = exports.GrpcStatus || (exports.GrpcStatus = {}));
var HttpStatusCode;
(function (HttpStatusCode) {
    HttpStatusCode[HttpStatusCode["InvalidArgument"] = 400] = "InvalidArgument";
    HttpStatusCode[HttpStatusCode["Unauthenticated"] = 401] = "Unauthenticated";
    HttpStatusCode[HttpStatusCode["PermissionDenied"] = 403] = "PermissionDenied";
    HttpStatusCode[HttpStatusCode["NotFound"] = 404] = "NotFound";
    HttpStatusCode[HttpStatusCode["Internal"] = 500] = "Internal";
})(HttpStatusCode = exports.HttpStatusCode || (exports.HttpStatusCode = {}));
function getDSError(httpStatusCode) {
    return httpCodeToExceptionMap[httpStatusCode];
}
exports.getDSError = getDSError;
class DSBaseError extends Error {
    constructor(httpCode, grpcStatus, defaultMessage, errorCode, data) {
        super();
        this.httpCode = httpCode;
        this.grpcStatus = grpcStatus;
        this.defaultMessage = defaultMessage;
        this.errorCode = errorCode;
        this.data = data !== null && data !== void 0 ? data : {};
        Object.setPrototypeOf(this, DSBaseError.prototype);
    }
    static fromGrpcError(err) {
        let data = Object.keys(err.metadata.getMap())
            .map((k) => [k, err.metadata.get(k)])
            .reduce((acc, [k, v]) => {
            const strValue = typeof v === "string" ? v : v.toString();
            try {
                acc[k] = JSON.parse(strValue);
            }
            catch (e) {
                acc[k] = strValue;
            }
            return acc;
        }, {});
        const errorCode = data[DSBaseError.ERROR_CODE_KEY];
        const httpCode = data[DSBaseError.HTTP_ERROR_CODE_KEY];
        delete data[DSBaseError.ERROR_CODE_KEY];
        delete data[DSBaseError.HTTP_ERROR_CODE_KEY];
        const error = getDSError(httpCode);
        return error // if error is undefined, the ServiceError received is not a DSBaseError
            ? error(errorCode, data)
            : getDSError(HttpStatusCode.Internal)(error_codes_1.ErrorCode.internal.UNKNOWN_INTERNAL_EXCEPTION);
    }
    toHttpResponse() {
        return {
            status: this.httpCode,
            message: this.defaultMessage,
            data: this.data,
            errorCode: this.errorCode,
        };
    }
    toGrpcError(meta) {
        meta.add(DSBaseError.HTTP_ERROR_CODE_KEY, this.httpCode.toString());
        meta.add(DSBaseError.ERROR_CODE_KEY, this.errorCode.toString());
        Object.entries(this.data).forEach(([key, v]) => {
            const parsedValue = v === null || v === undefined ? "[null or undefined]" : typeof v === "string" ? v : JSON.stringify(v);
            meta.add(key, parsedValue);
        });
        const error = getDSError(this.httpCode)(this.errorCode, this.data);
        return {
            name: error.defaultMessage || "",
            message: error.defaultMessage || "",
            details: error.defaultMessage || "",
            metadata: meta,
            code: error.grpcStatus || GrpcStatus.UNKNOWN,
        };
    }
}
exports.DSBaseError = DSBaseError;
DSBaseError.ERROR_CODE_KEY = "error_code";
DSBaseError.HTTP_ERROR_CODE_KEY = "http_error_code";
function mkException(httpCode, grpcStatus, defaultMessage) {
    const builder = (code, data) => new DSBaseError(httpCode, grpcStatus, defaultMessage, code, data);
    const refinedBuilder = (code, data) => builder(code, data);
    return [builder, refinedBuilder];
}
const [DSInternalError, DSInternalException] = mkException(HttpStatusCode.Internal, GrpcStatus.INTERNAL, "Internal Error");
exports.DSInternalException = DSInternalException;
const [DSInvalidArgumentError, DSInvalidArgumentException] = mkException(HttpStatusCode.InvalidArgument, GrpcStatus.INVALID_ARGUMENT, "Invalid Argument");
exports.DSInvalidArgumentException = DSInvalidArgumentException;
const [DSNotFoundError, DSNotFoundException] = mkException(HttpStatusCode.NotFound, GrpcStatus.NOT_FOUND, "Not Found");
exports.DSNotFoundException = DSNotFoundException;
const [DSPermissionDeniedError, DSPermissionDeniedException] = mkException(HttpStatusCode.PermissionDenied, GrpcStatus.PERMISSION_DENIED, "Permission Denied");
exports.DSPermissionDeniedException = DSPermissionDeniedException;
const [DSUnauthenticatedError, DSUnauthenticatedException] = mkException(HttpStatusCode.Unauthenticated, GrpcStatus.UNAUTHENTICATED, "Not Authenticated");
exports.DSUnauthenticatedException = DSUnauthenticatedException;
const httpCodeToExceptionMap = {
    [HttpStatusCode.InvalidArgument]: DSInvalidArgumentError,
    [HttpStatusCode.Unauthenticated]: DSUnauthenticatedError,
    [HttpStatusCode.PermissionDenied]: DSPermissionDeniedError,
    [HttpStatusCode.NotFound]: DSNotFoundError,
    [HttpStatusCode.Internal]: DSInternalError,
};
