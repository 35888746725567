import { Button, CircularProgress, DialogProps } from "@material-ui/core";
import { useState } from "react";
import { useScopedTranslation } from "../../i18n";

import { Modal } from "../../components/Modal";
import { UpdateUserExtended } from "@unissey/common";
import UpdateTeamMemberForm, { ExceptionEditStatus } from "./UpdateTeamMemberForm";
import { addPersonalDataAuthException, removePersonalDataAuthException, updateUser } from "../../services/api_services";
import { useEffect } from "react";
import { toJS } from "mobx";
import { useNotif } from "../../contexts/notif_context";
import { flatObjectHaveEmptyField, isFlatObjectEqual } from "../../utils/compare_utils";

type Props = DialogProps & {
  onClose: () => void;
  onEdit: () => void;
  userToEdit: Required<UpdateUserExtended>;
  targetWorkspaceId?: string;
  parentWorkspaceId?: string;
};

export default function UpdateTeamMemberModal({
  open,
  onClose,
  onEdit,
  userToEdit,
  targetWorkspaceId,
  parentWorkspaceId,
}: Props) {
  const { t } = useScopedTranslation("workspace_page.update_member");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userToUpdate, setUserToUpdate] = useState<Required<UpdateUserExtended>>(userToEdit);
  const [personalDataException, setPersonalDataException] = useState<ExceptionEditStatus>("none");

  const notif = useNotif();

  useEffect(() => {
    setUserToUpdate(userToEdit);
  }, [userToEdit]);

  const onUpdate = async () => {
    setIsLoading(true);

    const { ...u } = toJS(userToUpdate);

    const tasks: (() => Promise<unknown | undefined>)[] = [
      () => updateUser(u, u.id),
      //() => updateRoles(u.roleKey, u.id),
    ];

    if (personalDataException === "add") {
      tasks.push(() => addPersonalDataAuthException(u.id));
    } else if (personalDataException === "delete") {
      tasks.push(() => removePersonalDataAuthException(u.id));
    }

    let editionsSucceeded = true;
    for (const task of tasks) {
      const result = await task();

      // Note (Guillaume): When an api call returns undefined it should mean
      // that it failed
      // This is a very dubious design but that's what we have at to work with
      // at the moment…
      if (result === undefined) {
        editionsSucceeded = false;
        break;
      }
    }

    onEdit();

    if (editionsSucceeded) notif.trigger?.({ textKey: "success.user_edited" });

    setIsLoading(false);
  };

  const validateForm = () => {
    const compareWorkspaceKeys = ["firstName", "lastName", "email", "roleKey"];
    const isValidForm =
      isFlatObjectEqual(userToUpdate, userToEdit, compareWorkspaceKeys) ||
      !flatObjectHaveEmptyField(userToUpdate, compareWorkspaceKeys);

    return isValidForm && personalDataException === "none";
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      maxWidth="md"
      body={
        <UpdateTeamMemberForm
          userToEdit={userToUpdate}
          targetWorkspaceId={targetWorkspaceId}
          parentWorkspaceId={parentWorkspaceId}
          onFieldChanged={(field, value) => setUserToUpdate({ ...userToUpdate, [field]: value })}
          onPersonalDataChange={(v) => setPersonalDataException(v)}
        />
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={onUpdate}
          startIcon={isLoading && <CircularProgress size={14} />}
          disabled={isLoading || validateForm()}
          fullWidth
          data-testid="edit-team-member-form-btn"
        >
          {t("save")}
        </Button>
      }
    />
  );
}
