import { Button, Collapse, Grid, List, ListItem, makeStyles, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { ReadWorkspace } from "@unissey/common";

type Props = {
  workspace: ReadWorkspace & { expand: boolean };
  isMyWorkspace: boolean;
  isExpandable: boolean | undefined;
  handleClick: (workspace: ReadWorkspace & { expand: boolean }) => void;
  children: JSX.Element;
};

const useStyles = makeStyles({
  actionBtn: {
    textTransform: "none",
  },
  createBtn: {
    background: "#05DB91",
    color: "#FFFFFF",
    fontWeight: "bold",
    textTransform: "none",
  },
});

function ListWrapper({ workspace, isMyWorkspace, isExpandable, handleClick, children }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const wsName = (
    <Typography className={classes.actionBtn}>
      {workspace.name}{" "}
      <b className={classes.actionBtn}>
        {isMyWorkspace ? `(${t("workspace_page.own_workspace").toLowerCase()})` : null}
      </b>
    </Typography>
  );

  return (
    <List disablePadding={true}>
      <Grid container>
        <Grid item>
          <ListItem>
            {isExpandable ? (
              <Button
                variant="text"
                endIcon={workspace.expand ? <ExpandLess /> : <ExpandMore />}
                disableElevation
                onClick={() => {
                  handleClick(workspace);
                }}
              >
                {wsName}
              </Button>
            ) : (
              wsName
            )}
          </ListItem>
        </Grid>
      </Grid>
      <Collapse
        style={{ paddingLeft: "20px", paddingRight: "20px", width: "100%" }}
        in={workspace.expand}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding={true}>
          {children}
        </List>
      </Collapse>
    </List>
  );
}

export default ListWrapper;
