import { Box, Button, CircularProgress, DialogProps, FormGroup, Grid } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { Modal } from "../../components/Modal";
import { CreateApplication, IadPolicy, SecurityLevel, Subscription } from "@unissey/common";
import { has } from "../../types/utils";
import {
  createApplication,
  getIdentityRegistries,
  getSubscriptionsByWorkspaceId,
  getWorkspaces,
} from "../../services/api_services";
import CustomTextField from "../../components/forms/formComponents/CustomTextField";
import CustomSelect, { MenuItemsType } from "../../components/forms/formComponents/CustomSelect";

import { useScopedTranslation } from "../../i18n";
import { useNotif } from "../../contexts/notif_context";
import { flatObjectHaveEmptyField } from "../../utils/compare_utils";
import { useAuth } from "../../auth";
import { useQuery, useQueryClient } from "react-query";

type Props = DialogProps & {
  onClose: () => void;
};

export default function CreateApiKeyModal({ open, onClose }: Props) {
  const { t } = useScopedTranslation("api_page.create_dialog");

  const auth = useAuth();
  const notif = useNotif();

  const queryClient = useQueryClient();

  const iadPolicies: MenuItemsType = [
    ["disabled", t(`key_disabled`)],
    ["passive", t(`key_enabled`)],
  ];
  const securityLevels: MenuItemsType = [
    ["LEVEL_1", t(`key_level_1`)],
    ["LEVEL_2", t(`key_level_2`)],
    ["LEVEL_3", t(`key_level_3`)],
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workspaces, setWorkspaces] = useState<MenuItemsType>([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [canUseIad, setCanUseIad] = useState<boolean>(false);
  const [apiKeyForm, setApiKeyForm] = useState<CreateApplication>({
    name: "",
    referers: [],
    subscriptionId: 0,
    workspaceId: "",
    padPolicy: "enabled",
    iadPolicy: "disabled",
    livenessThreshold: "LEVEL_2",
    matchingThreshold: "LEVEL_2",
  });
  const [lastFormState, setLastFormState] = useState<{
    workspaceId: string | undefined;
    subscriptionId: number | undefined;
  }>({
    subscriptionId: undefined,
    workspaceId: undefined,
  });

  const { data: identityRegistries } = useQuery(["identity-registries"], () => getIdentityRegistries());

  const canEditSecurityLevels = auth.hasOneOfRoles(["super_administrator", "internal_tech_user"]);

  const canListWorkspaces = auth.hasRoles(["workspace.list"]);
  const subscriptionMenuItems = useMemo(
    () => (subscriptions.map((sub) => [sub.id, sub.name]) as MenuItemsType) ?? [],
    [subscriptions]
  );

  const validateForm = () => {
    const compareApiKeys = ["name", "subscriptionId", "workspaceId"];
    const isFormCompleted = flatObjectHaveEmptyField(apiKeyForm, compareApiKeys);

    return !isFormCompleted || subscriptions.length === 0;
  };

  const selectedSubscription = subscriptions.find((sub) => sub.id === apiKeyForm.subscriptionId);

  const availableIdentityRegistries =
    selectedSubscription?.featureKind === "one_to_many"
      ? identityRegistries?.filter((registry) => registry.workspace.id === apiKeyForm.workspaceId)
      : [];

  useEffect(() => {
    (async () => {
      if (canListWorkspaces) {
        const ws = await getWorkspaces();
        setWorkspaces((ws?.map((w) => [w.id, w.name]) as MenuItemsType) ?? []);
      } else {
        setApiKeyForm((form) => ({
          ...form,
          workspaceId: auth.user!.workspace.id,
        }));
        setWorkspaces([[auth.user!.workspace.id, auth.user!.workspace.name]]);
      }
      const subs = await getSubscriptionsByWorkspaceId(apiKeyForm.workspaceId, "free_demo", true);
      setSubscriptions(subs);
    })();
  }, [canListWorkspaces, auth.user, apiKeyForm.workspaceId]);

  async function createApiKey() {
    setIsLoading(true);

    if (!has(apiKeyForm, "subscriptionId") || !has(apiKeyForm, "name") || !has(apiKeyForm, "workspaceId")) {
      notif.trigger?.({
        textKey: "error.missing_api_key_fields",
      });
      setIsLoading(false);
      return;
    }
    if (await createApplication(apiKeyForm)) {
      notif.trigger?.({
        textKey: "success.api_key_created",
        textParams: { applicationName: apiKeyForm.name },
      });
      queryClient.invalidateQueries(`applications-${apiKeyForm.workspaceId}`);
    }
    onClose();
    setIsLoading(false);
  }

  useEffect(() => {
    const sub = subscriptions?.find((sub) => sub.id === apiKeyForm.subscriptionId);
    if (sub) {
      setCanUseIad(apiKeyForm.subscriptionId === lastFormState.subscriptionId && sub?.canUseIad);
    } else {
      setCanUseIad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions]);

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      maxWidth="md"
      data-testid="apikey-modal"
      body={
        <FormGroup>
          <Box py={2}>
            <Grid container spacing={2}>
              <CustomTextField
                autoFocus
                name="applicationName"
                label={t("field_application_name")}
                width={12}
                value={apiKeyForm.name}
                data-testid="apikey-name"
                onChange={(e) => setApiKeyForm((prev) => ({ ...prev, name: e.target.value }))}
                onBlur={(e) => setApiKeyForm((prev) => ({ ...prev, name: e.target.value.trim() }))}
              />
              {/* </Grid> */}
              {/* <Grid container spacing={2}> */}
              <CustomSelect
                width={6}
                labelId="create-application-form-select-workspace"
                labelValue={t("field_workspace")}
                disabled={!canListWorkspaces}
                menuItems={workspaces}
                data-testid="apikey-workspace"
                onChange={(e) => {
                  setLastFormState((prev) => ({
                    ...prev,
                    workspaceId: apiKeyForm.workspaceId,
                    registryId: undefined, // the selection of workspace reset selected registry
                  }));
                  setApiKeyForm((prev) => ({
                    ...prev,
                    registryId: undefined,
                    workspaceId: e.target.value as string,
                    subscriptionId: prev.subscriptionId === lastFormState.subscriptionId ? prev.subscriptionId : -1,
                    iadPolicy: "disabled",
                  }));
                }}
                value={apiKeyForm.workspaceId}
              />
              <CustomSelect
                width={6}
                labelId="create-application-form-select-environment"
                labelValue={t("field_subscription")}
                menuItems={subscriptionMenuItems}
                data-testid="apikey-subscription"
                onChange={(e) => {
                  let subscriptionId = e.target.value as number;
                  let canUseIad = subscriptions.find((sub) => sub.id === subscriptionId)!.canUseIad;

                  setLastFormState((prev) => ({
                    ...prev,
                    subscriptionId,
                    registryId: undefined,
                  }));

                  setApiKeyForm((prev) => ({
                    ...prev,
                    subscriptionId,
                    registryId: undefined,
                    iadPolicy: canUseIad ? apiKeyForm.iadPolicy : "disabled",
                  }));
                  setCanUseIad(canUseIad);
                }}
                value={apiKeyForm.subscriptionId}
                disabled={subscriptions.length === 0}
              />
              {availableIdentityRegistries && availableIdentityRegistries.length > 0 ? (
                <CustomSelect
                  width={6}
                  labelId="create-application-form-select-registry"
                  labelValue={t("field_identity_registry")}
                  data-testid="apikey-registry"
                  menuItems={availableIdentityRegistries.map((registry) => [registry.id, registry.name])}
                  onChange={(e) => {
                    let registryId = e.target.value as string;
                    setApiKeyForm((prev) => ({ ...prev, registryId }));
                  }}
                  value={apiKeyForm.registryId}
                />
              ) : (
                <></>
              )}
              {canUseIad && (
                <CustomSelect
                  width={6}
                  labelId="create-application-form-select-iad-policy"
                  labelValue={t("field_iad_policy")}
                  menuItems={iadPolicies}
                  onChange={(e) =>
                    setApiKeyForm((prev) => ({
                      ...prev,
                      iadPolicy: e.target.value as IadPolicy,
                    }))
                  }
                  value={apiKeyForm.iadPolicy}
                  disabled={!canUseIad}
                />
              )}
              {canEditSecurityLevels && apiKeyForm.subscriptionId > 0 && (
                <CustomSelect
                  width={6}
                  labelId="create-application-form-select-liveness-threshold"
                  labelValue={t("field_liveness_threshold")}
                  data-testid="apikey-liveness-threshold"
                  menuItems={securityLevels}
                  onChange={(e) => {
                    let livenessThreshold = e.target.value as SecurityLevel;
                    setApiKeyForm((prev) => ({ ...prev, livenessThreshold }));
                  }}
                  value={apiKeyForm.livenessThreshold}
                />
              )}
              {canEditSecurityLevels &&
                apiKeyForm.subscriptionId > 0 &&
                selectedSubscription?.featureKind === "one_one" && (
                  <CustomSelect
                    width={6}
                    labelId="create-application-form-select-matching-threshold"
                    labelValue={t("field_matching_threshold")}
                    data-testid="apikey-matching-threshold"
                    menuItems={securityLevels}
                    onChange={(e) => {
                      let matchingThreshold = e.target.value as SecurityLevel;
                      setApiKeyForm((prev) => ({ ...prev, matchingThreshold }));
                    }}
                    value={apiKeyForm.matchingThreshold}
                  />
                )}
            </Grid>
          </Box>
        </FormGroup>
      }
      action={
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={createApiKey}
          startIcon={isLoading && <CircularProgress size={14} />}
          fullWidth
          data-testid="apikey-create-btn"
          disabled={isLoading || validateForm()}
        >
          {t("button_create")}
        </Button>
      }
    />
  );
}
