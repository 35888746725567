import { IconButton, makeStyles } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import CustomTable from "../../components/CustomTable";
import OverflowTooltip from "../../components/OverflowTooltip";
import TitledSection from "../../components/TitledSection";

import { useScopedTranslation } from "../../i18n";

import { config } from "../../constants/env";
import { useAuth } from "../../auth";

type LinkData = {
  name: string;
  link: string;
  previousVersionNotice?: boolean;
};

const DATA: LinkData[] = [
  {
    name: "API V3",
    link: config.apiAnalyzeDocUrl,
  },
  {
    name: "SDK Web - JavaScript",
    link: "https://hackmd.io/@unissey/SJLztxCYt",
  },
  {
    name: "SDK Web - Web components",
    link: "https://v3-unissey-web-components.netlify.app",
  },
  {
    name: "SDK Web - React",
    link: "https://v3-unissey-sdk-react.netlify.app/",
  },
  {
    name: "SDK Web - Angular",
    link: "https://v3-unissey-sdk-angular.netlify.app/",
  },
  {
    name: "SDK Mobile - iOS",
    link: "https://github.com/unissey/sdk-ios/blob/master/README.md ",
  },
  {
    name: "SDK Mobile - Android",
    link: "https://github.com/unissey/sdk-android/blob/master/README.md",
  },
];

const useStyles = makeStyles({
  notice: {
    fontSize: "small",
  },
});

export default function DocumentationPage() {
  const auth = useAuth();
  const { t } = useScopedTranslation("documentation_page");
  const classes = useStyles();

  const headers = [t("table.header_type"), t("table.header_link")];

  const tableRows = DATA.map(({ name, link, previousVersionNotice }) => [
    <div>
      <OverflowTooltip text={name} />
      {previousVersionNotice && <span className={classes.notice}>{t("previous_sdk_version_notice")}</span>}
    </div>,

    <a href={name === "API V3" && auth.user.canViewAgeFeature ? `${link}/age` : link} target="_blank" rel="noreferrer">
      <IconButton>
        <OpenInNewIcon />
      </IconButton>
    </a>,
  ]);

  return (
    <TitledSection
      child={<CustomTable rows={tableRows} heads={headers} widths={["50%", "50%"]} setMinHeight />}
    ></TitledSection>
  );
}
