"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RndConfidence = exports.ConfidenceLevel = exports.MEDIA_TYPES = exports.PROCESS_TYPES = void 0;
exports.PROCESS_TYPES = [
    "cardiac",
    "face_matching",
    "conductor",
    "pad",
    "weak_face_matching",
    "face_quality",
    "age_estimation",
    "age_verification",
    "weak_age_estimation",
    "weak_age_verification",
];
exports.MEDIA_TYPES = ["audio", "video", "image"];
var ConfidenceLevel;
(function (ConfidenceLevel) {
    ConfidenceLevel[ConfidenceLevel["CONFIDENCE_LOW_0"] = 0] = "CONFIDENCE_LOW_0";
    ConfidenceLevel[ConfidenceLevel["CONFIDENCE_HIGH_0"] = 1] = "CONFIDENCE_HIGH_0";
    ConfidenceLevel[ConfidenceLevel["UNKNOWN"] = -1] = "UNKNOWN";
})(ConfidenceLevel = exports.ConfidenceLevel || (exports.ConfidenceLevel = {}));
var RndConfidence;
(function (RndConfidence) {
    RndConfidence[RndConfidence["LOW"] = 0] = "LOW";
    RndConfidence[RndConfidence["HIGH"] = 1] = "HIGH";
})(RndConfidence = exports.RndConfidence || (exports.RndConfidence = {}));
