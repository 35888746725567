import { useState } from "react";

import { Box, makeStyles, Popover, Theme, Typography } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

import { useScopedTranslation } from "../../i18n";
import { Trans } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: "#D0D0D0",
  },
  icon: {
    position: "relative",
    top: "0.2em",
    marginLeft: "4px",
  },

  container: {
    maxWidth: "960px",
    padding: "3em",
  },

  header: {
    color: "#000000",
    fontWeight: 700,
    fontSize: "14px",
  },

  paragraph: {
    fontSize: "14px",
    marginTop: "0.1em",
    marginBottom: "1em",
  },

  list: {
    margin: 0,
  },
}));

export const InjectionInstructionsPopover = () => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <span
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handleOpen}
        onMouseLeave={handleClose}
        style={{ cursor: "pointer" }}
      >
        <HelpIcon className={classes.icon} htmlColor="#C4C4C4" />
      </span>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleClose}
        disableRestoreFocus
      >
        <PopoverContent />
      </Popover>
    </>
  );
};

const PopoverContent = () => {
  const { t } = useScopedTranslation("demo_page.injection_instructions.content");

  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.header}>{t("what_is_injection_header")}</Typography>
      <Typography className={classes.paragraph}>{t("what_is_injection_text")}</Typography>

      <Typography className={classes.header}>{t("measures_header")}</Typography>
      <Typography className={classes.paragraph}>
        <Trans i18nKey="demo_page.injection_instructions.content.measures_text_p1" components={[<></>]} />
      </Typography>
      <Typography className={classes.paragraph}>{t("measures_text_p2")}</Typography>

      <Typography className={classes.header}>{t("prevention_header")}</Typography>
      <Typography component={"span"} className={classes.paragraph}>
        <Typography className={classes.paragraph}>
          <span>{t("prevention_text_p1")}</span>
          <ul className={classes.list}>
            <li>
              <Trans i18nKey="demo_page.injection_instructions.content.prevention_bullet1" components={[<></>]} />
            </li>
            <li>
              <Trans i18nKey="demo_page.injection_instructions.content.prevention_bullet2" components={[<></>]} />
            </li>
          </ul>
        </Typography>
        <Typography className={classes.paragraph}>
          <span className={classes.paragraph}>{t("prevention_text_p2")}</span>
          <span className={classes.paragraph}>{t("prevention_text_p3")}</span>
          <ul className={classes.list}>
            <li>{t("prevention_bullet3")}</li>
            <li>{t("prevention_bullet4")}</li>
          </ul>
        </Typography>

        <Typography className={classes.paragraph}>{t("prevention_text_p4")}</Typography>
      </Typography>
    </Box>
  );
};
