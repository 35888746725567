import { useState } from "react";
import { Link, Box, Button, CircularProgress, DialogProps, Grid } from "@material-ui/core";

import CreateTemporaryAccessForm from "./CreateTemporaryAccessForm";
import { Modal } from "../../components/Modal";
import theme from "../../config/theme";

import { TemporaryUserFormData } from "@unissey/common";
import { createTemporaryUser, sendTemporaryUserInvitation } from "../../services/api_services";

import { Trans } from "react-i18next";
import { useScopedTranslation } from "../../i18n";
import { useNotif } from "../../contexts/notif_context";

type Props = DialogProps & {
  onClose: () => void;
};

const EMPTY_USER: TemporaryUserFormData = {
  email: "",
  firstName: "",
  lastName: "",
};

export default function CreateTemporaryAccessModal({ open, onClose }: Props) {
  const { t, tCommon } = useScopedTranslation("workspace_page.create_temp_access_dialog");

  const notif = useNotif();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userCreated, setUserCreated] = useState<{ temporaryUserId: string }>();
  const [temporaryUser, setTemporaryUser] = useState<TemporaryUserFormData>(EMPTY_USER);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const onCreate = async () => {
    setIsLoading(true);
    const response = await createTemporaryUser(temporaryUser);
    if (response !== undefined) {
      const { id: temporaryUserId } = response;
      setUserCreated({ temporaryUserId });
      // Note (Guillaume): Keep some trace of the created user in case we misclick or something
      console.info(`[TEMP DEMO ACCESS] created user with id: ${temporaryUserId}`);
    } else {
      onClose();
    }
    setIsLoading(false);
  };

  const onFinish = () => {
    onClose();
    setUserCreated(undefined);
    setTemporaryUser(EMPTY_USER);
    setEmailSent(false);
  };

  return (
    <Modal
      open={open}
      onCancel={onClose}
      onClose={onClose}
      title={t("title")}
      hideCancel={true}
      maxWidth="md"
      body={
        userCreated ? (
          <>
            <Box>
              <Trans
                i18nKey="workspace_page.create_temp_access_dialog.notice_created_and_email_sent"
                components={[<strong>{{ email: temporaryUser.email }}</strong>]}
              />
              <p>{t("notice_interact_when_activated", { minutes: 15 })}</p>
            </Box>
            <Box>
              <p>{t("notice_send_procedure")}</p>
            </Box>
            <Box>
              <Link
                onClick={() =>
                  navigator.clipboard
                    .writeText(transientDemoLink(userCreated.temporaryUserId))
                    .then(() => notif.trigger?.({ textKey: "success.link_copied_to_clipboard" }))
                }
                style={{ cursor: "pointer", fontWeight: 700, color: theme.palette.primary.light }}
                data-testid="tmp-demo-link"
              >
                {transientDemoLink(userCreated.temporaryUserId)}
              </Link>
            </Box>
          </>
        ) : (
          <CreateTemporaryAccessForm
            temporaryUser={temporaryUser}
            onFieldChanged={(field, value) => setTemporaryUser({ ...temporaryUser, [field]: value })}
          />
        )
      }
      action={
        <>
          <Grid container spacing={2}>
            <Grid item xs={!userCreated ? 6 : emailSent ? 4 : 3}>
              <Button variant="outlined" disableElevation onClick={() => onClose()} fullWidth>
                {tCommon("button_cancel")}
              </Button>
            </Grid>
            {userCreated ? (
              <>
                <Grid item xs={emailSent ? 4 : 3}>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    fullWidth
                    onClick={() =>
                      navigator.clipboard
                        .writeText(transientDemoLink(userCreated.temporaryUserId))
                        .then(() => notif.trigger?.({ textKey: "success.link_copied_to_clipboard" }))
                    }
                  >
                    {t("button_copy_link")}
                  </Button>
                </Grid>

                {!emailSent ? (
                  <>
                    <Grid item xs={emailSent ? 4 : 3}>
                      <Button
                        variant="contained"
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={async () => {
                          await sendTemporaryUserInvitation(
                            userCreated.temporaryUserId,
                            temporaryUser.firstName,
                            temporaryUser.lastName
                          );
                          notif.trigger?.({
                            textKey: "success.transient_demo_email_sent",
                            textParams: { email: temporaryUser.email },
                          });
                          setEmailSent(true);
                        }}
                        disabled={!!emailSent}
                      >
                        {t("button_send_email")}
                      </Button>
                    </Grid>
                  </>
                ) : undefined}
                <Grid item xs={emailSent ? 4 : 3}>
                  <Button variant="contained" color="primary" disableElevation onClick={onFinish} fullWidth>
                    {t("button_finish")}
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={!userCreated ? 6 : emailSent ? 4 : 3}>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={onCreate}
                    startIcon={isLoading && <CircularProgress size={14} />}
                    disabled={isLoading || !validateFormData(temporaryUser)}
                    fullWidth
                    data-testid="create-tmp-access-form-btn"
                  >
                    {t("button_create")}
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </>
      }
    />
  );
}

function validateFormData(data: TemporaryUserFormData): boolean {
  const nonEmptyFirstName = data.firstName.length > 0;
  const nonEmptyLastName = data.lastName.length > 0;
  const validIshEmail = /\S+@\S+\.\S+/.test(data.email);

  return nonEmptyFirstName && nonEmptyLastName && validIshEmail;
}

function transientDemoLink(temporaryUserId: string): string {
  const url = new URL("/transient-demo", window.location.origin);
  url.searchParams.append("user_id", temporaryUserId);
  return url.toString();
}
