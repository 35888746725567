import { IdentityResult } from "@unissey/common";
import theme from "../../config/theme";
import { useScopedTranslation } from "../../i18n";
import { Chip } from "@material-ui/core";

type ChipProps = {
  result?: IdentityResult;
};

export function IdentityResultChip({ result }: ChipProps) {
  const { tCommon } = useScopedTranslation("identity_sessions_page");

  const colorFail = "#3d59e8";
  const colorSuccess = theme.palette.success.light;
  const colorConflict = "#EC9A39";

  const borderColor = result === "success" ? colorSuccess : result === "fail" ? colorFail : colorConflict;

  const style = { width: "150px", fontSize: "0.9rem", borderColor: result ? borderColor : undefined };

  return <Chip variant="outlined" style={style} label={result ? tCommon(`identity_result.${result}`) : "N/A"} />;
}
